import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { CartContext } from "../../../../contexts/cartContext";

export default function UserDashDemyNav() {
  const { user } = useContext(CartContext);
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  return (
    <div className="dashboardNavigator d-flex flex-wrap my-2">
      <NavLink
        to={`/${window.lang}/dashboard/user/demy/registertutor`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        ثبت‌نام{" "}
      </NavLink>
      {/* <NavLink to={`/${window.lang}/dashboard/user/demy/listrequests`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}> لیست ایمیل ها</NavLink> */}
      <NavLink
        to={`/${window.lang}/dashboard/user/demy/listmysessions`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        دانشجو <span className="small-gray-font font-10 my-2">(کلاس‌ها)</span>
      </NavLink>
      {user?.roles?.includes(1505) && (
        <>
          <NavLink
            to={`/${window.lang}/dashboard/user/demy/listsessions`}
            className={`sidebarListItem m-1 link  ${({ isActive }) => {
              return isActive && "active";
            }}`}
          >
            استاد{" "}
            <span className="small-gray-font font-10 my-2">(کلاس‌ها)</span>
          </NavLink>
          <NavLink
            to={`/${window.lang}/dashboard/user/demy/myTutorlist`}
            className={`sidebarListItem m-1 link  ${({ isActive }) => {
              return isActive && "active";
            }}`}
          >
            پروفایل‌های{" "}
            <span className="small-gray-font font-10 my-2">(تدریس)</span>
          </NavLink>
          <NavLink
            to={`/${window.lang}/dashboard/user/demy/tutortranslist`}
            className={`sidebarListItem m-1 link  ${({ isActive }) => {
              return isActive && "active";
            }}`}
          >
            گزارش <span className="small-gray-font font-10 my-2">(مالی)</span>
          </NavLink>
        </>
      )}
      {showEdit === "edit" && (
        <NavLink
          to={`/${window.lang}/dashboard/user/demy/edittutor`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
          ویرایش
        </NavLink>
      )}
    </div>
  );
}
