import React, { useContext, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../textEditor/EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "../textEditor/TextEditor.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Publish, CheckCircle, Error, WarningAmber } from "@mui/icons-material";
// import Resizer from "react-image-file-resizer";

import SearchBar from "./searchBar";
import { CartContext } from "../../../../../contexts/cartContext";
import apiBlog from "../../../../../../utils/apiBlog";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import Tooltip1 from "../../../../../golbal/tooltip/tooltip";
import ToastNote from "../../../../../golbal/notifications/toastNote";
// import DropdownSearch from "../../../../../golbal/dropdown/search";
// import countryStates from "../../../../../../utils/countryStates";
// import SearchMultiSelectFilters from "../../../../../golbal/dropdown/searchMultiSelectFilters";
import api from "../../../../../../utils/api";
import FormikErrRender from "../../../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../../../persian/common/alerts/addErrClass";
import tools from "../../../../../../utils/tools";
import CatsSelector from "../../../../../golbal/catsSelector";
// import DropdownSearch from "../../../../../golbal/dropdown/DropdownSearch";
import MetaDecorator from "../../../../../../utils/metaDecorator";
import CountryStateCitySelector from "../../../../../golbal/dropdown/countryStateCitySelector";

export default function PostRegister() {
  const { id } = useParams();
  const navigate = useNavigate();

  // console.log(id);
  const { user } = useContext(CartContext);
  const messageRef = useRef(null);
  const [loading, setLoading] = useState({
    submit: false,
    img: false,
  });
  // const [states, setStates] = useState([]);

  const sendPhotoFirebase = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " از فایل با فرمت عکس استفاده کنید.",
      });
    // let file = e.target.files[0];
    const file = await tools.resizeFile({
      file: e.target.files[0],
      blob: true,
    });
    // console.log("final file", file.size);
    // console.log("final file02", file);
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    setLoading({ ...loading, img: true });
    console.log("formData", formData);
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/blog",
    });
    setLoading({ ...loading, img: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });
    formik.setValues((prev) => ({
      ...prev,
      img: response.data,
    }));
  };

  const descriptionHandler = (value) => {
    formik.setValues((pre) => ({ ...pre, description: value }));
    // console.log("post description handler", value);
  };
  function isExternalLink(value) {
    let isExternalLink = false;
    const htmlString = value;
    let startIndex = 0;
    while ((startIndex = htmlString.indexOf("<a ", startIndex)) !== -1) {
      const endIndex = htmlString.indexOf("</a>", startIndex);
      const anchorTag = htmlString.slice(startIndex, endIndex + 4); // Include the '</a>' tag
      startIndex = endIndex + 4; // Move the startIndex to the end of the current anchor tag

      // Check if the link is internal
      const hrefStartIndex = anchorTag.indexOf('href="') + 'href="'.length;
      const hrefEndIndex = anchorTag.indexOf('"', hrefStartIndex);
      const href = anchorTag.slice(hrefStartIndex, hrefEndIndex);
      const parsedUrl = new URL(href, window.location.origin);
      console.log("parsedUrl", parsedUrl);
      if (parsedUrl.origin !== "https://www.metamarce.com")
        isExternalLink = true;
    }
    return isExternalLink;
  }

  const addPost = async () => {
    console.log("addPost", formik.values);
    if (
      !tools.compareCheck(user.roles, [1000, 1100]) &&
      isExternalLink(formik.values.description)
    )
      return window.alert(
        ' از لینک های داخل "https://www.metamarce.com" استفاده کنید.'
      );
    setLoading((pre) => ({ ...pre, submit: true }));
    const response = await apiBlog.addPost({
      postInfo: formik.values,
      token: user.token,
    });
    setLoading((pre) => ({ ...pre, submit: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "پست با موفقیت به ثبت شد",
    });
    localStorage.removeItem("postInfo");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(
      () =>
        navigate(`/${window.lang}/blog/post/${response.data._id}`, {
          replace: true,
        }),
      100
    );
  };

  const editPost = async () => {
    setLoading((pre) => ({ ...pre, submit: true }));
    const response = await apiBlog.editPost({
      postInfo: formik.values,
      token: user.token,
      id,
    });
    setLoading((pre) => ({ ...pre, submit: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "پست با موفقیت به روز رسانی شد",
    });
    localStorage.removeItem("postInfo");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(
      () =>
        navigate(`/${window.lang}/blog/post/${tools.slugMaker({title:response.data.title,city:response.data?.moreInfo?.address?.city,state:response.data?.moreInfo?.address?.state,serial:response.data?.serial})}`, {
          replace: true,
        }),
      100
    );
  };

  const formik = useFormik({
    initialValues: {
      lang: window.lang,
      lcs: window.lang,
      _id: "",
      title: "",
      description: "",
      coverSummary: "",
      tags: "",
      category: {
        mainCat: "",
        subCat: "",
      },
      img: "",
      references: [],
      author: {
        authorName: "",
        email: "",
        _id: "",
      },
      location: {
        country: "",
        countryShort: "",
        state: "",
        stateShort: "",
        city: "",
        cities: [],
      },
    },
    onSubmit: async (value) => {
      if (!formik.values._id.length) return addPost();
      if (formik.values._id.length) return editPost();
      alert("submited");
    },
    validationSchema: Yup.object({
      category: Yup.object().shape({
        mainCat: Yup.object().shape({
          _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
        }),
        subCat: Yup.object().shape({
          _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
        }),
      }),
      title: Yup.string()
        .required(" عنوان پست را وارد کنید.")
        .min(10, "عنوان پست حداقل باید 10 حرف باشد.")
        .max(80, "عنوان پست حداکثر باید 80 حرف باشد.")
        .trim(),
      coverSummary: Yup.string()
        .required(" متن معرفی مطلب را وارد کنید.")
        .min(50, "معرفی مطلب حداقل باید 50 حرف باشد.")
        .max(200, "معرفی مطلب حداکثر باید 200 حرف باشد.")
        .trim(),
      description: Yup.string()
        .required(" متن پست را وارد کنید.")
        .min(150, "متن پست حداقل باید 150 حرف باشد.")
        .trim(),
      // tags: Yup.string().test(
      //   "array-length",
      //   "حداقل باید یک برچسب انتخاب کنید.",
      //   (value) => value.length > 0
      // ),
      img: Yup.string().required(" عکس روی جلد را انتخاب کنید.").trim(),
    }),
  });

  useEffect(() => {
    if (id) {
      fetchDetails();
    } else {
      const storedPostInfo = JSON.parse(localStorage.getItem("postInfo"));
      if (storedPostInfo !== null) {
        // const {description ,...storedPostInfo} = storedPostInfo
        const { description, ...otherPostInfo } = storedPostInfo;
        console.log("description : ", description, otherPostInfo);
        formik.setValues(otherPostInfo);
        setTimeout(
          () =>
            formik.setValues((pre) => ({
              ...pre,
              description: description || "",
            })),
          100
        );
      }
    }
  }, []);

  async function fetchDetails() {
    const response = await apiBlog.getPostDetails(id);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    primaryValues({ postInfo: response.data });
  }

  function primaryValues({ postInfo }) {
    // console.log(postInfo);

    formik.setValues({
      _id: postInfo._id || "",
      title: postInfo.title || "",
      coverSummary: postInfo.coverSummary || "",
      tags: postInfo?.tags.join("+") || "",
      category: {
        mainCat: postInfo?.category?.mainCat || {},
        subCat: postInfo?.category?.subCat || {},
      },
      img: postInfo?.img,
      references: [],
      location: postInfo?.address,
      author: {
        authorName: postInfo?.author?.authorName || "",
        email: postInfo?.author?.email || "",
        _id: postInfo?.author?._id || "",
      },
    });
    setTimeout(
      () =>
        formik.setValues((pre) => ({
          ...pre,
          description: postInfo.description || "",
        })),
      100
    );
    // formik.setValues(pre=>({...pre,description:postInfo.description  || "",}))
  }

  const structuredData = [
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: " ایجاد پست",
      description: " ثبت پست در متامارس، سریع و آسان و رایگان ",

      url: "https://www.metamarce.com/fa/dashboard/user/blog/register",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/fa/dashboard/user/blog/register`,
              name: " مجله متامارس | ثبت پست در مجله متامارس ",
              description: " ثبت پست در متامارس، سریع و آسان و رایگان ",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: " مجله متامارس | ثبت پست در مجله متامارس ",
    description: " ثبت پست در متامارس، سریع و آسان و رایگان ",
    section: " پست جدید",
    url: `https://www.metamarce.com/fa/dashboard/user/blog/register`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
    index: false,
  };

  return (
    <div className="AppContainer ">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />

      <ToastNote messageRef={messageRef} />
      <div className="container px-md-5">
        {tools.compareCheck(user.roles, [1000, 1105, 1100]) ? (
          <div className="row">
            <form
              onSubmit={formik.handleSubmit}
              className="update__forms"
              name="postRegForm"
              autoComplete="true"
            >
              <h3 className="myaccount-content p-3">پست جدید بساز! </h3>
              <div className="form-row">
                <CatsSelector formik={formik} section={"blog"} />

                {/* cover photo */}

                <div>
                  <label
                    className={`${addErrClass({
                      formik,
                      field: "img",
                    })}`}
                    htmlFor={`coverPhoto`}
                  >
                    <Publish className="userUpdateIcon my-4" />
                    عکس روی جلد
                  </label>
                  {loading.img ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    formik.values.img.length > 0 && (
                      <img
                        className="UserUpdateImg my-4"
                        src={formik.values.img}
                        alt={formik.values.title}
                        loading="lazy"
                      />
                    )
                  )}
                  <input
                    id="coverPhoto"
                    type="file"
                    onChange={sendPhotoFirebase}
                    style={{ display: "none" }}
                  />
                  <FormikErrRender formik={formik} field="img" />
                </div>

                <div className="form-group col-md-12">
                  <label className="font-weight-bold required my-3">
                    عنوان پست
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="title"
                    value={formik.values.title}
                    className={`form-control ${addErrClass({
                      formik,
                      field: "title",
                    })}`}
                    placeholder="عنوان پست را اینجا بنویس!"
                    {...formik.getFieldProps("title")}
                  />
                </div>
                <div className="clearfix"></div>
                <FormikErrRender formik={formik} field="title" />

                <div>
                  <label htmlFor="coverInfo" className=" my-3">
                    معرفی مطلب
                  </label>
                  <br />
                  <textarea
                    className={addErrClass({ formik, field: "coverSummary" })}
                    id="coverInfo"
                    type="text"
                    autoComplete="off"
                    rows={3}
                    placeholder={
                      "هر جی اینجا بنویسی در کارت معرفی پست آورده میشه!"
                    }
                    style={{ width: "100%" }}
                    {...formik.getFieldProps("coverSummary")}
                  />
                </div>
                <FormikErrRender formik={formik} field="coverSummary" />

                <div className="form-group col-md-12 editor">
                  <label className="font-weight-bold required my-3">
                    متن پست
                  </label>
                  <EditorToolbar toolbarId={"t1"} />
                  <ReactQuill
                    theme="snow"
                    value={formik.values.description}
                    onChange={descriptionHandler}
                    placeholder={"شروع پست ..."}
                    modules={modules("t1")}
                    formats={formats}
                    className={
                      "dir-rtl" +
                      " " +
                      addErrClass({
                        formik,
                        field: "description",
                      })
                    }
                  />
                </div>
                <FormikErrRender formik={formik} field="description" />

                <br />
                <br />

                <CountryStateCitySelector formik={formik} />

                {/* <div>
                <label htmlFor="tags" className=" my-3">
                  برچسب‌ها
                </label>
                <br />
                <input
                  id="tags"
                  type="text"
                  onChange={(e) => setTag(e.target.value)}
                ></input>
                <button
                  className=" btn-secondary login-btn mx-3"
                  onClick={tagHandle}
                >
                  اضافه کن
                </button>
                {formik.values.tags?.map((tag, i) => (
                  <span
                    key={i}
                    style={{
                      backgroundColor: "white",
                      border: "solid gray",
                      borderRadius: "20px",
                      margin: "5px",
                      padding: "5px",
                    }}
                  >
                    <i
                      className="ti-close bi bi-x"
                      id={tag}
                      onClick={deleteTagHandle}
                    ></i>
                    {tag}
                  </span>
                ))}
              </div> */}
                <div>
                  <div id="tagsInput" className="form-titr ">
                    <span>
                      <i className="bi bi-circle-fill me-1"></i>
                    </span>
                    برچسب‌ها (کلمات کلیدی) (با "+" جدا شوند )
                    <Tooltip1
                      message={
                        <div>
                          <div>
                            برچسب‌ها - تگ - را توسط '+' (بدون فاصله)از یکدیگر
                            جداکنید.
                          </div>
                          <div>حداکثر ۱۰ برچسب.</div>
                        </div>
                      }
                    />
                  </div>
                  <div className="small-gray-font mt-1">
                    (بیشتر دیده شدن آگهی. 10 کلمه کلیدی که ممکنه کسی اون رو
                    جستجو کنه را وارد کنید)
                  </div>

                  <input
                    type="text"
                    autoComplete="off"
                    name="other"
                    placeholder={
                      (formik?.values?.tags.length && formik?.values?.tags) ||
                      `نمونه : مهاجرت+دانشگاه‌های کانادا+ مونترال`
                    }
                    className="form-input1 mt-3"
                    {...formik.getFieldProps("tags")}
                  />
                  <FormikErrRender formik={formik} field="tags" />
                </div>
                <br />

                {tools.compareCheck(user.roles, [1000, 1100]) && (
                  <div className=" mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      style={{ display: "none" }}
                      // value={payM.name}
                      // checked=""
                      id="showAuthorSection"
                      onChange={(e) =>
                        (document.querySelector(
                          ".authorSection"
                        ).style.display = e.target.checked ? "block" : "none")
                      }
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="showAuthorSection"
                    >
                      <h4>نویسنده دیگر</h4>
                    </label>
                    <div
                      className="authorSection mb-5"
                      style={{ display: "none" }}
                    >
                      <SearchBar formik={formik} />

                      <div
                        onClick={(e) =>
                          window.scrollTo({ top: "0", behavior: "smooth" })
                        }
                      >
                        <div className="mt-3">
                          <Link
                            to={`/${window.lang}/dashboard/users/list`}
                            className=" btn-secondary login-btn mx-3 "
                          >
                            نویسنده جدید
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group col-sm-12 text-center mt-5">
                  {/* <span className="login-btn login-btn1 w-50 mt-5 ">
                    پیش نمایش
                  </span> */}
                  {!formik.values._id?.length && (
                    <button
                      className="login-btn login-btn1 w-50 mt-5 "
                      onClick={(e) => {
                        e.preventDefault();
                        localStorage.setItem(
                          "postInfo",
                          JSON.stringify(formik.values)
                        );
                        messageRef.current.showSuccess({
                          message: "پست به صورت موقت ذخیره شد.",
                        });
                      }}
                    >
                      ذخیره موقت
                    </button>
                  )}
                </div>
                <div className="form-group col-sm-12 text-center">
                  <button
                    disabled={formik.isSubmitting || loading.submit}
                    type="submit"
                    className="login-btn login-btn2 w-50 mb-5 "
                    style={{
                      backgroundColor:
                        Object.keys(formik.errors).length > 0 && "grey",
                    }}
                  >
                    {loading.submit ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : (
                      (function () {
                        if (!formik.values._id?.length) return " فرستادن پست";
                        if (formik.values._id?.length > 0) return " ویرایش پست";
                      })()
                    )}
                    {!loading.submit && (
                      <span>
                        {Object.keys(formik.errors).length === 0 && (
                          <CheckCircle />
                        )}
                        {Object.keys(formik.errors).length > 0 && (
                          <Tooltip1
                            message=" موارد ستاره دار را کامل کنید."
                            icon={<Error sx={{ fontSize: 18, color: "red" }} />}
                            position="left"
                          />
                        )}
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <div>
              <WarningAmber sx={{ color: "red" }} />
              پرفایل نویسندگی شما تاکنون تکمیل نشده است، از طریق کلید زیر می
              توانید آنرا تکمیل کنید.
            </div>
            <Link
              to={`/${window.lang}/dashboard/user/profile/author`}
              className="btn btn-primary share-cover-btn mx-2"
            >
              برو به پروفایل نویسندگی
            </Link>
          </div>
        )}
      </div>
    </div>
  );

  // function setCitiesHandle(value) {
  //   formik.setValues((prev) => {
  //     const address = { ...prev.address };
  //     address.cities = value;
  //     return { ...prev, address };
  //   });
  // }

  // function countrySet(countryShort) {
  //   const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  //   console.log(countryShort);
  //   const states = countryStates.find(
  //     (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
  //   ).states;
  //   console.log(states);
  //   // const state = countryStates.find(
  //   //   (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
  //   // ).states[0];
  //   setStates(states);

  //   formik.setValues((prev) => {
  //     const address = { ...prev.address };
  //     address.country = regionNames.of(countryShort).toLowerCase();
  //     address.countryShort = countryShort.toLowerCase();
  //     address.state = "";
  //     address.stateShort = "";
  //     address.cities = [];
  //     return { ...prev, address };
  //   });
  // }
}
