import { useState } from "react";
import { useNavigate } from "react-router-dom";
import countryStates from "../../../utils/countryStates";
import "./landingPage.css";
import DropdownSearch from "../../golbal/dropdown/DropdownSearch";
import MetaDecorator from "../../../utils/metaDecorator";
// import DropdownSearch from "../../golbal/dropdown/search";

export default function LandingPage() {
  const navigate = useNavigate();
  const initSetup = JSON.parse(localStorage.getItem("initSetup"));
  const [setting, setSetting] = useState({  
    lang: initSetup?.lang?.toLowerCase() || "fa",
    country: initSetup?.country?.toLowerCase() || "canada",
    countryShort: initSetup?.countryShort?.toLowerCase() || "ca",
    state: {
      state: initSetup?.state.state?.toLowerCase() ?? "quebec",
      stateShort: initSetup?.state?.stateShort?.toLowerCase() ?? "qc",
      lat: initSetup?.state?.lat ?? "52.93991590",
      lng: initSetup?.state?.lng ?? "-73.54913610",
    },
    firstRender: false,
  });

  
  // function affiliateSet(){
  //   const urlParams  = new URLSearchParams(window.location.search)
  //   const affiliateCode = urlParams.get("aff")
  //   const ttl = 7*24*60*60*1000   
  //   if(affiliateCode?.length>=10) {
  //     const now = new Date();
  //     const item = {
  //       affCode: affiliateCode,
  //       expiry: now.getTime() + ttl,
  //   };
  //   localStorage.setItem('affiliate', JSON.stringify(item));
     
  //   }
  // }





  function enterHandler() {
    // affiliateSet()
    localStorage.setItem("initSetup", JSON.stringify(setting));
    let url = `/${setting.lang}`    
    if(setting.lang==='en')url = `/${setting.lang}/demy`

    navigate(url);
   
  }

  function countrySet(countryShort) {
    // const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    const countryIndex = countryStates.findIndex(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    );
    const state = (
      countryIndex !== -1 ? countryStates[countryIndex] : countryStates[38]
    ).states[0];
    console.log('countryStates[countryIndex]',countryStates[countryIndex]);
    
    setSetting((prev) => ({
      ...prev,
      // country: regionNames.of(countryShort).toLowerCase(),
      country: countryStates[countryIndex].country.toLowerCase(),
      countryShort: countryShort.toLowerCase(),
      state: {
        state: state.state.toLowerCase(),
        stateShort: state.stateShort.toLowerCase(),
        lat: state.lat,
        lng: state.lng,
      },
    }));
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://www.metamarce.com/",
        url: "https://www.metamarce.com/",
        name: "متامارس | پلی بین پارسی‌زبانان سراسر دنیا",
        isPartOf: { "@id": "https://www.metamarce.com/#website" },

        datePublished: "2023-10-01T20:55:19+00:00",
        dateModified: "2023-12-18T07:50:42+00:00",
        description:
          "فرقی نمیکنه خانه داری یا بیکاری، صاحب کسب و کاری یا سوشال مدیا داری در متامارس همیشه برای شما جایی هست",
        breadcrumb: { "@id": "https://www.metamarce.com/#breadcrumb" },
        inLanguage: "fa-IR",
        potentialAction: [
          { "@type": "ReadAction", target: ["https://www.metamarce.com/"] },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.metamarce.com/#breadcrumb",
        itemListElement: [{ "@type": "ListItem", position: 1, name: "ورود" }],
      },
      {
        "@type": "WebSite",
        "@id": "https://www.metamarce.com/#website",
        url: "https://www.metamarce.com/",
        name: "Metamarce",
        description:
          "متامارس جایی برای پیدا کردن یک دیگر و کمک به رشد همگی با هم ...",
        inLanguage: "fa-IR",
      },
      {
        "@type": "Organization",
        "@id": "https://www.metamarce.com/#organization",
        name: "metamarce",
        url: "https://www.metamarce.com",
        logo: "https://www.metamarce.com/favicon.ico",
        image: "https://www.metamarce.com/img/plans/x4-2.jpg",
        email: "metamarce.com@gmail.com",
        sameAs: [
          "https://www.instagram.com/metamarce?igsh=azJoejNtdjdydXY4&utm_source=qr",
          "https://www.facebook.com/profile.php?id=61555192350817&mibextid=LQQJ4d",
          "https://twitter.com/i/flow/login?redirect_after_login=%2FMetamarceCom",
          "https://t.me/joinchat/BfW__kawWflm6_tY_fwH8w",
        ],
        contactPoint: [
          { "@type": "ContactPoint", email: "metamarce.com@gmail.com" },
        ],
      },
    ],
  };
  const metaTagsData = {
    title: "Metamarce",
    description: "پلی بین پارسی‌زبانان سراسر دنیا",
    section: "Landing page",
    url: "https://www.metamarce.com",
    img: "https://www.metamarce.com/img/plans/x4-2.jpg",
  };
  return (
    <div className="">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="landingPage ">
        <div className="container row">
          <div className="col-lg-4 col-md-5 pe-2  text-center">
            <h1
              className="mx-auto titr7 font-20 "
              style={{ color: "whitesmoke" }}
            >              
              به متامارس خوش آمدید
            </h1>

            {/* <h3 className="mx-auto titr3 mt-0">
  پلی بین پارسی‌زبانان دنیا
</h3> */}

            <div className="accordion " id="accordionLanding">
              <div className="accordion-item my-4 login-btn border-0 card-background0">
                <h2
                  className="accordion-header d-flex justify-content-between "
                  id="headingLanding1"
                >
                  <button
                    className="accordion-button accordion-button-landingpage p-1 accordion-button-blog collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseLanding1"
                    aria-expanded="false"
                    aria-controls="collapse1"
                  >
                    <span>
                      <i className="bi bi-translate p-2 landing_icon "></i>
                    </span>
                    کدوم زبان؟ ({setting.lang})
                  </button>
                </h2>
                <div
                  id="collapseLanding1"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingLanding1"
                  data-bs-parent="#accordionLanding"
                >
                  <div className="accordion-body  me-5 ms-5 px-5">
                    <div className="d-flex justify-content-between" dir="rtl">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="englishBtn"
                        value="en"
                        name="lang"
                        required
                        disabled
                        checked={setting.lang === "en"}
                        onChange={(e) =>
                          setSetting((prev) => ({
                            ...prev,
                            lang: e.target.value,
                          }))
                        }
                        // onChange={(e) =>
                        //   window.open(`/coming`, {
                        //     target: "_blank",
                        //   })
                        // }
                      />
                      <label className=" small-gray-fornt" htmlFor="englishBtn">English</label>
                    </div>
                    <div className="d-flex justify-content-between" dir="rtl">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="frenchBtn"
                        value="fr"
                        name="lang"
                        required
                        disabled
                        // checked={setting.lang === "fr"}
                        // onChange={(e) =>
                        //   setSetting((prev) => ({
                        //     ...prev,
                        //     lang: e.target.value,
                        //   }))
                        // }
                        // onChange={(e) =>
                        //   window.open(`/coming`, {
                        //     target: "_blank",
                        //   })
                        // }
                      />
                      <label htmlFor="frenchBtn">Français</label>
                    </div>
                    <div className="d-flex justify-content-between" dir="rtl">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="persianBtn"
                        value="fa"
                        name="lang"
                        required
                        checked={setting.lang === "fa"}
                        onChange={(e) =>
                          setSetting((prev) => ({
                            ...prev,
                            lang: e.target.value,
                          }))
                        }
                      />
                      <label htmlFor="persianBtn">فارسی</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item my-4 login-btn border-0 card-background7">
                <h2 className="accordion-header" id="headingLandingTwo">
                  <button
                    className="accordion-button accordion-button-landingpage p-1 accordion-button-blog collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseLandingTwo"
                    aria-expanded="false"
                    aria-controls="collapseLandingTwo"
                  >
                    <span className="ps-1 pe-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="bi bi-globe-americas"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                      </svg>
                    </span>
                    کدوم کشور؟ ({setting.countryShort})
                  </button>
                </h2>
                <div
                  id="collapseLandingTwo"
                  className="accordion-collapse   collapse"
                  aria-labelledby="headingLandingTwo"
                  data-bs-parent="#accordionLanding"
                >
                  <div className="accordion-body text-center landingPage-search">
                    <DropdownSearch updateParent={countrySet} />
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={enterHandler}
              className="landing-explore w-100  "
              dir="ltr"
            >
              <span className="m-3">بریم ببینیم</span>
              <i className="bi bi-arrow-right icon-m-t "></i>
            </button>
          </div>
          <div className="col-lg-8 col-md-7 mt-md-5 mt-1">
            <div className="text-center max-w-1000 mx-auto px-3">
              {/* <img
                      src="/img/homePage/metamarceHome.png"
                      className="mx-auto"
                      style={{width:'100%'}}
                      alt="metamarceHome"
                    /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="text-center  lets-go display-none-md">
            <img
              src="/img/landing/Metamarce-8.jpg"
              className="mx-auto "
              style={{ width: "100%" }}
              alt="metamarceHome"
              loading="lazy"
            />
          </div> */}

      {/* <div className="row g-3" >
        <div className="col-lg-4 p-0 text-center">
          <div className="lets-go me-lg-3 py-5 h-100">
            <div className="accordion " id="accordionLanding">
              <div className="accordion-item m-4 login-btn border-0 card-background0">
                <h2
                  className="accordion-header d-flex justify-content-between "
                  id="headingLanding1"
                >
                  <button
                    className="accordion-button accordion-button-landingpage p-1 accordion-button-blog collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseLanding1"
                    aria-expanded="false"
                    aria-controls="collapse1"
                  >
                    <span>
                      <i className="bi bi-translate p-2 landing_icon "></i>
                    </span>
                    کدوم زبان؟ ({setting.lang})
                  </button>
                </h2>
                <div
                  id="collapseLanding1"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingLanding1"
                  data-bs-parent="#accordionLanding"
                >
                  <div className="accordion-body  me-5 ms-5 px-5">
                    <div className="d-flex justify-content-between" dir="rtl">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="englishBtn"
                        value="en"
                        name="lang"
                        required
                        // defaultChecked={setting.lang === "en"}
                        // onChange={(e) =>
                        //   setSetting((prev) => ({
                        //     ...prev,
                        //     lang: e.target.value,
                        //   }))
                        // }
                        onChange={e=>window.open(`/coming` , {target:"_blank"})}
                      />
                      <label htmlFor="englishBtn" >English</label>
                    </div>
                    <div className="d-flex justify-content-between" dir="rtl">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="frenchBtn"
                        value="fr"
                        name="lang"
                        required
                        // defaultChecked={setting.lang === "fr"}
                        // onChange={(e) =>
                        //   setSetting((prev) => ({
                        //     ...prev,
                        //     lang: e.target.value,
                        //   }))
                        // }
                        onChange={e=>window.open(`/coming` , {target:"_blank"})}
                      />
                      <label htmlFor="frenchBtn" >Français</label>
                    </div>
                    <div className="d-flex justify-content-between" dir="rtl">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="persianBtn"
                        value="fa"
                        name="lang"
                        required
                       checked={setting.lang === "fa"}
                        onChange={(e) =>
                          setSetting((prev) => ({
                            ...prev,
                            lang: e.target.value,
                          }))
                        }
                      />
                      <label htmlFor="persianBtn">فارسی</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item m-4 login-btn border-0 card-background7">
                <h2 className="accordion-header" id="headingLandingTwo">
                  <button
                    className="accordion-button accordion-button-landingpage p-1 accordion-button-blog collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseLandingTwo"
                    aria-expanded="false"
                    aria-controls="collapseLandingTwo"
                  >
                    <span className="ps-1 pe-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="bi bi-globe-americas"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                      </svg>
                    </span>
                    کدوم کشور؟ ({setting.countryShort})
                  </button>
                </h2>
                <div
                  id="collapseLandingTwo"
                  className="accordion-collapse   collapse"
                  aria-labelledby="headingLandingTwo"
                  data-bs-parent="#accordionLanding"
                >
                  <div className="accordion-body text-center landingPage-search">
                    <DropdownSearch updateParent={countrySet} />
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={enterHandler}
              className="landing-explore w-100  "
              dir="ltr"
            >
              <span className="m-3">بریم ببینیم</span>
              <i className="bi bi-arrow-right icon-m-t "></i>
            </button>
          </div>
        </div>
        
        <div className="col-lg-8 p-0  ">
          <div className="text-center max-w-1000 mx-auto ">
            <img
              src="/img/homePage/metamarsHome5.png"
              className="mx-auto lets-go"
              style={{ width: "100%" }}
              alt="metamarceHome"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}
