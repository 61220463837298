import { NavLink } from "react-router-dom";

export default function UserDashBlogNav() {
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lang}/dashboard/user/blog/register`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        ایجاد پست جدید{" "}
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/blog/myPostsList`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        پست های من{" "}
      </NavLink>
      {showEdit === "edit" && (
        <NavLink
          to={`/${window.lang}/dashboard/user/blog/edit`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
          ویرایش پست
        </NavLink>
      )}
    </div>
  );
}
