// import "./user.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CheckCircle, Error, Publish } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../../contexts/cartContext";
import ToastNote from "../../golbal/notifications/toastNote";
import tools from "../../../utils/tools";
import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";
import FormikErrRender from "../../persian/common/alerts/formikErrRender";
import addErrClass from "../../persian/common/alerts/addErrClass";
import apiUsers from "../../../utils/apiUsers";
import api from "../../../utils/api";
import AddSocialMedia from "../../persian/addSocialMedia";
import Tooltip1 from "../../golbal/tooltip/tooltip";
import apiEvent from "../../../utils/apiEvent";
import DropdownSearch from "../../golbal/dropdown/DropdownSearch";
import SearchMultiSelectFilters from "../../golbal/dropdown/searchMultiSelectFilters";
import countryStates from "../../../utils/countryStates";
import MetaDecorator from "../../../utils/metaDecorator";
import FormikListErr from "../../persian/common/alerts/formikListErr";

export default function ArtistRegister({ user2, action }) {
  const navigate = useNavigate();
  const messageRef = useRef(null);
  const { user, signedUser } = useContext(CartContext);
  const { id } = useParams();
  console.log("id", id);
  const [loading, setLoading] = useState({ spinner: false });
  const [states, setStates] = useState([]);
  const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  const photoHandle = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " از فایل با فرمت عکس استفاده کنید.",
      });
    console.log("photoHandle auth", originalPhoto);
    setResisedPhotoAuth(
      await tools.resizeFile({
        file: e.target.files[0],
        width: 500,
        height: 500,
      })
    );
    setOriginalPhoto(e.target.files[0]);
  };

  const uploadFileDatabase = async (e, file) => {
    e.preventDefault();
    if (!file) return;
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    console.log("formData", formData);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/profile/artist",
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });

    console.log(response.data);
    formik.setValues((pre) => ({ ...pre, img: response.data }));
    setResisedPhotoAuth(null);
    messageRef.current.showSuccess({
      message: "عکس با موفقیت بارگذاری شد، جهت ثبت کلید ویرایش را فشار دهید. ",
    });
  };

  useEffect(() => {
    if (id) fetch();
  }, [id]);

  async function fetch() {
    console.log("fetch");
    setLoading({ ...loading, spinner: true });
    const response = await apiEvent.fetchArtDetails({ id, token: user.token });
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });

    formikInitializer(response.data);

    if (document.querySelector("#termsContent"))
      document.querySelector("#termsContent").style.display = response.data
        ?.terms
        ? "none"
        : "block";
  }
  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  const englishRegex = /^[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/`'"\-]+$/;

  const formik = useFormik({
    initialValues: {
      action,
      lcs: window.lcs,
      lang: window.lang,
      img: window.img,
      terms: false,
      name: { fa: "", en: "", fr: "" },
      email: "",
      address: {
        country: "",
        countryShort: "",
        state: "",
        stateShort: "",
        cities: [],
      },
      phone: "",
      bio: { fa: "", en: "", fr: "" },
      firstName: { fa: "", en: "", fr: "" },
      lastName: { fa: "", en: "", fr: "" },
      albums: { fa: "", en: "", fr: "" },
      born: { fa: "", en: "", fr: "" },
      genres: { fa: "", en: "", fr: "" },
      occupations: { fa: "", en: "", fr: "" },
      instruments: { fa: "", en: "", fr: "" },
      activeSince: { fa: "", en: "", fr: "" },
      tags: "",
      website: "",
      spouse: { fa: "", en: "", fr: "" },
      childrens: "",
      socialMedia: [{ platform: "", url: "" }],
      _id: "",
    },
    onSubmit: async (values) => {
      console.log(values._id);
      console.log(user._id);
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });

      setLoading({ ...loading, spinner: true, submitBtn: true });
      const response = await apiEvent.registerArtist({
        id: values._id,
        token: user.token,
        data: values,
      });

      setLoading({ ...loading, spinner: false, submitBtn: false });
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، عملیات با موفقیت انجام شد.",
      });
      // formik.resetForm()

      // signedUser(response.data);
      // setTimeout(
      //   () =>
      //     navigate(`/${window.lang}/dashboard/user/blog/register`, {
      //       replace: true,
      //     }),
      //   1000
      // );
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        " قوانین نویسندگی را مطالعه و دکمه قبول را فشار دهید."
      ),
      name: Yup.object().shape({
        fa: Yup.string()
          .required(" نام هنری هنرمند را وارد نمائید.")
          .min(3, "نام هنرمند حداقل باید ۳ حرف باشد.")
          .max(50, "نام هنرمند حداکثر باید 50 حرف باشد.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Artist name must be 3 letters and more.")
          .max(50, "Artist name must be 50 letters and less.")
          .trim(),
        fr: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Artist name must be 3 letters and more.")
          .max(50, "Artist name must be 50 letters and less.")
          .trim(),
      }),
      bio: Yup.object().shape({
        fa: Yup.string()
          .required(" نام کسب و کار را انتخاب کنید.")
          .min(3, "نام کسب و کار حداقل باید ۳ حرف باشد.")
          .max(2500, "نام کسب و کار حداکثر باید 2500 حرف باشد.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .max(2500, "Artist name must be 2500 letters and less.")
          .trim(),
        fr: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .max(2500, "Artist name must be 2500 letters and less.")
          .trim(),
      }),

      socialMedia: Yup.array().of(
        Yup.object().shape({
          platform: Yup.string("پلتفرم نمی تواند خالی باشد."),
          url: Yup.string()
            .when("platform", (platform, schema) => {
              return platform
                ? schema.required("لطفاً آدرس URL را وارد نمایید.")
                : schema;
            })
            .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
              if (!value) return true; // Allow empty value
              // Regular expression to validate URL
              return urlRegex.test(value);
            }),
        })
      ),
      // website: Yup.string().matches(
      //   /^https?:\/\/(?:www\.)?metamarce\.com(?:\/.*)?$/,
      //   " آدرس را در دامنه www.metamarce.com انتخاب کنید."
      // ),
    }),
  });
  console.log("formik", formik.values);
  const [lang, setLang] = useState(window.lang);
  const langName =
    lang === "fa"
      ? " فارسی "
      : lang === "en"
      ? " انگلیسی "
      : lang === "fr"
      ? " فرانسه "
      : "";
  const metaTagsData = {
    title: "متامارس | داشبورد | تشکیل پروفایل هنرمند ",
    description:
      "پروفایل نویسندگی | به روز رسانی اطلاعات نویسندگی | به روز رسانی عکس خود به عنوان نویسنده ",
    section: "oreders list",
    url: `${window.location.origin}/${window.lang}/dashboard/user/profile/author`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };

  return (
    <div className="user mx-md-5 mt-3">
      <MetaDecorator data={{ ...metaTagsData }} />

      {loading.spinner && <ColoredSpinner />}
      <ToastNote messageRef={messageRef} />

      {/* changing language start */}
      <div className="col-6 d-flex justify-content-center ">
        <span
          className={`me-1 map-card my-2 d-flex justify-content-center ${
            lang === "fa" && "map-card-active"
          }`}
          onClick={(e) => setLang("fa")}
        >
          فارسی <i className="bi bi-map mx-1"></i>
        </span>
        <span
          className={`me-1 map-card my-2 d-flex justify-content-center ${
            lang === "en" && "map-card-active"
          }`}
          onClick={(e) => setLang("en")}
        >
          English <i className="bi bi-map mx-1"></i>
        </span>
        <span
          className={`me-1 map-card my-2 d-flex justify-content-center ${
            lang === "fr" && "map-card-active"
          }`}
          onClick={(e) => setLang("fr")}
        >
          French <i className="bi bi-map mx-1"></i>
        </span>
      </div>
      {/* changing language End */}
      <div className="row ">
        <div></div>
        <div className="col">
          <p className="divider2 mt-2 ">
             
            <svg
              className="text-dark-550 dark:text-white ml-2"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
              <circle
                cx="35"
                cy="16"
                r="8"
                fill="currentColor"
                fillOpacity="0.4"
              ></circle>
              <circle
                cx="20"
                cy="8"
                r="5"
                fill="currentColor"
                fillOpacity="0.7"
              ></circle>
            </svg>
            <span className="p-0 m-z ">
              ایجاد پروفایل هنری
              <strong>( {langName})</strong>
            </span>
          </p>
        </div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="row userShow"
        name="authorNameForm"
        autoComplete="true"
      >
        {/* Right Section */}
        <div className="col-md-6 px-3">
          <div className="userUpdateItem mb-5">
            <label htmlFor={`name[${lang}]`}>
              نام هنری قابل نمایش به زبان
              {langName}
            </label>
            <input
              id={`name[${lang}]`}
              {...formik.getFieldProps(`name[${lang}]`)}
              className={`userUpdateInput form-control box2 ${addErrClass({
                formik,
                field: `name.${lang}`,
              })}`}
              type="text"
              autoComplete="off"
              placeholder={formik.values.name?.[lang]}
            />
            <FormikErrRender formik={formik} field={`name.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label htmlFor={`firstName[${lang}]`}>
              نام
              {langName}
            </label>
            <input
              id={`firstName[${lang}]`}
              {...formik.getFieldProps(`firstName[${lang}]`)}
              className={`userUpdateInput form-control box2  ${addErrClass({
                formik,
                field: `firstName.${lang}`,
              })}`}
              type="text"
              autoComplete="off"
              placeholder={formik.values.firstName?.[lang]}
            />
            <FormikErrRender formik={formik} field={`firstName.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label htmlFor={`lastName[${lang}]`}>
              نام فامیلی
              {langName}
            </label>
            <input
              id={`lastName[${lang}]`}
              {...formik.getFieldProps(`lastName[${lang}]`)}
              className={`userUpdateInput form-control box2  ${addErrClass({
                formik,
                field: `lastName.${lang}`,
              })}`}
              type="text"
              autoComplete="off"
              placeholder={formik.values.lastName?.[lang]}
            />
            <FormikErrRender formik={formik} field={`lastName.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`bio[${lang}]`}>
              بیوگرافی {langName}
            </label>
            <textarea
              id={`bio[${lang}]`}
              {...formik.getFieldProps(`bio[${lang}]`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `bio.${lang}`,
                }
              )}`}
              type="text"
              rows={5}
              placeholder={formik.values?.bio?.[lang]}
            />
            <FormikErrRender formik={formik} field={`bio.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`born[${lang}]`}>
              تاریخ و محل تولد {langName}
            </label>
            <input
              id={`born[${lang}]`}
              {...formik.getFieldProps(`born[${lang}]`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `born.${lang}`,
                }
              )}`}
              type="text"
              placeholder={formik.values?.born?.[lang]}
            />
            <FormikErrRender formik={formik} field={`born.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`albums[${lang}]`}>
              آلبوم ها به {langName}
            </label>
            <input
              id={`albums[${lang}]`}
              {...formik.getFieldProps(`albums[${lang}]`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `albums.${lang}`,
                }
              )}`}
              type="text"
              placeholder={formik.values?.albums?.[lang]}
            />
            <FormikErrRender formik={formik} field={`albums.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`genres[${lang}]`}>
              ژانر های هنری به {langName}
            </label>
            <input
              id={`genres[${lang}]`}
              {...formik.getFieldProps(`genres[${lang}]`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `genres.${lang}`,
                }
              )}`}
              type="text"
              placeholder={formik.values?.genres?.[lang]}
            />
            <FormikErrRender formik={formik} field={`genres.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`occupations[${lang}]`}>
              پیشه و کار به {langName}
            </label>
            <input
              id={`occupations[${lang}]`}
              {...formik.getFieldProps(`occupations[${lang}]`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `occupations.${lang}`,
                }
              )}`}
              type="text"
              placeholder={formik.values?.occupations?.[lang]}
            />
            <FormikErrRender formik={formik} field={`occupations.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`instruments[${lang}]`}>
              ساز(ها) موسیقی به {langName}
            </label>
            <input
              id={`instruments[${lang}]`}
              {...formik.getFieldProps(`instruments[${lang}]`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `instruments.${lang}`,
                }
              )}`}
              type="text"
              placeholder={formik.values?.instruments?.[lang]}
            />
            <FormikErrRender formik={formik} field={`instruments.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`activeSince[${lang}]`}>
              سال‌های فعالیت هنری به {langName}
            </label>
            <input
              id={`activeSince[${lang}]`}
              {...formik.getFieldProps(`activeSince[${lang}]`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `activeSince.${lang}`,
                }
              )}`}
              type="text"
              placeholder={formik.values?.activeSince?.[lang]}
            />
            <FormikErrRender formik={formik} field={`activeSince.${lang}`} />
          </div>
          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`spouse[${lang}]`}>
              نام همسر به {langName}
            </label>
            <input
              id={`spouse[${lang}]`}
              {...formik.getFieldProps(`spouse[${lang}]`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `spouse.${lang}`,
                }
              )}`}
              type="text"
              placeholder={formik.values?.spouse?.[lang]}
            />
            <FormikErrRender formik={formik} field={`childrens.${lang}`} />
          </div>

          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`childrens`}>
              تعداد فرزندان
            </label>
            <input
              id={`childrens`}
              {...formik.getFieldProps(`childrens`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `childrens`,
                }
              )}`}
              type="text"
              placeholder={formik.values?.childrens}
            />
            <FormikErrRender formik={formik} field={`childrens`} />
          </div>

          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor={`tags`}>
              برچسب ها
            </label>
            <input
              id={`tags`}
              {...formik.getFieldProps(`tags`)}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                {
                  formik,
                  field: `tags`,
                }
              )}`}
              type="text"
              placeholder={formik.values?.tags}
            />
            <FormikErrRender formik={formik} field={`tags`} />
          </div>

          <div className=" userUpdateItem mb-5">
            <label className=" ps-3" htmlFor="website">
              وبسایت
            </label>
            <input
              id="website"
              {...formik.getFieldProps("website")}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                { formik, field: "website" }
              )}`}
              type="text"
              rows={2}
            />
            <FormikErrRender formik={formik} field="website" />
          </div>
          <div className=" userUpdateItem mb-5">
            <label className=" ps-3" htmlFor="email">
              ایمیل
            </label>
            <input
              id="email"
              {...formik.getFieldProps("email")}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                { formik, field: "email" }
              )}`}
              type="text"
              rows={2}
            />
            <FormikErrRender formik={formik} field="email" />
          </div>
          <div className=" userUpdateItem">
            لینک شبکه‌های اجتماعی
            <AddSocialMedia formik={formik} />
          </div>
        </div>

        {/* Left Section */}
        <div className="userUpdateItem col-md-6 px-3">
          <div className="userUpdateUpload d-flex flex-column justify-content-center">
            <img
              src={resisedPhotoAuth || formik.values?.img}
              alt={formik.values.name[window.lang]}
              className="userShowImg-profile"
              loading="lazy"
            />

            <label className="my-2" htmlFor="fileAuth">
              <Publish className="userUpdateIcon" />
              تغییر عکس
            </label>
            <input
              type="file"
              id="fileAuth"
              onChange={photoHandle}
              style={{ display: "none" }}
            />
            <span
              className="userUpdateButton edit-btn"
              style={{ display: resisedPhotoAuth ? "block" : "none" }}
              onClick={(e) => uploadFileDatabase(e, originalPhoto)}
            >
              بارگذاری
            </span>
          </div>

          <div>
            {/* <!-- Button country 2 *******************************--> */}
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-primary button-filter dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  <i className="bi bi-geo-alt me-1"></i>
                </span>
                کشور
                <span className="ps-3">
                  <i className="bi bi-chevron-down"></i>
                </span>
              </button>

              {/* <!-- Modal --> */}

              <div className="dropdown-menu checkbox-form">
                <DropdownSearch updateParent={countrySet} />
              </div>
              <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
                {formik.values.address?.country}
              </div>
            </div>

            {/* <!-- Button State 2 *******************************--> */}
            {formik.values.address?.country?.length > 0 && (
              <div className="dropdown">
                <button
                  type="button"
                  className="btn btn-primary button-filter dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <i className="bi bi-geo-alt me-1"></i>
                  </span>
                  استان
                  <span className="ps-3">
                    <i className="bi bi-chevron-down"></i>
                  </span>
                </button>

                {/* <!-- Modal --> */}

                <div className="dropdown-menu checkbox-form">
                  <select
                    name="statePostRegSel"
                    id=""
                    className="login-btn  selectpicker shadow-none w-100 "
                    onChange={(e) => {
                      console.log(e.target.value);
                      formik.setValues((prev) => {
                        const address = { ...prev.address };
                        address.state = e.target.value.split(",")[0];
                        address.stateShort = e.target.value.split(",")[1];
                        return { ...prev, address };
                      });
                    }}
                  >
                    <option value="">
                      {(formik.values?.state &&
                        window.capitalize(formik.values?.state)) ||
                        " انتخاب کنید"}
                    </option>
                    {states?.map((state, i) =>
                      state.state.toLowerCase() ===
                      formik.values?.state?.toLowerCase() ? (
                        ""
                      ) : (
                        <option
                          value={[
                            state.state,
                            state.stateShort,
                            state.lat,
                            state.lng,
                          ]}
                          key={i}
                        >
                          {window.capitalize(state.state)}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
                  {formik.values.address?.state}
                </div>
              </div>
            )}

            {/* <!-- Button City 3 *******************************--> */}
            {formik.values.address?.state?.length > 0 && (
              <div className="dropdown">
                <button
                  type="button"
                  className="btn btn-primary button-filter dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <i className="bi bi-geo-alt me-1"></i>
                  </span>
                  شهر/ محله
                  <span className="ps-3">
                    <i className="bi bi-chevron-down"></i>
                  </span>
                </button>

                {/* <!-- Modal --> */}

                <div className="dropdown-menu checkbox-form">
                  <SearchMultiSelectFilters
                    updateParent={setCitiesHandle}
                    data={formik.values.address}
                  />
                </div>
                {formik.values?.address?.cities?.map((city) => (
                  <span className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
                    {city}
                  </span>
                ))}
              </div>
            )}
          </div>

          <input
            style={{ display: "none" }}
            className="form-check-input"
            id="termsConditions"
            type="checkbox"
            onChange={(e) =>
              (document.querySelector("#termsContent").style.display = e.target
                .checked
                ? "block"
                : "none")
            }
          />
          <label
            className=" terms-con ps-3 mb-2 mt-5"
            htmlFor="termsConditions"
          >
            قوانین متامارس برای بیوگرافی هنرمند
          </label>
          <Roles />

          <label
            htmlFor="termsBtn"
            className={`required my-3 mb-5 ${addErrClass({
              formik,
              field: "terms",
            })} `}
          >
            قوانین را مطالعه کردم و می‌پذیرم
            <input
              id="termsBtn"
              type="checkbox"
              className="form-check-input mx-2"
              onChange={(e) => {
                if (formik.values.terms) return;
                formik.setValues((pre) => ({ ...pre, terms: true }));
              }}
              checked={formik.values.terms}
            />
            <FormikErrRender formik={formik} field="terms" />
          </label>
        </div>
        <FormikListErr formik={formik} />
        <SubmitBtn formik={formik} text="بفرست!" />
      </form>
    </div>
  );

  function Roles() {
    return (
      <div
        id="termsContent"
        className="userUpdateInput form-control userUpdateInputTextarea"
        dir="rtl"
        style={{ display: "none" }}
      >
        قوانین و شرایط خدمات برای ایجاد کنندگان محتوا
        <br />
        1. واجدین شرایط و ثبت‌نام
        <br />
        برای تبدیل شدن به یک ایجاد کننده محتوا در پلتفرم ما، باید به معیارهای
        واجدین شرایط زیر پاسخ دهید: باید حداقل 18 سال سن داشته باشید یا به سن
        مورد نیاز مشخص شده توسط مقامات محلی شما برسید. باید یک حساب کاربری ایجاد
        کرده و در طول فرآیند ثبت‌نام اطلاعات دقیق و کاملی را ارائه دهید. باید
        موافقت کنید و با این قوانین و شرایط خدمات موافقت کنید و از آن پیروی
        کنید.
        <br />
        2. رهنمودهای محتوا
        <br />
        به عنوان یک ایجاد کننده محتوا، می‌بایست محتوایی ایجاد کنید که با
        رهنمودهای زیر هماهنگ باشد: محتوای شما نباید در تضاد با هیچ قانون محلی،
        ایالتی، ملی یا بین‌المللی باشد. شما مسئولیت دارید که محتوای خود شامل
        مواد جنسی صریح، ترویج سخنان نفرت‌آمیز، تبعیض یا آزار، خشونت، خودآسیبی یا
        فعالیت‌های غیرقانونی نباشد. از حقوق مالکیت معنوی دیگران احترام بگذارید و
        به معنویت‌ها، تجاری‌نامها و پتنت‌ها تجاوز نکنید. هنگام استفاده از محتوای
        یا تصاویر ساخته‌شده توسط دیگران، همیشه مجوزهای مناسب را تهیه کنید و
        ارجاع‌دهی صحیح را فراهم آورید. اگر ارجاع‌ها یا منابعی در محتوای خود
        استفاده می‌کنید، اطمینان حاصل کنید که آنها دقیق، با افتخار اشاره شده و
        منبع اصلی را تبلیغ نکنند. از اشتراک دادن اطلاعات شخصی دیگران بدون موافقت
        آنان پرهیز کنید.
        <br />
        3. حقوق ناشر
        <br />
        با فرستادن محتوای خود در پلتفرم ما، یک مجوز غیر انحصاری، بدون حق تقلیدی،
        به ما اعطا می‌کنید تا محتوای شما را در پلتفرم ما استفاده، نمایش و توزیع
        کنیم. شما حق مالکیت محتوای خود را حفظ کرده و می‌توانید آن را در مکان‌های
        دیگر منتشر کنید.
        <br />
        4. رفتار حرفه‌ای
        <br />
        به عنوان یک ایجاد کننده محتوا در پلتفرم ما باید:
        <br />
        در تعاملات خود با کاربران و دیگر ایجاد کنندگان محتوا رفتار حرفه‌ای و
        احترام‌آمیزی حفظ کنید. در اسپم‌گذاری، ترولینگ یا رفتارهای مخل به ترتیب و
        انضباط خوش‌نامی نپیش‌برین. در مورد هویت خود و محتوایی که ایجاد می‌کنید،
        صادق و شفاف باشید.
        <br />
        5. گزارش تخلفات
        <br />
        اگر به محتوایی یا ایجاد کنندگان محتوایی برخورد کنید که با این قوانین در
        تضاد باشند، لطفاً آن را به تیم نظارت ما از طریق مکانیزم گزارش ارائه‌شده
        گزارش دهید.
        <br />
        6. اجرای قوانین
        <br />
        تخلف از این قوانین ممکن است منجر به عواقبی از جمله اخطار، حذف محتوا،
        تعلیق موقت یا فسخ دائمی حساب ایجاد کننده محتوا شود. شدت اجرای قوانین
        بستگی به نوع و فراوانی تخلف دارد.
        <br />
        7. حریم خصوصی و استفاده از داده‌ها
        <br />
        حریم خصوصی شما برای ما مهم است. لطفاً
        <a dir="rtl" href="/prompt/privacy" target="_blank">
          (ازاینجا ببینین)
        </a>
        ما را بررسی کنید تا درک کنید که چگونه ما داده‌های شما را جمع‌آوری،
        استفاده و حفاظت می‌کنیم.
        <br />
        <br />
        8. اختیارات ناشر
        <br />
        با فرستادن محتوای خود در پلتفرم ما، یک مجوز غیر انحصاری، بدون حق تقلیدی،
        به ما اعطا می‌کنید تا محتوای شما را در پلتفرم ما استفاده، نمایش و توزیع
        کنیم. شما حق مالکیت محتوای خود را حفظ کرده و می‌توانید آن را در مکان‌های
        دیگر منتشر کنید.
        <br />
        9. اعلامیه قانونی
        <br />
        شما به تنهایی مسئول محتوایی هستید که در پلتفرم ما ایجاد و فرستاده
        می‌کنید. ما محتوای شما را تأیید یا تضمین نمی‌کنیم. ما مسئول اعمال یا
        محتوای فرستاده شده در پلتفرم ما نیستیم.
        <br />
        10. تغییرات در قوانین
        <br />
        ممکن است این قوانین و شرایط خدمات به صورت دوره‌ای به‌روزرسانی شوند.
        ایجاد کنندگان محتوا از هر تغییری مطلع می‌شوند و وظیفه شما است که نسخه
        جدید را بررسی کنید و با آن پیروی کنید.
        <br />
        با تبدیل شدن به یک ایجاد کننده محتوا در پلتفرم ما، شما موافقت می‌کنید که
        با این قوانین و شرایط خدمات پایبندی به عمل آورید. عدم انجام این کار ممکن
        است منجر به فسخ حساب ایجاد کننده محتوا شود یا تدابیر دیگری اعمال شود.
        <br />
        اگر سوال یا نگرانی‌ای در مورد این قوانین و شرایط دارید، لطفاً با ما تماس
        بگیرید .
        <br />
      </div>
    );
  }

  function SubmitBtn({ formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2 "
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
  function countrySet(countryShort) {
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    console.log(countryShort);
    const states = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    ).states;
    console.log(states);
    // const state = countryStates.find(
    //   (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    // ).states[0];
    setStates(states);

    formik.setValues((prev) => {
      const address = { ...prev.address };
      address.country = regionNames.of(countryShort).toLowerCase();
      address.countryShort = countryShort.toLowerCase();
      address.state = "";
      address.stateShort = "";
      address.cities = [];
      return { ...prev, address };
    });
  }
  function setCitiesHandle(value) {
    formik.setValues((prev) => {
      const address = { ...prev.address };
      address.cities = value;
      return { ...prev, address };
    });
  }

  function formikInitializer(data) {
    function reverseStringSpliter(splittedStrings) {
      const reversedField = {};
      for (const lang in splittedStrings) {
        if (splittedStrings.hasOwnProperty(lang)) {
          reversedField[lang] = splittedStrings[lang].join("+");
        }
      }
      return reversedField;
    }
    formik.setValues({
      action,
      lcs: data.lcs,
      img: data.img,
      lang: data.lang,
      terms: data.moreInfo.terms,
      name: data.name,
      email: data.email,
      address: data.moreInfo.address,
      phone: data.moreInfo.phone,
      bio: data.moreInfo.bio,
      firstName: data.moreInfo.firstName,
      lastName: data.moreInfo.lastName,
      albums: reverseStringSpliter(data.moreInfo.albums),
      born: data.moreInfo.born,
      genres: reverseStringSpliter(data.moreInfo.genres),
      occupations: reverseStringSpliter(data.moreInfo.occupations),
      instruments: reverseStringSpliter(data.moreInfo.instruments),
      activeSince: data.moreInfo.activeSince,
      tags: data.moreInfo.tags?.join("+"),
      website: data.moreInfo.website,
      spouse: reverseStringSpliter(data.moreInfo.spouse),
      childrens: data.moreInfo.childrens,
      socialMedia: data.moreInfo.socialMedia,
      _id: data._id,
    });
  }
  async function editHandle({
    messageRef,
    setLoading,
    user,
    values,
    navigate,
  }) {
    console.log("edit handle triggred");
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiEvent.editArtist({
      token: user.token,
      data: values,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div dir="ltr">{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: " پروفایل هنری با موفقیت ویرایش شد.",
    });
    formikInitializer(response.data);
    // window.scrollTo({ top: 0, behavior: "smooth" });

    // navigate(
    //   `/${window.lang}/media/filters?mediaId=${response.data._id}&title=${response.data.moreInfo.title}`
    // );
  }
}
