import "./sidebar.css";
import {
  ArrowForwardIos,
  AttachMoneyTwoTone,
  StorefrontTwoTone,
  LineStyleTwoTone,
  PersonTwoTone,
  PostAdd,
  ListAlt,
  ModeEdit,
  ArrowBackIos,
  PointOfSale,
  CurrencyExchange,
} from "@mui/icons-material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../../../contexts/cartContext";

export default function DashSidebar() {
  const { user } = useContext(CartContext);

  const dashUrl =
    window.location.origin +
    "/" +
    window.location.pathname.split("/").slice(1, 3).join("/");
  const matchUrlMain =
    window.location.origin +
    "/" +
    window.location.pathname.split("/").slice(1, 4).join("/");
  const matchUrlSub =
    window.location.origin +
    "/" +
    window.location.pathname.split("/").slice(1, 5).join("/");

  function renderCheck(arr1, arr2) {
    let set = new Set(arr1);
    for (let i = 0; i < arr2.length; i++) {
      if (set.has(arr2[i])) {
        return true;
      }
    }
    return false;
  }

  const navigate = useNavigate();

  function navigateHandle(url) {
    window.scrollTo(0, 0);
    setTimeout(() => navigate(url), 200);
  }

  return (
    <div className="sidebar-dash  ">
      {/* <input type="checkbox" id="menu" />
      <label
        htmlFor="menu"
        id="navIcon"
        className="profile-icon justify-content-start py-3 ps-3"
      >
        &#9776;
        <h3 className="sidbarHead ps-3">پنل کاربری من</h3>
      </label> */}
      <div className="dropdown-center display-tablet text-center pt-3 ">
        <button
          className="btn primary-btn my-dash-btn dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="bi bi-list font-20 pe-2"></i>
          پنل کاربری من
        </button>
        <ul className="dropdown-menu w-75 px-4 bg-nav-soft-dark">
          <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/profile/me`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/profile" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-person sidebarIcon"></i>پروفایل
          </li>
          <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/trans/invlist`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/trans" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-coin sidebarIcon"></i>
            تراکنش‌ها
          </li>
          <li
            onClick={(e) =>
              navigateHandle(
                `/${window.lang}/dashboard/user/demy/listmysessions`
              )
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/demy" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-book sidebarIcon"></i>
            متادمی
          </li>
          <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/saved/posts`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/saved" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-bookmark sidebarIcon"></i> ذخیره‌ها
          </li>
          <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/business/register`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/business" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-shop-window sidebarIcon "></i>
            کسب و کار
          </li>
          <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/blog/register`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/blog" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-pencil-square sidebarIcon"></i>
            وبلاگ
          </li>
          <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/ads/plans`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/ads" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-badge-ad sidebarIcon"></i>
            تبلیغات
          </li>
          <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/jobs/register`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/jobs" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-briefcase sidebarIcon"></i>
            کاریابی
          </li>
          <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/media/register`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/media" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-people sidebarIcon"></i>
            شبکه اجتماعی
          </li>
          <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/mp/usedregister`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/mp" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-people sidebarIcon"></i>
کی‌چی‌کجا          </li>

          {renderCheck(user.roles, [1000, 1200]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="BusinessCheckbox" />
              <label
                htmlFor="BusinessCheckbox"
                className={
                  dashUrl + "/business" === matchUrlMain
                    ? "sidebarTitle sidebarListItem active"
                    : "sidebarTitle sidebarListItem"
                }
              >
                Business directory 2
                <ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/business/register`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/business" + "/register" === matchUrlSub &&
                    "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Business Register
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/business/list/all`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/business" + "/list" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Business List
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(
                      `/${window.lang}/dashboard/business/categories`
                    )
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/business" + "/categories" === matchUrlSub &&
                    "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Edit Categories
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/business/waiting`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/business" + "/waiting" === matchUrlSub &&
                    "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Waiting List
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/business/edit/:id`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/business" + "/edit" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Edit Business
                </li>
              </ul>
            </div>
          )}

          {renderCheck(user.roles, [1000]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="promotionsCheck" />
              <label
                htmlFor="promotionsCheck"
                className={
                  dashUrl + "/promo" === matchUrlMain
                    ? "sidebarTitle sidebarListItem active"
                    : "sidebarTitle sidebarListItem"
                }
              >
                Promotion 3<ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/promo/create`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/promo" + "/create" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Create promotion
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/promo/list`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/promo" + "/list" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Promotions List
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/promo/rewards`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/promo" + "/rewards" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Rewards
                </li>
              </ul>
            </div>
          )}
          {renderCheck(user.roles, [1000, 1400]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="jobCheckbox" />
              <label
                htmlFor="jobCheckbox"
                className="sidebarTitle sidebarListItem"
              >
                Jobs 4<ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/jobs/register`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/jobs" + "/register" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Create Job
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/jobs/list`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/jobs" + "/list" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Jobs List
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/jobs/categories`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/jobs" + "/categories" === matchUrlSub &&
                    "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Edit Categories
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/jobs/edit/:id`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/jobs" + "/edit" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Edit Job
                </li>
              </ul>
            </div>
          )}
          {renderCheck(user.roles, [1000, 1800]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="SocialMediaCheckbox" />
              <label
                htmlFor="SocialMediaCheckbox"
                className={
                  dashUrl + "/media" === matchUrlMain
                    ? "sidebarTitle sidebarListItem active"
                    : "sidebarTitle sidebarListItem"
                }
              >
                Media Directory 10
                <ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/media/register`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/media" + "/register" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Create Media
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/media/list`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/media" + "/list" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Medias list
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/media/categories`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/media" + "/categories" === matchUrlSub &&
                    "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Edit Categories
                </li>
              </ul>
            </div>
          )}

          {renderCheck(user.roles, [1000, 1100]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="blogCheckbox" />
              <label
                htmlFor="blogCheckbox"
                className={
                  dashUrl + "/blog" === matchUrlMain
                    ? "sidebarTitle sidebarListItem active"
                    : "sidebarTitle sidebarListItem"
                }
              >
                Blog 5<ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/blog/createpost`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/blog" + "/createpost" === matchUrlSub &&
                    "active"
                  }`}
                >
                  <PostAdd className="sidebarIcon" />
                  Create post
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/blog/postsList`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/blog" + "/postsList" === matchUrlSub && "active"
                  }`}
                >
                  <ListAlt className="sidebarIcon" />
                  Posts List
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/blog/setting`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/blog" + "/setting" === matchUrlSub && "active"
                  }`}
                >
                  <ModeEdit className="sidebarIcon" />
                  Blog Setting
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/blog/categories`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/blog" + "/categories" === matchUrlSub &&
                    "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Edit Categories
                </li>
              </ul>
            </div>
          )}

          {renderCheck(user.roles, [1000]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="dashboardOverview" />
              <label
                htmlFor="dashboardOverview"
                className="sidebarTitle sidebarListItem"
              >
                Reports 6<ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/home`)
                  }
                  className={`sidebarListItem  link ${
                    matchUrlMain + "/home" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Home
                </li>
              </ul>
            </div>
          )}
          {renderCheck(user.roles, [1000]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="usersCheckbox" />
              <label
                htmlFor="usersCheckbox"
                className={
                  dashUrl + "/users" === matchUrlMain
                    ? "sidebarTitle sidebarListItem active"
                    : "sidebarTitle sidebarListItem"
                }
              >
                Users 7<ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/users/list`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/users" + "/list" === matchUrlSub && "active"
                  }`}
                >
                  <PersonTwoTone className="sidebarIcon" />
                  Users List
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/users/overView`)
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/users" + "/overView" === matchUrlSub && "active"
                  }`}
                >
                  <PersonTwoTone className="sidebarIcon" />
                  Users overView
                </li>
              </ul>
            </div>
          )}
          {renderCheck(user.roles, [1000, 1600]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="eventCheckboxMob" />
              <label
                htmlFor="eventCheckboxMob"
                className="sidebarTitle sidebarListItem"
              >
                Event 666
                <ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/event/register`)
                  }
                  className={`sidebarListItem  link ${
                    matchUrlMain + "/event" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Event Register 123
                </li>
              </ul>
            </div>
          )}
          {renderCheck(user.roles, [1000, 1500]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="eventCheckbox" />
              <label
                htmlFor="eventCheckbox"
                className="sidebarTitle sidebarListItem"
              >
                MetaDemy 7<ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/demy/register`)
                  }
                  className={`sidebarListItem  link ${
                    matchUrlMain + "/demy" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  MetaDemy Register 1
                </li>
              </ul>
            </div>
          )}
          {renderCheck(user.roles, [1000, 1700]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="rideCheckbox" />
              <label
                htmlFor="rideCheckbox"
                className="sidebarTitle sidebarListItem"
              >
                Ride Share 7<ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/ride`)
                  }
                  className={`sidebarListItem  link ${
                    matchUrlMain + "/ride" === matchUrlSub && "active"
                  }`}
                >
                  <LineStyleTwoTone className="sidebarIcon" />
                  Create Ride
                </li>
              </ul>
            </div>
          )}

          {renderCheck(user.roles, [1000, 1800]) && (
            <div className="sidebarItems">
              <input type="checkbox" id="dashLabel2" />
              <label
                htmlFor="dashLabel2"
                className="sidebarTitle sidebarListItem"
              >
                Store 9<ArrowBackIos className="arrowForwardIcon" />
              </label>
              <ul className="sidebarList">
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/store/currencies`)
                  }
                  className={`sidebarListItem  link ${
                    matchUrlMain + "/currencies" === matchUrlSub && "active"
                  }`}
                >
                  <CurrencyExchange className="sidebarIcon" /> Currencies
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(
                      `/${window.lang}/dashboard/store/trans/overview`
                    )
                  }
                  className={`sidebarListItem  link ${
                    dashUrl + "/store" + "/trans" === matchUrlSub && "active"
                  }`}
                >
                  <PointOfSale className="sidebarIcon" /> Transactions
                </li>
                <li
                  onClick={(e) =>
                    navigateHandle(`/${window.lang}/dashboard/Products`)
                  }
                  className={`sidebarListItem  link ${
                    matchUrlMain + "/Products" === matchUrlSub && "active"
                  }`}
                >
                  <StorefrontTwoTone className="sidebarIcon" /> Products
                </li>

                <li>
                  <div className="subItem">
                    <input type="checkbox" id="subMenuProducts" />
                    <label
                      htmlFor="subMenuProducts"
                      className="sidebarListItem link"
                      style={{
                        fontWeight: "600",
                        fontSize: "1.1em",
                        marginLeft: "0.5em",
                      }}
                    >
                      Store Users
                      <ArrowForwardIos className="arrowForwardIcon" />
                    </label>
                    <ul className="sidebarList">
                      <li
                        onClick={(e) =>
                          navigateHandle(
                            `/${window.lang}/dashboard/Transactions`
                          )
                        }
                        className={`sidebarListItem  link ${
                          matchUrlMain + "/Transactions" === matchUrlSub &&
                          "active"
                        }`}
                      >
                        <AttachMoneyTwoTone className="sidebarIcon" />
                        Suppliers
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </ul>
      </div>

      <div className="display-none-md bg-nav-soft-dark d-flex h-100">
        <div
          className="profile-icon justify-content-start display-none-md mt-4"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseWidthExample"
          aria-expanded="true"
          aria-controls="collapseWidthExample"
        >
          <i className="bi bi-list px-3 py-2"></i>
        </div>

        <div
          className="sidebar side-collapse show collapse collapse-horizontal mt-0"
          id="collapseWidthExample"
        >
          {renderCheck(user.roles, [1000, 1001, 9999]) && (
            <div className="sidebarWrapper bg-nav-soft-dark">
              <div className="sidebarItems">
                <input type="checkbox" id="dashLabel" />
                <label
                  htmlFor="dashLabel"
                  className={`sidebarTitle sidebarListItem  ${
                    dashUrl + "/user" === matchUrlMain && "active"
                  }`}
                >
                  پنل کاربری من
                  {/* <ArrowBackIos className="arrowForwardIcon" /> */}
                </label>
                <ul className="sidebarList mx-auto">
                  <li
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/dashboard/user/profile/me`)
                    }
                    className={`sidebarListItem  link ${
                      dashUrl + "/user" + "/profile" === matchUrlSub && "active"
                    }`}
                  >
                    <i className="bi bi-person sidebarIcon"></i>پروفایل
                  </li>
                  <li
                    onClick={(e) =>
                      navigateHandle(
                        `/${window.lang}/dashboard/user/trans/invlist`
                      )
                    }
                    className={`sidebarListItem  link ${
                      dashUrl + "/user" + "/trans" === matchUrlSub && "active"
                    }`}
                  >
                    <i className="bi bi-coin sidebarIcon"></i>
                    تراکنش‌ها
                  </li>
                  <li
                    onClick={(e) =>
                      navigateHandle(
                        `/${window.lang}/dashboard/user/demy/listmysessions`
                      )
                    }
                    className={`sidebarListItem  link ${
                      dashUrl + "/user" + "/demy" === matchUrlSub && "active"
                    }`}
                  >
                    <i className="bi bi-book sidebarIcon"></i>
                    متادمی
                  </li>
                  <li
                    onClick={(e) =>
                      navigateHandle(
                        `/${window.lang}/dashboard/user/saved/posts`
                      )
                    }
                    className={`sidebarListItem  link ${
                      dashUrl + "/user" + "/saved" === matchUrlSub && "active"
                    }`}
                  >
                    <i className="bi bi-bookmark sidebarIcon"></i> ذخیره‌ها
                  </li>
                  <li
                    onClick={(e) =>
                      navigateHandle(
                        `/${window.lang}/dashboard/user/business/register`
                      )
                    }
                    className={`sidebarListItem  link ${
                      dashUrl + "/user" + "/business" === matchUrlSub &&
                      "active"
                    }`}
                  >
                    <i className="bi bi-shop-window sidebarIcon "></i>
                    کسب و کار
                  </li>
                  <li
                    onClick={(e) =>
                      navigateHandle(
                        `/${window.lang}/dashboard/user/blog/register`
                      )
                    }
                    className={`sidebarListItem  link ${
                      dashUrl + "/user" + "/blog" === matchUrlSub && "active"
                    }`}
                  >
                    <i className="bi bi-pencil-square sidebarIcon"></i>
                    وبلاگ
                  </li>
                  <li
                    onClick={(e) =>
                      navigateHandle(`/${window.lang}/dashboard/user/ads/plans`)
                    }
                    className={`sidebarListItem  link ${
                      dashUrl + "/user" + "/ads" === matchUrlSub && "active"
                    }`}
                  >
                    <i className="bi bi-badge-ad sidebarIcon"></i>
                    تبلیغات
                  </li>
                  <li
                    onClick={(e) =>
                      navigateHandle(
                        `/${window.lang}/dashboard/user/jobs/register`
                      )
                    }
                    className={`sidebarListItem  link ${
                      dashUrl + "/user" + "/jobs" === matchUrlSub && "active"
                    }`}
                  >
                    <i className="bi bi-briefcase sidebarIcon"></i>
                    کاریابی
                  </li>
                  <li
                    onClick={(e) =>
                      navigateHandle(
                        `/${window.lang}/dashboard/user/media/register`
                      )
                    }
                    className={`sidebarListItem  link ${
                      dashUrl + "/user" + "/media" === matchUrlSub && "active"
                    }`}
                  >
                    <i className="bi bi-people sidebarIcon"></i>
                    شبکه اجتماعی
                  </li>
                  <li
            onClick={(e) =>
              navigateHandle(`/${window.lang}/dashboard/user/mp/usedregister`)
            }
            className={`sidebarListItem  link ${
              dashUrl + "/user" + "/mp" === matchUrlSub && "active"
            }`}
          >
            <i className="bi bi-people sidebarIcon"></i>
            کی‌چی‌کجا 
          </li>
                </ul>
              </div>

              {renderCheck(user.roles, [1000, 1200]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="BusinessCheckbox2" />
                  <label
                    htmlFor="BusinessCheckbox2"
                    className={
                      dashUrl + "/business" === matchUrlMain
                        ? "sidebarTitle sidebarListItem active"
                        : "sidebarTitle sidebarListItem"
                    }
                  >
                    Business directory 2
                    <ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/business/register`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/business" + "/register" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Create Business
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/business/schedule`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/business" + "/register" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Create Schedule
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/business/list/all`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/business" + "/list" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Business List
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/business/categories`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/business" + "/categories" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Edit Categories
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/business/waiting`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/business" + "/waiting" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Waiting List
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/business/edit/:id`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/business" + "/edit" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Edit Business
                    </li>
                  </ul>
                </div>
              )}

              {renderCheck(user.roles, [1000]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="promotionsCheck2" />
                  <label
                    htmlFor="promotionsCheck2"
                    className={
                      dashUrl + "/promo" === matchUrlMain
                        ? "sidebarTitle sidebarListItem active"
                        : "sidebarTitle sidebarListItem"
                    }
                  >
                    Promotion 3<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/promo/create`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/promo" + "/create" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Create promotion
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/promo/list`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/promo" + "/list" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Promotions List
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/promo/rewards`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/promo" + "/rewards" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Rewards
                    </li>
                  </ul>
                </div>
              )}
              {renderCheck(user.roles, [1000, 1400]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="jobCheckbox2" />
                  <label
                    htmlFor="jobCheckbox2"
                    className="sidebarTitle sidebarListItem"
                  >
                    Jobs 4<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/jobs/register`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/jobs" + "/register" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Create Job
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/jobs/list`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/jobs" + "/list" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Jobs List
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/jobs/registerready`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/jobs" + "/registerready" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Register ready
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/jobs/listready`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/jobs" + "/registerready" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Register List Ready
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/jobs/registerhide`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/jobs" + "/registerhide" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Register hide
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/jobs/listhide`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/jobs" + "/registerhide" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Register List hide
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/jobs/categories`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/jobs" + "/categories" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Edit Categories
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/jobs/edit/:id`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/jobs" + "/edit" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Edit Job
                    </li>
                  </ul>
                </div>
              )}
              {renderCheck(user.roles, [1000, 1800]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="SocialMediaCheckbox2" />
                  <label
                    htmlFor="SocialMediaCheckbox2"
                    className={
                      dashUrl + "/media" === matchUrlMain
                        ? "sidebarTitle sidebarListItem active"
                        : "sidebarTitle sidebarListItem"
                    }
                  >
                    Media Directory 10
                    <ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/media/register`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/media" + "/register" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Create Media
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/media/list`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/media" + "/list" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Medias list
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/media/categories`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/media" + "/categories" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Edit Categories
                    </li>
                  </ul>
                </div>
              )}

              {renderCheck(user.roles, [1000, 1100]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="blogCheckbox2" />
                  <label
                    htmlFor="blogCheckbox2"
                    className={
                      dashUrl + "/blog" === matchUrlMain
                        ? "sidebarTitle sidebarListItem active"
                        : "sidebarTitle sidebarListItem"
                    }
                  >
                    Blog 5<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/blog/createpost`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/blog" + "/createpost" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <PostAdd className="sidebarIcon" />
                      Create post
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/blog/postsList`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/blog" + "/postsList" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <ListAlt className="sidebarIcon" />
                      Posts List
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/blog/setting`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/blog" + "/setting" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <ModeEdit className="sidebarIcon" />
                      Blog Setting
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/blog/categories`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/blog" + "/categories" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Edit Categories
                    </li>
                  </ul>
                </div>
              )}

              {renderCheck(user.roles, [1000]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="dashboardOverview2" />
                  <label
                    htmlFor="dashboardOverview2"
                    className="sidebarTitle sidebarListItem"
                  >
                    Reports 6<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/home`)
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/home" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Home
                    </li>
                  </ul>
                </div>
              )}
              {renderCheck(user.roles, [1000]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="usersCheckbox2" />
                  <label
                    htmlFor="usersCheckbox2"
                    className={
                      dashUrl + "/users" === matchUrlMain
                        ? "sidebarTitle sidebarListItem active"
                        : "sidebarTitle sidebarListItem"
                    }
                  >
                    Users 7<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/users/list`)
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/users" + "/list" === matchUrlSub && "active"
                      }`}
                    >
                      <PersonTwoTone className="sidebarIcon" />
                      Users List
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/users/overView`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/users" + "/overView" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <PersonTwoTone className="sidebarIcon" />
                      Users overView
                    </li>
                  </ul>
                </div>
              )}
              {renderCheck(user.roles, [1000, 1600]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="eventCheckbox2" />
                  <label
                    htmlFor="eventCheckbox2"
                    className="sidebarTitle sidebarListItem"
                  >
                    Event 6<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/event/register`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/event" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Event Register
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/event/registerart`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/event" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Artist Register
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/event/listart`)
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/event" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Artist list
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/event/listevent`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/event" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Events list
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/event/categories`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/event" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Event Categories
                    </li>
                  </ul>
                </div>
              )}
              {renderCheck(user.roles, [1000, 1500]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="demyCheckbox2" />
                  <label
                    htmlFor="demyCheckbox2"
                    className="sidebarTitle sidebarListItem"
                  >
                    MetaDemy 8<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/demy/registerprogram`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/demy" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Program Register
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/demy/registercenter`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/demy" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Center Register
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/demy/listCenters`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/demy" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Centers list
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/demy/registertutor`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/demy" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Tutor Register
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/demy/listtutors`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/demy" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Tutors list
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/demy/listrequests`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/demy" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Tutor requests list
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/demy/listsessions`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/demy" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Session requests List
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/demy/listprograms`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/demy" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Programs list
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/demy/categoriesTutor`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/demy" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Tutor Categories
                    </li>
                  </ul>
                </div>
              )}
              {renderCheck(user.roles, [1000, 1500]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="marketolaceCheckbox2" />
                  <label
                    htmlFor="marketolaceCheckbox2"
                    className="sidebarTitle sidebarListItem"
                  >
                    MarketPlace 9<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/mp/registerused`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/mp" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Used Register
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/mp/categoriesUsed`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/mp" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Used Cats
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/mp/registermatch`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/mp" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Match Register
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/mp/categoriesMatch`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/mp" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Match Cats
                    </li>
            
                  </ul>
                </div>
              )}

              {renderCheck(user.roles, [1000, 1700]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="rideCheckbox2" />
                  <label
                    htmlFor="rideCheckbox2"
                    className="sidebarTitle sidebarListItem"
                  >
                    Ride Share 7<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/ride`)
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/ride" === matchUrlSub && "active"
                      }`}
                    >
                      <LineStyleTwoTone className="sidebarIcon" />
                      Create Ride
                    </li>
                  </ul>
                </div>
              )}

              {renderCheck(user.roles, [1000, 1800]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="dashLabel22" />
                  <label
                    htmlFor="dashLabel22"
                    className="sidebarTitle sidebarListItem"
                  >
                    Store 9<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/store/currencies`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/currencies" === matchUrlSub && "active"
                      }`}
                    >
                      <CurrencyExchange className="sidebarIcon" /> Currencies
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/store/trans/overview`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/store" + "/trans" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <PointOfSale className="sidebarIcon" /> Transactions
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/Products`)
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/Products" === matchUrlSub && "active"
                      }`}
                    >
                      <StorefrontTwoTone className="sidebarIcon" /> Products
                    </li>

                    <li>
                      <div className="subItem">
                        <input type="checkbox" id="subMenu" />
                        <label
                          htmlFor="subMenu"
                          className="sidebarListItem m-1 link"
                          style={{
                            fontWeight: "600",
                            fontSize: "1.1em",
                            marginLeft: "0.5em",
                          }}
                        >
                          Store Users
                          <ArrowForwardIos className="arrowForwardIcon" />
                        </label>
                        <ul className="sidebarList">
                          <li
                            onClick={(e) =>
                              navigateHandle(
                                `/${window.lang}/dashboard/Transactions`
                              )
                            }
                            className={`sidebarListItem  link ${
                              matchUrlMain + "/Transactions" === matchUrlSub &&
                              "active"
                            }`}
                          >
                            <AttachMoneyTwoTone className="sidebarIcon" />
                            Suppliers
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
              {renderCheck(user.roles, [1000]) && (
                <div className="sidebarItems">
                  <input type="checkbox" id="dashLabelWallet" />
                  <label
                    htmlFor="dashLabelWallet"
                    className="sidebarTitle sidebarListItem"
                  >
                    Wallet 10<ArrowBackIos className="arrowForwardIcon" />
                  </label>
                  <ul className="sidebarList">
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/wallet/myWallet`
                        )
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/wallet"  + "/myWallet"=== matchUrlSub && "active"
                      }`}
                    >
                      <CurrencyExchange className="sidebarIcon" /> My Wallet
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(
                          `/${window.lang}/dashboard/wallet/pool`
                        )
                      }
                      className={`sidebarListItem  link ${
                        dashUrl + "/wallet" + "/pool" === matchUrlSub &&
                        "active"
                      }`}
                    >
                      <PointOfSale className="sidebarIcon" /> Transactions
                    </li>
                    <li
                      onClick={(e) =>
                        navigateHandle(`/${window.lang}/dashboard/Products`)
                      }
                      className={`sidebarListItem  link ${
                        matchUrlMain + "/Products" === matchUrlSub && "active"
                      }`}
                    >
                      <StorefrontTwoTone className="sidebarIcon" /> Products
                    </li>

                   
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
