import { Link } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function Privacy() {
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "درباره متامارس",
      description:
        "یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",

      url: "https://www.metamarce.com/fa/prompt/privacy",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/privacy`,
              name: "متامارس | حفظ حریم شخصی | Privacy policy",
              description:
                "سیاست حفظ حریم خصوصی توضیح می‌دهد که چگونه متامارس از اطلاعات کاربران در هنگام ورود محافظت و استفاده می نماید.",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "متامارس | حفظ حریم شخصی | Privacy policy",
    description:
      "سیاست حفظ حریم خصوصی توضیح می‌دهد که چگونه متامارس از اطلاعات کاربران در هنگام ورود محافظت و استفاده می نماید.",
    section: "Privacy",
    url: `https://www.metamarce.com/${window.lang}/prompt/privacy`,
    canonical: `https://www.metamarce.com/${window.lang}/prompt/privacy`,

    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="d-flex flex-column justify-content-center align-items-center mx-md-5 mx-2">
        <div>
          <h1 className="titr1 font-40 my-5">
            سیاست حفظ حریم خصوصی کاربران متامارس
          </h1>
          <p>
            سیاست حفظ حریم خصوصی توضیح می‌دهد که چگونه متامارس اطلاعاتی را که
            کاربران در هنگام ورود ارائه می‌دهند، جمع‌آوری، استفاده و حفاظت
            می‌کند.
          </p>

          <h2 className="titr7 text-start mt-3 mt-md-4">
            ۱. اطلاعات جمع‌آوری‌شده
          </h2>
          <ul className="flex-column">
            <li>شناسه کاربری </li>
            <li>آدرس ایمیل در زمان ورود </li>
            <li>و اطلاعات پروفایل شما (مانند نام و تصویر پروفایل)</li>
            <li>
              کد احراز هویت شما که برای احراز هویت و دسترسی به پروفایل استفاده
              می‌شود
            </li>
          </ul>

          <h2 className="titr7 text-start mt-3 mt-md-4">
            ۲. استفاده از اطلاعات
          </h2>
          <ul className="flex-column">
            <li>ایجاد و مدیریت حساب کاربری شما</li>
            <li>بهبود و شخصی‌سازی تجربه کاربری شما</li>
            <li>
              ارتباط با شما از جمله فرستادن به‌روزرسانی‌ها و اطلاعیه‌های مهم
            </li>
            <li>حفظ امنیت و اصالت برنامه وب ما</li>
            <li>رعایت الزامات قانونی و نظارتی</li>
          </ul>

          <h2 className="titr7 text-start mt-3 mt-md-4">
            ۳. دسترسی به اطلاعات توسط شخص ثالث
          </h2>
          <p>
            ما اطلاعات ورود شما را به اشخاص ثالث به اشتراک نمی‌گذاریم، مگر در
            موارد زیر:
          </p>
          <ul className="flex-column">
            <li>برای رعایت تعهدات قانونی </li>
            <li>
              یا ارائه‌دهندگان خدمات قابل اعتماد (شخص ثالث) که در عملکرد برنامه
              به ما کمک می‌کنند. مانند google.
            </li>
            <li>
              در صورت ادغام، یا فروش بخشی از دارایی‌های ما، اطلاعات شما ممکن است
              به شرکت دریافت‌کننده منتقل شود
            </li>
          </ul>

          <h2 className="titr7 text-start mt-3 mt-md-4">۴. امنیت داده</h2>
          <p>
            ما از تدابیر امنیتی استاندارد برای حفاظت از اطلاعات شما استفاده
            می‌کنیم. با این حال، لطفاً به یاد داشته باشید که هیچ انتقال داده ای
            از طریق اینترنت یا ذخیره‌سازی الکترونیک به‌طور کامل ایمن نیست.
          </p>

          <h2 className="titr7 text-start mt-3 mt-md-4">۵. گزینه‌های شما</h2>
          <p>
            شما حق دارید هر زمان اطلاعات حساب کاربری خود را بررسی، به‌روزرسانی
            یا حذف کنید. این کار را با ورود به حساب کاربری خود یا تماس مستقیم با
            ما می‌توانید انجام دهید.
          </p>
          <h2 className="titr7 text-start mt-3 mt-md-4">
            ۶. تغییرات در سیاست حفظ حریم خصوصی
          </h2>
          <p>
            در هر زمان ممکن است ما سیاست حفظ حریم خصوصی را به‌روز کنیم تا بتواند
            منعکس کنده تغییرات در متامارس یا الزامات قانونی باشد. مطمئن باشید که
            هرگونه تغییرات بر روی این صفحه قرار داده خواهد شد و ما توصیه می‌کنیم
            که هر چند وقت یک بار این سیاست‌ها را مرور کنید.
          </p>
          <h2 className="titr7 text-start mt-3 mt-md-4">۷. تماس با ما</h2>
          <p>
            اگر سوال یا نگرانی‌ای در مورد روش‌های حفظ حریم خصوصی ما یا گزینه‌های
            سیاست حفظ حریم خصوصی دارید، لطفاً با ما به آدرس metamarce@gmail.com
            تماس بگیرید. با ورود و ثبت‌نام در وبسایت ما، شما با شرایط سیاست حفظ
            حریم خصوصی موافقت می‌کنید.
          </p>
        </div>

        {/* <div className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2"> */}
        <Link
          className="btn primary-btn btn-404 checkout-btn my-5"
          onClick={(e) => window.scroll(0, 0)}
          to={`/${window.lang}/prompt/disclaimer`}
        >
          شرایط استفاده از متامارس (بیانیه رفع مسئولیت)
        </Link>
        {/* </div> */}

        <div
          dir="ltr"
          className="container-404 d-flex flex-column align-items-start "
        >
          {/* <img className=" img-404 p-0 m-0" src="/img/AccessDenied.png" alt="AccessDenied" /> */}
          <div className="mb-3 text-end mt-5" dir="ltr">
            <h1 className="titr1 font-40 my-5 text-center">
              Metamarce Privacy Policy for user registration
            </h1>
            This Privacy Policy explains how Metamarce collects, uses, and
            protects the information provided by users when logging in to our
            web application.
            <h2 className="titr7 mt-3 mt-md-4 text-end ">
              1. Information Collected
            </h2>
            When you log in to our web application, we may collect the following
            information: Your login provider user ID Your email address
            associated with your login. Your profile information (e.g., name,
            profile picture) Access to your OAuth token, which we use to
            authenticate and authorize your access to our application
            <h2 className="titr7 mt-3 mt-md-4 text-end">
              2. Use of Information
            </h2>
            We collect and use the information mentioned above for the following
            purposes: To create and manage your account To personalize and
            improve your user experience To communicate with you, including
            sending important updates and notifications To ensure the security
            and integrity of our web application To comply with legal and
            regulatory requirements
            <h2 className="titr7 mt-3 mt-md-4 text-end">
              3. Third-Party Access
            </h2>
            We do not share, sell, or disclose your login information to third
            parties except in the following cases: To comply with legal
            obligations or protect our rights and interests. With trusted
            third-party service providers who assist in the operation of our web
            application and are bound by confidentiality. In the event of a
            merger, acquisition, or sale of all or a portion of our assets, your
            information may be transferred to the acquiring entity.
            <h2 className="titr7 mt-3 mt-md-4 text-end">4. Data Security </h2>
            We employ industry-standard security measures to protect your
            information. However, please be aware that no data transmission over
            the internet or electronic storage is entirely secure.
            <h2 className="titr7 mt-3 mt-md-4 text-end"> 5. Your Choices </h2>
            You have the right to review, update, or delete your account
            information at any time. You can do this by logging into your
            account or contacting us directly.
            <h2 className="titr7 mt-3 mt-md-4 text-end">
              6. Changes to this Privacy Policy
            </h2>
            We may update this Privacy Policy to reflect changes in our data
            practices or legal requirements. Any changes will be posted on this
            page, and we recommend reviewing this policy periodically.
            <h2 className="titr7 mt-3 mt-md-4 text-end"> 7. Contact Us </h2>
            If you have any questions or concerns about our privacy practices or
            this Privacy Policy, please contact us at metamarce.com@gmail.com.
            By logging in to our web application, you agree to the terms of this
            Privacy Policy. If you do not agree with any part of this policy,
            please do not use our services.
            {/* <div
            className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-5"
            onClick={(e) => window.close()}
          >
            بستن
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
