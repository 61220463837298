import React, { useContext, useEffect, useRef, useState } from "react";
import { CircularProgress, Rating } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import tools from "../../../../utils/tools";
import CommentsRating from "../../../comment/commentsRating";

export default function HideCard(props) {
  const section = "hide";
  // console.log(props);
  const [data, setData] = useState(props.data);
  useEffect(() => setData(props.data), [props.data._id]);
  const repliesCounter = (() => {
    const comments = data?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  })();
  const messageRef = useRef(null);
  const [spiner, setSpiner] = useState(false);
  const { signedUser, user } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: data._id,
      section,
      token: user.token,
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> دوباره وارد شوید.</div>
          </div>
        ),
      });
    signedUser(response.data);

    !user?.saved?.[section]?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "کار در گوشه و کنار با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "کار در گوشه و کنار از لیست ذخیره حذف شد.",
        });
  };

  return (
    <div key={data._id} className="col px-3 ">
      <ToastNote messageRef={messageRef} />
      <div className="card entry p-md-2 p-1 mb-0 ">
        <div className="d-flex flex-column justify-content-end flex-grow-1 px-1">
          <div className="bold-dark-font font-18 mt-3 ">
            <div
            // to={`/${window.lang}/jobs/hide/page/${data._id}`}
            >
              {data?.title}
            </div>
            <div className="small-gray-font my-3">
                      {tools.timeAgo(data?.moreInfo?.lastUpdate)}
            </div>
          </div>

          <div
            className=" my-1 small-gray-font d-flex flex-wrap  justify-content-end "
            dir="ltr"
          >
            <i className="bi bi bi-geo-alt mx-2"></i>
            {`${window.capitalize(data?.moreInfo.address?.city || "")},
                    ${window.capitalize(data?.moreInfo.address?.state)},
                    ${window.capitalize(data?.moreInfo.address?.countryShort)}
                `}
          </div>

          <div className="med-gray-font my-3">
            {tools.linesSpliter(data?.moreInfo?.description)}
          </div>
          {/* <div>{data?.email}</div>
            <div>{tools.formatPhoneNumber(data?.moreInfo?.phone)}</div> */}
              {data?.moreInfo?.link && (
                  <div >
                    <a href={data?.moreInfo?.link} target="_new"> <i className="bi bi-link mx-1"></i> تماس  </a>
                  </div>
                )}

          <div className=" d-flex justify-content-center">
            <img
              src={data?.moreInfo?.img}
              // className="picture-src"
              style={{ maxHeight: "700px", width: "auto", maxWidth: "100%" }}
              // id="wizardPicturePreview"
              title=""
            />
          </div>
          <div className=" card-footer  d-flex flex-wrap  justify-content-end">
            <div className="d-flex  justify-content-between">
              <div
                className="cart-nav "
                onClick={(e) => {
                  const el = document.querySelector(
                    `#commentContainer${data._id}`
                  );
                  el.style.display =
                    el.style.display === "none" ? "block" : "none";
                }}
              >
                <button
                  title="دیدگاه ها"
                  id={`commentBtn${data._id}`}
                  className="icon-btn icon-stack border-0"
                >
                  <i className="bi bi-chat icon-btn font-25" />
                </button>
                <span className="cart-count">
                  {tools.dynamicNumber({
                    number: repliesCounter,
                    lang: window.lang,
                  })}
                </span>
              </div>
              <div>
                <button
                  id={`saveBtn${data._id}`}
                  className="icon-btn icon-stack"
                  onClick={saveHandler}
                >
                  {spiner ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <i
                      className={
                        user.saved?.[section]?.includes(data._id)
                          ? "bi bi-bookmark-fill icon-stack-1x"
                          : "bi bi-bookmark icon-stack-1x"
                      }
                      title="ذخیره"
                      // style={{ color: "rgba(123, 99, 90, 0.894)" }}
                    ></i>
                  )}
                </button>
              </div>

              <div>
                <button
                  className=" icon-stack mx-1 "
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data._id}`}
                  id="shareBtn"
                  data-bs-placement="top"
                  title="Share!"
                >
                  <i className="bi bi-share" title="همرسانی"></i>
                </button>
              </div>
              <ShareSocialMedias
                url={`${window.location.origin}/${window.lang}/hide/filters`}
                title={data?.title}
                img={data?.moreInfo.img}
                _id={data?._id}
              />

              {user?.roles?.includes(1000) && (
                <button
                  id={`editBtn${data._id}`}
                  className="icon-btn icon-stack"
                  onClick={(e) =>
                    window.navigateHandle(
                      `/${window.lang}/dashboard/jobs/edithide/${data._id}`
                    )
                  }
                >
                  <i
                    className="bi bi-pencil icon-stack-1x"
                    title="ویرایش"
                    // style={{ color: "rgba(123, 99, 90, 0.894)" }}
                  ></i>
                </button>
              )}
            </div>
          </div>
          <div id={"commentContainer" + data._id} style={{ display: "none" }}>
            <CommentsRating
              id={data?._id}
              value={data}
              setData={setData}
              collection="Hide"
            />
            <div className="w-100 text-end">
              <button
                id={`closeBtn${data._id}`}
                className="icon-btn icon-stack p-0"
                onClick={(e) => {
                  const el = document.querySelector(
                    `#commentContainer${data._id}`
                  );
                  el.style.display =
                    el.style.display === "none" ? "block" : "none";
                }}
              >
                <i class="bi bi-x-lg  "></i>
              </button>
            </div>
          </div>
        </div>

        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
