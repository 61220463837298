import { useState, useRef, useEffect, useContext, useMemo } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import api from "../../../../utils/apiMP";
// import Cluster from "../../../googleMap/googleCluster";
import renderData from "../../../../utils/renderData";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import { CartContext } from "../../../contexts/cartContext";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import PlansX4 from "../../../golbal/sliders/plansX4";
import BusSmallCardSlider from "../../../golbal/sliders/busCardSliderS1";
import MainSidebar from "../../media/components/mainSidebar";
import tools from "../../../../utils/tools";
import MetaDecorator from "../../../../utils/metaDecorator";
// import CenterCard from "../components/hideCard";
import { Checkbox, CircularProgress, TextField } from "@mui/material";
import BlogSearch from "../../../persian/common/pagination/searchPagination";
import countryStates from "../../../../utils/countryStates";
import NewPosts2x2 from "../../../golbal/sliders/components/newPosts2x2";
// import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
import UsedCard from "../components/usedCard";
import FiltersCatsSelector from "../../../golbal/filtersCatsSelector";
// import demyData from "../../../../utils/demyData";
// import Slider from "@mui/material/Slider";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ToastNote from "../../../golbal/notifications/toastNote";

export default function UsedFilters() {
  const navigate = useNavigate();
  const section = "used";
  const page = "usedFilters"; 
  const { lang, state, country, countryShort, stateInfo } =
    tools.getItemFromLocalStorage("initSetup", {});
  // console.log("countryInfo", countryInfo);
  const { user } = useContext(CartContext);
  const [pubData] = useOutletContext();
  const messageRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [view, setView] = useState({
  //   thumbnail: true,
  //   cluster: false,
  // });
  const [states, setStates] = useState([]);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [loading, setLoading] = useState({ spinner: false });
  const [data, setData] = useState([]);
  // console.log("data", data);
  // const currentYear = useMemo(()=>new Date().getFullYear(),[])
  const initialFilters = {
    lang,
    country,
    countryShort,
    states: [],
    state: "",
    stateShort: "",
    cities: [],
    mainCatId: "",
    subCat: [],
    subCatId: [],
    userId: "",
    userName: "",
    searchKey: "",
    tag: "",
    isFree: "",
    status: [],
    img: "",
    // price: {},
    condition: [],
    delivery: [],
    pageNumber: 1,
    itemsToShow: 12,
    sort: "default",
    lotMin: "",
    lotMax: "",
    homeYearStart: "",
    homeYearEnd: "",
    priceMin: "",
    priceMax: "",
    beds: "",
    bath: "",
    parkingQty: "",
    transType: [],
    proType: [],
    parking: [],
    airCondition: [],
    heating: [],
    hFacilities: [],
    carYearStart: "",
    carYearEnd: "",
    carCompanies: [],
    bodyTypes: [],
    transmission: [],
    fuelTypes: [],
    activityType : [],
    color: "",
    odometerMin: "",
    odometerMax: "",
    dateStart: "",
    dateEnd: "",
    depPort: "",
    arrPort: "",

    class: [],
  };
  
  const storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];
  const [filters, setFilters] = useState(tools.filtersInitiate(initialFilters,storedFilters,state.state));
  console.log("filters",filters)
  const [backFilters, setBackFilters] = useState({ ...filters });
  // console.log('backFilters',backFilters);

  const effectRan = useRef(true);
  useEffect(() => {
    if (effectRan.current) return;
    fetchData();
    setBackFilters({ ...filters });
    storeFilters({ section, filters: { ...filters } });
  }, [filters, window.lcs]);
  function storeFilters({ section, filters }) {
    const storedFilters =
      JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }

  useEffect(() => {
    // const keys = Array.from(searchParams.keys())
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
    countrySet(countryShort, "first");

    if (Object.keys(params).length > 0) {
      setFilters((pre) => ({
        ...pre,
        country,
        mainCat: params?.mainCat ?? pre.mainCat,
        mainCatId: params?.mainCatId ?? pre.mainCatId,
        subCat: params?.subCat ? [params?.subCat] : pre.subCat,
        subCatId: params?.subCatId ? [params?.subCatId] : pre.subCatId,
        userId: params?.userId ? params?.userId : pre.userId,
        userName: params?.userName ? params?.userName : pre.userName,
        cities: params?.cities ? [params?.cities] : pre.cities,
        searchKey: params?.searchKey ?? pre.searchKey,
        tag: params?.tag ?? pre.tag,

      }));
    }
    // if(params?.mainCatId)mainCatHandle(params?.mainCat,params?.mainCatId,)
    effectRan.current = false;
  }, [searchParams]);



  function queryRemover(field) {
    // console.log(field);
    searchParams?.delete(field);
    const searchString = searchParams?.toString();

    navigate(`?${searchString}`);
  }

  async function fetchData() {
    setLoading((pre) => ({
      ...pre,
      spinner: true,
    }));
    const response = await api.filtersUsed({ filters });
    setLoading((pre) => ({
      ...pre,
      spinner: false,
    }));
    if (response?.error) return alert(response?.error);
    setResultsTotal(response.data.resultsTotal);
    setData(response.data.data);
    // console.log("response.data.data", response.data.data);
  }

  async function searchHandle(e) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;

    filters.searchKey = value;
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: value,
        pageNumber: 1,
      }));
    }
  }
  function deleteFilter(e) {
    const field = e.currentTarget.id;
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
                queryRemover("mainCatId");
              }
              if (key === "userName") {
                newFilters.userId = "";
                queryRemover("userId");
              }
              // if (key === "country") {
              //   newFilters.countryShort = "";

              // }
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    // effectRan.current = false;
    setFilters(update());
  }

  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }
    setFilters(update());
  }

  const selectedFiltersRender = (() => {
    let rows = [];

    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (
            key !== "states" &&
            key !== "cities" &&
            key !== "subCat" &&
            key !== "subCatId"
          ) {
            const translate = renderData[key]?.find(
              (option1) => option1.name === option
            );
            // key === "languages"
            //   ? renderData[key]?.find((option1) => option1.name === option)
            //   : renderData[key]?.find((option1) => option1.name === option);
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                {/* <span>{option} </span>        */}
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "states" || key === "cities" || key === "subCat") {
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                {/* <span>{translate.nameFa} </span> */}
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&
        key !== "userId" &&
        key !== "country" &&
        key !== "countryShort" &&
        key !== "stateShort" &&        
        key !== "state" &&
        key !== "section" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&
        key !== "mainCatId" &&
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {key === "homeYearStart" && "سال ساخت از "}
              {key === "homeYearEnd" && "سال ساخت تا "}
              {key === "priceMin" && "قیمت از "}
              {key === "priceMax" && "قیمت تا "}
              {key === "lotMin" && "زمین از(متر) "}
              {key === "lotMax" && "زمین تا(متر) "}
              {key === "parkingQty" && "پارکینگ داخلی "}
              {key === "beds" && "اطاق خواب "}
              {key === "bath" && "سرویس دستشویی "}
              {key === "odometerMin" && "کارکرداز(کیلومتر) "}
              {key === "odometerMax" && "کارکرد تا(کیلومتر) "}
              {key === "carYearStart" && "سال ساخت وسیله از "}
              {key === "carYearEnd" && "سال ساخت وسیله تا "}
              {filters[key]}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  })();

  function setCitiesHandle(value) {
    setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
    // effectRan.current = false;
  }
  async function viewMorePosts(e) {
    e.preventDefault();
    setFilters((pre) => ({
      ...pre,
      pageNumber: pre.pageNumber + 1,
    }));
  }

  // const { translations, states: states0 } =
  //   JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `کی چی کجا`;
  const url = `https://www.metamarce.com/${window.lang}/mp/used/filters`;
  const structuredData = [    
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: title,
      url:window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },

          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/mp/used/filters`,
              name: `کی چی کجا`,
              description: ` نمایش لیست آگهی های نیازمندی در سراسر دنیا`,
            },
          },
        ],
      },
      // about: {
      //   "@type": "Place",
      //   name: state?.state?.toUpperCase(),
      //   address: {
      //     "@type": "PostalAddress",
      //     // "addressLocality": "City",
      //     addressRegion: state?.state?.toUpperCase(),
      //     // "postalCode": "Postal Code",
      //     addressCountry: country?.toUpperCase(),
      //   },
      //   geo: {
      //     "@type": "GeoCoordinates",
      //     latitude: stateInfo?.lat, // Replace with the actual latitude
      //     longitude: stateInfo?.lng, // Replace with the actual longitude
      //   },
      // },
    },
  ];
  const metaTagsData = {
    title,
    description: `کی چی کجا، نیازمندیهای ایرانیان سراسر دنیا`,
    section: "کی چی کجا",
    // url: `https://www.metamarce.com/${window.lang}/mp/used/filters`,
    url,
    img: "https://www.metamarce.com/img/illustration/man-with-join-us-sign-for-open-recruitment.svg",
    type: "webpage",
  };
  return (
    <div>
      <ToastNote messageRef={messageRef} />
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      {/* <!-- *********************************** main ******************************************************************** --> */}

      <section className="mx-auto ads-all py-0">
        <div className="p-md-3 p-2">
          <nav style={{ bsBreadcrumbDivider: ">", ariaLabel: "breadcrumb" }}>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/${window.lang}`}>خانه</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/${window.lang}/mp`}>نیازمندی‌ها</Link>
              </li>
              <li className="breadcrumb-item">
                <strong className="breadcrumb-item active">
                کی‌چی‌کجا
                </strong>
              </li>
            </ol>
          </nav>
        </div>

        <div className="row blog px-md-4 p-0 py-1">
          
          {/* <!-- **************Right side ************** --> */}
          <div className="col-lg-9 entries  "> 
          <div className="dashboardNavigator d-flex justify-content-center">
            <ul className="d-flex justify-content-center mt-3 px-0 font-12">
            <li
                className={`sidebarListItem sidebarListItem2 mx-2 ${filters.mainCatId==="66c0d7e8c79e6e1af314f511"?"active":""}`}
                onClick={(e) => {
                  setFilters(pre=>({...pre,mainCatId:"66c0d7e8c79e6e1af314f511",mainCat:'همراه و همسفر / ارسال بار'}))
                  // window.navigateHandle(`?mainCat=همراه و همسفر&mainCatId=66c0d7e8c79e6e1af314f511`)
                }}
              >
            همراه و همسفر / ارسال بار

            </li>
            <li
                className={`sidebarListItem sidebarListItem2 mx-2 ${filters.mainCatId==="66a822aaf9de1066d06b2d92"?"active":""}`}
                onClick={(e) => setFilters(pre=>({...pre,mainCatId:"66a822aaf9de1066d06b2d92",mainCat:"خودرو و وسایل نقلیه"}))}
              >                    
                خودرو
              </li>
            <li
                className={`sidebarListItem sidebarListItem2 mx-2 ${filters.mainCatId==="66c126fec79e6e1af31547d1"?"active":""}`}
                onClick={(e) => setFilters(pre=>({...pre,mainCatId:"66c126fec79e6e1af31547d1",mainCat:"املاک و مستقلات"}))}
              >                    
                املاک 
              </li>            
            </ul>
          
          </div>

            <a id="top"> </a>           

            <div className="mx-1 p-0 ">
              <div>
                {/* <!-- **************Filters  ************** --> */}

                <div className=" d-flex flex-wrap x-3 justify-content-between my-2 border-0 ">
                  <div className="d-flex">
                    <span className="pt-2" title="نمایش ذخیره ها">
                      <i
                        className={`bi ${
                          filters.saved ? "bi-bookmark-fill" : "bi-bookmark"
                        } icon-stack-1x cursor`}
                        onClick={(e) => {
                          if (!window.logined(9999)) return;
                          if (user?.saved?.[section]?.length <= 0)
                            return messageRef.current.showWarning({
                              message:
                                "شما تاکنون نیازمندی ذخیره شده‌ای ندارین.",
                            });
                          setFilters((pre) => ({
                            ...initialFilters,
                            saved: !pre.saved,
                            section,
                          }));
                        }}
                      ></i>
                    </span>
                    {resultsTotal !== 0 && (
                      <span className="box4 py-1 px-2 mx-md-2 mx-0">
                        {resultsTotal} یافته
                      </span>
                    )}
                    <div className="blog-search ">
                      <BlogSearch searchHandle={searchHandle} />
                    </div>
                  </div>
                  <div className="d-flex">

                   
                    <div
                      className="btn btn-primary addbusiness-btn my-2 me-2 pt-2"
                      onClick={(e) => {
                        if (!window.logined(9999, "used")) {
                          return window.navigateHandle(
                            `/${window.lang}/mp/used/register`
                          );
                        }
                        window.navigateHandle(
                          `/${window.lang}/dashboard/user/mp/usedregister`
                        );
                      }}
                    >
                      <i className="bi bi-geo me-1"></i>
                      افزودن به کی‌چی‌کجا
                    </div>
                    <button
                      className="btn btn-primary button-filter me-md-3 me-0 ms-auto my-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarTogglerDemo02"
                      aria-controls="navbarTogglerDemo02"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="bi bi-filter me-1"> فیلترها</i>
                    </button>
                  </div>
                  <div
                    className="collapse navbar-collapse "
                    id="navbarTogglerDemo02"
                  >
                    <div className="d-flex flex-wrap mt-3">
                      {/* <!-- Button filter 1 *******************************--> */}
                      <div className="dropdown ">
                        <button
                          type="button"
                          className="btn btn-primary button-filter dropdown-toggle smaller"
                          data-bs-toggle="dropdown"
                          // id="#filter5"
                          aria-expanded="false"
                          // aria-controls="filter5"
                        >
                          <span>
                            <i className="bi bi-arrow-down-up me-1"></i>
                          </span>
                          مرتب کن
                          <span className="ps-3">
                            <i className="bi bi-chevron-down"></i>
                          </span>
                        </button>

                        {/* <!-- Modal --> */}

                        <div className="dropdown-menu checkbox-form px-0 ">
                      <div className=" ">
                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={filters.sort === "default"}
                            onChange={(e) => {
                              setFilters((prev) => ({
                                ...prev,
                                sort: "default",
                                pageNumber: 1,
                              }));
                              // effectRan.current = false;
                            }}
                          />
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault1"
                          >
                            پیش فرض
                          </label>
                        </div>

                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={filters.sort === "dateB"}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "dateB",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            تازه‌ترین
                          </label>
                        </div>
                        <div className="form-check px-3 d-flex justify-content-between  flex-row-reverse">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                            // value={flexRadioDefault3}
                            checked={filters.sort === "priceB"}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "priceB",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <span className="checkmark-radio-btn "></span>
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault3"
                          >
                            گرانترین
                          </label>
                        </div>
                        <div className="form-check px-3 d-flex justify-content-between  flex-row-reverse">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault334"
                            // value={flexRadioDefault334}
                            checked={filters.sort === "priceA"}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "priceA",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <span className="checkmark-radio-btn "></span>
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault334"
                          >
                            ارزان ترین
                          </label>
                        </div>                       
                      </div>
                    </div>
                      </div>

                      {/* <!-- Button country 2 *******************************--> */}
                      {/* <div className="dropdown">
                  <button
                    type="button"
                    className="btn button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="bi bi-geo-alt me-1"></i>
                    </span>
                    کشور
                    <span className="ps-3">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

           

                  <div className="dropdown-menu checkbox-form">
                    <DropdownSearch updateParent={countrySet} />
                  </div>
                </div> */}

                      {/* <!-- Button State 2.5 *******************************--> */}
                      {filters.country?.length > 0 && (
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn button-filter dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span>
                              <i className="bi bi-geo-alt me-1"></i>
                            </span>
                            استان
                            <span className="ps-3">
                              <i className="bi bi-chevron-down"></i>
                            </span>
                          </button>

                          {/* <!-- Modal --> */}

                          <div className="dropdown-menu px-0 checkbox-form">
                            <div
                              className="reset-btn w-100"
                              onClick={(e) => {
                                setFilters((prev) => ({
                                  ...prev,
                                  states: [],
                                  state: "",
                                  stateShort: "",
                                  pageNumber: 1,
                                }));
                              }}
                            >
                              پاک کردن
                            </div>
                            {states?.map((state, i) => (
                              <div
                                key={"states" + i}
                                className="form-check d-flex justify-content-between flex-row-reverse px-3"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  // value={state.name?.toLowerCase()}
                                  onChange={(e) => {
                                    const states = filters?.states
                                      ? [...filters.states]
                                      : [];
                                    const state1 = {
                                      stateShort: "",
                                      state: "",
                                    };
                                    const value = state.state;
                                    console.log("value01", value);
                                    console.log("states02", states);
                                    const index = states.indexOf(value);
                                    if (index !== -1) {
                                      states.splice(index, 1);
                                      const contry = countryStates.find(
                                        (count) =>
                                          count.countryShort ===
                                          filters.countryShort.toUpperCase()
                                      );
                                      console.log("contry03", contry);
                                      // const stateShort = contry.states.find(
                                      //   (state) => {
                                      //     console.log('state.state === states[0]',state.state , states[0]);
                                      //     return state.state === states[0]}
                                      // ).stateShort;
                                      const stateShort = countryStates.find(
                                        (count) =>
                                          count.countryShort ===
                                          filters.countryShort
                                      );
                                      if (states.length === 1) {
                                        state1.stateShort = stateShort;
                                        state1.state = states[0];
                                      } else {
                                        state1.stateShort = "";
                                        state1.state = "";
                                      }
                                    } else {
                                      states.push(value);
                                      if (states.length === 1) {
                                        state1.stateShort = state.stateShort;
                                        state1.state = value;
                                      } else {
                                        state1.stateShort = "";
                                        state1.state = "";
                                      }
                                    }
                                    setFilters((pre) => ({
                                      ...pre,
                                      states,
                                      state: state1.state,
                                      stateShort: state1.stateShort,
                                    }));
                                  }}
                                  checked={filters.states?.includes(
                                    state.state
                                  )}
                                  id={`statesCheckBox${i}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`statesCheckBox${i}`}
                                >
                                  {state?.state}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* <!-- Button City 3 *******************************--> */}
                      {filters.states.length === 1 &&
                        filters?.state?.length > 0 && (
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn button-filter dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <i className="bi bi-geo-alt me-1"></i>
                              </span>
                              شهر/ محله
                              <span className="ps-3">
                                <i className="bi bi-chevron-down"></i>
                              </span>
                            </button>

                            {/* <!-- Modal --> */}

                            <div className="dropdown-menu checkbox-form">
                              <SearchMultiSelect
                                updateParent={setCitiesHandle}
                                data={{
                                  countryShort: filters.countryShort,
                                  stateShort: filters.stateShort,
                                  cities: filters.cities,
                                }}
                              />
                            </div>
                          </div>
                        )}

                      {/* <!-- Button MainCat 3 *******************************--> */}
                      <FiltersCatsSelector
                        section={"used"}
                        filters={filters}
                        setFilters={setFilters}
                      />

                      {/* <!-- Button filter 5 *******************************--> */}
                      <div className="dropdown">
                        <button
                          className="btn button-filter"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasWithBothOptionsFilters"
                          aria-controls="offcanvasWithBothOptionsFilters"
                        >
                          <span>
                            <i className="bi bi-filter me-1"></i>
                          </span>
                          فیلتر بیشتر
                          <span className="ps-3">
                            <i className="bi bi-chevron-down"></i>
                          </span>
                        </button>

                        <div
                          className="offcanvas offcanvas-end offcanvas-countryLanguage "
                          data-bs-scroll="true"
                          tabIndex="-1"
                          id="offcanvasWithBothOptionsFilters"
                          aria-labelledby="offcanvasWithBothOptionsLabelFilters"
                        >
                          <div className="offcanvas-header offcanvas-countryLanguageTitle ">
                            <h5
                              className="offcanvas-title "
                              id="offcanvasWithBothOptionsLabelFilters"
                            >
                              فیلترهای بیشتر
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>

                          <div className="offcanvas-footer  text-center px-5 py-4">
                            <button
                              className="btn  checkout-btn  text-center w-100 mb-2 py-2"
                              type="button"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={(e) => {
                                 // removing all url queries
                                 navigate('')
                                setFilters(initialFilters);
                              }}
                            >
                              پاک کردن فیلترها
                            </button>
                            <button
                              className="btn  view-cart text-center w-100 py-2"
                              // type="button"
                              // data-bs-dismiss="offcanvas"
                              // aria-label="Close"
                              onClick={(e) => {
                                // effectRan.current = false;
                                if (
                                  backFilters.priceMin > 0 &&
                                  backFilters.priceMax > 0 &&
                                  backFilters.priceMax - backFilters.priceMin <
                                    0
                                )
                                  return messageRef.current.showWarning({
                                    message:
                                      "لطفا قیمت کمترین و بیشترین را کنترل نمائید.",
                                  });
                                const can = document.getElementById(
                                  "offcanvasWithBothOptionsFilters"
                                );
                                const ins =
                                  window.bootstrap.Offcanvas.getInstance(can);
                                ins.hide();
                                backFilters.pageNumber = 1;
                                setFilters(backFilters);
                              }}
                            >
                              نمایش موارد
                            </button>
                          </div>

                          <div className="offcanvas-body px-0 pb-3 pt-0">
                            <div
                              className="accordion w-100 p-0 m-0"
                              id="accordionPanelsStayOpenBusinessFilters"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed accordion-button-business-filter"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpen-carInfo-price"
                                    aria-expanded="true"
                                    aria-controls="panelsStayOpen-carInfo-price"
                                  >
                                    <p className="form-titr2">قیمت</p>
                                  </button>
                                </h2>
                                <div
                                  id="panelsStayOpen-carInfo-price"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionExampleCarInfo"
                                >
                                  <div className="accordion-body form-font">                                   
                                    <div className="form-check form-switch m-0 py-2 px-1">
                                      <input
                                        className="form-check-input form-check-input-filter1 m-0 p-0"
                                        type="checkbox"
                                        role="switch"
                                        name="isFree"
                                        // value={'tr.name'}
                                        id={`isfreebackF`}
                                        checked={
                                          backFilters?.isFree?.length > 0
                                        }
                                        onChange={(e) => {
                                          setBackFilters((prev) => {
                                            const isFree =
                                              backFilters?.isFree?.length;
                                            const pre = { ...prev };
                                            pre.isFree = isFree ? "" : "رایگان";
                                            if (!isFree) {
                                              pre.priceMin = "";
                                              pre.priceMax = "";
                                            }
                                            return pre;
                                          });
                                        }}
                                      />
                                      <label
                                        className="form-check-label px-2"
                                        htmlFor={`isfreebackF`}
                                      >
                                        رایگان
                                      </label>
                                    </div>
                                    <div className="d-flex flex-column ms-3 my-2">
                                      <div>
                                        <input
                                          id="backFilters-price-start"
                                          className={
                                            backFilters?.priceMax > 0 &&
                                            backFilters?.priceMin >
                                              backFilters.priceMax
                                              ? "error-red-border"
                                              : ""
                                          }
                                          type="text"
                                          disabled={backFilters?.isFree?.length}
                                          placeholder="کمترین"
                                          value={backFilters?.priceMin.toLocaleString()}
                                          onChange={(e) => {
                                            // if(backFilters?.priceMax>0&&(backFilters?.priceMax-backFilters?.priceMin<0))return
                                            setBFHandle(
                                              "priceMin",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className=" small-gray-font my-2">
                                        تا
                                      </div>
                                      <div>
                                        <input
                                          id="backFilters-price-end "
                                          className={
                                            backFilters?.priceMin > 0 &&
                                            backFilters?.priceMax > 0 &&
                                            backFilters.priceMax <
                                              backFilters?.priceMin
                                              ? "error-red-border"
                                              : ""
                                          }
                                          type="text"
                                          disabled={backFilters?.isFree?.length}
                                          placeholder="بیشترین"
                                          value={backFilters?.priceMax.toLocaleString()}
                                          onChange={(e) => {
                                            // if(backFilters?.priceMin>0&&backFilters?.priceMax>0&&backFilters?.priceMax-backFilters?.priceMin<0)return
                                            setBFHandle(
                                              "priceMax",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            {backFilters?.mainCatId !==
                                  "66c0d7e8c79e6e1af314f511"?<div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="panelsStayOpenBusinessFilters-headingTwo"
                                >
                                  <button
                                    className="accordion-button collapsed accordion-button-business-filter"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpenBusinessFilters-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="panelsStayOpenBusinessFilters-collapseTwo"
                                  >
                                    <p className="form-titr2">
                                      {/* <span>
                              <i className="bi bi-circle-fill "></i>
                            </span> */}
                                      وضعیت سلامت
                                    </p>
                                  </button>
                                </h2>
                                <div
                                  id="panelsStayOpenBusinessFilters-collapseTwo"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="panelsStayOpenBusinessFilters-headingTwo"
                                >
                                  <div className="accordion-body form-font px-5">
                                    {renderData.condition.map((em, i) => (
                                      <div key={i} className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={em.name}
                                          checked={backFilters?.condition?.includes(
                                            em.name
                                          )}
                                          id={`checkBoxEmType${i}`}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            const condition =
                                              backFilters.condition
                                                ? [...backFilters.condition]
                                                : []; // Create a shallow copy of the array
                                            console.log("condition", condition);
                                            const index =
                                              backFilters.condition?.indexOf(
                                                value
                                              );
                                            if (index !== -1) {
                                              condition.splice(index, 1);
                                            } else {
                                              // Add the value if it's not selected
                                              condition.push(value);
                                            }
                                            // backFilters.condition=condition
                                            setBackFilters((pre) => ({
                                              ...pre,
                                              condition,
                                            }));
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`checkBoxEmType${i}`}
                                        >
                                          {em[lang]}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="panelsStayOpenBusinessFilters-delivery"
                                >
                                  <button
                                    className="accordion-button collapsed accordion-button-business-filter"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpenBusinessFilters-collapseDelivery"
                                    aria-expanded="false"
                                    aria-controls="panelsStayOpenBusinessFilters-collapseDelivery"
                                  >
                                    <p className="form-titr2">نحوه تحویل</p>
                                  </button>
                                </h2>
                                <div
                                  id="panelsStayOpenBusinessFilters-collapseDelivery"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="panelsStayOpenBusinessFilters-delivery"
                                >
                                  <div className="accordion-body form-font px-5">
                                    {renderData.delivery.map((em, i) => (
                                      <div key={i} className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={em.name}
                                          checked={backFilters?.delivery?.includes(
                                            em.name
                                          )}
                                          id={`checkBoxDelivery${i}`}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            const delivery =
                                              backFilters.delivery
                                                ? [...backFilters.delivery]
                                                : []; // Create a shallow copy of the array
                                            console.log("delivery", delivery);
                                            const index =
                                              backFilters.delivery?.indexOf(
                                                value
                                              );
                                            if (index !== -1) {
                                              delivery.splice(index, 1);
                                            } else {
                                              // Add the value if it's not selected
                                              delivery.push(value);
                                            }
                                            // backFilters.delivery=delivery
                                            setBackFilters((pre) => ({
                                              ...pre,
                                              delivery,
                                            }));
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`checkBoxDelivery${i}`}
                                        >
                                          {em[lang]}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="panelsStayOpenBusinessFilters-heading-six"
                                >
                                  <button
                                    className="accordion-button collapsed accordion-button-business-filter"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpenBusinessFilters-collapse-six"
                                    aria-expanded="false"
                                    aria-controls="panelsStayOpenBusinessFilters-collapse-six"
                                  >
                                    <p className="form-titr2"> امکانات دیگر</p>
                                  </button>
                                </h2>
                                <div
                                  id="panelsStayOpenBusinessFilters-collapse-six"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="panelsStayOpenBusinessFilters-heading-six"
                                >
                                  <div className="accordion-body form-font px-5">
                                    <div className="form-check form-switch m-0 py-0 px-1">
                                      <input
                                        className="form-check-input form-check-input-filter1 m-0 p-0"
                                        type="checkbox"
                                        role="switch"
                                        name="img"
                                        // value={'tr.name'}
                                        id={`imgBackF`}
                                        checked={backFilters?.img?.length > 0}
                                        // checked={false}
                                        onChange={(e) => {
                                          setBackFilters((prev) => ({
                                            ...prev,
                                            img:
                                              prev?.img?.length > 0
                                                ? ""
                                                : "دارای عکس",
                                          }));
                                        }}
                                      />
                                      <label
                                        className="form-check-label px-2"
                                        htmlFor={`imgBackF`}
                                      >
                                        دارای عکس
                                      </label>
                                    </div>

                                    {/* <div className="form-check form-switch m-0 py-2 px-1">
                            <input
                              className="form-check-input form-check-input-filter1 m-0 p-0"
                              type="checkbox"
                              role="switch"
                              name="freeSession"
                              // value={'tr.name'}
                              id={`freeSessionBackF`}
                              checked={backFilters?.freeSession}
                              onChange={(e) => {
                                setBackFilters((prev) => ({
                                  ...prev,
                                  freeSession: prev.freeSession?.length
                                    ? ""
                                    : "جلسه آزمایشی",
                                }));
                              }}
                            />
                            <label
                              className="form-check-label px-2"
                              htmlFor={`freeSessionBackF`}
                            >
                              جلسه آزمایشی
                            </label>
                          </div> */}
                                  </div>
                                </div>
                              </div>

                              {(!backFilters?.mainCatId?.length ||
                                backFilters?.mainCatId ===
                                  "66c126fec79e6e1af31547d1") && (
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="panelsStayOpenBusinessFilters-heading-homeInfo"
                                  >
                                    <button
                                      className="accordion-button collapsed accordion-button-business-filter"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#panelsStayOpenBusinessFilters-collapse-homeInfo"
                                      aria-expanded="false"
                                      aria-controls="panelsStayOpenBusinessFilters-collapse-homeInfo"
                                    >
                                      <p className="form-titr2">
                                        {" "}
                                        فیلترهای املاک
                                      </p>
                                    </button>
                                  </h2>
                                  <div
                                    id="panelsStayOpenBusinessFilters-collapse-homeInfo"
                                    className="accordion-collapse collapse"
                                  >
                                    <div className="accordion-body form-font px-5">
                                      <div
                                        className="accordion w-100 p-0 m-0"
                                        id="accordionPanelsHomeInfoFilters"
                                      >
                                        <div className="accordion-item">
                                          <button
                                            className="accordion-button collapsed accordion-button-business-filter accordion-button2f"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-homeInfo"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-homeInfo"
                                          >
                                            نوع معامله
                                          </button>
                                          <div
                                            id="panelsStayOpen-homeInfo"
                                            data-bs-parent="#accordionPanelsHomeInfoFilters"
                                            className="accordion-collapse collapse"
                                          >
                                            <ul className="accordion-body form-font ">
                                              {renderData.transType.map(
                                                (da, i) => (
                                                  <li
                                                    key={i}
                                                    className="form-check"
                                                  >
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      value={da.name}
                                                      checked={backFilters?.transType?.includes(
                                                        da.name
                                                      )}
                                                      id={`checkBoxTransType${i}`}
                                                      onChange={(e) => {
                                                        const value =
                                                          e.target.value;
                                                        const transType =
                                                          backFilters.transType
                                                            ? [
                                                                ...backFilters.transType,
                                                              ]
                                                            : []; // Create a shallow copy of the array
                                                        console.log(
                                                          "transType",
                                                          transType
                                                        );
                                                        const index =
                                                          backFilters.transType?.indexOf(
                                                            value
                                                          );
                                                        if (index !== -1) {
                                                          transType.splice(
                                                            index,
                                                            1
                                                          );
                                                        } else {
                                                          // Add the value if it's not selected
                                                          transType.push(value);
                                                        }
                                                        // backFilters.transType=transType
                                                        setBackFilters(
                                                          (pre) => ({
                                                            ...pre,
                                                            transType,
                                                          })
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={`checkBoxTransType${i}`}
                                                    >
                                                      {da[lang]}
                                                    </label>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </div>

                                        <div className="accordion-item">
                                          <button
                                            className="accordion-button collapsed accordion-button-business-filter accordion-button2f"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-homeInfo-proType"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-homeInfo-proType"
                                          >
                                            نوع ملک
                                          </button>
                                          <div
                                            id="panelsStayOpen-homeInfo-proType"
                                            data-bs-parent="#accordionPanelsHomeInfoFilters"
                                            className="accordion-collapse collapse"
                                          >
                                            <ul className="accordion-body form-font ">
                                              {renderData.proType.map(
                                                (da, i) => (
                                                  <li
                                                    key={i}
                                                    className="form-check"
                                                  >
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      value={da.name}
                                                      checked={backFilters?.proType?.includes(
                                                        da.name
                                                      )}
                                                      id={`checkBoxProType${i}`}
                                                      onChange={(e) => {
                                                        const value =
                                                          e.target.value;
                                                        const proType =
                                                          backFilters.proType
                                                            ? [
                                                                ...backFilters.proType,
                                                              ]
                                                            : []; // Create a shallow copy of the array
                                                        console.log(
                                                          "proType",
                                                          proType
                                                        );
                                                        const index =
                                                          backFilters.proType?.indexOf(
                                                            value
                                                          );
                                                        if (index !== -1) {
                                                          proType.splice(
                                                            index,
                                                            1
                                                          );
                                                        } else {
                                                          // Add the value if it's not selected
                                                          proType.push(value);
                                                        }
                                                        // backFilters.proType=proType
                                                        setBackFilters(
                                                          (pre) => ({
                                                            ...pre,
                                                            proType,
                                                          })
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={`checkBoxProType${i}`}
                                                    >
                                                      {da[lang]}
                                                    </label>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="accordion-item">
                                          <button
                                            className="accordion-button collapsed accordion-button-business-filter accordion-button2f"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-homeInfo-hFacilities"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-homeInfo-hFacilities"
                                          >
                                            امکانات رفاهی
                                          </button>
                                          <div
                                            id="panelsStayOpen-homeInfo-hFacilities"
                                            data-bs-parent="#accordionPanelsHomeInfoFilters"
                                            className="accordion-collapse collapse"
                                          >
                                            <div className="my-2">
                                              <div>تعداد خواب</div>
                                              <select
                                                className="form-control small-gray-font"
                                                name=""
                                                id="homeInfoBeds"
                                                value={backFilters.beds}
                                                onChange={(e) =>
                                                  setBFHandle(
                                                    "beds",
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  انتخاب کنید:{" "}
                                                </option>

                                                {[...Array(5).keys()].map(
                                                  (index) => {
                                                    const beds = index + 1;
                                                    return (
                                                      <>
                                                        <option
                                                          key={beds}
                                                          value={beds}
                                                        >
                                                          {beds}
                                                        </option>
                                                        <option
                                                          key={beds + "+1"}
                                                          value={"+" + beds}
                                                        >
                                                          {beds + "+ "}
                                                        </option>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </select>
                                              <div className="mt-3">
                                                تعداد سرویس بهداشتی
                                              </div>
                                              <select
                                                className="form-control small-gray-font"
                                                name=""
                                                id="homeInfoBath"
                                                value={backFilters.bath}
                                                onChange={(e) =>
                                                  setBFHandle(
                                                    "bath",
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  {" "}
                                                  انتخاب کنید:{" "}
                                                </option>

                                                {[...Array(5).keys()].map(
                                                  (index) => {
                                                    const bath = index + 1;
                                                    return (
                                                      <>
                                                        <option
                                                          key={bath}
                                                          value={bath}
                                                        >
                                                          {bath}
                                                        </option>
                                                        <option
                                                          key={bath + "+1"}
                                                          value={"+" + bath}
                                                        >
                                                          {bath + "+ "}
                                                        </option>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </select>

                                              <div className="mt-3">
                                                <div>
                                                  <div> پارکینگ</div>
                                                  <ul className="mb-1">
                                                    {" "}
                                                    {renderData.parking.map(
                                                      (da, i) => (
                                                        <li
                                                          key={i}
                                                          className="form-check small-gray-font"
                                                        >
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={da.name}
                                                            checked={backFilters?.parking?.includes(
                                                              da.name
                                                            )}
                                                            id={`checkBoxParking${i}`}
                                                            onChange={(e) => {
                                                              const value =
                                                                e.target.value;
                                                              const parking =
                                                                backFilters.parking
                                                                  ? [
                                                                      ...backFilters.parking,
                                                                    ]
                                                                  : []; // Create a shallow copy of the array
                                                              console.log(
                                                                "parking",
                                                                parking
                                                              );
                                                              const index =
                                                                backFilters.parking?.indexOf(
                                                                  value
                                                                );
                                                              if (
                                                                index !== -1
                                                              ) {
                                                                parking.splice(
                                                                  index,
                                                                  1
                                                                );
                                                              } else {
                                                                // Add the value if it's not selected
                                                                parking.push(
                                                                  value
                                                                );
                                                              }
                                                              // backFilters.parking=parking
                                                              setBackFilters(
                                                                (pre) => ({
                                                                  ...pre,
                                                                  parking,
                                                                })
                                                              );
                                                            }}
                                                          />
                                                          <label
                                                            className="form-check-label"
                                                            htmlFor={`checkBoxParking${i}`}
                                                          >
                                                            {da[lang]}
                                                          </label>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                                {backFilters.parking.includes(
                                                  "garage"
                                                ) && (
                                                  <div className="px-4 ">
                                                    <select
                                                      className="form-control small-gray-font "
                                                      name=""
                                                      id="homeInfoGarage"
                                                      onChange={(e) =>
                                                        setBFHandle(
                                                          "parkingQty",
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option value="">
                                                        تعداد پارکینگ داخلی:
                                                      </option>

                                                      {[...Array(5).keys()].map(
                                                        (index) => {
                                                          const bath =
                                                            index + 1;
                                                          return (
                                                            <>
                                                              <option
                                                                key={bath}
                                                                value={bath}
                                                              >
                                                                {bath}
                                                              </option>
                                                              <option
                                                                key={
                                                                  bath + "+1"
                                                                }
                                                                value={
                                                                  "+" + bath
                                                                }
                                                              >
                                                                {bath + "+ "}
                                                              </option>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <ul className="accordion-body form-font ">
                                              {renderData.hFacilities.map(
                                                (da, i) => (
                                                  <li
                                                    key={i}
                                                    className="form-check"
                                                  >
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      value={da.name}
                                                      checked={backFilters?.hFacilities?.includes(
                                                        da.name
                                                      )}
                                                      id={`checkBoxAirhFacilities${i}`}
                                                      onChange={(e) => {
                                                        const value =
                                                          e.target.value;
                                                        const hFacilities =
                                                          backFilters.hFacilities
                                                            ? [
                                                                ...backFilters.hFacilities,
                                                              ]
                                                            : []; // Create a shallow copy of the array
                                                        console.log(
                                                          "hFacilities",
                                                          hFacilities
                                                        );
                                                        const index =
                                                          backFilters.hFacilities?.indexOf(
                                                            value
                                                          );
                                                        if (index !== -1) {
                                                          hFacilities.splice(
                                                            index,
                                                            1
                                                          );
                                                        } else {
                                                          // Add the value if it's not selected
                                                          hFacilities.push(
                                                            value
                                                          );
                                                        }
                                                        // backFilters.hFacilities=hFacilities
                                                        setBackFilters(
                                                          (pre) => ({
                                                            ...pre,
                                                            hFacilities,
                                                          })
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={`checkBoxAirhFacilities${i}`}
                                                    >
                                                      {da[lang]}
                                                    </label>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </div>

                                        <div className="accordion-item">
                                          <button
                                            className="accordion-button collapsed accordion-button-business-filter accordion-button2f"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-homeInfo-airCondition"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-homeInfo-airCondition"
                                          >
                                            سیستم تهویه
                                          </button>
                                          <div
                                            id="panelsStayOpen-homeInfo-airCondition"
                                            data-bs-parent="#accordionPanelsHomeInfoFilters"
                                            className="accordion-collapse collapse"
                                          >
                                            <ul className="accordion-body form-font ">
                                              {renderData.airCondition.map(
                                                (da, i) => (
                                                  <li
                                                    key={i}
                                                    className="form-check"
                                                  >
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      value={da.name}
                                                      checked={backFilters?.airCondition?.includes(
                                                        da.name
                                                      )}
                                                      id={`checkBoxAirCondition${i}`}
                                                      onChange={(e) => {
                                                        const value =
                                                          e.target.value;
                                                        const airCondition =
                                                          backFilters.airCondition
                                                            ? [
                                                                ...backFilters.airCondition,
                                                              ]
                                                            : []; // Create a shallow copy of the array
                                                        console.log(
                                                          "airCondition",
                                                          airCondition
                                                        );
                                                        const index =
                                                          backFilters.airCondition?.indexOf(
                                                            value
                                                          );
                                                        if (index !== -1) {
                                                          airCondition.splice(
                                                            index,
                                                            1
                                                          );
                                                        } else {
                                                          // Add the value if it's not selected
                                                          airCondition.push(
                                                            value
                                                          );
                                                        }
                                                        // backFilters.airCondition=airCondition
                                                        setBackFilters(
                                                          (pre) => ({
                                                            ...pre,
                                                            airCondition,
                                                          })
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={`checkBoxAirCondition${i}`}
                                                    >
                                                      {da[lang]}
                                                    </label>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="accordion-item">
                                          <button
                                            className="accordion-button collapsed accordion-button-business-filter accordion-button2f"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-homeInfo-heating"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-homeInfo-heating"
                                          >
                                            سیستم گرمایشی
                                          </button>
                                          <div
                                            id="panelsStayOpen-homeInfo-heating"
                                            data-bs-parent="#accordionPanelsHomeInfoFilters"
                                            className="accordion-collapse collapse"
                                          >
                                            <ul className="accordion-body form-font ">
                                              {renderData.heating.map(
                                                (da, i) => (
                                                  <li
                                                    key={i}
                                                    className="form-check"
                                                  >
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      value={da.name}
                                                      checked={backFilters?.heating?.includes(
                                                        da.name
                                                      )}
                                                      id={`checkBoxAirHeating${i}`}
                                                      onChange={(e) => {
                                                        const value =
                                                          e.target.value;
                                                        const heating =
                                                          backFilters.heating
                                                            ? [
                                                                ...backFilters.heating,
                                                              ]
                                                            : []; // Create a shallow copy of the array
                                                        console.log(
                                                          "heating",
                                                          heating
                                                        );
                                                        const index =
                                                          backFilters.heating?.indexOf(
                                                            value
                                                          );
                                                        if (index !== -1) {
                                                          heating.splice(
                                                            index,
                                                            1
                                                          );
                                                        } else {
                                                          // Add the value if it's not selected
                                                          heating.push(value);
                                                        }
                                                        // backFilters.heating=heating
                                                        setBackFilters(
                                                          (pre) => ({
                                                            ...pre,
                                                            heating,
                                                          })
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={`checkBoxAirHeating${i}`}
                                                    >
                                                      {da[lang]}
                                                    </label>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </div>

                                        <div className="accordion-item">
                                          <button
                                            className="accordion-button collapsed accordion-button-business-filter accordion-button2f"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-homeInfo-other"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-homeInfo-other"
                                          >
                                            دیگر فیلترها
                                          </button>
                                          <div
                                            id="panelsStayOpen-homeInfo-other"
                                            data-bs-parent="#accordionPanelsHomeInfoFilters"
                                            className="accordion-collapse collapse "
                                          >
                                            <div className="accordion-body">
                                              <div>
                                                {/* <Slider
                                          value={[backFilters.homeYear.start, backFilters.homeYear.end]}
                                          onChange={(event, newValue)=> setBackFilters(prev => ({
                                            ...prev,
                                            homeYear: {
                                              start: newValue[0],
                                              end: newValue[1],
                                            },
                                          }))}
                                          valueLabelDisplay="auto"
                                          min={1920}
                                          max={currentYear}
                                          sx={{ marginTop: 4 }}
                                        /> */}
                                                <div>
                                                  <div> سال ساخت </div>
                                                  <input
                                                    id="backFilters-homeYearStart"
                                                    type="text"
                                                    placeholder="کمترین"
                                                    className={
                                                      backFilters?.homeYearEnd >
                                                        0 &&
                                                      backFilters?.homeYearStart >
                                                        backFilters.homeYearEnd
                                                        ? "error-red-border"
                                                        : ""
                                                    }
                                                    value={
                                                      backFilters?.homeYearStart
                                                    }
                                                    onChange={(e) =>
                                                      setBFHdle(
                                                        "homeYearStart",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <div className="small-gray-font my-2">
                                                    تا
                                                  </div>
                                                  <input
                                                    id="backFilters-homeYearEnd"
                                                    type="text"
                                                    className={
                                                      backFilters?.homeYearStart >
                                                        0 &&
                                                      backFilters?.homeYearEnd >
                                                        0 &&
                                                      backFilters.homeYearEnd <
                                                        backFilters?.homeYearStart
                                                        ? "error-red-border"
                                                        : ""
                                                    }
                                                    placeholder="بیشترین"
                                                    value={
                                                      backFilters?.homeYearEnd
                                                    }
                                                    onChange={(e) =>
                                                      setBFHdle(
                                                        "homeYearEnd",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <div></div>
                                                </div>
                                              </div>
                                              <div className="my-3">
                                                <div>
                                                  {" "}
                                                  مساحت زمین به مترمربع{" "}
                                                </div>
                                                <input
                                                  id="backFilters-lot-start"
                                                  className={
                                                    backFilters?.lotMax > 0 &&
                                                    backFilters?.lotMin >
                                                      backFilters.lotMax
                                                      ? "error-red-border"
                                                      : ""
                                                  }
                                                  type="text"
                                                  placeholder="کمترین"
                                                  value={backFilters?.lotMin.toLocaleString()}
                                                  onChange={(e) =>
                                                    setBFHandle(
                                                      "lotMin",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <div className=" small-gray-font my-2">
                                                  تا
                                                </div>
                                                <input
                                                  id="backFilters-lot-end"
                                                  type="text"
                                                  placeholder="بیشترین"
                                                  className={
                                                    backFilters?.lotMin > 0 &&
                                                    backFilters?.lotMax > 0 &&
                                                    backFilters.lotMax <
                                                      backFilters?.lotMin
                                                      ? "error-red-border"
                                                      : ""
                                                  }
                                                  value={backFilters?.lotMax.toLocaleString()}
                                                  onChange={(e) =>
                                                    setBFHandle(
                                                      "lotMax",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {(!backFilters?.mainCatId?.length ||
                                backFilters?.mainCatId ===
                                  "66a822aaf9de1066d06b2d92") && (
                                <div className="accordion-item">
                                  <div className="accordion-item ">
                                    <h2
                                      className="accordion-header"
                                      id="panelsStayOpenBusinessFilters-heading-carInfo"
                                    >
                                      <button
                                        className="accordion-button collapsed accordion-button-business-filter "
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpenBusinessFilters-collapse-carInfo"
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpenBusinessFilters-collapse-carInfo"
                                      >
                                        <p className="form-titr2">
                                          فیلترهای خودرو
                                        </p>
                                      </button>
                                    </h2>
                                    <div
                                      id="panelsStayOpenBusinessFilters-collapse-carInfo"
                                      className="accordion-collapse collapse"
                                    >
                                      <div className="accordion-body form-font px-5">
                                        <div
                                          className="accordion w-100 p-0 m-0 "
                                          id="accordionExampleCarInfo"
                                        >
                                          <div className="accordion-item ">
                                            <button
                                              className=" accordion-button collapsed accordion-button-business-filter accordion-button2f collapsed"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#panelsStayOpen-carInfo-carCompanies"
                                              aria-expanded="true"
                                              aria-controls="panelsStayOpen-carInfo-carCompanies"
                                            >
                                              شرکت سازنده
                                            </button>
                                            <div
                                              id="panelsStayOpen-carInfo-carCompanies"
                                              className="accordion-collapse collapse"
                                              data-bs-parent="#accordionExampleCarInfo"
                                            >
                                              <div className="accordion-body form-font row row-cols-3">
                                                <div className="w-100 px-2">
                                                  <Autocomplete
                                                    autoComplete="off"
                                                    multiple
                                                    id={`checkboxes-tags-demo-carCompanies`}
                                                    options={
                                                      renderData.carCompanies ||
                                                      []
                                                    }
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) =>
                                                      option.en
                                                    }
                                                    renderOption={(
                                                      props,
                                                      option,
                                                      { selected }
                                                    ) => (
                                                      <li {...props}>
                                                        <Checkbox
                                                          icon={
                                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                                          }
                                                          checkedIcon={
                                                            <CheckBoxIcon fontSize="small" />
                                                          }
                                                          style={{
                                                            marginRight: 8,
                                                            fontFamily: "vazir",
                                                          }}
                                                          checked={selected}
                                                        />
                                                        {option.en}
                                                      </li>
                                                    )}
                                                    style={{
                                                      fontFamily: "vazir",
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label="انتخاب شرکت"
                                                        placeholder="انتخاب کنید"
                                                      />
                                                    )}
                                                    onChange={(
                                                      event,
                                                      selectedOptions
                                                    ) => {
                                                      // Update the car companies based on user selection
                                                      setBackFilters(
                                                        (prev) => ({
                                                          ...prev,
                                                          carCompanies:
                                                            selectedOptions.map(
                                                              (option) =>
                                                                option.name
                                                            ), // Use the 'name' property
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </div>

                                                {/* {renderData.carCompanies.map((da, i) => (
                                            <div key={i} className="morelang-line">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={da.name}
                                                checked={backFilters?.carCompanies?.includes(
                                                  da.name
                                                )}
                                                id={`checkBoxMake${i}`}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  const carCompanies =
                                                    backFilters.carCompanies
                                                      ? [
                                                          ...backFilters.carCompanies,
                                                        ]
                                                      : []; // Create a shallow copy of the array
                                                  console.log(
                                                    "carCompanies",
                                                    carCompanies
                                                  );
                                                  const index =
                                                    backFilters.carCompanies?.indexOf(
                                                      value
                                                    );
                                                  if (index !== -1) {
                                                    carCompanies.splice(index, 1);
                                                  } else {
                                                    // Add the value if it's not selected
                                                    carCompanies.push(value);
                                                  }
                                                  // backFilters.carCompanies=carCompanies
                                                  setBackFilters((pre) => ({
                                                    ...pre,
                                                    carCompanies,
                                                  }));
                                                }}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={`checkBoxMake${i}`}
                                              >
                                                {da[lang]}
                                              </label>
                                            </div>
                                          ))} */}
                                              </div>
                                            </div>
                                          </div>

                                          <div className="accordion-item">
                                            <button
                                              className="accordion-button collapsed accordion-button-business-filter accordion-button2f collapsed"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#panelsStayOpen-carInfo-transmission"
                                              aria-expanded="true"
                                              aria-controls="panelsStayOpen-carInfo-transmission"
                                            >
                                              دنده
                                            </button>
                                            <div
                                              id="panelsStayOpen-carInfo-transmission"
                                              className="accordion-collapse collapse"
                                              data-bs-parent="#accordionExampleCarInfo"
                                            >
                                              <ul className="accordion-body form-font ">
                                                {renderData.transmission.map(
                                                  (da, i) => (
                                                    <li
                                                      key={i}
                                                      className="form-check"
                                                    >
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={da.name}
                                                        checked={backFilters?.transmission?.includes(
                                                          da.name
                                                        )}
                                                        id={`checkBoxtransmission${i}`}
                                                        onChange={(e) => {
                                                          const value =
                                                            e.target.value;
                                                          const transmission =
                                                            backFilters.transmission
                                                              ? [
                                                                  ...backFilters.transmission,
                                                                ]
                                                              : []; // Create a shallow copy of the array
                                                          console.log(
                                                            "transmission",
                                                            transmission
                                                          );
                                                          const index =
                                                            backFilters.transmission?.indexOf(
                                                              value
                                                            );
                                                          if (index !== -1) {
                                                            transmission.splice(
                                                              index,
                                                              1
                                                            );
                                                          } else {
                                                            // Add the value if it's not selected
                                                            transmission.push(
                                                              value
                                                            );
                                                          }
                                                          // backFilters.transmission=transmission
                                                          setBackFilters(
                                                            (pre) => ({
                                                              ...pre,
                                                              transmission,
                                                            })
                                                          );
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`checkBoxtransmission${i}`}
                                                      >
                                                        {da[lang]}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                          </div>

                                          <div className="accordion-item">
                                            <button
                                              className="accordion-button collapsed accordion-button-business-filter accordion-button2f collapsed"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#panelsStayOpen-carInfo-fuelTypes"
                                              aria-expanded="true"
                                              aria-controls="panelsStayOpen-carInfo-fuelTypes"
                                            >
                                              سوخت
                                            </button>
                                            <div
                                              id="panelsStayOpen-carInfo-fuelTypes"
                                              data-bs-parent="#accordionExampleCarInfo"
                                              className="accordion-collapse collapse"
                                            >
                                              <ul className="accordion-body form-font">
                                                {renderData.fuelTypes.map(
                                                  (da, i) => (
                                                    <li
                                                      key={i}
                                                      className="form-check"
                                                    >
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={da.name}
                                                        checked={backFilters?.fuelTypes?.includes(
                                                          da.name
                                                        )}
                                                        id={`checkBoxFuelTypes${i}`}
                                                        onChange={(e) => {
                                                          const value =
                                                            e.target.value;
                                                          const fuelTypes =
                                                            backFilters.fuelTypes
                                                              ? [
                                                                  ...backFilters.fuelTypes,
                                                                ]
                                                              : []; // Create a shallow copy of the array

                                                          const index =
                                                            backFilters.fuelTypes?.indexOf(
                                                              value
                                                            );
                                                          if (index !== -1) {
                                                            fuelTypes.splice(
                                                              index,
                                                              1
                                                            );
                                                          } else {
                                                            // Add the value if it's not selected
                                                            fuelTypes.push(
                                                              value
                                                            );
                                                          }
                                                          // backFilters.fuelTypes=fuelTypes
                                                          setBackFilters(
                                                            (pre) => ({
                                                              ...pre,
                                                              fuelTypes,
                                                            })
                                                          );
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`checkBoxFuelTypes${i}`}
                                                      >
                                                        {da[lang]}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="accordion-item">
                                            <button
                                              className="accordion-button collapsed accordion-button-business-filter accordion-button2f collapsed"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#panelsStayOpen-carInfo-bodyTypes"
                                              aria-expanded="true"
                                              aria-controls="panelsStayOpen-carInfo-bodyTypes"
                                            >
                                              شکل بدنه
                                            </button>
                                            <div
                                              id="panelsStayOpen-carInfo-bodyTypes"
                                              className="accordion-collapse collapse"
                                            >
                                              <ul className="accordion-body form-font d-flex row row-cols-3 px-2">
                                                {renderData.bodyTypes.map(
                                                  (da, i) => (
                                                    <li>
                                                      <div
                                                        key={i}
                                                        className={`form-check d-flex align-items-center flex-column cursor px-1 pb-1 my-2 ${
                                                          backFilters?.bodyTypes?.includes(
                                                            da.name
                                                          )
                                                            ? "selected-session"
                                                            : ""
                                                        }`}
                                                        onClick={(e) => {
                                                          const value = da.name;
                                                          const bodyTypes =
                                                            backFilters.bodyTypes
                                                              ? [
                                                                  ...backFilters.bodyTypes,
                                                                ]
                                                              : []; // Create a shallow copy of the array
                                                          console.log(
                                                            "bodyTypes",
                                                            bodyTypes
                                                          );
                                                          const index =
                                                            backFilters.bodyTypes?.indexOf(
                                                              value
                                                            );
                                                          if (index !== -1) {
                                                            bodyTypes.splice(
                                                              index,
                                                              1
                                                            );
                                                          } else {
                                                            // Add the value if it's not selected
                                                            bodyTypes.push(
                                                              value
                                                            );
                                                          }
                                                          // backFilters.bodyTypes=bodyTypes
                                                          setBackFilters(
                                                            (pre) => ({
                                                              ...pre,
                                                              bodyTypes,
                                                            })
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          className="arrow2"
                                                          src={da.img}
                                                          alt={da.en}
                                                        />
                                                        <div
                                                          className="form-check-label small-gray-font "
                                                          // htmlFor={`checkBoxbodyTypes${i}`}
                                                        >
                                                          {da[lang]}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                          </div>

                                          <div className="accordion-item ">
                                            <button
                                              className="accordion-button collapsed accordion-button-business-filter accordion-button2f collapsed "
                                              data-bs-toggle="collapse"
                                              data-bs-target="#panelsStayOpen-carInfo-other"
                                              aria-expanded="true"
                                              aria-controls="panelsStayOpen-carInfo-other"
                                            >
                                              دیگر فیلترها
                                            </button>
                                            <div
                                              id="panelsStayOpen-carInfo-other"
                                              data-bs-parent="#accordionExampleCarInfo"
                                              className="accordion-collapse collapse pb-5"
                                            >
                                              <div className="accordion-body">
                                                <div>
                                                  <div className=" med-gray-font">
                                                    <div> کارکرد</div>
                                                    <input
                                                      id="backFilters-odometerMin"
                                                      type="text"
                                                      className={
                                                        backFilters?.odometerMax >
                                                          0 &&
                                                        backFilters?.odometerMin >
                                                          backFilters.odometerMax
                                                          ? "error-red-border"
                                                          : ""
                                                      }
                                                      placeholder="کمترین"
                                                      value={backFilters?.odometerMin.toLocaleString()}
                                                      onChange={(e) =>
                                                        setBFHdle(
                                                          "odometerMin",
                                                          tools.convertToEnglish(
                                                            e.target.value
                                                          )
                                                        )
                                                      }
                                                    />
                                                    <div className="small-gray-font my-2">
                                                      تا
                                                    </div>
                                                    <input
                                                      id="backFilters-odometerMax"
                                                      type="text"
                                                      className={
                                                        backFilters?.odometerMin >
                                                          0 &&
                                                        backFilters?.odometerMax >
                                                          0 &&
                                                        backFilters.odometerMax <
                                                          backFilters?.odometerMin
                                                          ? "error-red-border"
                                                          : ""
                                                      }
                                                      placeholder="بیشترین"
                                                      value={backFilters?.odometerMax.toLocaleString()}
                                                      onChange={(e) =>
                                                        setBFHdle(
                                                          "odometerMax",
                                                          tools.convertToEnglish(
                                                            e.target.value
                                                          )
                                                        )
                                                      }
                                                    />
                                                    <div></div>
                                                  </div>
                                                </div>

                                                <div className=" med-gray-font mt-5">
                                                  <div> سال ساخت </div>
                                                  <input
                                                    id="backFilters-carYearStart"
                                                    type="text"
                                                    className={
                                                      backFilters?.carYearEnd >
                                                        0 &&
                                                      backFilters?.carYearStart >
                                                        backFilters.carYearEnd
                                                        ? "error-red-border"
                                                        : ""
                                                    }
                                                    placeholder="کمترین"
                                                    value={
                                                      backFilters?.carYearStart
                                                    }
                                                    onChange={(e) =>
                                                      setBFHandle(
                                                        "carYearStart",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <div className="small-gray-font my-2">
                                                    تا
                                                  </div>
                                                  <input
                                                    id="backFilters-carYearEnd"
                                                    type="text"
                                                    className={
                                                      backFilters?.carYearStart >
                                                        0 &&
                                                      backFilters?.carYearEnd >
                                                        0 &&
                                                      backFilters.carYearEnd <
                                                        backFilters?.carYearStart
                                                        ? "error-red-border"
                                                        : ""
                                                    }
                                                    placeholder="بیشترین"
                                                    value={
                                                      backFilters?.carYearEnd
                                                    }
                                                    onChange={(e) =>
                                                      setBFHandle(
                                                        "carYearEnd",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>:""}
                              {(!backFilters?.mainCatId?.length ||
                                backFilters?.mainCatId ===
                                  "66c0d7e8c79e6e1af314f511") && (
                                <div className="accordion-item">
                                  <div className="accordion-item ">
                                    <h2
                                      className="accordion-header"
                                      id="panelsStayOpenBusinessFilters-heading-tripInfo"
                                    >
                                      <button
                                        className="accordion-button collapsed accordion-button-business-filter "
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpenBusinessFilters-collapse-tripInfo"
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpenBusinessFilters-collapse-tripInfo"
                                      >
                                        <p className="form-titr2">
                                          فیلترهای همراه و همسفر
                                        </p>
                                      </button>
                                    </h2>
                                    <div
                                      id="panelsStayOpenBusinessFilters-collapse-tripInfo"
                                      className="accordion-collapse collapse"
                                    >
                                      <div className="accordion-body form-font px-5">
                                        <div
                                          className="accordion w-100 p-0 m-0 "
                                          id="accordionExampletripInfo"
                                        >
                                    

                                          <div className="accordion-item">
                                            <button
                                              className="accordion-button collapsed accordion-button-business-filter accordion-button2f collapsed"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#panelsStayOpen-tripInfo-activityType"
                                              aria-expanded="true"
                                              aria-controls="panelsStayOpen-tripInfo-activityType"
                                            >
                                             نقش آگهی گذار
                                            </button>
                                            <div
                                              id="panelsStayOpen-tripInfo-activityType"
                                              className="accordion-collapse collapse"
                                              data-bs-parent="#accordionExampletripInfo"
                                            >
                                              <ul className="accordion-body form-font ">
                                                {renderData.activityType.map(
                                                  (da, i) => (
                                                    <li
                                                      key={i}
                                                      className="form-check"
                                                    >
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={da.name}
                                                        checked={backFilters?.activityType?.includes(
                                                          da.name
                                                        )}
                                                        id={`checkBoxactivityType${i}`}
                                                        onChange={(e) => {
                                                          const value =
                                                            e.target.value;
                                                          const activityType =
                                                            backFilters.activityType
                                                              ? [
                                                                  ...backFilters.activityType,
                                                                ]
                                                              : []; // Create a shallow copy of the array
                                                          console.log(
                                                            "activityType",
                                                            activityType
                                                          );
                                                          const index =
                                                            backFilters.activityType?.indexOf(
                                                              value
                                                            );
                                                          if (index !== -1) {
                                                            activityType.splice(
                                                              index,
                                                              1
                                                            );
                                                          } else {
                                                            // Add the value if it's not selected
                                                            activityType.push(
                                                              value
                                                            );
                                                          }
                                                          // backFilters.activityType=activityType
                                                          setBackFilters(
                                                            (pre) => ({
                                                              ...pre,
                                                              activityType,
                                                            })
                                                          );
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`checkBoxactivityType${i}`}
                                                      >
                                                        {da[lang]}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                          </div>

                                          {/* <div className="accordion-item">
                                            <button
                                              className="accordion-button collapsed accordion-button-business-filter accordion-button2f collapsed"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#panelsStayOpen-tripInfo-fuelTypes"
                                              aria-expanded="true"
                                              aria-controls="panelsStayOpen-tripInfo-fuelTypes"
                                            >
                                              سوخت
                                            </button>
                                            <div
                                              id="panelsStayOpen-tripInfo-fuelTypes"
                                              data-bs-parent="#accordionExampletripInfo"
                                              className="accordion-collapse collapse"
                                            >
                                              <ul className="accordion-body form-font">
                                                {renderData.fuelTypes.map(
                                                  (da, i) => (
                                                    <li
                                                      key={i}
                                                      className="form-check"
                                                    >
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={da.name}
                                                        checked={backFilters?.fuelTypes?.includes(
                                                          da.name
                                                        )}
                                                        id={`checkBoxFuelTypes${i}`}
                                                        onChange={(e) => {
                                                          const value =
                                                            e.target.value;
                                                          const fuelTypes =
                                                            backFilters.fuelTypes
                                                              ? [
                                                                  ...backFilters.fuelTypes,
                                                                ]
                                                              : []; // Create a shallow copy of the array

                                                          const index =
                                                            backFilters.fuelTypes?.indexOf(
                                                              value
                                                            );
                                                          if (index !== -1) {
                                                            fuelTypes.splice(
                                                              index,
                                                              1
                                                            );
                                                          } else {
                                                            // Add the value if it's not selected
                                                            fuelTypes.push(
                                                              value
                                                            );
                                                          }
                                                          // backFilters.fuelTypes=fuelTypes
                                                          setBackFilters(
                                                            (pre) => ({
                                                              ...pre,
                                                              fuelTypes,
                                                            })
                                                          );
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`checkBoxFuelTypes${i}`}
                                                      >
                                                        {da[lang]}
                                                      </label>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="accordion-item">
                                            <button
                                              className="accordion-button collapsed accordion-button-business-filter accordion-button2f collapsed"
                                              data-bs-toggle="collapse"
                                              data-bs-target="#panelsStayOpen-tripInfo-bodyTypes"
                                              aria-expanded="true"
                                              aria-controls="panelsStayOpen-tripInfo-bodyTypes"
                                            >
                                              شکل بدنه
                                            </button>
                                            <div
                                              id="panelsStayOpen-tripInfo-bodyTypes"
                                              className="accordion-collapse collapse"
                                            >
                                              <ul className="accordion-body form-font d-flex row row-cols-3 px-2">
                                                {renderData.bodyTypes.map(
                                                  (da, i) => (
                                                    <li>
                                                      <div
                                                        key={i}
                                                        className={`form-check d-flex align-items-center flex-column cursor px-1 pb-1 my-2 ${
                                                          backFilters?.bodyTypes?.includes(
                                                            da.name
                                                          )
                                                            ? "selected-session"
                                                            : ""
                                                        }`}
                                                        onClick={(e) => {
                                                          const value = da.name;
                                                          const bodyTypes =
                                                            backFilters.bodyTypes
                                                              ? [
                                                                  ...backFilters.bodyTypes,
                                                                ]
                                                              : []; // Create a shallow copy of the array
                                                          console.log(
                                                            "bodyTypes",
                                                            bodyTypes
                                                          );
                                                          const index =
                                                            backFilters.bodyTypes?.indexOf(
                                                              value
                                                            );
                                                          if (index !== -1) {
                                                            bodyTypes.splice(
                                                              index,
                                                              1
                                                            );
                                                          } else {
                                                            // Add the value if it's not selected
                                                            bodyTypes.push(
                                                              value
                                                            );
                                                          }
                                                          // backFilters.bodyTypes=bodyTypes
                                                          setBackFilters(
                                                            (pre) => ({
                                                              ...pre,
                                                              bodyTypes,
                                                            })
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          className="arrow2"
                                                          src={da.img}
                                                          alt={da.en}
                                                        />
                                                        <div
                                                          className="form-check-label small-gray-font "
                                                          // htmlFor={`checkBoxbodyTypes${i}`}
                                                        >
                                                          {da[lang]}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                          </div> */}

                                          <div className="accordion-item ">
                                            <button
                                              className="accordion-button collapsed accordion-button-business-filter accordion-button2f collapsed "
                                              data-bs-toggle="collapse"
                                              data-bs-target="#panelsStayOpen-tripInfo-other"
                                              aria-expanded="true"
                                              aria-controls="panelsStayOpen-tripInfo-other"
                                            >
                                              بازه زمانی سفر
                                            </button>
                                            <div
                                              id="panelsStayOpen-tripInfo-other"
                                              data-bs-parent="#accordionExampletripInfo"
                                              className="accordion-collapse collapse pb-5"
                                            >
                                              <div className="accordion-body">
                                                <div>
                                                  <div className="med-gray-font">
                                                    <div className="d-flex ">
                                                    <div>
                                                    <label htmlFor="backFilters-dateStart" className="cursor"> تاریخ شروع</label>
                                                    <input
                                                      id="backFilters-dateStart"
                                                      type="date"
                                                      className={
                                                        backFilters?.dateEnd >
                                                          0 &&
                                                        backFilters?.dateStart >
                                                          backFilters.dateEnd
                                                          ? "error-red-border"
                                                          : ""
                                                      }                                                      
                                                      value={backFilters?.dateStart}
                                                      onChange={(e) =>
                                                        setBackFilters(pre=>({
                                                          ...pre,
                                                          "dateStart":e.target.value})                                                      
                                                        )
                                                      }
                                                    />
                                                    </div>
                                                    <div>
                                                      <label htmlFor="backFilters-depPort" className="cursor">شهر مبدا
                                                        <img className="arrow2 pe-1 h-100 rtl-icon" src="/img/used/departure.png" alt="departure" />
                                                      </label >
                                                    <input
                                                    id="backFilters-depPort"
                                                    type="text"                                            
                                                    placeholder="شهر مبدا"
                                                    value={
                                                      backFilters?.depPort
                                                    }
                                                    onChange={(e) =>
                                                      setBackFilters(pre=>({
                                                        ...pre,
                                                        "depPort":e.target.value})                                                      
                                                      )
                                                    }
                                                  />
                                                    </div>
                                                    </div>
                                                    <div className="d-flex ">
                                                    <div>
                                                    <label  htmlFor="backFilters-dateEnd" className="cursor"> تاریخ پایان</label>
                                                    <input
                                                      id="backFilters-dateEnd"
                                                      type="date"
                                                      className={
                                                        backFilters?.dateStart >
                                                          0 &&
                                                        backFilters?.dateEnd >
                                                          0 &&
                                                        backFilters.dateEnd <
                                                          backFilters?.dateStart
                                                          ? "error-red-border"
                                                          : ""
                                                      }
                                                      placeholder="تاریخ پایان"
                                                      value={backFilters?.dateEnd.toLocaleString()}
                                                      onChange={(e) =>
                                                        setBackFilters(pre=>({
                                                          ...pre,
                                                          "dateEnd":e.target.value})                                                      
                                                        )
                                                      }
                                                    />
                                                    
                                                    </div>
                                                    <div>
                                                      <label htmlFor="backFilters-arrPort" className="cursor">شهر مقصد
                                                      <img className="arrow2 pe-1 h-100 rtl-icon" src="/img/used/arrival.png" alt="arrival" />

                                                      </label>

                                                      <input
                                                    id="backFilters-arrPort"
                                                    type="text"
                                                    className={
                                                      backFilters?.carYearStart >
                                                        0 &&
                                                      backFilters?.arrPort >
                                                        0 &&
                                                      backFilters.arrPort <
                                                        backFilters?.carYearStart
                                                        ? "error-red-border"
                                                        : ""
                                                    }
                                                    placeholder="شهر مقصد"
                                                    value={
                                                      backFilters?.arrPort
                                                    }
                                                    onChange={(e) =>
                                                      setBackFilters(pre=>({
                                                        ...pre,
                                                        "arrPort":e.target.value})                                                      
                                                      )
                                                    }
                                                  />
                                                    </div>
                                                    </div>
                                                
                                                  </div>
                                                </div>

                                               
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* ***************** */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-More filters- Button filter 5 here*******************************--> */}

                <div className=" d-flex flex-wrap my-2 show-filters ">
                  <div
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasScrolling2"
                    aria-controls="offcanvasScrolling2"
                    className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7"
                  >
                    <span>{window.capitalize(filters?.country)}</span>
                    <span className="ms-3 me-2">
                      <i className="bi bi-arrow-repeat"></i>
                    </span>
                  </div>
                  {selectedFiltersRender}
                  {selectedFiltersRender.length > 0 && (
                    <div
                      className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7 px-3 filter-del-btn"
                      onClick={(e) => {
                        // removing all url queries
                        navigate('')
                        setFilters(initialFilters)
                      }}
                    >
                      <span>پاک کردن فیلترها</span>
                    </div>
                  )}
                </div>

                {loading.spinner ? (
                  <img src="/img/searching-error.svg" alt="Searching..." />
                ) : (
                  data?.length <= 0 && (
                    <div className="row m-5 px-md-5 px-0">
                      <div className="col-md-6 d-flex align-items-center justify-content-center px-0">
                        داده ای پیدا نشد، لطفا فیلتر های دیگر را امتحان کنید !
                        <div
                          className="btn-search shadow-none m-2 d-flex  align-items-center filter-del-btn img-banner-text-7"
                          onClick={(e) =>
                            document.querySelector("#lcsChangeBtn").click()
                          }
                        >
                          دیگر استانها یا کشورها
                        </div>
                      </div>
                      <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img
                          src="/img/cart-is-empty.svg"
                          alt="cart-is-empty"
                          loading="lazy"
                        />
                      </div>
                      <div className="mt-5 " dir="rtl"></div>
                    </div>
                  )
                )}
                {/* <!-- ************** Cards************** --> */}
                <div
                  className="row row-cols-1 
                     g-4"
                >
                  {data?.map((da, i) => (
                    <div key={i}>
                      <UsedCard data={da} countryShort={countryShort} messageRef={messageRef} ToastNote={ToastNote} setFilters={setFilters}/>
                    </div>
                  ))}
                </div>
                <div className="mt-2 d-flex justify-content-center pe-5">
                  <button
                    className="btn px-5 btn-sm shadow-none login-btn p-1 "
                    onClick={viewMorePosts}
                    disabled={resultsTotal <= data?.length || loading.spinner}
                  >
                    {loading.spinner ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : resultsTotal <= data?.length ? (
                      "پایان یافته ها"
                    ) : (
                      "موارد بیشتر"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3  sidebar-all align-items-center text-center  p-md-4 p-1"
            dir="rtl"
          >
            {/* <!-- ********left side *************************************************************** --> */}

            <MainSidebar section={section} />
          </div>
        </div>
        {/* <!-- *********** end of listing ******* --> */}
      </section>

      <section className="mx-md-5 pt-0 mx-0 p-0">
        <div className="divider2 mb-0">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">معرفی ویژه</span>
        </div>
        <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">برگزیدگان</span>
        </div>
        <BusSmallCardSlider plansS1={pubData?.ads?.plansS1} page={page} />
      </section>

      {/* <!-- تازه‌های بلاگ  --> */}

      <div className=" mx-5 ">
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
          </svg>
          <span className="p-0 mb-0 ">تازه‌های بلاگ</span>
        </div>
        <div className="row row-cols-1 row-cols-md-2 g-4 my-5 ads-all mx-auto ">
          {pubData?.newPosts?.length > 0 && (
            <NewPosts2x2 newPosts={pubData.newPosts} user={user} />
          )}
        </div>
      </div>

      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>

      {/* <!-- **********end of back to top************************* --> */}
    </div>
  );
  function countrySet(countryShort, first) {
    const coun = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    );
    setStates(coun.states);
    setFilters((prev) => ({
      ...prev,
      // country: coun.country,
      countryShort: countryShort.toLowerCase(),
      state: first ? prev.state : "",
      stateShort: first ? prev.stateShort : "",
      states: first ? prev.states : [],
      cities: first ? prev.cities : [],
    }));
  }
  function setBFHandle(field, value) {
    const numericValue = Number(value);
    // Check if the value is a valid number
    if (isNaN(numericValue)) return; // Do nothing if it's not a valid number
    setBackFilters((prev) => {
      const fields = field.split("."); // Split the field by dot notation
      const newBackFilters = { ...prev }; // Shallow copy of the state

      let current = newBackFilters;
      // Traverse the nested structure based on the fields
      for (let i = 0; i < fields.length - 1; i++) {
        current[fields[i]] = { ...current[fields[i]] }; // Shallow copy each level
        current = current[fields[i]]; // Move to the next level
      }

      current[fields[fields.length - 1]] = value; // Set the value at the deepest level

      return newBackFilters;
    });
  }
  function setBFHdle(field, value) {
    const numericValue = Number(value);
    // Check if the value is a valid number
    if (isNaN(numericValue)) return; // Do nothing if it's not a valid number
    setBackFilters((pre) => {
      const bf = { ...pre };
      bf[field] = value;
      return bf;
    });
  }
  function handleSliderChange(event, newValue) {
    // Adjust minValue
    const adjustedMinValue =
      Math.round(newValue[0] / getStep(newValue[0])) * getStep(newValue[0]);

    // Adjust maxValue
    const adjustedMaxValue =
      Math.round(newValue[1] / getStep(newValue[1])) * getStep(newValue[1]);

    setBackFilters((prev) => ({
      ...prev,
      price: {
        min: adjustedMinValue,
        max: adjustedMaxValue,
      },
    }));
  }

  function getStep(value) {
    const number = Number(value);
    console.log("number", number);

    if (number < 10000) return 1000;
    if (number < 100000) return 5000;
    if (number < 500000) return 25000;
    if (number < 1000000) return 100000;
    if (number <= 2000000) return 200000;
    if (number > 2000000) return 1000000;
    return 1000000;
  }
}
