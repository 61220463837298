import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import {
  CheckCircle,
  Clear,
  Error,
  Publish,
  WarningAmber,
} from "@mui/icons-material";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import AddSocialMedia from "../../../persian/addSocialMedia";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import RegisterAddressPlaces from "../../business/register/registerAddressPlaces";
import MyIcons from "../../../golbal/icons/myIcons";
import { Link, useNavigate, useParams } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import apiDemy from "../../../../utils/apiDemy";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/api";
import MetaDecorator from "../../../../utils/metaDecorator";
import demyData from "../../../../utils/demyData";
import tools from "../../../../utils/tools";
import AddLinks from "../../../golbal/addLinks";
import FormikListErr from "../../../persian/common/alerts/formikListErr";

export default function CenterRegister({ action }) { 

  const { lang, state,country, countryShort, stateShort} =
    tools.getItemFromLocalStorage("initSetup", {});

  const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  const [resisedLogoAuth, setResisedLogoAuth] = useState(null);
  const [originalLogo, setOriginalLogo] = useState(null);
  const photoHandle = async (e, setResisedState, setOriginalState) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " از فایل با فرمت عکس استفاده کنید.",
      });
    console.log("photoHandle auth", originalPhoto);
    setResisedState(
      await tools.resizeFile({
        file: e.target.files[0],
      })
    );
    setOriginalState(e.target.files[0]);
  };
  const uploadFileDatabase = async (e, file, field, fieldState) => {
    e.preventDefault();
    if (!file) return;
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    console.log("formData", formData);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/demy/center",
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });

    console.log(response.data);
    formik.setValues((pre) => ({ ...pre, [field]: response.data }));
    fieldState(null);
    messageRef.current.showSuccess({
      message: "عکس با موفقیت بارگذاری شد، جهت ثبت کلید ویرایش را فشار دهید. ",
    });
  };

  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false });
  const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));
  const [currencies, setCurrencies] = useState([
    { currency: "CAD", currency_name: "CAD" },
    { currency: "USD", currency_name: "USD" },
    { currency: "EUR", currency_name: "EUR" },
    { currency: "AUD", currency_name: "AUD" },
    { currency: "GBP", currency_name: "GBP" },
    { currency: "IRR", currency_name: "ریال" },
  ]);

  async function fetchCurrencies() {
    setLoading((pre) => ({ ...pre, currencies: true }));
    const response = await api.getAcceptedCur({
      token: user.token,
      accepted: "all",
    });
    setLoading((pre) => ({ ...pre, currencies: false }));
    if (response.error)
      return messageRef.current.showError({
        message: `مشکل در انجام عملیات : ${response.error}`,
      });
    setCurrencies(response.data);
  }

  const handleChange = (e) => {
    const { value } = e.target;
    if (value !== "more")
      return formik.setValues((pre) => ({
        ...pre,
        appFee: { ...pre.appFee, currency: value },
      }));

    fetchCurrencies();
  };

  // const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  // const country = regionNames.of(countryShort.toUpperCase()).toLowerCase();

  const { id } = useParams();

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

  const formik = useFormik({
    initialValues: {
      action,
      img: "",
      logo: "",
      yearBuilt: "",
      _id: "",
      lang,
      lcs: window.lcs,
      email: "",
      website: "",
      center: "",
      centerType: "",
      dli: "",
      appFee: { fee: "", currency: "CAD", waived: false, desc: "" },
      pgwp: "condition",
      pgwpLink: "",
      description: "",
      languages: ["en"],
      phone: "",
      socialMedia: [{ platform: "", url: "" }],
      links: [{ section: "ADMISSIONS", urls: [{ name: "", url: "" }] }],
      tuitions: [
        {
          resideStatus: "",
          details: [
            {
              studyLevel: "",
              min: 0,
              max: 0,
              currency: countryDetails?.currency,
            },
          ],
        },
      ],
      resideLinks: [{ name: "", url: "" }],
      resideDesc: "",
      tuitionDesc: "",
      enrolment: [
        {
          type: "",
          studyLevel: "",
          qty: 0,
        },
      ],
      enrolDesc: "",
      location: {
        country,
        countryShort,
        state,
        stateShort,
        city: "",
        route: "",
        municipality: "",
        streetNumber: "",
        number: "",
        postalCode: "",
        googleAddress: "",
        lat: "",
        lng: "",
      },
    },
    onSubmit: async (values) => {
      console.log("onSubmit");
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });

      console.log("passed Formik", values);
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiDemy.registerCenter({
        token: user.token,
        data: values,
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، مرکز آموزشی با موفقیت ثبت شد.",
      });

      const updateduser = await api.getUser();
      if (updateduser.error) return;
      signedUser(updateduser.data);
      window.scrollTo({ top: "0", behavior: "smooth" });
      navigate(`/${window.lang}/demy/center/page/${tools.slugMaker({title:response?.data.center,city:response?.data?.moreInfo?.address?.city,state:response?.data?.moreInfo?.address?.state,serial:response?.data?.serial})}`, {
        state: response.data,
      });
    },
    validationSchema: Yup.object({
      center: Yup.string()
        .required("لطفاً نام دانشگاه را وارد کنید.")
        .max(200, "نام دانشگاه حداکثر باید 200 حرف باشد .")
        .min(5, "نام دانشگاه حداقل باید 5 حرف باشد."),
      centerType: Yup.string()
        .required("لطفاً نوع مرکز را انتخاب کنید.")
        .max(200, "نام مرکز حداکثر باید 200 حرف باشد .")
        .min(5, "نام مرکز حداقل باید 5 حرف باشد."),
      description: Yup.string()
        .required("لطفاً اطلاعات تکمیلی را وارد کنید.")
        .max(4000, "اطلاعات تکمیلی حداکثر باید 4000 حرف باشد .")
        .min(10, "اطلاعات تکمیلی حداقل باید 10 حرف باشد."),
      phone: Yup.string()
        .trim()
        .when("suggestMode", (suggestMode, schema, value) => {
          return (
            value.parent.phone?.length &&
            schema.test(
              "is-valid-phone",
              "شماره تلفن نامعتبر میباشد.",
              (value) => {
                return isValidPhoneNumber(value || "");
              }
            )
          );
        }),
      // ... your other validation rules for phone

      email: Yup.string()
        .notRequired()
        .trim()
        .lowercase()
        .email("ایمیل را به درستی وارد کنید."),
      languages: Yup.array()
        .required(" دست کم یک زبان را انتخاب کنید.")
        .test(
          "array-length",
          " دست کم یک زبان را انتخاب کنید.",
          (value) => value.length > 0
        ),

      location: Yup.object().shape({
        country: Yup.string().required("کشور نمیتواند خالی باشد."),
        state: Yup.string().required("استان نمیتواند خالی باشد."),
        city: Yup.string().required("شهر نمیتواند خالی باشد."),
        municipality: Yup.string(),
      }),
      website: Yup.string().matches(
        urlRegex,
        " آدرس وبسایت را به درستی وارد کنید."
      ),
      socialMedia: Yup.array().of(
        Yup.object().shape({
          platform: Yup.string(),

          url: Yup.string()
            .when("platform", (platform, schema) => {
              return platform
                ? schema.required("لطفاً آدرس URL را وارد نمایید.")
                : schema;
            })
            .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
              if (!value) return true; // Allow empty value
              // Regular expression to validate URL
              return urlRegex.test(value);
            }),
        })
      ),
    }),
  });

  useEffect(() => {
    fetchDemyDetails();
  }, [id]);

  async function fetchDemyDetails() {
    console.log(id);
    if (!id) return;
    const response = await apiDemy.fetchCenterDetails(id);
    if (response.error) return alert(response.error);
    // console.log(response.data);
    editInitialData(formik, response.data, action);
  }

  // console.log("formik", formik.values);
  // console.log("formik", formik.errors);
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "University Register",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy`,
              name: `آکادمی متامارس`,
              description:
                "پل ارتباطی بین جویندگان کار و کارفرماها در شهرها و محله‌های گوناگون ... ",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/dashboard/demy/registercenter`,
              name: "ثبت رایگان آگهی استخدام",
              description: "ثبت آسان، انتشار سریع و خودکار آگهی استخدام",
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "ثبت اطلاعات دانشگاه",
    description: "ثبت اطلاعات مربوط به دانشگاه",
    section: "Center register",
    url: `https://www.metamarce.com/${window.lang}/demy/registercenter`,
    img: "https://www.metamarce.com/img/demy/Hiring-rafiki.svg",
    type: "webpage",
    
  };

  return (
    <div className="user  mt-3  px-0">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />

      <p className="divider2 border-0 mt-3 ">
           <MyIcons icon="dotsTitr" />
        <span className="p-0 m-z ">جزییات دانشگاه</span>
      </p>
      <form
        onSubmit={formik.handleSubmit}
        className="row userShow"
        name="jobRegForm00"
        autoComplete="true"
      >
        {/* Right Section */}
        <div className="col-md-6 px-3">
          <div>
            <div className="row">
              <div className="col px-3">
                <div className="mb-5 ">
                  <div>
                    <label
                      className="form-label mt-3 titr6 mb-0 required"
                      htmlFor="center"
                    >
                      Center Name
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className={`form-control py-1 box2 ${addErrClass({
                        formik,
                        field: "center",
                      })} `}
                      id="center"
                      placeholder=""
                      name="centerName"
                      value=""
                      {...formik.getFieldProps("center")}
                    />
                    <FormikErrRender formik={formik} field="center" />
                  </div>
                  <div>
                    <label
                      className="form-label mt-3 titr6 mb-0 required"
                      htmlFor="yearBuilt"
                    >
                      Year Built
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className={`form-control py-1 box2 ${addErrClass({
                        formik,
                        field: "yearBuilt",
                      })} `}
                      id="yearBuilt"
                      placeholder=""
                      name="yearBuilt"
                      value=""
                      {...formik.getFieldProps("yearBuilt")}
                    />
                    <FormikErrRender formik={formik} field="yearBuilt" />
                  </div>
                  <div>
                    <label
                      className="form-label mt-3 titr6 mb-0 required"
                      htmlFor="dli"
                    >
                      DLI Number
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className={`form-control py-1 box2 ${addErrClass({
                        formik,
                        field: "dli",
                      })} `}
                      id="dli"
                      placeholder=""
                      name="busName"
                      value=""
                      {...formik.getFieldProps("dli")}
                    />
                    <FormikErrRender formik={formik} field="dli" />
                  </div>

                  <div>
                    <div className=" p-2 mb-5">
                      <p className="form-label titr6">PGWP</p>
                      <div className="form-check  ">
                        <div className="d-flex flex-wrap">
                          {/* {renderData.jobExperience.map((JobExp) => ( */}
                          <div className="">
                            <input
                              className="form-check-input ms-5 me-1"
                              type="radio"
                              id="pgwp-yes"
                              value="true"
                              checked={formik.values.pgwp === "true"}
                              name="pgwp"
                              onChange={(e) =>
                                formik.setValues((pre) => ({
                                  ...pre,
                                  pgwp: e.target.value,
                                }))
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="pgwp-yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="">
                            <input
                              className="form-check-input ms-5 me-1"
                              type="radio"
                              id="pgwp-no"
                              value="false"
                              checked={formik.values.pgwp === "false"}
                              name="pgwp"
                              onChange={(e) =>
                                formik.setValues((pre) => ({
                                  ...pre,
                                  pgwp: e.target.value,
                                }))
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="pgwp-no"
                            >
                              No
                            </label>
                          </div>
                          <div className="">
                            <input
                              className="form-check-input ms-5 me-1"
                              type="radio"
                              id="pgwp-condition"
                              value="condition"
                              checked={formik.values.pgwp === "condition"}
                              name="pgwp"
                              onChange={(e) =>
                                formik.setValues((pre) => ({
                                  ...pre,
                                  pgwp: e.target.value,
                                }))
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="pgwp-condition"
                            >
                              Condition
                            </label>
                          </div>
                          {formik.values.pgwp === "condition" && (
                            <input
                              type="text"
                              autoComplete="off"
                              className={`form-control py-1 box2 ${addErrClass({
                                formik,
                                field: "pgwpLink",
                              })} `}
                              id="pgwpLink"
                              placeholder="Enter condition Link"
                              name="pgwpLink"
                              {...formik.getFieldProps("pgwpLink")}
                            />
                          )}
                          {/* ))} */}
                        </div>
                      </div>
                    </div>
                    <FormikErrRender formik={formik} field="pgwp" />
                  </div>
                  <div>
                    {/* <div>
                  <label
                    className="form-label mt-3 titr6 mb-0 required"
                    htmlFor="appFee"
                  >
                    Application Fee's
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control py-1 box2 ${addErrClass({
                      formik,
                      field: "appFee.fee",
                    })} `}
                    id="appFee"
                    placeholder=""
                    name="appFee"                    
                    {...formik.getFieldProps("appFee.fee")}
                  />
                  <FormikErrRender formik={formik} field="appFee" />
                  </div> */}
                    {/* <div>
                  <label
                    className="form-label mt-3 titr6 mb-0 required"
                    htmlFor="appFee"
                  >
                    Application Fee's
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control py-1 box2 ${addErrClass({
                      formik,
                      field: "appFee.fee",
                    })} `}
                    id="appFee"
                    placeholder=""
                    name="appFee"                    
                    {...formik.getFieldProps("appFee.fee")}
                  />
                  <FormikErrRender formik={formik} field="appFee" />
                  </div> */}
                  </div>

                  <div className="">
                    <p className="form-label titr6">Application Fee's</p>
                    <div className="">
                      <div className="">
                        <label htmlFor="waivedAppFeeInp">Waived</label>
                        <input
                          type="checkbox"
                          id="waivedAppFeeInp"
                          autoComplete="off"
                          aria-label="Amount"
                          className="form-check-input form-check-input-filter1  m-0 p-0"
                          placeholder="نرخ پایه براساس هر ساعت"
                          {...formik.getFieldProps("appFee.waived")}
                        />
                      </div>
                      {!formik.values?.appFee?.waived && (
                        <div className="d-flex">
                          <div className=" mx-2">
                            <input
                              type="text"
                              autoComplete="off"
                              aria-label="Amount"
                              className="form-control"
                              placeholder="Intrenational students application fee's"
                              {...formik.getFieldProps("appFee.fee")}
                            />
                          </div>
                          {loading.currencies ? (
                            <CircularProgress color="inherit" size="20px" />
                          ) : (
                            <select
                              className="login-btn my-2"
                              name="appFeeCurrency"
                              value={formik.values.appFee.currency}
                              // {...formik.getFieldProps("appFee.currency")}
                              onChange={handleChange}
                            >
                              <option value="">انتخاب کنید :</option>
                              {currencies.map((curr) => (
                                <option value={curr.currency}>
                                  {curr.currency_name}
                                </option>
                              ))}
                              {currencies.length < 10 && (
                                <option value="more">موارد بیشتر</option>
                              )}
                            </select>
                          )}
                        </div>
                      )}
                      <textarea
                        className={`form-control ${addErrClass({
                          formik,
                          field: "appFee.desc",
                        })}`}
                        rows="3"
                        name="appFee.desc"
                        {...formik.getFieldProps("appFee.desc")}
                      ></textarea>
                      <FormikErrRender formik={formik} field="appFee.desc" />
                    </div>
                    <FormikErrRender formik={formik} field="appFee.fee" />
                    <FormikErrRender formik={formik} field="appFee.currency" />
                  </div>

                  <label
                    className="form-label mt-3 titr6 mb-0 required"
                    htmlFor="centerType"
                  >
                    Center Type
                  </label>

                  <select
                    id="centerType"
                    className="login-btn  selectpicker shadow-none w-100"
                    aria-label="Select center typr"
                    name="mainCatSel001"
                    {...formik.getFieldProps("centerType")}
                  >
                    <option value="">انتخاب کنید:</option>
                    {demyData.centerTypes?.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>

                  <FormikErrRender formik={formik} field="centerType" />

                  <div className="mb-5">
                    <label className="form-label titr6">Description</label>
                    <textarea
                      className={`form-control ${addErrClass({
                        formik,
                        field: "description",
                      })}`}
                      rows="3"
                      name="description"
                      defaultValue={formik.values.description}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          description: e.target.value,
                        }))
                      }
                    ></textarea>
                    <FormikErrRender formik={formik} field="description" />
                  </div>

                  <label
                    className="form-label mt-5 titr6 mb-0 required"
                    htmlFor="emailApply"
                  >
                    Email
                  </label>
                  <input
                    dir="ltr"
                    id="emailApply"
                    {...formik.getFieldProps("email")}
                    className={`userUpdateInput  form-control box2 ${addErrClass(
                      {
                        formik,
                        field: "email",
                      }
                    )}`}
                    type="text"
                    autoComplete="off"
                    placeholder={formik.values.email}
                  />
                  <FormikErrRender formik={formik} field="email" />
                  <label
                    className="form-label mt-5 titr6 mb-0 required"
                    htmlFor="website"
                  >
                    Website
                  </label>
                  <div>
                    <input
                      dir="ltr"
                      id="website"
                      {...formik.getFieldProps("website")}
                      className={`userUpdateInput  form-control box2 ${addErrClass(
                        {
                          formik,
                          field: "website",
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.website}
                    />
                    <FormikErrRender formik={formik} field="website" />
                  </div>
                  <div className="form-label mt-5 titr6 mb-0  border-0 ">
                    شبکه‌های اجتماعی
                  </div>

                  <div className=" border-0 mb-0 px-5">
                    <AddSocialMedia formik={formik} />
                  </div>

                  <div className="form-label mt-5 titr6 mb-0 ">
                    Important Links
                  </div>

                  <AddLinks formik={formik} />

                  <label
                    className="form-label mt-5 titr6 mb-0 "
                    htmlFor="phoneApply"
                  >
                    شماره تماس
                  </label>
                  <input
                    dir="ltr"
                    id="phoneApply"
                    {...formik.getFieldProps("phone")}
                    className={`userUpdateInput  form-control box2 ${addErrClass(
                      {
                        formik,
                        field: "phone",
                      }
                    )} `}
                    type="text"
                    autoComplete="off"
                    placeholder={formik.values.phone}
                  />
                  <FormikErrRender formik={formik} field="phone" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Left Section */}
        <div className="userUpdateItem col-md-6 px-3">
          <div className="d-flex">
            <div className="userUpdateUpload d-flex flex-column justify-content-center">
              <img
                src={resisedPhotoAuth || formik.values?.img}
                alt={formik.values.center}
                className="userShowImg-profile"
                loading="lazy"
              />

              <label className="my-2" htmlFor="fileAuth">
                <Publish className="userUpdateIcon" />
                تغییر عکس
              </label>
              <input
                type="file"
                id="fileAuth"
                onChange={(e) =>
                  photoHandle(e, setResisedPhotoAuth, setOriginalPhoto)
                }
                style={{ display: "none" }}
              />
              <span
                className="userUpdateButton edit-btn"
                style={{ display: resisedPhotoAuth ? "block" : "none" }}
                onClick={(e) =>
                  uploadFileDatabase(
                    e,
                    originalPhoto,
                    "img",
                    setResisedPhotoAuth
                  )
                }
              >
                بارگذاری
              </span>
            </div>

            <div className="userUpdateUpload d-flex flex-column justify-content-center">
              <img
                src={resisedLogoAuth || formik.values?.logo}
                alt={formik.values.center}
                className="userShowImg-profile"
                loading="lazy"
              />

              <label className="my-2" htmlFor="filelogo">
                <Publish className="userUpdateIcon" />
                Change Logo
              </label>
              <input
                type="file"
                id="filelogo"
                onChange={(e) =>
                  photoHandle(e, setResisedLogoAuth, setOriginalLogo)
                }
                style={{ display: "none" }}
              />
              <span
                className="userUpdateButton edit-btn"
                style={{ display: resisedLogoAuth ? "block" : "none" }}
                onClick={(e) =>
                  uploadFileDatabase(
                    e,
                    originalLogo,
                    "logo",
                    setResisedLogoAuth
                  )
                }
              >
                بارگذاری
              </span>
            </div>
          </div>
          <div>
            <div className="form-label mt-5 titr6 mb-0  border-0 required">
              Address
              {(formik.touched.location?.country &&
                formik.errors.location?.country) ||
              (formik.touched.location?.state &&
                formik.errors.location?.state) ||
              (formik.touched.location?.city &&
                formik.errors.location?.city) ? (
                //    ||
                // (formik.touched.location?.route &&
                //   formik.errors.location?.route) ||
                // (formik.touched.location?.streetNumber &&
                //   formik.errors.location?.streetNumber) ||
                // (formik.touched.location?.postalCode &&
                //   formik.errors.location?.postalCode) ||
                // (formik.touched.location?.lat &&
                //   formik.errors.location?.lat) ||
                // (formik.touched.location?.lng &&
                //   formik.errors.location?.lng)
                <Error sx={{ color: "red" }} />
              ) : (
                ""
              )}
            </div>
            <RegisterAddressPlaces formik={formik} section="demy" />
          </div>

          <p className="form-label titr6">Enrolment</p>
          <AddEnrolment formik={formik} />
          <div className="mb-5">
            <label className="form-label titr6">Enrolment Description</label>
            <textarea
              className={`form-control ${addErrClass({
                formik,
                field: "enrolDesc",
              })}`}
              rows="3"
              name="enrolDesc"
              defaultValue={formik.values.enrolDesc}
              onBlur={(e) =>
                formik.setValues((pre) => ({
                  ...pre,
                  enrolDesc: e.target.value,
                }))
              }
            ></textarea>
            <FormikErrRender formik={formik} field="enrolDesc" />
          </div>

          <div className="mb-5">
            <label className="form-label titr6">Residence</label>
            <textarea
              className={`form-control ${addErrClass({
                formik,
                field: "resideDesc",
              })}`}
              rows="3"
              name="resideDesc"
              defaultValue={formik.values.resideDesc}
              onBlur={(e) =>
                formik.setValues((pre) => ({
                  ...pre,
                  resideDesc: e.target.value,
                }))
              }
            ></textarea>
            <AddResideLinks formik={formik} />
            <FormikErrRender formik={formik} field="resideDesc" />
          </div>
          <div>
            <p className="form-label titr6">Suported Languages</p>
            <div className="form-check ">
              {/* <div
                        id="panelsStayOpenBusinessFilters-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingOne"
                      > */}
              <div
                className={` form-font row row-cols-3 row-cols-md-4 row-cols-lg-5 g-2 ${addErrClass(
                  { formik, field: "languages" }
                )} `}
              >
                {renderData.languages.map((lang, i) =>
                  !lang[window.lang].length ? (
                    ""
                  ) : (
                    <div key={i} className="morelang-line my-0 ">
                      <input
                        className="form-check-input  ms-5 me-1 red moreLang"
                        type="checkbox"
                        value={lang.name}
                        checked={formik.values.languages?.includes(lang.name)}
                        id={`flexLanguages${i}`}
                        onChange={(e) => {
                          const languages = [...formik.values.languages] || [];
                          const index = languages.indexOf(lang.name);
                          if (index !== -1) {
                            languages.splice(index, 1);
                          } else {
                            languages.push(e.target.value);
                          }

                          formik.setValues((pre) => ({
                            ...pre,
                            languages,
                          }));
                        }}
                      />
                      <label
                        className="form-check-label ps-2 red moreLang"
                        htmlFor={`flexLanguages${i}`}
                      >
                        {lang[window.lang]}
                      </label>
                    </div>
                  )
                )}
              </div>
              <button className="btn btn-click ms-5 mt-2 mb-5" type="button">
                <label htmlFor="moreLangCheckbox"> بیشتر... </label>
                {/* <span>
                              <i className="bi bi-three-dots ps-2 py-0 my-0"></i>
                            </span> */}
                <input
                  id="moreLangCheckbox"
                  type="checkbox"
                  onClick={(e) => {
                    const elemet = document.querySelectorAll(".moreLang");
                    if (e.target.checked) {
                      document.querySelector(
                        "label[for=moreLangCheckbox]"
                      ).innerHTML = "کمتر...";
                      Array.from(elemet).map(
                        (el) => (el.style.display = "inline-block")
                      );
                    } else {
                      document.querySelector(
                        "label[for=moreLangCheckbox]"
                      ).innerHTML = "بیشتر...";
                      Array.from(elemet).map(
                        (el) => (el.style.display = "none")
                      );
                    }
                  }}
                />
              </button>
              <FormikErrRender formik={formik} field="languages" />
              {/* </div> */}
            </div>
          </div>
        </div>
        <div>
          <p className="form-label titr6">Tuition fee's</p>
          <AddTuition2 formik={formik} />
          <div className="mb-5">
            <label className="form-label titr6">Tuition's Description</label>
            <textarea
              className={`form-control ${addErrClass({
                formik,
                field: "tuitionDesc",
              })}`}
              rows="3"
              name="tuitionDesc"
              defaultValue={formik.values.tuitionDesc}
              onBlur={(e) =>
                formik.setValues((pre) => ({
                  ...pre,
                  tuitionDesc: e.target.value,
                }))
              }
            ></textarea>
            <FormikErrRender formik={formik} field="tuitionDesc" />
          </div>
        </div>
        <FormikListErr formik={formik} />
        <SubmitBtn formik={formik} text="" />
      </form>

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
}

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    _id: action === "edit" ? data._id : "",
    lang: data?.lang ?? window.lang,
    lcs: data?.lcs ?? window.lcs,
    center: data.center,
    email: data.email,
    img: data.moreInfo.img,
    logo: data.moreInfo.logo,
    yearBuilt: data.moreInfo.yearBuilt,
    website: data.moreInfo.website,
    centerType: data.moreInfo.centerType,
    dli: data.moreInfo.dli,
    appFee: data.moreInfo.appFee,
    pgwp: data.moreInfo.pgwp,
    pgwpLink: data.moreInfo.pgwpLink,
    description: data.moreInfo.description,
    resideDesc: data.moreInfo.resideDesc,
    resideLinks: data.moreInfo.resideLinks,
    languages: data.moreInfo.languages,
    phone: data.moreInfo.phone,
    socialMedia: data.moreInfo.socialMedia,
    tuitions: data.moreInfo.tuitions,
    tuitionDesc: data.moreInfo.tuitionDesc,
    enrolment: data.moreInfo.enrolment,
    enrolDesc: data.moreInfo.enrolDesc,
    links: data.moreInfo.links,
    location: {
      ...data.moreInfo.address,
      lat: data.moreInfo.address?.coordinates?.lat,
      lng: data.moreInfo.address?.coordinates?.lng,
    },
  };
  formik.setValues(initialValues);
}

async function editHandle({ messageRef, setLoading, user, values, navigate }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiDemy.editCenter({
    token: user.token,
    data: values,
  });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });

  messageRef.current.showSuccess({
    message: " مرکز آموزشی با موفقیت ویرایش شد.",
  });
  window.scrollTo({ top: "0", behavior: "smooth" });
  navigate(`/${window.lang}/demy/center/page/${tools.slugMaker({title:response?.data.center,city:response?.data?.moreInfo?.address?.city,state:response?.data?.moreInfo?.address?.state,serial:response?.data?.serial})}`, {
    state: response.data,
  });
}

function AddEnrolment({ formik }) {
  return (
    <div className="table" id="maintable3">
      {formik.values?.enrolment.map((tuition, i) => (
        <div key={i} className="mb-2">
          <div id={i} className="input-group mb-1 addSocialMedia">
            <select
              className=" login-btn  selectpicker shadow-none w-30"
              aria-label="Default select example"
              name={tuition + i}
              style={{
                borderTopRightRadius: "20px",
                bordeBbottomRightRadius: "20px",
                fontSize: "small",
              }}
              {...formik.getFieldProps(`enrolment[${i}].studyLevel`)}
            >
              <option>Select :</option>
              {demyData.studyLevel?.map((stl, i) => (
                <option key={i} value={stl}>
                  {stl}
                </option>
              ))}
            </select>
            <select
              className=" login-btn  selectpicker shadow-none w-30"
              aria-label="Default select example"
              name={tuition + i}
              style={{
                borderTopRightRadius: "20px",
                bordeBbottomRightRadius: "20px",
                fontSize: "small",
              }}
              {...formik.getFieldProps(`enrolment[${i}].type`)}
            >
              <option>Select :</option>
              {demyData.enrolTypes?.map((enrolrT, i) => (
                <option key={i} value={enrolrT}>
                  {enrolrT}
                </option>
              ))}
            </select>
            <div className=" mx-2">
              <input
                type="text"
                autoComplete="off"
                aria-label="Amount (to the nearest dollar)"
                className={`form-control ${addErrClass({
                  formik,
                  field: `enrolment[${i}].qty`,
                })}`}
                placeholder="Min"
                {...formik.getFieldProps(`enrolment[${i}].qty`)}
              />
            </div>
            <FormikErrRender formik={formik} field={`enrolment.${i}.type`} />
            <FormikErrRender
              formik={formik}
              field={`enrolment.${i}.studyLevel`}
            />
            <FormikErrRender formik={formik} field={`enrolment.${i}.qty`} />
          </div>
          {i > 0 && (
            <button
              type="button"
              className="deleteContact btn btn login-trigger3 btn-xs mx-2"
              title="پاک کردن"
              onClick={() => removeHandle(i)}
            >
              <i
                className="bi bi-x-circle-fill p-0 "
                style={{ fontSize: "1.5em" }}
              ></i>
            </button>
          )}
        </div>
      ))}

      {!formik.errors.enrolment && (
        <div
        // className='border-top border-secondary'
        >
          <div
            className="btn btn-xs shadow-none login-trigger2  "
            title="افزودن شبکه اجتماعی"
          >
            <i
              className="bi bi-plus-circle-fill "
              style={{ fontSize: "1.5em" }}
              onClick={addTuitionHandle}
            ></i>
          </div>
        </div>
      )}
    </div>
  );

  function addTuitionHandle() {
    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.enrolment.length;
      newF.enrolment.push(newF.enrolment[index - 1]);
      return newF;
    });
  }

  function removeHandle(index) {
    console.log("removeHandle");
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.enrolment.splice(index, 1);
      return newFormik;
    });
  }
}
function AddTuition2({ formik }) {
  const addSection = () => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      tuitions: [
        ...prevValues.tuitions,
        {
          resideStatus: "",
          details: [{ studyLevel: "", min: 0, max: 0, currency: "CAD" }],
        },
      ],
    }));
  };
  const removeSection = (index) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      tuitions: prevValues.tuitions.filter((_, i) => i !== index),
    }));
  };

  const addName = (sectionIndex) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      tuitions: prevValues.tuitions.map((section, i) =>
        i === sectionIndex
          ? {
              ...section,
              details: [
                ...section.details,
                { studyLevel: "", min: 0, max: 0, currency: "CAD" },
              ],
            }
          : section
      ),
    }));
  };
  const removeName = (sectionIndex, nameIndex) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      tuitions: prevValues.tuitions.map((section, i) =>
        i === sectionIndex
          ? {
              ...section,
              details: section.details.filter((_, j) => j !== nameIndex),
            }
          : section
      ),
    }));
  };
  return (
    <div className="table">
      {formik.values.tuitions.map((section, sectionIndex) => (
        <div className="mb-2" key={sectionIndex}>
          <select
            className=" login-btn  selectpicker shadow-none w-30"
            aria-label="Default select example"
            name={section + sectionIndex}
            style={{
              borderTopRightRadius: "20px",
              bordeBbottomRightRadius: "20px",
              fontSize: "small",
            }}
            {...formik.getFieldProps(`tuitions[${sectionIndex}].resideStatus`)}
          >
            <option>Select :</option>
            {demyData.resideStatus?.map((stat, i) => (
              <option key={i} value={stat}>
                {stat}
              </option>
            ))}
          </select>

          {section.details.map((detail, detailIndex) => (
            <div className="d-flex flex-row" key={detailIndex}>
              <select
                className=" login-btn  selectpicker shadow-none w-30"
                aria-label="Default select example"
                name={detail + detailIndex}
                style={{
                  borderTopRightRadius: "20px",
                  bordeBbottomRightRadius: "20px",
                  fontSize: "small",
                }}
                {...formik.getFieldProps(
                  `tuitions[${sectionIndex}].details[${detailIndex}].studyLevel`
                )}
              >
                <option>Select :</option>
                {demyData.studyLevel?.map((stl, detailIndex) => (
                  <option key={detailIndex} value={stl}>
                    {stl}
                  </option>
                ))}
              </select>
              Min:
              <input
                type="number"
                value={detail.min}
                {...formik.getFieldProps(
                  `tuitions[${sectionIndex}].details[${detailIndex}].min`
                )}
              />
              Max:
              <input
                type="number"
                value={detail.max}
                {...formik.getFieldProps(
                  `tuitions[${sectionIndex}].details[${detailIndex}].max`
                )}
              />
              Currency:
              <select
                className="login-btn my-2"
                name="salaryCurrency"
                {...formik.getFieldProps(
                  `tuitions[${sectionIndex}].details[${detailIndex}].currency`
                )}
              >
                <option>
                  {
                    formik.values.tuitions[sectionIndex].details[detailIndex]
                      .currency
                  }
                </option>
                <option value="CAD">CAD</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="AUD">AUD</option>
                <option value="GBP">GBP</option>
              </select>
              {detailIndex > 0 && (
                <button
                  className="deleteContact btn btn login-trigger3 btn-xs mx-2"
                  onClick={() => removeName(sectionIndex, detailIndex)}
                >
                  <i
                    className="bi bi-x-circle-fill p-0 "
                    style={{ fontSize: "1.5em" }}
                  ></i>
                </button>
              )}
            </div>
          ))}
          <div
            className="btn btn-primary share-cover-btn mx-2"
            onClick={() => addName(sectionIndex)}
          >
            Add Study Level
          </div>

          {sectionIndex > 0 && (
            <div
              className="btn btn-primary share-cover-btn mx-2"
              onClick={() => removeSection(sectionIndex)}
            >
              Remove Reside Status
            </div>
          )}
        </div>
      ))}
      <div
        className="btn btn-primary share-cover-btn mx-2"
        onClick={addSection}
      >
        Add Reside Status
      </div>
    </div>
  );
}

function AddResideLinks({ formik }) {
  return (
    <div className="table" id="maintable3">
      {formik.values?.resideLinks?.map((link, i) => (
        <div key={i} className="mb-2">
          <div id={i} className="input-group mb-1 addSocialMedia">
            <div className=" mx-2">
              <input
                type="text"
                autoComplete="off"
                aria-label="Amount (to the nearest dollar)"
                className={`form-control ${addErrClass({
                  formik,
                  field: `resideLinks[${i}].name`,
                })}`}
                placeholder="Description"
                {...formik.getFieldProps(`resideLinks[${i}].name`)}
              />
            </div>
            <div className=" mx-2">
              <input
                type="text"
                autoComplete="off"
                aria-label="Amount (to the nearest dollar)"
                className={`form-control ${addErrClass({
                  formik,
                  field: `resideLinks[${i}].url`,
                })}`}
                placeholder="Link"
                {...formik.getFieldProps(`resideLinks[${i}].url`)}
              />
            </div>
          </div>
          {i > 0 && (
            <button
              type="button"
              className="deleteContact btn btn login-trigger3 btn-xs mx-2"
              title="Delete"
              onClick={() => removeHandle(i)}
            >
              <i
                className="bi bi-x-circle-fill p-0 "
                style={{ fontSize: "1.5em" }}
              ></i>
            </button>
          )}
        </div>
      ))}
      <div>
        <div className="btn btn-xs shadow-none login-trigger2  " title="Add">
          <i
            className="bi bi-plus-circle-fill "
            style={{ fontSize: "1.5em" }}
            onClick={addLevelHandle}
          ></i>
        </div>
      </div>
    </div>
  );

  function addLevelHandle() {
    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.resideLinks.length;
      newF.resideLinks.push(newF.resideLinks[index - 1]);
      return newF;
    });
  }

  function removeHandle(index) {
    console.log("removeHandle");
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.resideLinks.splice(index, 1);
      return newFormik;
    });
  }
}
