import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress, Rating } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
// import Tooltip1 from "../../../golbal/tooltip/tooltip";
import MyIcons from "../../../golbal/icons/myIcons";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import renderData from "../../../../utils/renderData";
import tools from "../../../../utils/tools";

export default function ProgramCard({ data }) {
  const { countryShort } = tools.getItemFromLocalStorage("initSetup", {});

  // console.log(props);
  const messageRef = useRef(null);
  const [spiner, setSpiner] = useState(false);
  const { signedUser, user } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: data._id,
      section: "program",
      token: user.token,
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> دوباره وارد شوید.</div>
          </div>
        ),
      });
    signedUser(response.data);

    !user?.saved?.program?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "فرصت شغلی با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "فرصت شغلی از لیست ذخیره حذف شد.",
        });
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, smooth: true });
  };
  return (
    <div key={data._id} className="col px-3 ">
      <ToastNote messageRef={messageRef} />

      <div
        className="card entry h-100 p-md-4 p-1 pt-4"
        // style="max-width: 540px;"
      >
        <Link
          to={`?process=${data.moreInfo?.process}`}
          className="btn btn-primary button-filter text-center button-filter-tag button-filter-tag5 mx-0 my-0 urgent-btn"
        >
          <span>
            <i className="bi bi-clock me-1 med-gray-font"></i>
          </span>
          فوری
        </Link>

        <div className=" px-3 text-center ">
          <img
            src={`${data?.moreInfo?.logo}`}
            className="img-fluid  jobCrad-img mb-2 "
            alt="jobCrad"
            loading="lazy"
          />
          <h5 dir="rtl" className="card-title card-titel2 m-1 small-gray-font">
            {data?.moreInfo.busName?.[window.lang]}
          </h5>

          {/* <!-- *******rating stars shold show up here --> */}

          <p dir="ltr" className="text-center">
            <span
              title={
                Number(data?.feedbackInfo?.rate) > 0 &&
                Number(data?.feedbackInfo?.rate.toFixed(2))
              }
            >
              <Rating
                name="read-only"
                value={Number(data?.feedbackInfo?.rate)}
                readOnly
                precision={0.5}
                style={{ fontSize: "14px" }}
              />
            </span>
          </p>
        </div>

        <div className="card-body p-md-1 p-0 text-center">
          <h5 className="card-title d-flex justify-content-center ">
            {data?.moreInfo.title}
            {compareDates({ date: data?.moreInfo?.dates?.start, days: 14 }) && (
              <MyIcons icon="new" />
            )}
            {data?.moreInfo.process === "urgent"}
          </h5>

          <div className="card-text card-text1 mb-4 mt-3">
            <small>
              <ul className="list-group">
                {data?.moreInfo?.address?.city && (
                  <li className="group-list-item2  med-gray-font mb-3 mt-1  ">
                    <i className="bi bi bi-geo-alt mx-2"></i>
                    {`${window.capitalize(data?.moreInfo?.address?.city)},
                    ${window.capitalize(data?.moreInfo?.address?.state)},
                    ${window.capitalize(data?.moreInfo?.address?.countryShort)}
                `}
                  </li>
                )}
              </ul>
            </small>
          </div>
          <div className="me-auto d-flex flex-wrap justify-content-start ">
            <Link
              title="دسته‌بندی اصلی"
              to={`?mainCat=${
                data?.category?.mainCat?.[window.lang]
              }&mainCatId=${data?.category?.mainCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag mx-2 my-0 py-0 px-3"
            >
              {/* <span>
                      <i className="bi bi-list-ul "></i>
                    </span> */}
              {/* {data?.mainCategory} */}
              {data?.category?.mainCat?.[window.lang]}
            </Link>
            <Link
              title="دسته‌بندی کوچکتر"
              to={`?subCat=${data?.category?.subCat?.[window.lang]}&subCatId=${
                data?.category?.subCat._id
              }&mainCat=${data?.category?.mainCat?.[window.lang]}&mainCatId=${
                data?.category?.mainCat?._id
              }`}
              className="btn btn-primary button-filter button-filter-tag  button-filter-tag2 mx-2 me-auto my-0 py-0 px-3"
            >
              {data?.category?.subCat?.[window.lang]}
            </Link>
            {data?.moreInfo.emType?.length > 0 && (
              <Link
                to={`?emType=${data?.moreInfo.emType?.[0]}`}
                className="btn btn-primary button-filter button-filter-tag button-filter-tag3 mx-2 my-0 py-0 px-2"
              >
                {renderData.emType.find(
                  (emT) => emT.name === data?.moreInfo.emType?.[0]
                )?.[window.lang] ?? ""}
                {data?.moreInfo.emType?.length > 1 && (
                  <span className="small-gray-font ms-2">
                    +
                    {(data?.moreInfo.emType?.length - 1).toLocaleString(
                      window.lang
                    )}
                  </span>
                )}
              </Link>
            )}
          </div>
          <div
            title="حقوق"
            className="btn button-filter-tag button-filter-tag4 mt-3 px-2 text-center mx-auto  med-gray-font "
            style={{ cursor: "auto" }}
            href="/fa/CA/QC/jobs/filters"
          >
            <span className=" small-gray-font px-2"> از </span>
            {tools.showPrice(
              data?.moreInfo.salary?.min,
              data?.moreInfo.salary?.currency
            )}
            <span className=" small-gray-font px-2"> تا </span>
            {tools.showPrice(
              data?.moreInfo.salary?.max,
              data?.moreInfo.salary?.currency
            )}
            <span className=" small-gray-font px-2">
              {`(${
                renderData.jobPeriod.find(
                  (per) => per.name === data?.moreInfo.salary?.period
                )?.[window.lang]
              })`}
            </span>
          </div>
        </div>
        {/* <!-- end of card bady --> */}

        <div className="card-footer  d-flex flex-wrap  justify-content-between border-top pt-3 pb-0 mt-3">
          <span className="m-0 med-gray-font">
            <i className="bi bi-calendar-plus  px-2 "></i>

            {tools.dynamicDate({
              date: data?.moreInfo?.dates?.start,
              countryShort,
              lang: window.lang,
              showYear: true,
              short: true,
            })}
          </span>
          <div>
            <Link
              onClick={scrollTop}
              to={`/${window.lang}/jobs/page/${data._id}`}
              className="btn btn-primary btn-card mx-1"
              state={{
                data: data,
              }}
            >
              جزییات بیشتر
            </Link>
            {/* <span className="icon-stack "> */}
            <button
              id={`saveBtn${data._id}`}
              className="icon-btn icon-stack"
              onClick={saveHandler}
            >
              {spiner ? (
                <CircularProgress color="inherit" size="20px" />
              ) : (
                <i
                  className={
                    user.saved?.program?.includes(data._id)
                      ? "bi bi-bookmark-fill icon-stack-1x"
                      : "bi bi-bookmark icon-stack-1x"
                  }
                  title="ذخیره"
                  style={{ color: "rgba(123, 99, 90, 0.894)" }}
                ></i>
              )}
            </button>
            {/* </span> */}
            <span>
              <button
                className=" icon-stack mx-1 "
                data-bs-toggle="modal"
                data-bs-target={`#myModel${data._id}`}
                id="shareBtn"
                data-bs-placement="top"
                title="Share!"
              >
                <i className="bi bi-share" title="همرسانی"></i>
              </button>
            </span>
            <ShareSocialMedias
              url={`${window.location.origin}/${window.lang}/jobs/page/${data?._id}`}
              title={data?.moreInfo.busName?.[window.lang]}
              img={data?.moreInfo.photo}
              _id={data?._id}
            />
          </div>
        </div>

        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
function compareDates({ date, days }) {
  const diffInMs = new Date() - new Date(date);
  const mSCheck = days * 24 * 60 * 60 * 1000;
  return diffInMs < mSCheck;
}

// function showPrice(amount,currency){
//   const cur = currency||'USD'
//   const final = amount.toLocaleString(window.lang, {
//     style: 'currency',
//     currency:cur,
//     minimumFractionDigits: cur==="IRR"?0:2,
//     maximumFractionDigits: cur==="IRR"?0:2,
//   })
//   return final
// }
