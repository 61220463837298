import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import apiBlog from "../../../../utils/apiBlog";
import { CartContext } from "../../../contexts/cartContext";
import AuthorBio from "../blogCarts/authorBio";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import CommentsRating from "../../../comment/commentsRating";
import api from "../../../../utils/api";
import { CircularProgress } from "@mui/material";
import tools from "../../../../utils/tools";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import MyIcons from "../../../golbal/icons/myIcons";
// import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
// import SliderPosts from "../../../golbal/sliders/postsSlider";

export default function PostScreen() {
  const { countryShort } = tools.getItemFromLocalStorage("initSetup", {});

  // const [newPosts, viewedPosts] = useOutletContext().pubData;
  const section = "blog";
  const messageRef = useRef(null);
  const stateData = useLocation().state;
  const data = stateData?.data ?? {};
  const posts = stateData?.posts ?? [];

  const [loading, setLoading] = useState({ spinner: false });

  const [post, setPost] = useState(data);
  const { user, signedUser } = useContext(CartContext);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPostDetails();
  }, [id]);

  const [showMore, setShowMore] = useState(true);

  const fetchPostDetails = async () => {
    // if (id?.length !== 24)
    //   return messageRef.current.showError({
    //     message: "شناسه صحیح نمی باشد،  دوباره وارد این صفحه شوید.",
    //   });
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiBlog.getPostDetails(id);
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setPost(response.data);
    // console.log(response.data);
    setTimeout(() => {
      const elementHeight = document?.querySelector(".content")?.clientHeight;
      if (elementHeight < 1400) {
        return setShowMore(false);
      }
    }, 500);
  };

  const repliesCounter = () => {
    const comments = post?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };

  const likeHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.classList.toggle("bi-heart");
    el.classList.toggle("bi-heart-fill");
    const parentLi = el.parentElement;
    const span = parentLi.querySelector("span");

    const number = el.classList.contains("bi-heart")
      ? Number(parentLi.textContent) - 1
      : Number(parentLi.textContent) + 1;
    span.textContent = number;
    el.setAttribute("disabled", true);
    const response = await apiBlog.likeHandler(e.target.id, user.token);
    setTimeout(() => el.removeAttribute("disabled"), 1000);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات پسند ناموفق!</div>
            <div> {response.error}</div>
          </div>
        ),
      });
    setPost((pre) => ({ ...pre, feedbackInfo: response.data }));
  };
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setLoading((pre) => ({ ...pre, save: true }));
    const response = await api.saveHandler({
      id: post._id,
      token: user.token,
      section,
    });
    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> دوباره وارد شوید.</div>
          </div>
        ),
      });

    signedUser(response.data);

    !user?.saved?.post?.includes(post?._id)
      ? messageRef.current.showSuccess({
          message: "پست با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "پست از لیست ذخیره حذف شد.",
        });
  };
  function navigateHandle(url) {
    window.scroll(0, 0);
    setTimeout(() => navigate(url, { state: post }), 200);
  }
  const url= `https://www.metamarce.com/${window.lang}/blog/post/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`
 
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      headline: post?.title,
      name: post?.title,
      description: post?.coverSummary,
      url,
      image: [post?.img],
      datePublished: post?.createdAt,
      dateModified: post?.moreInfo?.lastUpdate,
      author: [
        {
          "@type": "Person",
          name: post?.author?.authorName,
          url: post?.author?.socialMedia?.[0]?.url,
          image: {
            "@type": "ImageObject",
            "@id": post?.author?.thumbnail,
            url: post?.author?.thumbnail,
            // height: "96",
            // width: "96",
          },
        },
      ],
      isPartOf: {
        "@type": "Blog",
        "@id": "https://www.metamarce.com/blog/filters",
        name: "مجله متامارس",
        publisher: {
          "@type": "Organization",
          "@id": "https://www.metamarce.com",
          name: "Metamarce",
        },
      },
      commentCount: repliesCounter(),
      ...(post?.feedbackInfo?.comment?.comments?.length > 0 && {
        comment: post?.feedbackInfo?.comment?.comments?.map((rev) => ({
          "@type": "Comment",
          "@id": `${window.location.href}#comments2`,
          dateCreated: rev?.date,
          description: rev?.comment,
          author: {
            "@type": "Person",
            name: rev?.userName,
            url: `${window.location.href}#comments2`,
          },
        })),
      }),
      ...(post?.tags?.length > 0 && {
        keywords: post?.tags?.map((tag) => tag),
      }),
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: post?.title,
      description: post?.coverSummary,
      url,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/blog/filters`,
              name: `مجله`,
              description:
                " و نکات کاربردی مهاجرت و زندگی، جاهای دیدنی و خوراکی خوشمزه و خوردنی، تجربیات باحال و آموزنده ...",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": url,
              name: post?.title,
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: `مجله متامارس | ${post?.title}  | ${
      post?.category?.subCat?.[window.lang]
    }`,
    description: post?.coverSummary?.substring(0, 170),
    section: "مجله",
    url,
    canonical: url,
    img: post?.img,
    type: "article",
  };

  return (
    <div className="col-lg-8 entries">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />

      {loading.spinner && <ColoredSpiner />}
      <div className=" mx-0  entry entry3  row align-items-center w-100 ">
        <div className="col w-100 ">
          <nav className="p-md-3 p-2" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/${window.lang}`} className="breadcrumb-item">
                  خانه
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/${window.lang}/blog/filters`}>مجله</Link>
              </li>
              {/* <li className="breadcrumb-item">
                <Link
                  to={`/${window.lang}/demy/tutor/filters`}
                  className="breadcrumb-item"
                >
                  لیست اساتید
                </Link>
              </li> */}
              <li className="breadcrumb-item">
                <span className="breadcrumb-item active">
                  <strong> {post?.title}</strong>
                </span>
              </li>
            </ol>
          </nav>
          <div className="entry-meta d-flex justify-content-end mt-1 icon-stack3">
            <ul>
              <li>
                {/* setting section */}

                {((user?._id === post?.author?._id &&
                  post?.status === "01-draft") ||
                  user?.roles?.some(
                    (role) => role === 1000 || role === 1100
                  )) && (
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-primary button-filter dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      تنظیمات
                      <span className="ps-4 pe-0 me-0">
                        <i className="bi bi-chevron-down me-0"></i>
                      </span>
                    </button>

                    {/* <!-- Modal --> */}
                    <div className="dropdown-menu checkbox-form">
                      <div>
                        <div>
                          <div className="form-check  text-secondary">
                            <div
                              onClick={(e) =>
                                navigateHandle(
                                  `/${window.lang}/dashboard/user/blog/edit/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`
                                )
                              }
                            >
                              ویرایش
                            </div>
                          </div>

                          {post?.status !== "00-deleted" && (
                            <div
                              className="form-check text-danger"
                              onClick={deleteHandle}
                            >
                              پاک کردن همیشگی
                            </div>
                          )}
                        </div>

                        {user?.roles?.some(
                          (role) => role === 1000 || role === 1100
                        ) && (
                          <div>
                            {post?.status !== "02-active" && (
                              <div
                                className="form-check text-secondary"
                                onClick={activeHandle}
                              >
                                انتقال به بخش اصلی
                              </div>
                            )}
                            {post?.status !== "01-draft" && (
                              <div
                                className="form-check  text-secondary"
                                onClick={darftHandle}
                              >
                                انتقال به پیشنهادات
                              </div>
                            )}
                            {post?.status !== "00-suspended" && (
                              <div
                                className="form-check  text-secondary"
                                onClick={suspensionHandle}
                              >
                                عدم نمایش
                              </div>
                            )}
                            {!post?.isPaid?.showAuthor ? (
                              <div
                                className="form-check  text-secondary"
                                onClick={showAuthorHandle}
                              >
                                نمایش بیوگرافی
                              </div>
                            ) : (
                              <div
                                className="form-check  text-secondary"
                                onClick={hideAuthorHandle}
                              >
                                عدم نمایش بیوگرافی
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </li>
              <li className="d-flex align-items-center me-1">
                {/* <span>{post?.feedbackInfo?.likes?.length}</span> */}
                <span>
                  {tools.dynamicNumber({
                    number: post?.feedbackInfo?.likes?.length,
                    lang: window.lang,
                  })}
                </span>
                <button
                  className={
                    post?.feedbackInfo?.likes?.includes(user._id)
                      ? "icon-btn bi bi-heart-fill ms-1"
                      : "icon-btn bi bi-heart ms-1"
                  }
                  title="پسندیدن"
                  id={post?._id}
                  onClick={likeHandler}
                ></button>
              </li>
              <li className="d-flex align-items-center me-1 ">
                <button
                  className="icon-btn"
                  onClick={saveHandler}
                  title="ذخیره"
                >
                  {loading.save ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <i
                      className={
                        user?.saved?.post?.includes(post?._id)
                          ? "bi bi-bookmark-fill"
                          : "bi bi-bookmark"
                      }
                      id={post?._id}
                    ></i>
                  )}
                </button>
              </li>

              <li className="d-flex align-items-center me-1">
                <div
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${post?._id}`}
                  id="shareBtn"
                  data-bs-placement="top"
                  title="همرسانی!"
                >
                  <i className="bi bi-share"></i>
                </div>
              </li>
              <li>
                <ShareSocialMedias
                  url={`${window.location.origin}/${window.lang}/blog/post/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`}
                  title={post?.title}
                  img={post?.img}
                  _id={post?._id}
                  section="post"
                />
              </li>
            </ul>
          </div>
          <div className="text-start my-3">
            <h1 className="p-0 blog-single-title  ">{post?.title}</h1>
            <div className="entry-meta mt-1">
              <ul>
                <li className="d-flex align-items-center me-1">
                  <i className="bi bi-pencil" title="نویسنده"></i>
                  <div
                    onClick={(e) =>
                      navigateHandle(
                        `/${window.lang}/blog/filters?authorId=${post?.author?._id}&title=${post?.author?.authorName}`
                      )
                    }
                    // to={`/${window.lang}/blog/filters?authorId=${post?.author?._id}&title=${post?.author?.authorName}`}
                  >
                    {post?.author?.authorName}
                  </div>
                </li>
                <li className="d-flex align-items-center me-1">
                  <i className="bi bi-calendar-check" title="تاریخ انتشار"></i>
                  {/* <a href="blog-single.html"> */}
                  <time dateTime="2020-01-01" title="تاریخ انتشار">
                    {tools.dynamicDate({
                      date: post?.createdAt,
                      countryShort,
                      lang: window.lang,
                      showYear: true,
                    })}
                  </time>
                  {/* </a> */}
                </li>
                <li className="d-flex align-items-center me-1">
                  <a href="#comments2">
                    <i className="bi bi-chat-dots"></i>
                    {tools.dynamicNumber({
                      number: repliesCounter(),
                      lang: window.lang,
                    })}
                    دیدگاه
                  </a>
                </li>
                <li className="d-flex align-items-center me-1">
                  {/* <a href="#comments2"> */}
                  <i className="bi bi-eye"></i>
                  {/* {post?.feedbackInfo?.views} */}
                  {tools.dynamicNumber({
                    number: post?.feedbackInfo?.views,
                    lang: window.lang,
                  })}
                  بازدید
                  {/* </a> */}
                </li>
                <li className="d-flex align-items-center me-1 icon-stack3 ">
                  <i className="bi bi-stopwatch"></i>
                  {tools.dynamicNumber({
                    number:
                      post?.description &&
                      Math.ceil(post?.description?.length / 60 / 30),
                    lang: window.lang,
                  })}
                  دقیقه
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* <!-- End blog entry --> */}
        <article className="entry entry3">
          <div className="entry-content w-100">
            <div className="mb-3" style={{ position: "relative" }}>
              <img src={post.img} alt={post?.title} loading="lazy" />
              {post.status === "01-draft" && (
                <div className=" user-sug-tag" to={`?status=draft`}>
                  نوشته‌های شما
                  <Tooltip1
                    icon={<MyIcons icon={post?.status} />}
                    message={`این مطلب توسط ${post?.author?.authorName} عزیز گردآوری و همه حقوق و مسئولیت‌ها متعلق به ایشان می باشد. `}
                  />
                </div>
              )}
            </div>

            <div className="blog-post">
              <div
                className="content ql-editor"
                dangerouslySetInnerHTML={{ __html: post?.description }}
              />
            </div>
            {showMore && (
              <div
                className="read-more btn box5 mx-0 text-center ms-auto my-2 small-gray-font"
                onClick={showHandle}
              >
                ادامه مطلب...
              </div>
            )}

            {/* <h4 className=" recom-title mt-1 mb-4 ">
              <a href="blog-single.html">...........</a>
            </h4> */}
          </div>
        </article>
        {/* <!-- End blog Article --> */}

        {/* <!-- tags  --> */}
        <ul className=" my-5 mega-menu-subtitle d-flex flex-wrap ">
          {post?.tags?.map((tag, i) => (
            <li className="m-1 blog-tag cursor" key={i} id={tag}>
              <div
                onClick={(e) =>
                  navigateHandle(`/${window.lang}/blog/filters?tag=${tag}`)
                }
              >
                {tag}
              </div>
            </li>
          ))}
        </ul>

        {post?.isPaid?.showAuthor && <AuthorBio post={post} />}

        <NextPrevRender posts={posts} data={data} />
        <CommentsRating
          id={post?._id}
          value={post}
          setData={setPost}
          collection="Post"
        />
      </div>
      {/* <!-- End blog entries list --> */}

      {/* <LeftCard/> */}

      {/* <!-- End Blog Section --> */}
    </div>
  );

  async function deleteHandle(e) {
    e.preventDefault();
    if (!window.logined(9999)) return;
    if (
      window.confirm(
        "با حذف همیشگی و کم کردن ۱۰ متاپوینت مربوط به این پست موافقم."
      ) !== true
    )
      return;
    console.log(e);
    console.log(post);
    setLoading({ ...loading, spinner: true });
    const response = await apiBlog.deletePost({ token: user.token, id });
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({ message: "پست با موفقیت حذف شد." });
    // setLoading({ ...loading, spinner: true });
    // const response2 = await api.getUser();
    // setLoading({ ...loading, spinner: false });
    // if (response2?.error) return;
    // signedUser(response2.data);

    // signedUser(response.data);
    setTimeout(() => navigate(`/${window.lang}/blog/filters`), 1000);
  }
  async function activeHandle(e) {
    e.preventDefault();
    if (window.confirm("پست موقت مورد تائید و قابل فعالسازی می باشد.") !== true)
      return;
    console.log(e);
    console.log(post);
    setLoading({ ...loading, spinner: true });
    const response = await apiBlog.activatePost({ token: user.token, id:post?._id });
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({ message: "پست با موفقیت فعال شد." });

    setPost((pre) => {
      const post = { ...pre };
      post.status = response.data.status;
      return post;
    });
  }
  async function darftHandle(e) {
    e.preventDefault();
    if (
      window.confirm("با غیر فعالسازی پست و انتقال به پست موقت موافقم.") !==
      true
    )
      return;
    console.log(e);
    console.log(post);
    setLoading({ ...loading, spinner: true });
    const response = await apiBlog.draftedPost({ token: user.token, id:post?._id });
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "پست با موفقیت غیر فعال شد.",
    });
    setPost((pre) => {
      const post = { ...pre };
      post.status = response.data.status;
      return post;
    });

    // async function deleteHandle(e) {
    //   e.preventDefault();
    //   if (
    //     window.confirm(
    //       "با حذف همیشگی و کم کردن 10 متاپوینت مربوط به این پست موافقم."
    //     ) !== true
    //   )
    //     return;
    //   console.log(e);
    //   console.log(post);
    //   setLoading({ ...loading, spinner: true });
    //   const response = await apiBlog.deletePost({ token: user.token, id });
    //   setLoading({ ...loading, spinner: false });
    //   if (response.error)
    //     return messageRef.current.showError({
    //       message: (
    //         <div>
    //
    //           <div>مشکل در انجام عملیات !</div>
    //           <div>{response.error}</div>
    //         </div>
    //       ),
    //     });
    //   messageRef.current.showSuccess({ message: "پست با موفقیت حذف شد." });
    //   signedUser(response.data);
    //   setTimeout(() => navigate(`/${window.lang}/blog/filters`), 1000);
    // }
    // async function activeHandle(e) {
    //   e.preventDefault();
    //   if (window.confirm("پست موقت مورد تائید و قابل فعالسازی می باشد.") !== true)
    //     return;
    //   console.log(e);
    //   console.log(post);
    //   setLoading({ ...loading, spinner: true });
    //   const response = await apiBlog.activatePost({ token: user.token, id });
    //   setLoading({ ...loading, spinner: false });
    //   if (response.error)
    //     return messageRef.current.showError({
    //       message: (
    //         <div>
    //
    //           <div>مشکل در انجام عملیات !</div>
    //           <div>{response.error}</div>
    //         </div>
    //       ),
    //     });
    //   messageRef.current.showSuccess({ message: "پست با موفقیت فعال شد." });
    //   setPost(response.data);
    // }
    // async function darftHandle(e) {
    //   e.preventDefault();
    //   if (
    //     window.confirm("با غیر فعالسازی پست و انتقال به پست موقت موافقم.") !== true
    //   )
    //     return;
    //   console.log(e);
    //   console.log(post);
    //   setLoading({ ...loading, spinner: true });
    //   const response = await apiBlog.draftedPost({ token: user.token, id });
    //   setLoading({ ...loading, spinner: false });
    //   if (response.error)
    //     return messageRef.current.showError({
    //       message: (
    //         <div>
    //
    //           <div>مشکل در انجام عملیات !</div>
    //           <div>{response.error}</div>
    //         </div>
    //       ),
    //     });
    //   messageRef.current.showSuccess({
    //     message: "پست با موفقیت غیر فعال شد.",
    //   });
    //   setPost(response.data);

    //   // setTimeout(()=>navigate(`/${window.lang}/blog/filters`),1000)
    // }
  }
  async function suspensionHandle(e) {
    e.preventDefault();
    if (window.confirm("با تعلیق پست موافقم.") !== true) return;
    console.log(e);
    console.log(post);
    setLoading({ ...loading, spinner: true });
    const response = await apiBlog.suspendingPost({ token: user.token, id:post?._id });
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "تعلیق پست با موفقیت انجام شد.",
    });
    setPost((pre) => {
      const post = { ...pre };
      post.status = response.data.status;
      return post;
    });
  }

  async function showAuthorHandle(e) {
    e.preventDefault();
    if (post.isPaid.showAuthor)
      return messageRef.current.showError({
        message: "بیوگرافی شما فعال و قابل مشاهده می باشد.",
      });
    // if (!user.roles.includes(1000)||user.points < 20000)
    //   return messageRef.current.showError({
    //     message: ` شما ${user?.points}متاپوینت دارید و برای فعالسازی بیوگرافی 20,000 متاپوینت نیازاست که می توانید از روشهای زیر تامین کنید.`,
    //   });
    if (window.confirm("با نمایش بیوگرافی موافقم.") !== true) return;
    console.log(e);
    console.log(post);
    setLoading({ ...loading, spinner: true });
    const response = await apiBlog.featuredAuthor({ token: user.token, id:post?._id });
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "نمایش بیوگرافی نویسنده پست با موفقیت انجام شد.",
    });
    setPost((pre) => {
      const post = { ...pre };
      post.isPaid = response.data.isPaid;
      return post;
    });
  }
  async function hideAuthorHandle(e) {
    e.preventDefault();
    if (window.confirm("با عدم نمایش بیوگرافی نویسنده موافقم.") !== true)
      return;
    console.log(e);
    console.log(post);
    setLoading({ ...loading, spinner: true });
    const response = await apiBlog.hideAuthor({ token: user.token, id:post?._id });
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: " عدم نمایش بیوگرافی نویسنده با موفقیت انجام شد.",
    });
    setPost((pre) => {
      const post = { ...pre };
      post.isPaid = response.data.isPaid;
      return post;
    });
  }

  function NextPrevRender({ posts, data }) {
    const navigate = useNavigate();
    if (!posts) return;
    const currentIndex = posts?.findIndex((post) => post._id === data._id);
    const getPreviousPost = () => {
      const previousIndex =
        currentIndex === 0 ? posts?.length - 1 : currentIndex - 1;
      return posts[previousIndex];
    };

    const getNextPost = () => {
      const nextIndex =
        currentIndex === posts?.length - 1 ? 0 : currentIndex + 1;
      return posts[nextIndex];
    };
    function redirectHandle(post) {
      if (!post?._id) return;
      navigate(
        `/${window.location.pathname
          .split("/")
          .slice(1, 2)
          .join("/")}/blog/post/${tools.slugMaker({title:post?.title,city:post?.moreInfo?.address?.city,state:post?.moreInfo?.address?.state,serial:post?.serial})}`,
        { state: post }
      );
    }

    return (
      <div className="row my-5 g-0 px-2">
        <div
          className="col-md-5 recom-title"
          onClick={(e) => redirectHandle(getPreviousPost())}
        >
          <p className="recom-head p-0 m-0 text-start">
            <span>
              <i className="bi bi-caret-right-fill"></i>
            </span>
            نوشته‌ی قبلی
          </p>
          <h4 className="py-2 my-0 prev-next-post text-start">
            {getPreviousPost()?.title}
          </h4>
        </div>
        <div
          className="col-md-5 recom-title"
          onClick={(e) => redirectHandle(getNextPost())}
        >
          <p className="recom-head p-0 m-0  text-end">
            نوشته‌ی بعدی
            <span>
              <i className="bi bi-caret-left-fill"></i>
            </span>
          </p>
          <h4 className="text-end my-0 py-2 prev-next-post">
            {getNextPost()?.title}
          </h4>
        </div>
      </div>
    );
  }
  function showHandle(e) {
    const target = e.target;
    const el = document.querySelector(".blog-post");
    el.classList.toggle("expanded");
    target.innerHTML = el.classList.contains("expanded")
      ? "کمتر..."
      : "ادامه مطلب...";
    if (!el.classList.contains("expanded"))
      window.scrollTo({ top: 1800, behavior: "smooth" });
  }
}
