import { useContext, useRef, useState } from "react";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpinner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function Faq() {
  const messageRef = useRef(null);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(CartContext);
  const questions = [
    {
      row: 1,
      q: "چگونه می‌توانم یک حساب در متامارس ایجاد کنم؟",
      a: "برای ایجاد یک حساب، بر روی دکمه ورود/ثبت نام کلیک کنید و مراحل را دنبال و اطلاعات ورود خود را تنظیم کنید.",
    },
    {
      row: 2,
      q: "کلمه عبور خود را فراموش کرده‌ام، چکار کنم؟",
      a: "دکمه ورود/ثبت نام در گوشه سمت راست و بالا را بزنید، سپس دکمه فعالسازی/کلمه عبور را فراموش کردم و سپس ایمیل خود را وارد و ادامه دستور العمل را جهت بازیابی دنبال کنید",
    },
    {
      row: 3,
      q: "چگونه می‌توانم نویسنده در وبلاگ شما شوم؟",
      a: " برای تبدیل شدن به نویسنده در مجله متامارس، ابتدا باید حساب کاربری خود را ایجاد کنید. سپس، وارد حساب کاربری شوید وارد بخش پروفایل نویسنده، جزئیات نویسنده‌ای خود را وارد کنید. پس از این مراحل، می‌توانید محتوای خود را به اشتراک بگذارید.",
    },
    {
      row: 4,
      q: "آیا کسب و کار هایی که ثبت رسمی نشده اند میتوانند با متامارس همکاری نمایند ؟",
      a: "بله ، در متامارس تمامی کسب و کارها به ویژه کسب و کارهای  خانگی، تازه و بزرگ جایگاه دارند و می توانند از فرصت برابر دیده شدن استفاده نمایند.",
    },
    {
      row: 5,
      q: "چگونه متامارس از کسب و کارها حمایت می کند  ؟",
      a: "متامارس در کنار کاربران برای حمایت از کسب و کارها فرصتی را فراهم کرده، تا در صورت رضایت از یک کسب و کار حتی کوچک، آنها را طریق متامارس، رایگان به دیگران معرفی کنید. این گزینه از طریق  افزودن کسب و کار/ پیشنهاد کسب و کار در اختیار تمامی کاربران وبسایت قراردارد.",
    },
    {
      row: 6,
      q: "چگونه در متامارس گروه و صفحات در شبکه های اجتماعی را پیدا کنم  ؟",
      a: "بخش متامدیا در متامارس، مربوط به گروه ها و صفحه ها در شبکه های اجتماعی می باشد و از طریق آن میتوانید هر گروه یا صفحه ای را شبکه های اجتماعی بیابید",
    },
    {
      row: 7,
      q: "چگونه صفحه‌ام در شبکه های اجتماعی را معرفی کنم و آیا ثبت نام رایگان است ؟",
      a: "متامدیا رایگان و خودکار است و در دسترس تمامی کاربران و صاحبان صفحات شبکه اجتماعی است، ",
    },
    {
      row: 8,
      q: "آیا میتوانم به بعنوان اینفلوئنسر آمادگی همکاری خود را جهت تبلیغات  یا تبادل اعلام کنم؟",
      a: " بله در متامدیا اینفلوئنسر ها رایگان میتوانند آمادگی خود را جهت دریافت تبلیغات یا تبادل اعلام نمایند. تمایل شما به صورت اتوماتیک تنها به صاحبان کسب و کار و اینفلوئنسرهای دیگر نمایش داده خواهد شد و کاربران عادی سایت دسترسی به این ویژگی ندارند .",
    },
    {
      row: 9,
      q: "چطور مشکل Access denied رو حل کنم؟",
      a: "   یک مرتبه از حساب کاربری خارج و دوباره وارد شوید.",
    },
  ];
  const qBus = [
    {
      row: 1,
      q: "آیا هزینه ای برای ثبت کسب‌وکار من در دایرکتوری وجود دارد؟",
      a: "ثبت و ادعای مالکیت و همچنین ایجاد صفحه شخصی برای هر کسب و کار در متامارس کاملا رایگان است. با این حال، ممکن است فرصت‌های تبلیغاتی دیگری را با هزینه‌ ارائه دهیم.",
    },
    {
      row: 2,
      q: "مزایای افزودن کسب و کار در متامارس نسبت به سایر بانک های اطلاعاتی مشاغل چیست؟",
      a: "در متامارس کسب و کارها می توانند به صورت رایگان و خودکار کسب و کار خود را ثبت و فعالسازی نمایند و از طریق پنل کاربری کسب و کار خود را مدیریت کنند.",
    },
    {
      row: 3,
      q: "آیا کسب و کار هایی که ثبت رسمی نشده اند میتوانند با متامارس همکاری نمایند ؟",
      a: "بله ، در متامارس تمامی کسب و کارها به ویژه کسب و کارهای  خانگی، تازه و بزرگ جایگاه دارند و می توانند از فرصت برابر دیده شدن استفاده نمایند.",
    },
    {
      row: "4",
      q: "چطور میتوانم قرارملاقات های کسب و کارم رو سروسامان بدم؟",
      a: "متامارس در بخش  کسب و کارها به تازگی ویژگی تنظیم قرار ملاقات ها را (رایگان) در اختیار صاحبان کسب و کار قرارداده است که از این طریق کسب و کارها میتوانند قرار ملاقاتهای خود و همچنین سایر کارمندان خود را مدیریت نمایند.",
    },
    {
      row: "5",
      q: "من یک آرایشگاه دارم با چندین آرایشگر، من هم میتوانم برای هریک از آنها به صورت جداگانه قرار ملاقات تنظیم کنم؟",
      a: "صاحبین کسب و کار به صورت نامحدود می توانند برای کارکنان خود برنامه قرار ملاقات تنظیم و در اختیار کارمندان و مشتریان قرار دهند تا مشتریان از داخل جدول زمانبندی اقدام به رزرو قرار ملاقات باهریک از کارمندان شما را نمایند.",
    },
  ];
  const structuredData = [
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "درباره متامارس",
      description:
        "یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",

      url: "https://www.metamarce.com/fa/prompt/faq",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/faq`,
              name: "سوالات متداول",
              description:
                " پاسخ به سوالات پرتکرار در متامارس",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "متامارس | سوالا متداول | FAQ",
    description:
      "پرسش های پرتکرار | اینجا میتونین دنبال سوالاتتون بگردین و سوالاتتون رو بپرسین.",
    section: "سوالات پرتکرار",
    url: `https://www.metamarce.com/${window.lang}/prompt/faq`,
    canonical: `https://www.metamarce.com/${window.lang}/prompt/faq`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {loading && <ColoredSpinner />}
      <h1 className="titr1 font-40 my-5">ممکنه پرسش شما هم باشه! (FAQ) </h1>
      <div className="row">
        <div className="col-md-6  d-flex flex-column align-items-center p-md-5 p-3 pb-1  ">
          <h2 className="titr7 my-3">پرسش‌های کاربران</h2>

          <div className="accordion w-100" id="accordionPanelsStayOpenExample">
            {questions.map((question) => (
              <div key={question.row} className="accordion-item2 ">
                <h2 className="accordion-header" id={`question${question.row}`}>
                  <button
                    className="accordion-button accordion-button2 collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen-collapse${question.row}`}
                    aria-expanded="true"
                    aria-controls={`panelsStayOpen-collapse${question.row}`}
                  >
                    + {question.q}
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-collapse${question.row}`}
                  className="accordion-collapse collapse "
                  aria-labelledby={`question${question.row}`}
                >
                  <div className="accordion-body">
                    <strong>{question.a}</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Business */}
        <div className="col-md-6  d-flex flex-column align-items-center p-md-5 p-3 pb-1  ">
          <h2 className="titr7 my-3">پرسش‌های صاحبان کسب و کار</h2>

          <div className="accordion w-100" id="accordionPanelsStayOpenExample2">
            {qBus.map((question) => (
              <div key={question.row} className="accordion-item2 ">
                <h2 className="accordion-header" id={`qBus${question.row}`}>
                  <button
                    className="accordion-button accordion-button2 collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#open-col-qBus${question.row}`}
                    aria-expanded="true"
                    aria-controls={`open-col-qBus${question.row}`}
                  >
                    + {question.q}
                  </button>
                </h2>
                <div
                  id={`open-col-qBus${question.row}`}
                  className="accordion-collapse collapse "
                  aria-labelledby={`qBus${question.row}`}
                >
                  <div className="accordion-body">
                    <strong>{question.a}</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <!-- Ask us!--> */}
      <div className="banner-listing-2 w-100  mt-0">
        <div className="row ads-all mx-auto">
          <div className="col-md-6  d-flex flex-column align-items-center p-5  pt-1 img-banner-2 arrow-box ">
            <form
              action=""
              className="w-100 my-5 "
              name="homeForm"
              autoComplete="true"
            >
              <div className="my-5">
                <label htmlFor="f25" className="h4 form-label my-2 titr7">
                  پرسشتون رو نتونستید پیدا کنید؟ اینجا بپرسید!
                </label>
                <textarea
                  id="f25"
                  className="form-control shadow-none my-5"
                  rows="7"
                  placeholder=""
                  onChange={(e) => setQuestion(e.target.value)}
                ></textarea>
              </div>

              <button
                type="button"
                className="btn primary-btn checkout-btn cart-footer mx-auto w-75 mt-3"
                onClick={sendRequestHandle}
              >
                <i className="bi bi-pencil-square p-1"></i>
                بفرست!
              </button>
            </form>
          </div>

          <div className="col-md-6 d-flex flex-column align-items-center  px-md-5 py-0 ">
            <img
              src="/img/Images/questions-animate.svg"
              alt="faq"
              className="faq-img mx-md-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
  async function sendRequestHandle(e) {
    if (!window.logined(9999)) return;
    if (question.length < 20)
      return messageRef.current.showError({
        message: " توضیحات بیشتری بنویسید.",
      });
    if (question.length > 1000)
      return messageRef.current.showError({
        message: " لطفا توضیحات را به صورت مختصر بنویسید.",
      });

    e.target.setAttribute("disabled", true);
    setLoading(true);
    const response = await api.askq({
      token: user.token,
      data: question,
      lang: window.lang,
    });
    setLoading(false);
    setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "خطا در انجام عملیات : " + response.error,
      });
    messageRef.current.showSuccess({
      message: "پرسش شما با موفقیت فرستاده شد. ",
    });
    document.querySelector("textarea").value = "";
    setQuestion("");
  }
}
