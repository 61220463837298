import "./user.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../../../contexts/cartContext";
import ToastNote from "../../../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import { CheckCircle, Error, Publish } from "@mui/icons-material";
// import Resizer from "react-image-file-resizer";
import AddSocialMedia from "../../../../../persian/addSocialMedia";
import { CircularProgress } from "@mui/material";
import Tooltip1 from "../../../../../golbal/tooltip/tooltip";
import FormikErrRender from "../../../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../../../persian/common/alerts/addErrClass";
import { useNavigate } from "react-router-dom";
import apiUsers from "../../../../../../utils/apiUsers";
import tools from "../../../../../../utils/tools";
import api from "../../../../../../utils/api";
import MetaDecorator from "../../../../../../utils/metaDecorator";
import FormikListErr from "../../../../../persian/common/alerts/formikListErr";

export default function AuthorInfo({ user2 }) {
  // console.log(user2);
  // const effectRan = useRef(false);
  const navigate = useNavigate();
  const messageRef = useRef(null);
  const { user, signedUser } = useContext(CartContext);
  // const [updatedUser, setUpdatedUser] = useState(user2?.authorInfo || {});
  const [loading, setLoading] = useState({ spinner: false });
  const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  const photoHandle = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " از فایل با فرمت عکس استفاده کنید.",
      });
    console.log("photoHandle auth", originalPhoto);
    setResisedPhotoAuth(
      await tools.resizeFile({
        file: e.target.files[0],
        width: 500,
        height: 500,
      })
    );
    setOriginalPhoto(e.target.files[0]);
  };

  const uploadFileDatabase = async (e, file) => {
    e.preventDefault();
    if (!file) return;
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    console.log("formData", formData);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/profile/author",
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });

    console.log(response.data);
    formik.setValues((pre) => ({ ...pre, authorThumbnail: response.data }));
    setResisedPhotoAuth(null);
    messageRef.current.showSuccess({
      message: "عکس با موفقیت بارگذاری شد، جهت ثبت کلید ویرایش را فشار دهید. ",
    });
  };

  useEffect(() => {
    // if(document.querySelector('#termsContent'))document.querySelector('#termsContent').style.display = user2?.authorInfo?.terms ? "none":"block"
    if (!user2?.authorInfo) fetch();
  }, []);

  async function fetch() {
    console.log("fetch");
    setLoading({ ...loading, spinner: true });
    const response = await apiUsers.getAuthorInfo({
      id: user._id,
      token: user.token,
    });
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });

    formik.setValues({
      roles: response.data?.roles,
      terms: response.data?.terms,
      authorName: response.data?.authorName,
      authorThumbnail: response.data?.authorThumbnail,
      biography: response.data?.biography,
      metamarcePage: response.data?.metamarcePage,
      socialMedia: response.data?.socialMedia,
      _id: user?._id,
    });

    if (document.querySelector("#termsContent"))
      document.querySelector("#termsContent").style.display = response.data
        ?.terms
        ? "none"
        : "block";
  }
  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

  const formik = useFormik({
    initialValues: {
      terms: user2?.authorInfo?.terms || false,
      authorThumbnail: user2?.authorInfo?.authorThumbnail || "",
      authorName: user2?.authorInfo?.authorName || "",
      biography: user2?.authorInfo?.biography || "",
      metamarcePage: user2?.authorInfo?.metamarcePage || "",
      socialMedia: user2?.authorInfo?.socialMedia || [
        { platform: "", url: "" },
      ],
      _id: user2?._id || "",
    },
    onSubmit: async (values) => {
      console.log(values._id);
      console.log(user._id);

      if (!formik.values.terms)
        return messageRef.current.showWarning({
          message:
            " قوانین نویسندگی را مطالعه و پس از تائید دوباره اقدام کنید.",
        });
      setLoading({ ...loading, spinner: true, submitBtn: true });
      const response = await apiUsers.setAuthorInfo({
        id: values._id,
        token: user.token,
        authorInfo: values,
      });

      setLoading({ ...loading, spinner: false, submitBtn: false });
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، بخش نویسندگی با موفقیت به روز رسانی شد.",
      });
      // formik.resetForm()

      signedUser(response.data);
      setTimeout(
        () =>
          navigate(`/${window.lang}/dashboard/user/blog/register`, {
            replace: true,
          }),
        1000
      );
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        " قوانین نویسندگی را مطالعه و دکمه قبول را فشار دهید."
      ),
      authorName: Yup.string().max(
        30,
        "عنوان تولید کننده محتوا بایستی کمتر از 30 حرف باشد"
      ),
      biography: Yup.string().max(250, "درباره من بایستی کمتر از 250 حرف باشد"),
      socialMedia: Yup.array().of(
        Yup.object().shape({
          platform: Yup.string("پلتفرم نمی تواند خالی باشد."),
          url: Yup.string()
            .when("platform", (platform, schema) => {
              return platform
                ? schema.required("لطفاً آدرس URL را وارد نمایید.")
                : schema;
            })
            .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
              if (!value) return true; // Allow empty value
              // Regular expression to validate URL
              return urlRegex.test(value);
            }),
        })
      ),
      metamarcePage: Yup.string().matches(
        /^https?:\/\/(?:www\.)?metamarce\.com(?:\/.*)?$/,
        " آدرس را در دامنه www.metamarce.com انتخاب کنید."
      ),
    }),
  });
  const metaTagsData = {
    title: " متامارس | داشبورد | پروفایل نویسندگی ",
    description:
      "پروفایل نویسندگی | به روز رسانی اطلاعات نویسندگی | به روز رسانی عکس خود به عنوان نویسنده ",
    section: "َAuthor Info",
    url: `${window.location.origin}/${window.lang}/dashboard/user/profile/author`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };
  // console.log("formik", formik.values);

  return (
    <div className="user mx-md-5 mt-3">
      <MetaDecorator data={{ ...metaTagsData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <div className="row ">
        <div className="col">
          <p className="divider2 mt-2 ">
             
            <svg
              className="text-dark-550 dark:text-white ml-2"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
              <circle
                cx="35"
                cy="16"
                r="8"
                fill="currentColor"
                fillOpacity="0.4"
              ></circle>
              <circle
                cx="20"
                cy="8"
                r="5"
                fill="currentColor"
                fillOpacity="0.7"
              ></circle>
            </svg>
            <span className="p-0 m-z ">
              پروفایل من به عنوان نویسنده در متامارس
            </span>
          </p>
        </div>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="row userShow"
        name="authorNameForm"
        autoComplete="true"
      >
        {/* Right Section */}
        <div className="col-md-6 px-3">
          <div className="userUpdateItem mb-5">
            <label htmlFor="authorName">نام به عنوان تولید کننده محتوا</label>
            <input
              id="authorName"
              {...formik.getFieldProps("authorName")}
              className={`userUpdateInput form-control box2  ${addErrClass({
                formik,
                field: "authorName",
              })}`}
              type="text"
              autoComplete="off"
              placeholder={formik.values.authorName}
            />
            <FormikErrRender formik={formik} field="authorName" />
          </div>
          <div className="userUpdateItem mb-5">
            <label className=" ps-3" htmlFor="biography">
              درباره من
            </label>
            <textarea
              id="biography"
              {...formik.getFieldProps("biography")}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                { formik, field: "biography" }
              )}`}
              type="text"
              rows={10}
              placeholder={formik.values?.biography}
            />
            <FormikErrRender formik={formik} field="biography" />
          </div>
          <div className=" userUpdateItem mb-5">
            <label className=" ps-3" htmlFor="metamarcePage">
              لینک معرفی صفحه اصلی در متامارس
            </label>
            <textarea
              id="metamarcePage"
              {...formik.getFieldProps("metamarcePage")}
              className={`userUpdateInput form-control userUpdateInputTextarea ${addErrClass(
                { formik, field: "metamarcePage" }
              )}`}
              type="text"
              rows={2}
            />
            <FormikErrRender formik={formik} field="metamarcePage" />
          </div>
          <div className=" userUpdateItem">
            لینک شبکه‌های اجتماعی
            <AddSocialMedia formik={formik} />
          </div>
        </div>

        {/* Left Section */}

        <div className="userUpdateItem col-md-6 px-3">
          <div className="userUpdateUpload d-flex flex-column justify-content-center">
            <img
              src={
                (resisedPhotoAuth !== null && resisedPhotoAuth) ||
                formik.values?.authorThumbnail
              }
              alt={formik.values.authorName}
              className="userShowImg-profile"
              loading="lazy"
            />

            <label className="my-2" htmlFor="fileAuth">
              <Publish className="userUpdateIcon" />
              تغییر عکس
            </label>
            <input
              type="file"
              id="fileAuth"
              onChange={photoHandle}
              style={{ display: "none" }}
            />
            <span
              className="userUpdateButton edit-btn"
              style={{ display: resisedPhotoAuth ? "block" : "none" }}
              onClick={(e) => uploadFileDatabase(e, originalPhoto)}
            >
              بارگذاری
            </span>
          </div>

          <input
            style={{ display: "none" }}
            className="form-check-input"
            id="termsConditions"
            type="checkbox"
            onChange={(e) =>
              (document.querySelector("#termsContent").style.display = e.target
                .checked
                ? "block"
                : "none")
            }
          />
          <label
            className=" terms-con ps-3 mb-2 mt-5"
            htmlFor="termsConditions"
          >
            قوانین متامارس برای نویسنده‌های بلاگ
          </label>

          <div
            id="termsContent"
            className="userUpdateInput form-control userUpdateInputTextarea"
            dir="rtl"
          >
            قوانین و شرایط خدمات برای ایجاد کنندگان محتوا
            <br />
            1. واجدین شرایط و ثبت‌نام
            <br />
            برای تبدیل شدن به یک ایجاد کننده محتوا در متامارس، باید شرایط زیر را
            احراز کنید: باید حداقل 18 سال سن داشته باشید یا به سن مورد نیاز مشخص
            شده توسط مقامات محلی شما برسید. باید در طول فرآیند ثبت‌نام اطلاعات
            مورد درخواست را دقیق و کامل ارائه دهید. باید با این قوانین موافقت
            کنید و از آن پیروی کنید.
            <br />
            2. رهنمودهای محتوا
            <br />
            به عنوان یک ایجاد کننده محتوا، می‌بایست محتوایی ایجاد کنید که با
            رهنمودهای زیر هماهنگ باشد: محتوای شما نباید در تضاد با هیچ قانون
            محلی، ایالتی، ملی یا بین‌المللی باشد. شما مسئولیت دارید که محتوای
            مرتبط با مواد جنسی صریح، ترویج سخنان نفرت‌آمیز، تبعیض یا آزار،
            خشونت، خودآسیبی یا فعالیت‌های غیرقانونی نباشد. به حقوق مالکیت معنوی
            دیگران احترام بگذارید و به آنها تجاوز نکنید. هنگام استفاده از محتوای
            یا تصاویر ساخته‌شده توسط دیگران، همیشه مجوزهای مناسب را تهیه کنید و
            ارجاع‌دهی صحیح را فراهم آورید. اگر ارجاع‌ها یا منابعی در محتوای خود
            استفاده می‌کنید، اطمینان حاصل کنید که آنها دقیق و منبع اصلی را تبلیغ
            نکنند. از اشتراک دادن اطلاعات شخصی دیگران بدون موافقت آنان پرهیز
            کنید.
            <br />
            3. حقوق ناشر
            <br />
            با فرستادن محتوای خود در وبلاگ متامارس، یک مجوز غیر انحصاری، بدون حق
            تقلیدی، به ما اعطا می‌کنید تا محتوای شما را در مجله الکترونیکی
            متامارس منتشر کنیم. شما حق مالکیت محتوای خود را حفظ کرده و می‌توانید
            آن را در مکان‌های دیگر منتشر کنید.
            <br />
            4. رفتار حرفه‌ای
            <br />
            به عنوان یک ایجاد کننده محتوا وبلاگ متامارس باید:
            <br />
            در تعاملات خود با کاربران و دیگر ایجاد کنندگان محتوا رفتار حرفه‌ای و
            احترام‌آمیز را حفظ کنید. در اسپم‌گذاری، ترولینگ یا رفتارهای مخل به
            ترتیب و انضباط خوش‌نامی نپیش‌برین. در مورد هویت خود و محتوایی که
            ایجاد می‌کنید، صادق و شفاف باشید.
            <br />
            5. گزارش تخلفات
            <br />
            اگر به محتوایی یا ایجاد کنندگان محتوایی برخورد کنید که با این قوانین
            در تضاد باشند، لطفاً آن را به تیم نظارت ما از طریق متاچت و یا در زیر
            پست گزارش گزارش دهید.
            <br />
            6. اجرای قوانین
            <br />
            تخلف از این قوانین ممکن است منجر به عواقبی از جمله اخطار، حذف محتوا،
            تعلیق موقت یا فسخ دائمی حساب ایجاد کننده محتوا شود. شدت اجرای قوانین
            بستگی به نوع و فراوانی تخلف دارد.
            <br />
            7. حریم خصوصی و استفاده از داده‌ها
            <br />
            حریم خصوصی شما برای ما مهم است. لطفاً
            <a dir="rtl" href="/prompt/privacy" target="_blank">
              (حریم خصوصی)
            </a>
            ما را بررسی کنید تا درک کنید که چگونه ما داده‌های شما را جمع‌آوری و
            حفاظت می‌کنیم.
            <br />
            <br />
            8. اعلامیه قانونی
            <br />
            شما به تنهایی مسئول محتوایی هستید که در پلتفرم ما ایجاد و می‌فرستید
            . ما محتوای شما را تأیید یا تضمین نمی‌کنیم. ما مسئول اعمال یا محتوای
            فرستاده شده در پلتفرم ما نیستیم.
            <br />
            9. تغییرات در قوانین
            <br />
            ممکن است این قوانین و شرایط خدمات به صورت دوره‌ای به‌روزرسانی شوند.
            ایجاد کنندگان محتوا از هر تغییری مطلع می‌شوند و وظیفه شما است که
            نسخه جدید را بررسی کنید و از آن پیروی کنید.
            <br />
            با تبدیل شدن به یک ایجاد کننده محتوا در پلتفرم ما، شما موافقت
            می‌کنید که با این قوانین و شرایط خدمات پایبندی به عمل آورید. عدم
            انجام این کار ممکن است منجر به بستن حساب ایجاد کننده محتوا شود یا
            تدابیر دیگری اعمال شود.
            <br />
            اگر سوال یا نگرانی‌ای در مورد این قوانین و شرایط دارید، لطفاً با ما
            تماس بگیرید .
            <br />
          </div>
          <label
            htmlFor="termsBtn"
            className={`required my-3 mb-5 ${addErrClass({
              formik,
              field: "terms",
            })} `}
          >
            (قوانین) را مطالعه کردم و می‌پذیرم
            <input
              id="termsBtn"
              type="checkbox"
              className="form-check-input mx-2"
              onChange={(e) => {
                if (formik.values.terms) return;
                formik.setValues((pre) => ({ ...pre, terms: true }));
              }}
              checked={formik.values.terms}
            />
            <FormikErrRender formik={formik} field="terms" />
          </label>
        </div>
        <FormikListErr formik={formik} />
        <SubmitBtn formik={formik} text="بفرست!" />
      </form>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values?.roles?.includes(1105)) textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2 "
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
}
