import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import apiStore from "../../../../../../../utils/apiStore";
import MyIcons from "../../../../../../golbal/icons/myIcons";
import ToastNote from "../../../../../../golbal/notifications/toastNote";
import renderData from "../../../../../../../utils/renderData";
import SearchBarUser from "./searchBarUser";
import ColoredSpinner from "../../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import tools from "../../../../../../../utils/tools";
import { CartContext } from "../../../../../../contexts/cartContext";
import MetaDecorator from "../../../../../../../utils/metaDecorator";

export default function AffiliateList({ access, section }) {
  const { user } = useContext(CartContext);
  const [countryShort, stateShort] = window.location.pathname
    .toLowerCase()
    .split("/")
    .slice(2, 4);

  const navigate = useNavigate();
  const messageRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [user2, setUser2] = useState({ _id: user._id });

  const deleteHandle = async (id) => {
    console.log(id);
    if (!id)
      return messageRef.current.showError({ message: "آیدی فراهم نشده است." });
    if (window.confirm("Are you sure to delete this transa") !== true) return;
    const response = await apiStore.deleteRow({
      token: user.token,
      id,
      userId: user._id,
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({ message: "سفارش با موفقیت حذف شد." });
    setData((pre) => {
      return pre.filter((row) => row._id !== id);
    });
  };
  function showPageHandle({ props }) {
    let text = "";
    if (window.lang !== props.lang.toLowerCase())
      text += `شما در حال انتقال به متامارس با زبان ${window.capitalize(
        props.lang
      )} میباشد . \n`;
    if (countryShort.toLowerCase() !== props.address.countryShort.toLowerCase())
      text += `شما در حال انتقال به کشور ${window.capitalize(
        props.address.country
      )} میباشد . \n`;
    // if (stateShort.toLowerCase() !== props.address.stateShort.toLowerCase())
    //   text += `شما در حال انتقال به استان ${window.capitalize(
    //     props.address.state
    //   )} میباشد . \n`;

    if (!text.length)
      return navigate(`/${window.lang}/store/order/${props._id}`);

    if (window.confirm(text) != true) return;
    console.log("Passed");

    navigate(`/${window.lang}/store/order/${props._id}`);
  }

  useEffect(() => {
    fetchData();
  }, [user2._id, window.location.pathname]);
  async function fetchData() {
    setLoading(true);
    const response = await apiStore.fetchAffiliateTrans({
      token: user.token,
      id: user2._id,
      access,
      section,
      affiliateCode: user?.affiliateInfo?.code,
    });
    setLoading(false);
    console.log(response);
    if (response.error) return alert(response.error);

    setData(response.data);
  }

  function showPrice(amount, currency) {
    const cur = currency || "USD";
    const final = (amount || 0).toLocaleString(window.lang, {
      style: "currency",
      currency: cur,
      minimumFractionDigits: cur === "IRR" ? 0 : 2,
      maximumFractionDigits: cur === "IRR" ? 0 : 2,
    });
    return final;
  }

  const columns = [
    //   { field: '_id', headerName: 'ID', width: 70 },

    {
      field: "createdAt",
      headerName: "تاریخ ثبت",
      width: 100,
      renderCell: (params) => (
        <div title={tools.toSolar(params.row?.updatedAt)}>
          {tools.toGreg(params.row?.updatedAt)}
        </div>
      ),
    },
    {
      field: "affiliateInfo.affiliatedby",
      headerName: "کد معرفی",
      width: 150,
      renderCell: (params) => (
        <strong>{params.row.affiliateInfo.affiliatedby}</strong>
      ),
    },
    // {
    //   field: "marketerInfo.refPercent",
    //   headerName: "مارجین بازاریابی",
    //   width: 110,
    //   renderCell: (params) => (
    //     <strong title="">{`% ${
    //       params.row.marketerInfo.refPercent * 100
    //     }`}</strong>
    //   ),
    // },
    // {
    //   field: "marketerInfo.discPercent",
    //   headerName: "تخفیف کاربر",
    //   width: 110,
    //   renderCell: (params) => (
    //     <strong title="">{`% ${
    //       params.row.marketerInfo.discPercent * 100
    //     }`}</strong>
    //   ),
    // },
    {
      field: "isPaid",
      headerName: "تکمیل سفارش",
      width: 100,
      renderCell: (params) => <div>{params.row.isPaid ? "بله" : "خیر"}</div>,
    },
    // {
    //   field: "totalPrice1",
    //   headerName: "مبلغ پرداختی",
    //   width: 100,
    //   renderCell: (params) => <div>{showPrice(
    //     Number(params.row?.paymentResult[0]?.amount?.value),
    //     params.row?.paymentResult?.[0]?.amount?.currency_code
    //   )}</div>,
    // },
    {
      field: "netSales",
      headerName: "فروش پس از تخفیفات",
      width: 100,
      renderCell: (params) => (
        <div>
          {/* {showPrice(
            Number(params.row?.paymentResult?.[0]?.amount?.value) > 0
              ? params.row.itemsPrice - params.row.discAmount
              : 0,
            params?.row?.currency
          )} */}
          {/* {tools.showPrice(params.row?.paymentResult?.[0]?.amount?.value,params.row?.paymentResult?.[0]?.amount.currency_code)} */}
          {tools.showPrice(
            (params.row.itemsPrice - params.row.discAmount) / params.row.exRate,
            "CAD"
          )}
        </div>
      ),
    },
    {
      field: "marketerMargin",
      headerName: "کارمز بازاریابی",
      width: 100,
      renderCell: (params) => (
        <div>
          {/* {showPrice(
            Number(params.row?.paymentResult?.[0]?.amount?.value) > 0
              ? (params.row.itemsPrice - params.row.discAmount) *
                  .05
              : 0,
              params.row?.paymentResult?.[0]?.amount.currency_code
          )} */}
          {/* {tools.showPrice(params.row?.paymentResult?.[0]?.amount?.value*.05,params.row?.paymentResult?.[0]?.amount.currency_code)} */}
          {tools.showPrice(
            ((params.row.itemsPrice - params.row.discAmount) /
              params.row.exRate) *
              0.05,
            "CAD"
          )}
        </div>
      ),
    },
    {
      field: "address.country",
      headerName: "کشور",
      width: 150,
      renderCell: (params) => (
        <div dir="ltr">{`${window.capitalize(
          params.row?.address?.state
        )}, ${window.capitalize(params.row?.address?.country)}`}</div>
      ),
    },
    {
      field: "serviceItems",
      headerName: "نام امکانات",
      width: 200,
      renderCell: (params) => {
        return params.row.serviceItems.map((serv, i) => (
          <span key={i}>
            <span title={serv.title}>
              {
                renderData.plansName.find((plan) => plan.name === serv?.type)?.[
                  window.lang
                ]
              }
            </span>
            -
          </span>
        ));
      },
    },
    // {
    //   field: "totalPrice",
    //   headerName: "مبلغ صورتحساب",
    //   width: 150,
    //   renderCell: (params) => (
    //     <div
    //       title={`مبلغ پرداختی  ${showPrice(
    //         Number(params.row?.paymentResult[0]?.amount?.value),
    //         params.row?.paymentResult[0]?.amount?.currency_code
    //       )}`}
    //     >
    //       {showPrice(params.row?.totalPrice, params.row?.currency)}
    //     </div>
    //   ),
    // },
    access === "admin" && {
      field: "invNumber",
      headerName: "شماره صورتحساب",
      width: 150,
      // renderCell:params=>  <div title={params.row?.paymentResult[0]?.amount?.value}>{params.row?.paymentResult[0]?.amount?.currency_code+params.row?.paymentResult[0]?.amount?.value}</div>
    },
    access === "admin" && {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <div
              title="نمایش"
              className="widgetSmButton"
              onClick={(e) => showPageHandle({ props: params.row })}
            >
              <MyIcons icon="display" />
            </div>
            {!params.row.isPaid && (
              <div
                title="پاک کردن"
                onClick={() => deleteHandle(params.row._id)}
              >
                <MyIcons icon="delete2" />
              </div>
            )}
          </div>
        );
      },
    },
  ];
  // console.log('data',data);
  const metaTagsData = {
    title: "متامارس | داشبورد | گزارش عملکرد بازاریابی ",
    description: "مشاهده گزارش عملکرد بازاریابان متامارس ",
    section: "oreders list",
    url: `https://www.metamarce.com/${window.lang}/dashboard/user/trans/points`,
    canonical: `https://www.metamarce.com/${window.lang}/dashboard/user/trans/points`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };
  return (
    <div className="userList" style={{ height: "80vh", width: "100%" }}>
      <MetaDecorator data={{ ...metaTagsData }} />
      <ToastNote messageRef={messageRef} />
      {loading && <ColoredSpinner />}
      {!data.length ? (
        <div>
          <div>
            داده ای پیدا نشد. پس از اطمینان از وجود داده، دوباره تلاش کنید.
          </div>
          {/* <Link
            to={`/${window.lang}/dashboard/user/profile/marketer`}
            className="btn btn-primary share-cover-btn mx-2"
          >
            برو به پروفایل بازاریابی
          </Link> */}
        </div>
      ) : (
        <DataGrid
          getRowId={(row) => row._id}
          rows={data}
          components={{
            Toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          columns={columns}
          pageSize={[15]}
          rowsPerPageOptions={[1]}
          checkboxSelection
        />
      )}
      {access === "admin" && (
        <SearchBarUser setUser2={setUser2} user2={user2} />
      )}
    </div>
  );
}
