
import "./main.css";
import "./styleListfinal.css";
import LandingPage from "./components/screens/landingPage/landingPage";
import { Routes, Route } from "react-router-dom";
// import data from "./utils/LanguagesCountries";
// import StateOutlet from "./router/outlets/langFaOut";
// import iso2 from "./utils/iso2.json"
import NotFound from "./components/persian/common/alerts/404";
import FaRoutes from "./router/FaRoutes";
import ComingSoon from "./components/persian/common/alerts/comingSoon";
// import CountryRoutes from "./router/countryRoutes";
import { useEffect } from "react";
import countryStates from "./utils/countryStates";
import api from "./utils/api";
import EnRoutes from "./router/EnRoutes";
import LangFaOut from "./router/outlets/langFaOut";
import LangEnOut from "./router/outlets/langEnOut";

function App() {
  // const navigate = useNavigate();  
  const lang = window.location.pathname.split("/")[1];
  // const pathname = window.location.pathname.toLowerCase().split("/").slice(1, 4);
  // const languagesSet = new Set(data.languages);  
  // const matchedLang = languagesSet.has(pathname[0]) ? pathname[0] : null;
  // const countIndex = iso2.findIndex(count=> count.iso2.toLowerCase() === pathname[1])
  // const matchedCountry =countIndex!==-1? iso2[countIndex] : null;
  // const matchedState=matchedCountry?.states.find(state=> state.toLowerCase()===pathname[2]) 
useEffect(()=>{ 
  affiliateSet()
  // getGeoInfo()
  // setInitSetting()
  // updateBootstrapLink()
  initAppSetup();
},[lang])

async function initAppSetup() {
  const item = JSON.parse(localStorage.getItem('initSetup'));
  
  if (!item || item === 'undefined') {
    await fetchGeoInfo(); // If no setup, fetch Geo info
  } else {
    applyInitSetting(item); // If setup exists, apply it
  }
}

async function fetchGeoInfo() {
  try {
    const response = await api.getGeoInfo();
    
    if (response.error) {
      console.error(response.error);
      return;
    }

    const currentCountry = countryStates.find(co => co.countryShort === response?.country);
    const currentState = currentCountry?.states.find(st => st.state === response?.region);

    const [lat, lng] = response.loc.split(",");

    const initSetup = {
      lang: lang || "fa",
      country: currentCountry?.country?.toLowerCase() || "ca",
      countryShort: response?.country?.toLowerCase() || "canada",
      city: response?.city || 'montreal',
      state: {
        state: response?.region?.toLowerCase() || 'quebec',
        stateShort: currentState?.stateShort || "qc",
        lat: lat || "52.93991590",
        lng: lng || "-73.54913610",
      },
      firstRender: false,
    };

    localStorage.setItem("initSetup", JSON.stringify(initSetup));
    console.log('Geo setup applied:', initSetup);
  } catch (error) {
    console.error('Error fetching geo info:', error);
  }
}

function applyInitSetting(item) {
  const initSetup = {
    lang: lang || "fa",
    country: item?.country?.toLowerCase() || "ca",
    countryShort: item?.countryShort?.toLowerCase() || "canada",
    city: item?.city || 'montreal',
    state: item?.state || {
      state: 'quebec',
      stateShort: "qc",
      lat: "52.93991590",
      lng: "-73.54913610",
    },
    firstRender: false,
  };

  localStorage.setItem("initSetup", JSON.stringify(initSetup));
  console.log('Local setup applied:', initSetup);
}

// const updateBootstrapLink = () => {
//   const existingLink = document.querySelector('link[rel="stylesheet"][href*="bootstrap"]');
//   const html = document.querySelector('html');
//   console.log('html',html);
  
//   // If no existing Bootstrap link, add a new one
//   if (!existingLink) return;
  
//   // Update the href attribute based on language (RTL or LTR)
//   if (lang === 'en') {
//       existingLink.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css";
//     existingLink.integrity = "sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH";
//     // existingLink.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.rtl.min.css";
//     // existingLink.integrity = "sha384-DOXMLfHhQkvFFp+rWTZwVlPVqdIhpDVYT9csOnHSgWQWPX0v5MCGtjCJbY6ERspU";
//   } 
//   // else {
    
//   //   existingLink.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css";
//   //   existingLink.integrity = "sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH";
//   // }
// };

// async function setInitSetting(){
//   const item = localStorage.getItem('initSetup');
//   // console.log(item);  
//   // if(item && item !== 'undefined'){
//     // if(lang){
//       const initSetup= {
//         lang:lang?lang:"fa",
//         country: item?.country?.toLowerCase()||"ca",
//         countryShort: item?.country?.toLowerCase()||"canada",
//         city:item?.city||'montreal',
//         state: item?.state||{
//           state:  'quebec',
//           stateShort: "qc",
//           lat:"52.93991590",
//           lng:"-73.54913610",
//         },
//         firstRender: false,
//       }
//       console.log('initSetup',initSetup);
      
//       localStorage.setItem("initSetup",JSON.stringify(initSetup))

//     // }else{

//     // }
//     return
//   // }
    
//   }

//   async function getGeoInfo(){
//     const item = localStorage.getItem('initSetup');
//     // console.log(item);  
//     if(item && item !== 'undefined')return
//     const response  =  await api.getGeoInfo()
//     // console.log('response2',response);
//     if(response.error)return console.log(response.error)
//       const currentCount = countryStates.find(co=>co.countryShort===response?.country)
//       const currentState = currentCount.states.find(st=>st.state===response?.region)
//       // console.log("currentCount:",currentCount,"currentState:",currentState);
//       // alert(`شما به وبسایت  متامارس در کشور ${currentCount?.country} وصل شده اید در صورت تمایل می توانید آنرا تغییر دهید.`)
//       // messageRef.current.showWarning({message:`شما از کشور ${currentCount?.country} و استان  ${currentState.stateShort} در صورت تمایل میتوانید شهر یا کشور خود را تغییر دهید`})
//       const[lat,lng]=response.loc.split(",")
//       const initSetup={        
//         lang:lang?lang:"fa",
//         country: currentCount?.country?.toLowerCase(),
//         countryShort: response?.country?.toLowerCase(),
//         city:response?.city,
//         state: {
//           state:  response?.region?.toLowerCase(),
//           stateShort: currentState.stateShort,
//           lat: lat||"52.93991590",
//           lng: lng||"-73.54913610",
//         },
//         firstRender: false,
//       }
//       // console.log('initSetup',initSetup);
//       localStorage.setItem("initSetup",JSON.stringify(initSetup))
  
//     }
    function affiliateSet(){
      const urlParams  = new URLSearchParams(window.location.search)
      const affiliateCode = urlParams.get("aff")
      const ttl = 7*24*60*60*1000
      console.log('affiliateCode',affiliateCode)
      if(affiliateCode?.length>=10) {
        const now = new Date();
  
        const item = {
          affCode: affiliateCode,
          expiry: now.getTime() + ttl,
      };
      localStorage.setItem('affiliate', JSON.stringify(item));
        // localStorage.setItem("affiliate",affiliateCode)
      }
    }

return (
    <>
      <Routes>          
      {/* <Route path={"/"+matchedLang+"/"+matchedCountry?.iso2+"/"+matchedState} element={<StateOutlet/>} > */}
      {/* <Route path={"/"+matchedLang+"/"+matchedCountry?.iso2} element={<StateOutlet/>} >
      {CountryRoutes()}   
      </Route> */}
      <Route path={"/fa"} element={<LangFaOut/>} >
        {FaRoutes()}       
      </Route>
      <Route path={"/en"} element={<LangEnOut/>} >
        {EnRoutes()}       
      </Route>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/denied" element={<AccessDenied />} />
        <Route path="/privacy" element={<Privacy />} /> */}
        <Route path="/coming" element={<ComingSoon />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </>
  );

}

export default App;
