import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { Check, CheckCircle, Error } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import MyIcons from "../../../golbal/icons/myIcons";
import { Link, useNavigate, useParams } from "react-router-dom";
// import renderData from "../../../../utils/renderData";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/api";
import MetaDecorator from "../../../../utils/metaDecorator";
// import demyData from "../../../../utils/demyData";
import tools from "../../../../utils/tools";
// import CatsSelector from "../../../golbal/catsSelector";
// import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
// import SearchMultiSelectFilters from "../../../golbal/dropdown/searchMultiSelectFilters";
// import countryStates from "../../../../utils/countryStates";
import apiJobs from "../../../../utils/apiJobs";
import CountryStateCitySelector from "../../../golbal/dropdown/countryStateCitySelector";
import FormikListErr from "../../../persian/common/alerts/formikListErr";
// import AddSocialMedia from "../../../persian/addSocialMedia";

export default function HideRegister({ action }) {
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const { translations } =
  JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  // const [states, setStates] = useState([]);
  const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  // const [resisedLogoAuth, setResisedLogoAuth] = useState(null);
  // const [originalLogo, setOriginalLogo] = useState(null);
  const photoHandle = async (e, setResisedState, setOriginalState) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: "از فایل با فرمت عکس استفاده کنید.",
      });
    // console.log("photoHandle auth", originalPhoto);
    setResisedState(
      await tools.resizeFile({
        file: e.target.files[0],
      })
    );
    setOriginalState(e.target.files[0]);
  };
  const uploadFileDatabase = async (e, file, field, fieldState) => {
    e.preventDefault();
    if (!file) return;
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    console.log("formData", formData);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/demy/tutor",
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });

    console.log(response.data);
    formik.setValues((pre) => ({ ...pre, [field]: response.data }));
    fieldState(null);
    messageRef.current.showSuccess({
      message: "عکس با موفقیت بارگذاری شد، جهت ثبت کلید ویرایش را فشار دهید. ",
    });
  };

  const messageRef = useRef(null);
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false, addSub: false });
  const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));
  const { id } = useParams();

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;


  const formik = useFormik({
    initialValues: {
      action,
      img: "",
      title: "",
      _id: "",
      lang,
      lcs: window.lcs,
      description: "",
      // name: "",
      // serviceTypes: [],
      // availability: [{ availableDay: "", availableTime: "" }],
      // distances: [{ distance: "", cost: "" }],
      // studyArea: [],
      // sex: "",
      tags: `کاریابی در ${translations[window.lang]||country}+جستجوی شغل در ${translations[window.lang]||country}+استخدام در ${translations[window.lang]||country} +بزرگترین سایت استخدام ${translations[window.lang]||country}+`,
      // levelSub: [{ level: "", subjects: [] }],
      // category: {
      //   mainCat: {},
      //   subCat: {},
      // },
      location: {
        displayAddress: false,
        googleAddress: "",
        country,
        countryShort,
        state,
        stateShort,
        city: "",
        // route: "",
        // municipality: "",
        // streetNumber: "",
        // number: "",
        // postalCode: "",
        // lat: "",
        // lng: "",
      },
      // salary: {
      //   period: "hourly",
      //   rate: 0,
      //   currency: "CAD",
      //   min: "20",
      //   max: "30",
      //   desc: "",
      // },
      // website: "",
      // phone: "",
      // languages: [{ name: "", level: "" }],
      // socialMedia: [{ platform: "", url: "" }],
    },
    onSubmit: async (values) => {
      console.log("onSubmit");
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values });

      console.log("passed Formik", values);
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiJobs.registerHide({
        token: user.token,
        data: values,
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، اطلاعات فرصت شغلی با موفقیت ثبت شد.",
      });

      const updateduser = await api.getUser();
      if (updateduser.error) return;
      signedUser(updateduser.data);
      

      window.navigateHandle(`/${window.lang}/jobs/hide/filters`, {
        state: response.data,
      });
    },
    validationSchema: Yup.object({
      // name: Yup.string()
      //   .notRequired("لطفاً نام شرکت را وارد کنید.")
      //   .max(200, "نام شرکت حداکثر باید 200 حرف باشد .")
      //   .min(3, "نام شرکت حداقل باید 3 حرف باشد."),
      title: Yup.string()
        .required("لطفاً عنوان شغلی را وارد کنید.")
        .max(200, "عنوان شغلی حداکثر باید 200 حرف باشد .")
        .min(3, "عنوان شغلی حداقل باید 3 حرف باشد."),

      description: Yup.string()
        .required("لطفاً توضیحات را وارد کنید.")
        .max(2000, "توضیحات حداکثر باید 2000 حرف باشد .")
        .min(10, "توضیحات حداقل باید 10 حرف باشد."),
      // category: Yup.object().shape({
      //   mainCat: Yup.object().shape({
      //     _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
      //   }),
      //   subCat: Yup.object().shape({
      //     _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
      //   }),
      // }),
      // phone: Yup.string()
      //   .trim()
      //   .when("suggestMode", (suggestMode, schema, value) => {
      //     return (
      //       value.parent.phone?.length &&
      //       schema.test(
      //         "is-valid-phone",
      //         "شماره تلفن نامعتبر میباشد.",
      //         (value) => {
      //           return isValidPhoneNumber(value || "");
      //         }
      //       )
      //     );
      //   }),
      // ... your other validation rules for phone

      // email: Yup.string()
      //   .required("لطفا ایمیل خود را وارد نمائید.")
      //   .trim()
      //   .lowercase()
      //   .email("ایمیل را به درستی وارد کنید."),
      // languages: Yup.array()
      //   .required(" دست کم یک زبان را انتخاب کنید.")
      //   .test(
      //     "array-length",
      //     " دست کم یک زبان را انتخاب کنید.",
      //     (value) => value.length > 0
      //   ),

      location: Yup.object().shape({
        country: Yup.string().required("کشور نمیتواند خالی باشد."),
        state: Yup.string().required("استان نمیتواند خالی باشد."),
        city: Yup.string().required("شهر نمیتواند خالی باشد."),
        municipality: Yup.string(),
      }),
      // website: Yup.string().matches(
      //   urlRegex,
      //   " آدرس وبسایت را به درستی وارد کنید."
      // ),
      link: Yup.string().matches(
        urlRegex,
        " لطفا لینک تماس را درست وارد نمائید."
      ),
      // socialMedia: Yup.array().of(
      //   Yup.object().shape({
      //     platform: Yup.string(),

      //     url: Yup.string()
      //       .when("platform", (platform, schema) => {
      //         return platform
      //           ? schema.required("لطفاً آدرس URL را وارد نمایید.")
      //           : schema;
      //       })
      //       .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
      //         if (!value) return true; // Allow empty value
      //         // Regular expression to validate URL
      //         return urlRegex.test(value);
      //       }),
      //   })
      // ),
      // availability: Yup.array().of(
      //   Yup.object().shape({
      //     availableDay: Yup.string().required("لطفا روزهای کاری را انتخاب نمائید."),
      //     availableTime: Yup.string().required("لطفا ساعات کاری را انتخاب نمائید."),
      //   })
      // ),
      // distances: Yup.array().of(
      //   Yup.object().shape({
      //     distance: Yup.string().required("Please enter the distance."),
      //     cost: Yup.string().required("Please enter the cost."),
      //   })
      // ),
      // salary: Yup.object().shape({
      //   min: Yup.number().required(" حداقل حقوق را وارد کنید.").min(1),
      //   period: Yup.string().required(" دوره را انتخاب کنید."),
      // }),
      // languages: Yup.array().of(
      //   Yup.object().shape({
      //     level: Yup.string().notRequiredequired("لطفا مقطع آموزشی را انتخاب نمائید."),
      //     subjects: Yup.array()
      //       .of(Yup.string()) // Validate individual subject items if needed
      //       .min(1, "لطفا دست کم یک سرفصل آموزشی را انتخاب نمائید.") // Ensure at least one subject is entered
      //   })
      // )
    }),
  });

  useEffect(() => {
    fetchDetails();
    // fetchSubjects()
  }, [id]);

  async function fetchDetails() {
    console.log(id);
    if (!id) return;
    const response = await apiJobs.hideDetails({ token: user.token, id });
    if (response.error) return alert(response.error);
    // console.log(response.data);
    editInitialData(formik, response.data, action);
  }
  // console.log("formik", formik.values);
  // console.log("formik.errors", formik.errors);
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "University Register",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/mp`,
              name: ` نیازمندیها`,
              description: `نیازمندیهای ایرانیان در سراسر دنیا`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/dashboard/jobs/registerhide`,
              name: "ثبت رایگان آگهی استخدام",
              description: "ثبت آسان، انتشار سریع و خودکار آگهی استخدام",
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "کاریابی در گوشه و کنار",
    description: "ثبت فرصت های شغلی اطراف شما",
    section: "Tutor register",
    url: `https://www.metamarce.com/${window.lang}/jobs/registerhide`,
    img: "https://www.metamarce.com/img/demy/Hiring-rafiki.svg",
    type: "",
  };

  return (
    <div className="user  mt-3  px-0">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <p className="divider2 border-0 mt-3 ">
           <MyIcons icon="dotsTitr" />
        <span className="p-0 m-z ">شغل های گوشه و کنار</span>
      </p>
      {user?.roles?.includes(9999) ? (
        <form
          onSubmit={formik.handleSubmit}
          className="row userShow"
          name="jobRegForm00"
          autoComplete="true"
        >
          {/* Right Section */}
          <div className="col-md-6 px-3">
            <div>
              <div className="row">
                <div className="col px-3">
                  <div className="mb-5 ">
                    {/* <CatsSelector formik={formik} section={"jobs"} /> */}
                    <div>
                      <label
                        className="form-label mt-3 titr6 mb-0 required"
                        htmlFor="name"
                      >
                        عنوان شغل
                        <Tooltip1
                          message={
                            <div>
                              <div>
                                لطفا عنوان یا سمت شغلی را اینجا وارد نمائید.
                              </div>
                            </div>
                          }
                        />
                      </label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={`form-control py-1 box2 ${addErrClass({
                          formik,
                          field: "title",
                        })} `}
                        id="title"
                        placeholder=""
                        name="title"
                        {...formik.getFieldProps("title")}
                      />
                      <FormikErrRender formik={formik} field="title" />
                    </div>
                    {/* <div className="d-flex">
                    <p className="form-label mt-3 titr6 mb-0">جنسیت</p>
                    {renderData?.sex?.map((gen) => (
                      <div className="form-check form-check-px1 m-2">
                        <input
                          checked={formik.values.sex === gen.name}
                          className="form-check-input"
                          type="radio"
                          name="sex"
                          id={"sex" + gen.name}
                          value={gen.name}
                          onChange={(e) =>
                            formik.setValues((pre) => ({
                              ...pre,
                              sex: e.target.value,
                            }))
                          }
                        />
                        <label
                          className="form-check-label d-flex"
                          htmlFor={"sex" + gen.name}
                        >
                          {gen[lang]}
                        </label>
                      </div>
                    ))}
                  </div> */}

                    <div className="mb-5">
                      <label className="form-label titr6">توضیحات</label>
                      <textarea
                        className={`form-control ${addErrClass({
                          formik,
                          field: "description",
                        })}`}
                        rows="3"
                        name="description"
                        defaultValue={formik.values.description}
                        onBlur={(e) =>
                          formik.setValues((pre) => ({
                            ...pre,
                            description: e.target.value,
                          }))
                        }
                      ></textarea>
                      <FormikErrRender formik={formik} field="description" />
                    </div>
                    <div>
                      <p className="form-label mt-5 titr6 required"> محل کار</p>
                      <CountryStateCitySelector formik={formik} />
                    </div>
                    {/* <div className="mb-5">
                    <label className="form-label titr6">Experience</label>
                    <textarea
                      className={`form-control ${addErrClass({
                        formik,
                        field: "experience",
                      })}`}
                      rows="3"
                      name="experience"
                      defaultValue={formik.values.experience}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          experience: e.target.value,
                        }))
                      }
                    ></textarea>
                    <FormikErrRender formik={formik} field="experience" />
                  </div> */}
                    {/* <div className="mb-5">
                    <label className="form-label titr6">Hobbies</label>
                    <textarea
                      className={`form-control ${addErrClass({
                        formik,
                        field: "hobbies",
                      })}`}
                      rows="3"
                      name="hobbies"
                      defaultValue={formik.values.hobbies}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          hobbies: e.target.value,
                        }))
                      }
                    ></textarea>
                    <FormikErrRender formik={formik} field="hobbies" />
                  </div> */}

                    {/* <div>
                    <label
                      className="form-label mt-5 titr6 mb-0 required"
                      htmlFor="emailApply"
                    >
                      Email
                      <Tooltip1
                        message={
                          <div>
                            <div>این ایمیل محرمانه است و منتشر نخواهد شد.</div>
                            <div>
                              درخواست همکاری توسط متامارس به آن فرستاده می‌شود.
                            </div>
                          </div>
                        }
                      />
                    </label>
                    <input
                      dir="ltr"
                      id="emailApply"
                      {...formik.getFieldProps("email")}
                      className={`userUpdateInput  form-control box2 ${addErrClass(
                        {
                          formik,
                          field: "email",
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.email}
                    />
                    <FormikErrRender formik={formik} field="email" />
                  </div> */}
                    {/* <div>
                    <label
                      className="form-label mt-5 titr6 mb-0 required"
                      htmlFor="PhoneNumber"
                    >
                      Phone Number
                    </label>
                    <input
                      dir="ltr"
                      id="PhoneNumber"
                      {...formik.getFieldProps("phone")}
                      className={`userUpdateInput  form-control box2 ${addErrClass(
                        {
                          formik,
                          field: "phone",
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.phone}
                    />
                    <FormikErrRender formik={formik} field="phone" />
                  </div> */}

{tools.compareCheck(user?.roles , [1000])&&<div>
                    <label
                      className="form-label mt-5 titr6 mb-0 required"
                      htmlFor="link"
                    >
                       افزودن لینک  
                    </label>
                 
                    <input
                      dir="ltr"
                      id="link"
                      {...formik.getFieldProps("link")}
                      className={`userUpdateInput  form-control box2 ${addErrClass(
                        {
                          formik,
                          field: "link",
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.link}
                    />
                    <FormikErrRender formik={formik} field="link" />
                  </div>}
                    {/* <div className="form-label mt-5 titr6 mb-0  border-0 ">
                    شبکه‌های اجتماعی
                  </div> */}

                    {/* <div className=" border-0 mb-0 px-5">
                    <AddSocialMedia formik={formik} />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Left Section */}
          <div className="userUpdateItem col-md-6 px-3">
            <div className="d-flex">
              <div className="userUpdateUpload d-flex flex-column justify-content-center">
                {/* <img
                src={resisedPhotoAuth || formik.values?.img}
                alt={formik.values.name}
                className="userShowImg-profile"
                loading="lazy"
              /> */}

                {/* <label className="my-2" htmlFor="fileAuth">
                <Publish className="userUpdateIcon" />
                تغییر عکس
              </label> */}
                <div className="mb-0 form-group d-flex files ">
                  <input
                    className="form-control p-3"
                    type="file"
                    id="fileAuth"
                    onChange={(e) =>
                      photoHandle(e, setResisedPhotoAuth, setOriginalPhoto)
                    }
                    // style={{ display: "none" }}
                  />
                </div>
                <img
                  src={resisedPhotoAuth || formik.values?.img}
                  alt={formik.values.name}
                  className="userShowImg-profile"
                  loading="lazy"
                />
                <span
                  className="userUpdateButton edit-btn"
                  style={{ display: resisedPhotoAuth ? "block" : "none" }}
                  onClick={(e) =>
                    uploadFileDatabase(
                      e,
                      originalPhoto,
                      "img",
                      setResisedPhotoAuth
                    )
                  }
                >
                  بارگذاری
                </span>
              </div>
            </div>

            <br />
            <br />
            <br />
            <div>
              <p id="tagsInput" className="form-titr ">
                <span>
                  <i className="bi bi-circle-fill me-1"></i>
                </span>
                برچسب‌ها (با "+" جدا شوند )
                <Tooltip1
                  message={
                    <div>
                      <div>
                        برچسب‌ها - تگ - را توسط '+' (بدون فاصله)از یکدیگر
                        جداکنید.
                      </div>
                      <div>حداکثر ۱۰ برچسب.</div>
                    </div>
                  }
                />
              </p>
              <div className="small-gray-font">
                (بیشتر دیده شدن آگهی. 10 کلمه کلیدی که ممکنه کسی اون رو جستجو
                کنه را وارد کنید)
              </div>

              <input
                type="text"
                autoComplete="off"
                name="other"
                placeholder={
                  formik?.values?.tags ||
                  `برای مثال : بازسازی+طراحی داخلی+ طراحی فضای سبز`
                }
                className="form-input1 mt-3"
                {...formik.getFieldProps("tags")}
              />
            </div>

            {/* <div>
      <span className="p-0 m-z "> مهارتهای زبانی</span>
          <LangsLevel formik={formik}/> 
      </div> */}
          </div>
          <FormikListErr formik={formik} />

          <SubmitBtn formik={formik} text="" />
        </form>
      ) : (
        <div className="container-404 text-center d-flex flex-column align-items-center px-4 ">
          <div className="my-3 ">
            <div> در گام نخست شما نیاز دارید تا وارد حساب کاربری شوید.</div>

            <Link
              id="loginTrigger"
              className="btn primary-btn btn-404 checkout-btn my-5 mt-3"
              data-bs-toggle="modal"
              to="#exampleModalToggle"
              role="button"
            >
              ورود به حساب کاربری
            </Link>
            <img
              className=" img-404 w-75 "
              src="/img/illustration/access-denied.svg"
              alt="404"
            />
          </div>
        </div>
      )}
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
  // function countrySet(countryShort) {
  //   const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  //   console.log(countryShort);
  //   const states = countryStates.find(
  //     (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
  //   ).states;
  //   console.log(states);
  //   // const state = countryStates.find(
  //   //   (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
  //   // ).states[0];
  //   setStates(states);

  //   formik.setValues((prev) => {
  //     const location = { ...prev.location };
  //     location.country = regionNames.of(countryShort).toLowerCase();
  //     location.countryShort = countryShort.toLowerCase();
  //     location.state = "";
  //     location.stateShort = "";
  //     location.cities = [];
  //     return { ...prev, location };
  //   });
  // }
  // function setCitiesHandle(value) {
  //   formik.setValues((prev) => {
  //     const location = { ...prev.location };
  //     location.cities = value;
  //     return { ...prev, location };
  //   });
  // }
}

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    _id: action === "edit" ? data._id : "",
    lang: data?.lang ?? window.lang,
    lcs: data?.lcs ?? window.lcs,
    title: data.title,
    link: data.moreInfo.link,
    img: data.moreInfo.img,
    description: data.moreInfo.description,
    location: data.moreInfo.address,
    tags: data.moreInfo.tags.join("+"),
    // email: data.email,
    // name: data.name,
    // category: data.category,
    // logo: data.moreInfo.logo,
    // phone: data.moreInfo.phone,
    // website: data.moreInfo.website,
    // socialMedia: data.moreInfo.socialMedia,
  };
  formik.setValues(initialValues);
}

async function editHandle({ messageRef, setLoading, user, values }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiJobs.editHide({
    token: user.token,
    data: values,
  });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });

  messageRef.current.showSuccess({
    message: " اطلاعات شغل با موفقیت ویرایش شد.",
  });

  window.navigateHandle(`/${window.lang}/jobs/hide/filters`, {
    state: response.data,
  });
}
