import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { CartContext } from "../../../../contexts/cartContext";

export default function UserDashBusNav() {
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  const{user}=useContext(CartContext)
  return (
    <div className="dashboardNavigator d-flex font-12">
      <NavLink
        to={`/${window.lang}/dashboard/user/business/register`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        افزودن کسب و کار{" "}
      </NavLink>
      {user?.roles?.includes(1299)&&<>
      <NavLink
        to={`/${window.lang}/dashboard/user/business/myBuslist`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        کسب و کارهای من{" "}
      </NavLink>
      {/* <NavLink
        to={`/${window.lang}/dashboard/user/business/mySuglist`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        پیشنهادهای ادعا شده
      </NavLink> */}
      </>}
      {/* {user?.roles?.includes(1000,1205)&&<NavLink */}
      {user?.roles?.includes(1205)&&<NavLink
        to={`/${window.lang}/dashboard/user/business/mySch`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        برنامه کاری من
      </NavLink>}
      {showEdit === "edit" && (
        <NavLink
          to={`/${window.lang}/dashboard/user/business/edit`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
          ویرایش
        </NavLink>
      )}
      {showEdit === "addEmployes" && (
        <NavLink
          to={`/${window.lang}/dashboard/user/business/addEmployes`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
          کارمندان
        </NavLink>
      )}
    </div>
  );
}
