import { NavLink } from "react-router-dom";
import tools from "../../../../../utils/tools";
import { useContext } from "react";
import { CartContext } from "../../../../contexts/cartContext";


export default function UserDashJobsNav() {

  const {user}= useContext(CartContext)
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  return (
    <div className="dashboardNavigator d-flex">
      {tools.compareCheck (user?.roles,[1000,1299,1200])&&<>
      <NavLink
        to={`/${window.lang}/dashboard/user/jobs/register`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        افزودن فرصت شغلی
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/jobs/list`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        
        فرصت های شغلی
      </NavLink>
      </>}
      <NavLink
        to={`/${window.lang}/dashboard/user/jobs/registerhide`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        
        افزودن گوشه و کنار
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/jobs/listHide`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        
        گزارش گوشه و کنار
      </NavLink>
      {/* <NavLink
        to={`/${window.lang}/dashboard/user/jobs/registerReady`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        
        آماده به کار
      </NavLink> */}
      {/* <NavLink
        to={`/coming`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        لیست درخواست‌دهندگان
      </NavLink> */}
      {showEdit === "editReady" ? (
        <NavLink
          to={`/${window.lang}/dashboard/user/jobs/editReady`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
        آماده کار
        </NavLink>
      ):
      <NavLink
      to={`/${window.lang}/dashboard/user/jobs/registerReady`}
      className={`sidebarListItem m-1 link  ${({ isActive }) => {
        return isActive && "active";
      }}`}
    >
      آماده به کار
    </NavLink>
    
    }
    </div>
  );
}
