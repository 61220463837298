import { Route } from "react-router-dom";
import Disclaimer from "../components/persian/common/alerts/disclaimer";
import AboutUs from "../components/persian/common/alerts/aboutUs";
import ContactUs from "../components/persian/common/alerts/contactUs";
import Privacy from "../components/persian/common/alerts/privacy";
import ClaimAdv from "../components/persian/common/alerts/claimBen";
import ComingSoon from "../components/persian/common/alerts/comingSoon";
import Faq from "../components/persian/common/alerts/faq";
import Guides from "../components/persian/common/alerts/guides";
// import TutorGuide from "../components/persian/common/alerts/tutorsStudentsGuide";
import Affiliation from "../components/persian/common/alerts/affiliation";
import TutorsStudentsGuideEn from "../components/persian/common/alerts/tutorsStudentsGuideEn";

export default function promptRoutesEn() {
  return (
    <>
      <Route path="disclaimer" element={<Disclaimer />} />
      <Route path="tutor" element={<TutorsStudentsGuideEn />} />
      <Route path="about" element={<AboutUs />} />
      <Route path="guides" element={<Guides />} />
      <Route path="contact" element={<ContactUs />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="claimben" element={<ClaimAdv />} />
      <Route path="coming" element={<ComingSoon />} />
      <Route path="faq" element={<Faq />} />
      <Route path="affiliation" element={<Affiliation />} />
    </>
  );
}
