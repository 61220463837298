import * as React from 'react';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';
import countryStates from '../../../utils/countryStates';
import api from '../../../utils/api';
import { Checkbox, CircularProgress } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import addErrClass from '../../persian/common/alerts/addErrClass';
import FormikErrRender from '../../persian/common/alerts/formikErrRender';
// Theme.ts
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {
          renderOption: (props, option, state, ownerState) => (
            <Box
              sx={{
                borderRadius: '8px',
                margin: '5px',
                [`&.${autocompleteClasses.option}`]: {
                  padding: '8px',
                },
              }}
              component="li"
              {...props}
            >
              {ownerState.getOptionLabel(option)}
            </Box>
          ),
        },
      },
    },
  });

// function CountryStateCitySelector2({formik,mode,customPath}) {
//   // useTheme is used to determine the dark or light mode of the docs to maintain the Autocomplete component default styles.
//    const outerTheme = useTheme();
//    const [states, setStates] = React.useState([]);
//    const [cities, setCities] = React.useState([]);
//    const [loading, setLoading] = React.useState({spinner:false,cities:false});
//    const deepGet = (obj, path) => {
//     if (!path) return obj; // If no customPath, return the original object
//     const keys = path.split('.');  // Split the path into an array of keys
//     return keys.reduce((acc, key) => {
//       return acc && acc[key] !== undefined ? acc[key] : undefined;
//     }, obj);
//   };
//   const nested = deepGet(formik.values, customPath);

//   console.log('nested',nested);
  
// React.useEffect(()=>{
//     getCitiesHandle()
//     initialStateHandle(formik.values.location.country)
// },[formik.values.location.state])

// async function getCitiesHandle() {
//     const country =formik.values.location.countryShort
//     const state =formik.values.location.stateShort   
    
    
//     if(!country.length+!state.length)return console.log('country state must be filled');
//     setLoading(pre=>({...pre,cities:true}))
//     const response = await api.getCities(country.toUpperCase(), state.toUpperCase());
//     setLoading(pre=>({...pre,cities:false}))
//     if (response.error) return window.alert( response.error);   
//     setCities(response.data);    
//   }

//   function initialStateHandle(country){
//     if(!country?.length)return
//     const countryInfo = countryStates.find(
//       (count) => count.country?.toLowerCase() === country?.toLowerCase()
//     )
//     setStates(countryInfo.states)
//   }



//   return (
//     <div className="country-state-city">
//     <ThemeProvider  theme={customTheme(outerTheme)}>
//       <Stack spacing={5} sx={{ width: 300 }}>
//         <CountrySelect />
//         <FormikErrRender
//                     formik={formik}
//                     field={`location.country`}
//                   />
//         {states.length>0&&<div>
//           <StateSelect />
//         <FormikErrRender
//                     formik={formik}
//                     field={`location.state`}
//                   />

//         </div>}
        
        
        
//         {mode!=='multi'&&(loading.cities ? <CircularProgress /> : cities.length>0)&&<div>
//         <CitySelect />
//         <FormikErrRender formik={formik} field={`location.city`} />        </div>
        
//         }
//         {mode === 'multi' &&(loading.cities ? <CircularProgress /> : cities.length>0)&&<CityMultiSelect />}
//         {/* <MovieSelect /> */}
//         {/* <MovieSelect /> */}
//       </Stack>
//     </ThemeProvider></div>
//   );
// function handleCountryChange(event, country) {
//      console.log('handleCountryChange',!country);
//     if(!country) {
//         setStates([])
//         setCities([])
//         formik.setValues(pre=>({...pre , location:{country:"", countryShort:"",state:"",stateShort:"",city:""}}))
// }else{
  
//     const countryInfo = countryStates.find(
//         (count) => count.country?.toLowerCase() === country?.toLowerCase()
//       )
   
//     formik.setValues((prev) => {
//         const location = { ...prev.location };
//         location.country = country;
//         location.countryShort = countryInfo.countryShort?.toLowerCase();
//         location.state = "";
//         location.stateShort = "";
//         location.city  ='';
//         location.cities =[]
//         return { ...prev, location };
//       });
//       setStates(countryInfo.states);
//     }
//   };
//   function handleStateChange(event,stateName) {
//     if(!stateName) {
//         setCities([])
//         formik.setValues(pre=>({...pre , location:{...pre.location ,state:"",stateShort:"",city:""}}))
// }else{
//    const state = states.find(st=> st.state?.toLowerCase() === stateName?.toLowerCase())
//     formik.setValues((prev) => {
//         const location = { ...prev.location };
//         // location.country = newValue;
//         // location.countryShort = country.countryShort;
//         location.state = state.state?.toLowerCase();
//         location.stateShort = state.stateShort?.toLowerCase();
//         location.city  ='';
//         location.cities =[]
//         return { ...prev, location };
//       });
//    }
//   };
//   function handleCityChange(event,city) {
   
//     if(!city) {      
//         formik.setValues(pre=>({...pre , location:{...pre.location,city:""}}))
// }else{
//     formik.setValues((prev) => {
//         const location = { ...prev.location };
//         // location.country = newValue;
//         // location.countryShort = country.countryShort;
//         // location.state = state.state;
//         // location.stateShort = state.stateShort;
//         location.city  =city;
//         location.cities =[]
//         return { ...prev, location };
//       });
   
// }
//   };
//   function handleMultiCityChange(event,cities) {
//   //  console.log('handleMultiCityChange',cities);       
//         formik.setValues(pre=>({...pre , location:{...pre.location,cities}}))
//   };

// function CountrySelect() {
//   return (
//     <Autocomplete
//       id="country-customized-option-demo"
//       className={`${addErrClass({
//         formik,
//         field: `location.country`,
//       })} `}
//       options={countryStates.map(co=>co.country)}
//     //   disableCloseOnSelect
//       getOptionLabel={(option) => option}
      
//       renderInput={(params) => <TextField {...params} label="Choose a country" />}
//       onChange={handleCountryChange}
//       value={window.capitalize(formik?.values?.location?.country||"")}
//       />
//   );
// }
// function StateSelect() {
//   return (
//     <Autocomplete
//       id="country-customized-option-demo2"
//       className={`${addErrClass({
//         formik,
//         field: 'location.state',
//       })} `}
//       options={states.map(st=>st.state)}
//     //   disableCloseOnSelect
//       getOptionLabel={(option) => option}      
//       renderInput={(params) => <TextField {...params} label="Choose a state" />}
//       onChange={handleStateChange}
//       value={window.capitalize(formik?.values?.location?.state||"")}
//       />
//   );
// }
// function CitySelect() {
//   return (
//     <Autocomplete
//       id="country-customized-option-demo3"
//       className={`${addErrClass({
//         formik,
//         field: 'location.city',
//       })} `}
//       options={cities.map(city=>window.convert( city.name))}
//     //   disableCloseOnSelect
//       getOptionLabel={(option) =>option}      
//       renderInput={(params) => <TextField {...params} label="Choose a city" />}
//       onChange={handleCityChange}
//       value={window.capitalize(formik?.values?.location?.city||"")}
//       />
//   );
// }
// function CityMultiSelect() {
//   const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
//   return (
//     <Autocomplete
//     autoComplete="off"
//     multiple
//     id="checkboxes-tags-demo"
//     options={cities.map(city => city.name)}
// disableCloseOnSelect
//     getOptionLabel={(option) => option}
//     renderOption={(props, option, { selected }) => (
//       <li {...props}>
//         <Checkbox
//           icon={icon}
//           checkedIcon={checkedIcon}
//           style={{ marginRight: 8 }}
//           checked={selected}
//         />
//         {option}
//       </li>
//     )}
//     style={{ width: 500 }}
//     renderInput={(params) => (
//       <TextField {...params} label="Choose Cities" placeholder="select cities" />
//     )}
//     onChange={handleMultiCityChange}
//     // Use formik values directly for the value prop
//     value={formik?.values?.location?.cities}
//   />
//   );
// }
// }

export default function CountryStateCitySelector({formik, mode, customPath}) {
  const outerTheme = useTheme();
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [loading, setLoading] = React.useState({spinner: false, cities: false});

  const deepGet = (obj, path) => {
    if (!path) return obj; // If no customPath, return the original object
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
  };

  const deepSet = (obj, path, value) => {
    if (!path) return value; // If no customPath, set the whole object
    const keys = path.split('.');
    const lastKey = keys.pop();
    const deepObj = keys.reduce((acc, key) => (acc[key] = acc[key] || {}), obj);
    deepObj[lastKey] = value;
    return obj;
  };

  const nested = deepGet(formik.values, customPath || 'location');

  React.useEffect(() => {
    getCitiesHandle();
    initialStateHandle(nested?.country);
  }, [nested?.state]);

  async function getCitiesHandle() {
    const country = nested?.countryShort;
    const state = nested?.stateShort;

    if (!country || !state) return console.log('Country and state must be filled');
    setLoading(pre => ({...pre, cities: true}));

    const response = await api.getCities(country.toUpperCase(), state.toUpperCase());
    setLoading(pre => ({...pre, cities: false}));
    if (response.error) return window.alert(response.error);
    setCities(response.data);
  }

  function initialStateHandle(country) {
    if (!country?.length) return;
    const countryInfo = countryStates.find(count => count.country?.toLowerCase() === country?.toLowerCase());
    setStates(countryInfo.states);
  }

  function updateFormikValues(path, value) {
    formik.setValues(prevValues => {
      const updatedValues = {...prevValues};
      deepSet(updatedValues, path || 'location', value);
      return updatedValues;
    });
  }

  function handleCountryChange(event, country) {
    if (!country) {
      setStates([]);
      setCities([]);
      updateFormikValues(customPath, {country: "", countryShort: "", state: "", stateShort: "", city: ""});
    } else {
      const countryInfo = countryStates.find(count => count.country?.toLowerCase() === country?.toLowerCase());
      updateFormikValues(customPath, {
        country,
        countryShort: countryInfo.countryShort?.toLowerCase(),
        state: "",
        stateShort: "",
        city: "",
        cities: []
      });
      setStates(countryInfo.states);
    }
  }

  function handleStateChange(event, stateName) {
    if (!stateName) {
      setCities([]);
      updateFormikValues(customPath, {...nested, state: "", stateShort: "", city: ""});
    } else {
      const state = states.find(st => st.state?.toLowerCase() === stateName?.toLowerCase());
      updateFormikValues(customPath, {
        ...nested,
        state: state.state?.toLowerCase(),
        stateShort: state.stateShort?.toLowerCase(),
        city: "",
        cities: []
      });
    }
  }

  function handleCityChange(event, city) {
    updateFormikValues(customPath, {...nested, city: city || ""});
  }

  function handleMultiCityChange(event, selectedCities) {
    updateFormikValues(customPath, {...nested, cities: selectedCities});
  }
// console.log("`${customPath+'.location' || 'location'}.country`",`${customPath+'.location' || 'location'}.country`);

  return (
    <div className="country-state-city">
      <ThemeProvider theme={customTheme(outerTheme)}>
        <Stack spacing={5} sx={{ width: 300 }}>
          <CountrySelect />
          <FormikErrRender formik={formik} field={`${customPath || 'location'}.country`} />
          
          {states.length > 0 && (
            <div>
              <StateSelect />
              <FormikErrRender formik={formik} field={`${customPath || 'location'}.state`} />
            </div>
          )}
          
          {mode !== 'multi' && (loading.cities ? <CircularProgress /> : cities.length > 0) && (
            <div>
              <CitySelect />
              <FormikErrRender formik={formik} field={`${customPath || 'location'}.city`} />
            </div>
          )}

          {mode === 'multi' && (loading.cities ? <CircularProgress /> : cities.length > 0) && (
            <CityMultiSelect />
          )}
        </Stack>
      </ThemeProvider>
    </div>
  );

  // Components for Autocomplete fields:
  function CountrySelect() {
    return (
      <Autocomplete
        id="country-customized-option-demo"
        className={`${addErrClass({formik, field: `${customPath || 'location'}.country`})}`}
        options={countryStates.map(co => co.country)}
        getOptionLabel={option => option}
        renderInput={params => <TextField {...params} label="Choose a country" />}
        onChange={handleCountryChange}
        value={window.capitalize(nested?.country || "")}
      />
    );
  }

  function StateSelect() {
    
    return (
      <Autocomplete
        id="country-customized-option-demo2"
        className={`${addErrClass({formik, field: `${customPath || 'location'}.state`})}`}
        options={states.map(st => st.state)}
        getOptionLabel={option => option}
        renderInput={params => <TextField {...params} label="Choose a state" />}
        onChange={handleStateChange}
        value={window.capitalize(nested?.state || "")}
      />
    );
  }

  function CitySelect() {
    return (
      <Autocomplete
        id="country-customized-option-demo3"
        className={`${addErrClass({formik, field: `${customPath || 'location'}.city`})}`}
        options={cities.map(city => window.convert(city.name))}
        getOptionLabel={option => option}
        renderInput={params => <TextField {...params} label="Choose a city" />}
        onChange={handleCityChange}
        value={window.capitalize(nested?.city || "")}
      />
    );
  }

  function CityMultiSelect() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={cities.map(city => city.name)}
        disableCloseOnSelect
        getOptionLabel={option => option}
        renderOption={(props, option, {selected}) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option}
          </li>
        )}
        renderInput={params => <TextField {...params} label="Choose Cities" placeholder="select cities" />}
        onChange={handleMultiCityChange}
        value={nested?.cities || []}
      />
    );
  }
} 

