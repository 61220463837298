import React, { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
// import ToastNote from "../../../golbal/notifications/toastNote";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import tools from "../../../../utils/tools";
// import Tooltip1 from "../../../golbal/tooltip/tooltip";
// import { Link } from "react-router-dom";
// import CommentsRating from "../../../comment/commentsRating";
// import Tooltip1 from "../../../golbal/tooltip/tooltip";

export default function UsedCard({ data ,messageRef,countryShort,ToastNote , setFilters }) {
  const section = "used";
  const [spiner, setSpiner] = useState(false);
  const { signedUser, user } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: data._id,
      section,
      token: user.token,
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> دوباره وارد شوید.</div>
          </div>
        ),
      });
    signedUser(response.data);

    !user?.saved?.[section]?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "عملیات ذخیره موفق.",
        })
      : messageRef.current.showWarning({
          message: "عملیات حذف از لیست ذخیره موفق.",
        });
  };
  const isTrip = data?.category?.mainCat?._id ==="66c0d7e8c79e6e1af314f511"
  const isRide = data?.category?.subCat?._id==="66c0fc4fc79e6e1af314f52a"
  const isStudy = data?.category?.subCat?._id==="66c0deb7c79e6e1af314f51a"
  const isExpired = new Date().setHours(0,0,0,0)> new Date(data.tripInfo.from.date)
  const icon = isRide?"/img/used/rideshare.png":"/img/used/airplane.png"
  let image = data?.moreInfo?.photos?.[0];
  // if (!image) {
  //   if (data?.category?.subCat?._id === "66f9e687a5945e5e21d40e57") {
  //     image = data?.tripInfo?.activityType==="sender" ?"/img/used/travel-suitcase.avif":"/img/used/koolbar.avif";
  //   }else
  //   if (isRide) {
  //     image = "/img/used/ridesharePhoto.avif";
  //   }else
  //   if (data?.category?.subCat?._id === "66c0fc1dc79e6e1af314f523") {
  //     image = "/img/used/senior.jpg"
  //     // "sender" ?"/img/used/suitcase-packed.avif":"/img/used/koolbar.avif";
  //   }
  //   else if (data?.category?.mainCat?._id === "66c126fec79e6e1af31547d1") {    
  //     image = data?.moreInfo.transType === "sale" ? "/img/used/saling-house.avif" : "/img/used/renting-house.avif";
  //   }
  //   else if (isStudy) {    
  //     image = "/img/used/friends-reading2.jpg" 
  //   }
  //   else {
  //     image = "/img/used/tagPrice.avif";
  //   }
  // }
  
  return (
    <div key={data?._id} className="col px-md-3 px-0 ">
      {/* <ToastNote messageRef={messageRef} /> */}
      {/* card 2 section */}
      <div className="card entry p-md-4 p-3 pt-4">
        <div className=" row g-3">
          <div className="col-md-2 px-0">
            <div className="row display-tablet">
              <div className="col-4 p-md-0 ">
                <div>
                  <div
                    className="image-container image-container-media text-center cursor "
                    onClick={(e) =>
                      window.navigateHandle(
                        `/${window.lang}/mp/used/page/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,data
                        // { currencies, data }
                      )
                    }
                  >
                    <img
                      src={
                        image
                      }
                      // className="picture-src"
                      id={"used" + data?._id}
                      title={data?.title}
                    />
                  </div>
                </div>
                <div
                  className="prof-tag prof-tag2  my-2 w-100 text-center"
                  onClick={(e) => {setFilters(pre=>({...pre,
                    subCat:[data?.category?.subCat?.[window.lang]],
                    subCatId:[data?.category?.subCat._id],
                  }))}}
                >
                  {data?.category?.subCat?.[window.lang]}
                </div>
              </div>
              <div className="col-8">
                <div className="d-flex flex-column align-items-start h-100">
                  <div>
                    <div
                      className="titr5 font-18 text-start cursor"
                      onClick={(e) =>window.navigateHandle(`/${window.lang}/mp/used/page/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,data)}
                    >
                      {data?.title}
                    </div>

                   
                      {!(isTrip&&!isStudy)&&<div
                        className=" my-1 small-gray-font d-flex flex-wrap  justify-content-end "
                        dir="ltr"
                      >
                        <i className="bi bi bi-geo-alt mx-2"></i>
                        {`${window.capitalize(
                          data?.moreInfo.address?.city || ""
                        )},
                    ${window.capitalize(data?.moreInfo.address?.state)},
                    ${window.capitalize(data?.moreInfo.address?.countryShort)}
                `}
                      </div>}
                   
                  </div>
                  <div className="my-auto text-center text-pink-orange-dark">
                    {data?.moreInfo?.isFree ? (
                      <div className="prof-tag   text-center">رایگان</div>
                    ) : (
                      <strong>
                        {tools.showPrice(
                          data?.moreInfo?.price?.rate,
                          data?.moreInfo?.price?.currency
                        )}
                      </strong>
                    )}
                  </div>
                 
                </div>

                {/* <ShowAddress address={data?.moreInfo?.address}/> */}
              </div>
            </div>
            <div className="display-none-md">
              <div
                className="image-container image-container-media text-center cursor"
                onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lang}/mp/used/page/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,data
                    // { currencies, data }
                  )
                }
              >
                <img
                  src={
                    image
                  }
                  // className="picture-src"
                  id={"used" + data?._id}
                  title={data?.title}
                />
              </div>
            </div>
            {data?.homeInfo?.transType ? (
              <div className="mt-2 py-1 font-14 text-center">
                <div className="bg-beigeDark fw-bold">
                  {tools.renderTrans({
                    section: "proType",
                    key: data?.homeInfo?.proType,
                  }) +
                    " برای " +
                    tools.renderTrans({
                      section: "transType",
                      key: data?.homeInfo?.transType,
                    })}
                </div>
                <div className="row row-cols-2 mt-2 display-tablet text-center font-14">
                  <div className="col h-100">
                    {" "}
                    <img
                      className="arrow2 pe-1 h-100"
                      src="/img/used/hotel-bed.png"
                      alt="bed"
                    />
                    {tools.dynamicNumber({
                      number: data?.homeInfo?.beds,
                      lang: window.lang,
                    })}
                  </div>
                  <div className="col">
                    <img
                      className="arrow3 pe-1"
                      src="/img/used/bath.png"
                      alt="bathtub"
                    />{" "}
                    {tools.dynamicNumber({
                      number: data?.homeInfo?.bath,
                      lang: window.lang,
                    })}
                  </div>
                </div>
              </div>
            ) : data?.carInfo?.make ? (
              <div className="mt-2  text-center ">
                <div className="bg-beigeGray py-1 fw-bold" dir="ltr">
                  {" "}
                  <span>
                    {
                      window.capitalize(data?.carInfo?.make) + " | "
                      // + " "+ window.capitalize( data?.carInfo?.model)
                    }
                  </span>
                  <span className="my-2">
                    {" "}
                    {tools.dynamicNumber({
                      number: data?.carInfo?.year,
                      lang: window.lang,
                    })}
                  </span>
                </div>

                <div className="row row-cols-2 mt-2 display-tablet text-center font-14 ">
                  <div className=" col ">
                    <img
                      className="arrow3 pe-1"
                      src="/img/used/speedometer.png"
                      alt="gearbox"
                    />{" "}
                    {tools.dynamicNumber({
                      number: data?.carInfo?.odometer,
                      lang: window.lang,
                    })}
                    km
                  </div>
                  <div className="col ">
                    <img
                      className="arrow3 pe-1"
                      src="/img/used/gearbox.png"
                      alt="gearbox"
                    />{" "}
                    {tools.renderTrans({
                      key: data?.carInfo?.transmission,
                      section: "transmission",
                    })}
                  </div>
                </div>
              </div>
            ) :isTrip&&!isStudy?<div className="mt-2  text-center ">
            {data?.tripInfo?.activityType?.length?<div className="bg-beigeGray py-1 fw-bold" dir="ltr">
              <span>
              {tools.renderTrans({
                  key:  data?.tripInfo?.activityType,
                  section:isRide?"rideType": "activityType",
                })}
              </span>
          
            </div>:""}

            <div className="row row-cols-2 mt-2 display-tablet text-center font-14">
            <div className="col-5 ">                
                <span className="fw-bold">{window.capitalize( data?.tripInfo?.to?.location?.city)}</span>
                <div>  {tools.dynamicDate({
                            date: data?.tripInfo?.from?.date,
                            countryShort:data?.tripInfo?.to?.location?.countryShort,
                            lang: window.lang,
                            showYear: true,                            
                          })}</div>
              </div>
              <div className="col-2 " ><img className="arrow2 h-100" src={icon} alt="airplain" /></div>
              <div className=" col-5 ">               
                <span className="fw-bold">
                {window.capitalize( data?.tripInfo?.from?.location?.city)}
                </span>
                <div>  {tools.dynamicDate({
                            date: data?.tripInfo?.from?.date,
                            countryShort:data?.tripInfo?.from?.location?.countryShort,
                            lang: window.lang,
                            showYear: true,                            
                          })}
                </div>
                
              </div>
              {isExpired&&<div className="btn primary-btn checkout-btn cart-footer bg-pinkOrangeDark mt-3  border-0 shadow-none py-1 w-100  ">
                  این آگهی منقضی شده است
                  </div>}
              
            </div>
          </div>:""}
          </div>
          <div className="col-md-6 display-none-md d-flex flex-column ps-3">
            <div>
              <div
                className="titr5 text-start  cursor"
                onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lang}/mp/used/page/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,data
                  )
                }
              >
                {data?.title}
                {/* <span>{data?.carInfo?.year ? data?.carInfo?.year : ""}</span> */}
              </div>

              {!(isTrip&&!isStudy)&&<div
                className=" my-1 small-gray-font d-flex flex-wrap  justify-content-end "
                dir="ltr"
              >
                <i className="bi bi bi-geo-alt mx-2"></i>
                {`${window.capitalize(data?.moreInfo.address?.city || "")},
                ${window.capitalize(data?.moreInfo.address?.state)},
                ${window.capitalize(data?.moreInfo.address?.countryShort)}
                `}
              </div>}

              <div className="d-flex align-items-center">
                <div
                  className="prof-tag prof-tag2  "
                  onClick={(e) => {setFilters(pre=>({...pre,
                    subCat:[data?.category?.subCat?.[window.lang]],
                    subCatId:[data?.category?.subCat._id]
                  }))}}
                  >
                  {data?.category?.subCat?.[window.lang]}
                </div>

                {user?.roles?.includes(1000)?<span className="review-text text-center">
                  (
                  <i
                    className="bi bi-eye Business-cover-icon px-1  "
                    title="بازدید"
                  ></i>
                  {data?.feedbackInfo?.views} )
                </span>:""}
              </div>
            </div>
           

            {data?.carInfo?.make ? (
              <div className="row row-cols-2 mt-auto">
                <div className=" col " title="Mileage">
                  {" "}
                  <img
                    className="arrow3 pe-1"
                    src="/img/used/speedometer.png"
                    alt="Mileage"
                  />{" "}
                  {tools.dynamicNumber({
                    number: data?.carInfo?.odometer,
                    lang: window.lang,
                  })}
                  km
                </div>
                <div className="col " title="Transmission">
                  {" "}
                  <img
                    className="arrow3 pe-1"
                    src="/img/used/gearbox.png"
                    alt="gearbox"
                  />
                  {tools.renderTrans({
                    key: data?.carInfo?.transmission,
                    section: "transmission",
                  })}
                </div>
                {/* <div>{tools.dynamicNumber({number:data?.carInfo?.beds,lang:window.lang})}<img  className="arrow2 pe-2" src="/img/used/bath.png" alt="bathtub" /> </div> */}
              </div>
            ) : data?.homeInfo?.transType ? (
              <div className="row row-cols-2 mt-auto font-14">
                <div className="col h-100" title="Bed">
                  {" "}
                  <img
                    className="arrow2 pe-1 h-100"
                    src="/img/used/hotel-bed.png"
                    alt="bed"
                  />
                  {tools.dynamicNumber({
                    number: data?.homeInfo?.beds,
                    lang: window.lang,
                  })}
                </div>
                <div className="col" title="Bathtub">
                  <img
                    className="arrow3 pe-1"
                    src="/img/used/bath.png"
                    alt="bathtub"
                  />
                  {tools.dynamicNumber({
                    number: data?.homeInfo?.bath,
                    lang: window.lang,
                  })}
                </div>
              </div>
            ) :isTrip&&!isStudy?<div className="mt-2  text-center ">
            <div className="row row-cols-2 mt-2 text-center font-14">
            <div className="col-5 ">                
                <span className="fw-bold">{window.capitalize( data?.tripInfo?.to?.location?.city)}</span>
                <div>  {tools.dynamicDate({
                            date: data?.tripInfo?.from?.date,
                            countryShort:data?.tripInfo?.to?.location?.countryShort,
                            lang: window.lang,
                            showYear: true,                            
                          })}</div>
              </div>
              <div className="col-2 " ><img className="arrow2 h-100" src={icon} alt="airplain" /></div>
              <div className=" col-5 ">               
                <span className="fw-bold">
                {window.capitalize( data?.tripInfo?.from?.location?.city)}
                </span>
                <div>  {tools.dynamicDate({
                            date: data?.tripInfo?.from?.date,
                            countryShort:data?.tripInfo?.from?.location?.countryShort,
                            lang: window.lang,
                            showYear: true,                            
                          })}
                </div>
                
              </div>
              {isExpired&&<div className="btn primary-btn checkout-btn cart-footer bg-pinkOrangeDark mt-3  border-0 shadow-none py-1 w-100  ">
                  این آگهی منقضی شده است
                  </div>}
            </div>
          </div>:""}

          </div>
         
       
          
          <div className="col-md-4 px-3 d-flex flex-column ">
            <div>
              <ShareSocialMedias
                url={`${window.location.origin}/${window.lang}/mp/used/page/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`}
                title={data?.title}
                img={image}
                _id={data?._id}
              />
            </div>
            <div>
              <div
                className=" p-0"
                onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lang}/mp/used/page/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,
                    data
                  )
                }
              >
                <div className="d-flex flex-column align-items-end   ">
                  <div className="display-none-md text-center text-pink-orange-dark">
                    {data?.moreInfo?.isFree ? (
                      <div className="prof-tag text-center">رایگان</div>
                    ) : (
                      <div>
                        <strong>
                          {tools.showPrice(
                            data?.moreInfo?.price?.rate,
                            data?.moreInfo?.price?.currency
                          )}
                        </strong>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end w-100">
                    <div className="prof-tag prof-tag2 font-10 m-3">
                      {tools.renderTrans({
                        section: "usedStatus",
                        key: data?.moreInfo?.status,
                      })}
                    </div>
                    <div className="small-gray-font my-3">
                      {tools.timeAgo(data?.moreInfo?.lastUpdate)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="display-tablet">
              {/* <div className="  divider-small w-100 border-0 my-2 d-flex">
                <span>
                  <img
                    src="/img/icons/about.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <div className="p-0 mb-0 med-gray-font text-justify-metamarce ">
                  {tools.linesSpliter(
                    data?.moreInfo?.description?.length < 60
                      ? data?.moreInfo?.description
                      : data?.moreInfo?.description?.substring(0, 60) + " ..."
                  )}
                </div>
              </div> */}
              <div className="card-footer ">
                <div className="d-flex  justify-content-center">
                  <div>
                    <button
                      id={`saveBtn${data._id}`}
                      className="icon-btn icon-stack"
                      onClick={saveHandler}
                    >
                      {spiner ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        <i
                          className={
                            user.saved?.[section]?.includes(data._id)
                              ? "bi bi-bookmark-fill icon-stack-1x"
                              : "bi bi-bookmark icon-stack-1x"
                          }
                          title="ذخیره"
                          style={{ color: "rgba(123, 99, 90, 0.894)" }}
                        ></i>
                      )}
                    </button>
                  </div>

                  <div>
                    <button
                      className=" icon-stack mx-1 "
                      data-bs-toggle="modal"
                      data-bs-target={`#myModel${data._id}`}
                      id="shareBtn"
                      data-bs-placement="top"
                      title="Share!"
                    >
                      <i className="bi bi-share" title="همرسانی"></i>
                    </button>
                  </div>
                  <ShareSocialMedias
                    url={`${window.location.origin}/${window.lang}/mp/used/${data?._id}`}
                    title={data?.title}
                    img={image}
                    _id={data?._id}
                  />
                  {user?.roles?.includes(1000) && (
                    <button
                      id={`editBtn${data._id}`}
                      className="icon-btn icon-stack"
                      onClick={(e) =>
                        window.navigateHandle(
                          `/${window.lang}/dashboard/mp/editused/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`
                        )
                      }
                    >
                      <i
                        className={"bi bi-pencil icon-stack-1x"}
                        title="ویرایش"
                        style={{ color: "rgba(123, 99, 90, 0.894)" }}
                      ></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="card-footer display-none-md d-flex flex-wrap  justify-content-end mt-auto">
              <div className="d-flex  justify-content-between">
                <div>
                  <button
                    id={`saveBtn${data._id}`}
                    className="icon-btn icon-stack"
                    onClick={saveHandler}
                  >
                    {spiner ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : (
                      <i
                        className={
                          user.saved?.[section]?.includes(data._id)
                            ? "bi bi-bookmark-fill icon-stack-1x"
                            : "bi bi-bookmark icon-stack-1x"
                        }
                        title="ذخیره"
                        style={{ color: "rgba(123, 99, 90, 0.894)" }}
                      ></i>
                    )}
                  </button>
                </div>

                <div>
                  <button
                    className=" icon-stack mx-1 "
                    data-bs-toggle="modal"
                    data-bs-target={`#myModel${data._id}`}
                    id="shareBtn"
                    data-bs-placement="top"
                    title="Share!"
                  >
                    <i className="bi bi-share" title="همرسانی"></i>
                  </button>
                </div>
                <ShareSocialMedias
                  url={`${window.location.origin}/${window.lang}/mp/used/${data?._id}`}
                  title={data?.title}
                  img={image}
                  _id={data?._id}
                />

                {user?.roles?.includes(1000) && (
                  <button
                    id={`editBtn${data._id}`}
                    className="icon-btn icon-stack"
                    onClick={(e) =>
                      window.navigateHandle(
                        `/${window.lang}/dashboard/mp/editused/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`
                      )
                    }
                  >
                    <i
                      className={"bi bi-pencil icon-stack-1x"}
                      title="ویرایش"
                      style={{ color: "rgba(123, 99, 90, 0.894)" }}
                    ></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}

