import { useFormik } from "formik";
import * as Yup from "yup";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import TargetAddress01 from "../../../../../business/register/targetAddress01";
import renderData from "../../../../../../../utils/renderData";
import { CartContext } from "../../../../../../contexts/cartContext";
import ToastNote from "../../../../../../golbal/notifications/toastNote";
import CalendarMy from "../calendarMy";
import { CircularProgress } from "@mui/material";
import Tooltip1 from "../../../../../../golbal/tooltip/tooltip";
import { Clear } from "@mui/icons-material";
import apiBusiness from "../../../../../../../utils/apiBusiness";
import FormikErrRender from "../../../../../../persian/common/alerts/formikErrRender";
import api from "../../../../../../../utils/api";
import tools from "../../../../../../../utils/tools";
import MetaDecorator from "../../../../../../../utils/metaDecorator";

export default function AdsLocator() {
  const {
    lang,
    state: { state, stateShort },
    country,
    countryShort,
  } = tools.getItemFromLocalStorage("initSetup", {});
  const yearMonth = Number(
    `${new Date().getUTCFullYear()}${(new Date().getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}`
  );

  const {
    currency,
    currency_symbol,
    exRate,
    convertRate,
    accepted,
    translations,
  } = JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  function showNumber(number) {
    const amount = Math.ceil(number * exRate * convertRate);
    const cur = currency || "USD";
    const final = amount.toLocaleString(lang, {
      style: "currency",
      currency: cur,
      minimumFractionDigits: cur === "IRR" ? 0 : 2,
      maximumFractionDigits: cur === "IRR" ? 0 : 2,
    });
    return final;
  }
  const curCom = {
    currency,
    currency_symbol,
    exRate,
    convertRate,
    accepted,
    translations,
    state,
    showNumber,
  };
  const { serviceIncrement, serviceItems, user, cartEmptier } =
    useContext(CartContext);
  const [searchParams, setSearchPatams] = useSearchParams();
  const messageRef = useRef(null);
  const fetchedRef = useRef(null);
  const [existBus, setExistBus] = useState([]);
  const [loading, setLoading] = useState({
    spinner: false,
    addTrust: false,
    addVerify: false,
  });
  const navigate = useNavigate();
  const [resizedPhoto, setResizedPhoto] = useState({});

  const [originalPhoto, setOriginalPhoto] = useState({
    verifiedDoc1: "",
    verifiedDoc2: "",
    trustedDoc1: "",
    trustedDoc2: "",
  });

  const photoHandle = async (e, docType) => {
    if (
      !(
        e.target.files[0].type.startsWith("image/") ||
        e.target.files[0].type.startsWith("application/pdf")
      )
    )
      return messageRef.current.showError({
        message: " فرمت فایل را از میان  jpeg , png , gif, pdf انتخاب کنید.",
      });
    // const resizedFile = await resizeFile({ file: e.target.files[0] });
    // setResizedPhoto((pre) => ({ ...pre, [docType]: resizedFile }));
    setOriginalPhoto((pre) => ({ ...pre, [docType]: e.target.files[0] }));
    tools
      .resizeFile({ file: e.target.files[0] })
      .then((resizedP) => {
        setResizedPhoto((pre) => ({ ...pre, [docType]: resizedP }));
      })
      .catch((error) => {
        console.error("Error resizing image:", error);
      });
  };
  const uploadPhotoServer = async (file, path) => {
    console.log("uploadPhotoServer", path);
    if (file == undefined) return;
    const formData = new FormData();
    formData.append("file", file);
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: path,
    });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });
    return response.data;
  };
  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

  const formik = useFormik({
    initialValues: {
      _id: searchParams.get("_id") || "",
      title: "",
      businessLng: window.lang,
      img: "",
      mainImg: "",
      address: "",
      price: 50,
      items: [],
      type: "",
      targetIdentifier: searchParams.get("targetIdentifier"),
      targetAddress: [
        {
          country: country,
          countryShort: countryShort,
          states: [state],
        },
      ],
      verifiedDoc1: "",
      verifiedDoc2: "",
      trustedDoc1: "",
      trustedDoc2: "",
      video: {
        youtube: "",
        aparat: "",
        description: "",
      },
      facilities: [],    
      yearMonth: [yearMonth],
      plansX4: {
        lang: window.lang,
        size: "plansLg",
        img: "",
        price: 200,
        pages: ["busFilters"],
        yearMonth: [yearMonth],
        title: "",
        description: "",
        url: "",
        targetAddress: [
          {
            country: country,
            countryShort: countryShort,
            states: [state],
          },
        ],
      },
      plansX5: {
        lang: lang,
        size: "plansLg",
        img: "",
        price: 500,
        pages: ["home"],
        yearMonth: [yearMonth],
        title: "",
        description: "",
        url: "",
        targetAddress: [
          {
            country: country,
            countryShort: countryShort,
            states: [state],
          },
        ],
      },
      plansCats: {
        _id: searchParams.get("_id") || "",
        title: "",
        businessLng: window.lang,
        img: "",
        mainImg: "",
        address: "",
        price: 40,
        items: [],
        type: "",
        targetIdentifier: searchParams.get("targetIdentifier"),
        targetAddress: [
          {
            country: country,
            countryShort: countryShort,
            states: [state],
          },
        ],
        pages: ["home"],
        yearMonth: [yearMonth],
      },
      plansS1: {
        _id: searchParams.get("_id") || "",
        title: "",
        businessLng: window.lang,
        img: "",
        mainImg: "",
        address: "",
        price: 50,
        items: [],        
        type: "",
        targetIdentifier: searchParams.get("targetIdentifier"),
        targetAddress: [
          {
            country: country,
            countryShort: countryShort,
            states: [state],
          },
        ],
        pages: ["home"],
        yearMonth: [yearMonth],
      },
    },
    onSubmit: async (values, actions) => {
      // console.log("passed formik plansS1", values);
      if (serviceItems.length > 0 && checkCurrencies()) return;
      // const targetCount = values.targetAddress.reduce((a, c) => a + c.states.length,0);
      if (values.active === "plansX4")
        return addToCartPlansX4(values, actions, values.active);
      if (values.active === "plansX5")
        return addToCartPlansX5(values, actions, values.active);
      if (values.active === "plansCats")
        return addToCartPlansCats(values, actions, values.active);
      if (values.active === "plansS1")
        return addToCartPlansS1(values, actions, values.active);

      // const targetCount = values.targetAddress.reduce((a, c) => a + 1, 0);

      // const price =
      //   values.price *
      //   exRate *
      //   convertRate *
      //   values.pages.length *
      //   targetCount *
      //   values.yearMonth.length;
      // const countTotal =
      //   values.pages.length * targetCount * values.yearMonth.length;
      // if (values.businessLng !== window.lang)
      //   return messageRef.current.showError({
      //     message: `زبان کسب و کار شما ${values.businessLng} می باشد و در حال افزودن کسب و کار در بخش ${window.lang} می باشید`,
      //   });
      // if (targetCount <= 0)
      //   return messageRef.current.showError({
      //     message: " کشور را انتخاب کنید.",
      //   });
      // if (values.pages.length <= 0)
      //   return messageRef.current.showError({
      //     message: " صفحه را انتخاب کنید.",
      //   });
      // if (values._id?.length <= 0)
      //   return messageRef.current.showError({
      //     message: " کسب و کار را انتخاب کنید",
      //   });
      // serviceIncrement({
      //   type: "plansS1",
      //   // lang:values.lang,
      //   items: [
      //     {
      //       price: price,
      //       size: "plansLg",
      //       // img:values.itemImg,
      //       pages: values.pages,
      //       yearMonth: values.yearMonth,
      //       targetAddress: values.targetAddress,
      //       url: `/${window.lang}/business/page/${values._id}`,
      //       address: values.address,
      //       status: values.status,
      //       title: values.title,
      //       businessName: values.busName?.[window.lang],
      //       rate: values.rate,
      //       businessCard: values.businessCard,
      //       _id: values._id,
      //     },
      //   ],
      //   lcs: window.lcs,
      //   currency,
      //   currency_symbol,
      //   exRate,
      //   convertRate,
      //   accepted,
      //   price: Math.ceil(price),
      //   count: countTotal,
      //   _id: "64aab4a3bd1afbfb5e08bb05", //sample
      //   title: values.title,
      //   img: "/img/premium/business-competition-animate.svg",
      //   // itemImg: values.img,
      //   targetIdentifier: "Ads",
      // });

      // messageRef.current.showSuccess({
      //   message: "تبلیغات برگزیدگان متامارس با موفقیت به سبد خریداضافه شد.",
      // });
      // document.querySelector("#sidebarRightTrigger").click();
      // // actions.resetForm();
    },
  });
  console.log("formik0000", formik.values);

  async function addToCartPlansX4(values, actions, section) {
    console.log("formikX4 passed", values);
    if (serviceItems.length > 0 && checkCurrencies()) return;
    const targetCount = values[section].targetAddress.reduce(
      (a, c) => a + 1,
      0
    );
    const price =
      values[section].price *
      exRate *
      convertRate *
      values[section].pages.length *
      targetCount *
      values[section].yearMonth.length;
    const countTotal =
      values[section].pages.length *
      targetCount *
      values[section].yearMonth.length;
    if (!originalPhoto?.plansX4?.size)
      return messageRef.current.showWarning({
        message: " عکس را انتخاب کنید.",
      });
    if (targetCount <= 0)
      return messageRef.current.showWarning({
        message: " کشور را انتخاب کنید.",
      });
    if (values[section].pages.length <= 0)
      return messageRef.current.showWarning({
        message: " صفحه را انتخاب کنید.",
      });
    setLoading((pre) => ({ ...pre, plansX4: true }));
    const img = await uploadPhotoServer(
      originalPhoto.plansX4,
      "images/ads/plansX4"
    );
    values.img = img;
    // const response = await apiStore.createPlansX4({token:user.token , data: values})
    setLoading((pre) => ({ ...pre, plansX4: false }));

    serviceIncrement({
      type: "plansX4",
      // lang:values.lang,
      items: [
        {
          price: price,
          size: values[section].size,
          img: values[section].img,
          pages: values[section].pages,
          yearMonth: values[section].yearMonth,
          targetAddress: values[section].targetAddress,
          url: values[section].url,
          description: values[section].description,
          title: values[section].title,
        },
      ],
      lcs: window.lcs,
      lang: window.lang,
      accepted,
      exRate,
      convertRate,
      currency,
      currency_symbol,
      price: Math.ceil(price),
      count: countTotal,
      _id: "64aab4a3bd1afbfb5e08bb05",
      title: values[section].title,
      img: "/img/premium/target-animate.svg",
      itemImg: values[section].img,
      targetIdentifier: "Ads",
    });

    messageRef.current.showSuccess({
      message: "تبلیغات ویژه در متامارس با موفقیت به سبد خریداضافه شد.",
    });
    document.querySelector("#sidebarRightTrigger").click();
    // actions.resetForm();
  }
  async function addToCartPlansX5(values, actions, section) {
    console.log("formik addToCartPlansX5 passed", values);
    if (serviceItems.length > 0 && checkCurrencies()) return;
    const targetCount = values[section].targetAddress.reduce(
      (a, c) => a + 1,
      0
    );
    const price =
      values[section].price *
      exRate *
      convertRate *
      values[section].pages.length *
      targetCount *
      values[section].yearMonth.length;
    const countTotal =
      values[section].pages.length *
      targetCount *
      values[section].yearMonth.length;
    if (!originalPhoto?.plansX5?.size)
      return messageRef.current.showWarning({
        message: " عکس را انتخاب کنید.",
      });
    if (targetCount <= 0)
      return messageRef.current.showWarning({
        message: " کشور را انتخاب کنید.",
      });
    if (values[section].pages.length <= 0)
      return messageRef.current.showWarning({
        message: " صفحه را انتخاب کنید.",
      });
    setLoading((pre) => ({ ...pre, plansX5: true }));
    const img = await uploadPhotoServer(
      originalPhoto.plansX5,
      "images/ads/plansX5"
    );
    values[section].img = img;
    // const response = await apiStore.createPlansX4({token:user.token , data: values})
    setLoading((pre) => ({ ...pre, plansX5: false }));

    serviceIncrement({
      type: "plansX5",
      // lang:values[section].lang,
      items: [
        {
          price: price,
          size: values[section].size,
          img: values[section].img,
          pages: values[section].pages,
          yearMonth: values[section].yearMonth,
          targetAddress: values[section].targetAddress,
          url: values[section].url,
          description: values[section].description,
          title: values[section].title,
        },
      ],
      lcs: window.lcs,
      lang: window.lang,
      accepted,
      exRate,
      convertRate,
      currency,
      currency_symbol,
      price: Math.ceil(price),
      count: countTotal,
      _id: "64aab4a3bd1afbfb5e08bb05", //sample
      title: values[section].title,
      img: "/img/premium/business-challenge-animate-2.svg",
      itemImg: values[section].img,
      targetIdentifier: "Ads",
    });

    messageRef.current.showSuccess({
      message: "تبلیغات ممتاز با موفقیت به سبد خریداضافه شد.",
    });
    document.querySelector("#sidebarRightTrigger").click();
    // actions.resetForm();
  }
  async function addToCartPlansCats(values, actions, section) {
    console.log("passed formikCats", values);
    if (serviceItems.length > 0 && checkCurrencies()) return;
    const targetCount = values[section].targetAddress.reduce(
      (a, c) => a + 1,
      0
    );
    const price =
      values[section].price *
      exRate *
      convertRate *
      values[section].pages.length *
      targetCount *
      values[section].yearMonth.length;
    const countTotal =
      values[section].pages.length *
      targetCount *
      values[section].yearMonth.length;
    if (formik.values[section].businessLng !== window.lang)
      return messageRef.current.showError({
        message: `زبان کسب و کار شما ${values[section].businessLng} می باشد و در حال افزودن کسب و کار در بخش ${window.lang} می باشید`,
      });

    if (formik.values._id?.length <= 0)
      return messageRef.current.showError({
        message: " کسب و کار را انتخاب کنید",
      });
    serviceIncrement({
      type: "plansCats",
      // lang:values[section].lang,
      items: [
        {
          price: price,
          // size:"plansLg",
          img: values.itemImg,
          pages: values[section].pages,
          yearMonth: values[section].yearMonth,
          // targetAddress:values[section].targetAddress,
          url: `/${window.lang}/business/page/${formik.values._id}`,
          address: formik.values.address,
          status: formik.values.status,
          title: formik.values.title,
          _id: formik.values._id,
        },
      ],
      lcs: window.lcs,
      lang: window.lang,
      accepted,
      currency,
      exRate,
      convertRate,
      currency_symbol,
      price: price,
      count: countTotal,
      _id: formik.values._id, //sample
      title: formik.values.title,
      img: "/img/premium/think-different-animate.svg",
      itemImg: values.itemImg,
      targetIdentifier: "Business",
    });

    messageRef.current.showSuccess({
      message: "آگهی ویژه طبقه‌بندی با موفقیت به سبد خریداضافه شد.",
    });
    document.querySelector("#sidebarRightTrigger").click();
    // actions.resetForm();
  }
  async function addToCartPlansS1(values, actions, section) {
    console.log("passed formikCats", values);
    if (serviceItems.length > 0 && checkCurrencies()) return;
    const targetCount = values.targetAddress.reduce((a, c) => a + 1, 0);

    const price =
      values[section].price *
      exRate *
      convertRate *
      values[section].pages.length *
      targetCount *
      values[section].yearMonth.length;
    const countTotal = values[section].pages.length * targetCount * values[section].yearMonth.length;
    if (values.businessLng !== window.lang)
      return messageRef.current.showError({
        message: `زبان کسب و کار شما ${values.businessLng} می باشد و در حال افزودن کسب و کار در بخش ${window.lang} می باشید`,
      });
    if (targetCount <= 0)
      return messageRef.current.showError({
        message: " کشور را انتخاب کنید.",
      });
    if (values[section].pages.length <= 0)
      return messageRef.current.showError({
        message: " صفحه را انتخاب کنید.",
      });
    if (values._id?.length <= 0)
      return messageRef.current.showError({
        message: " کسب و کار را انتخاب کنید",
      });
    serviceIncrement({
      type: "plansS1",
      // lang:values.lang,
      items: [
        {
          price: price,
          size: "plansLg",
          // img:values.itemImg,
          pages: values[section].pages,
          yearMonth: values[section].yearMonth,
          targetAddress: values[section].targetAddress,
          url: `/${window.lang}/business/page/${values._id}`,
          address: values.address,
          status: values.status,
          title: values.title,
          businessName: values.busName?.[window.lang],
          rate: values.rate,
          businessCard: values.businessCard,
          _id: values._id,
        },
      ],
      lcs: window.lcs,
      lang: window.lang,
      currency,
      currency_symbol,
      exRate,
      convertRate,
      accepted,
      price: Math.ceil(price),
      count: countTotal,
      _id: "64aab4a3bd1afbfb5e08bb05", //sample
      title: values.title,
      img: "/img/premium/business-competition-animate.svg",
      // itemImg: values.img,
      targetIdentifier: "Ads",
    });

    messageRef.current.showSuccess({
      message: "تبلیغات برگزیدگان با موفقیت به سبد خریداضافه شد.",
    });
    document.querySelector("#sidebarRightTrigger").click();
    // actions.resetForm();
  }

  function checkCurrencies() {
    let multiCur = serviceItems.some((item) => item?.currency !== currency);
    const message =
      "(Cancel)واحد پول خرید فعلی شما با آیتم های داخل سبد همخوانی ندارد، با حذف آیتم ها قبلی موافقم)(Ok) ، با بازگشت به کشور قبل موافقم";
    if (multiCur) {
      if (window.confirm(message)) {
        cartEmptier();
        multiCur = false;
      } else {
        navigate(`/${serviceItems[0].lcs.split("/")[0]}/store/plans`);
        multiCur = true;
      }
    }
    return multiCur;
  }

  useEffect(() => {
    (async () => {
      if (!formik.values._id) return;
      const response = await apiBusiness.businessDetails(formik.values._id);
      if (response.error) return alert(response.error);
      // console.log(response.data);
      // const newTarget = updatedTarget(response.data?.moreInfo?.targetAddress);
      formik.setValues((pre) => ({
        ...pre,
        // targetAddress: it cause reset the targetOptions to the start.
        //   response.data?.moreInfo?.targetAddress || pre.targetAddress,
        facilities: response.data?.moreInfo?.facilities || pre.facilities,
        title: response.data?.busName?.[window.lang] || "",
        img: "",
        itemImg: response.data?.moreInfo?.photos?.businessCard,
        isPaid: response.data?.moreInfo?.isPaid,
        status: response.data?.moreInfo?.status,
        address: response.data?.moreInfo?.address,
        businessLng: response.data?.moreInfo?.businessLng,
        businessName: response.data?.busName?.[window.lang],
        rate: response.data?.feedbackInfo?.rate,
        businessCard: response.data?.moreInfo?.photos?.businessCard,
      }));
    })();
  }, [searchParams.get("_id"), formik.values._id]);

  useEffect(() => {
    fetchExistBus();
  }, [user]);

  async function fetchExistBus() {
    if (fetchedRef.current || !user?._id) return;
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiBusiness.checkExistBus({
      userId: user._id,
      values: [countryShort, stateShort],
      token: user.token,
      lang: window.lang,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message:
          "بروز مشکل در بازیابی اطلاعات،  لطفا دوباره وارد حساب کاربری خود شوید.",
      });
    if (response.data.length === 0) {
      const el = document.querySelector("#verifiedBusiness");
      if (!el) return;
      let msgTxt = `شما در ${
        curCom?.translations?.[window.lang]
      } کسب و کار ثبت شده ای ندارید.`;
      const customMsg = document.querySelector("#adsCustomMsg");
      if (customMsg) {
        customMsg.innerText = msgTxt;
      } else {
        // const txt = `<div id='customMsg' class="error-message">${msgTxt}</div>`
        const txt = `<div id='adsCustomMsg' class="error-message mt-3">${msgTxt}</div>`;
        el.insertAdjacentHTML("afterbegin", txt);
      }
      return;
    }
    const customMsg = document.querySelector("#adsCustomMsg");
    if (customMsg) {
      customMsg.remove();
    }

    setExistBus(response.data);
    formik.setValues((pre) => {
      const bus = response.data[0];
      console.log("bus00", bus);
      const pre2 = { ...pre };
      pre2.targetAddress = bus.targetAddress || pre2.targetAddress;
      pre2.facilities = bus?.facilities || pre2.facilities;
      pre2.title = bus?.busName?.[window.lang] || "";
      pre2.img = "";
      pre2.mainImg = bus?.businessCard;
      pre2.isPaid = bus?.isPaid;
      pre2.targetIdentifier = bus?.targetIdentifier;
      pre2._id = bus?._id;
      return pre2;
    });
    fetchedRef.current = true;
  }
  const structuredData = [
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "ads ",
      description: "تبلیغات در متامارس",

      url: `https://www.metamarce.com/${window.lang}/store/plans`,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/store/plans`,
              name: `تبلیغات`,
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "تبلیغات",
    description:
      "تبلیغات در متامارس | تبلیغات گسترده متامارس | تبلیغات هدفمند | بیشتر دیده شوید | بهتر دیده شوید | توسط مشتریهاانتخاب شوید",
    section: "Ads",
    url: `https://www.metamarce.com/${window.lang}/store/plans`,
    canonical: `https://www.metamarce.com/${window.lang}/store/plans`,
    img: "https://www.metamarce.com/img/premium/business-challenge-animate-2.svg",
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      <div className=" sticky-top2  ">
        <div id="existingBus   ">
          <button
            style={{
              cursor: "pointer",
              display: "block",
              backgroundColor: "rgba(77, 65, 46, 0.70)",
            }}
            onClick={existBusinesses}
            className=" primary-btn view-cart view-cart2 w-100 px-md-5 px-1 py-1 "
          >
            <div className="d-flex mx-auto align-items-center ads-all">
              <p className="text-center m-0 p-0">
                {formik?.values?.title?.length
                  ? "کسب و کار شما:"
                  : "کسب و کار خود را انتخاب کنید:"}
              </p>
              <div
                className="mx-md-5 mx-3 ms-auto titr5"
                style={{ color: "beige" }}
              >
                {formik.values.title?.length > 0 && `"${formik.values.title}"`}
              </div>
              <div>
                <img
                  className="jobCrad-img2"
                  src={formik.values.businessCard}
                  alt={formik.values.title}
                  loading="lazy"
                />
              </div>
            </div>

            {loading.existBus && (
              <CircularProgress color="inherit" size="20px" />
            )}
          </button>
        </div>

        {existBus.length > 0 && (
          <div
            id="existingBusShowHide"
            className="BusRegList p-3 mx-auto w-100 ads-all"
            style={{ display: "none" }}
          >
            <span
              title="بستن"
              onClick={(e) => {
                document.querySelector("#existingBusShowHide").style.display =
                  "none";
              }}
            >
              <Clear
                sx={{
                  color: "brown",
                  cursor: "pointer",
                  background: "beige",
                  borderRadius: "50%",
                  padding: "3px",
                }}
              />
            </span>
            {existBus.map((bus, i) => (
              <div key={i}>
                <ExistBusRender bus={bus} formik={formik} />
              </div>
            ))}
          </div>
        )}
      </div>
      {/*******verfied| business */}
      <div id="verifiedBusiness" className=" px-md-5 px-1 pt-5">
        <div className="row ads-all pt-5 mx-auto">
          <div className="col-md-7">
            <p className="form-titr mt-0 py-0 mb-3 ">
              <span>
                <img
                  src="/img/premium/premium-quality.png"
                  className="arrow4 mx-1"
                  alt="targetAddress"
                  loading="lazy"
                />
              </span>
              گرفتن نشان تأیید متامارس
              <IsActive isActive={formik.values?.isPaid?.verifiedDoc} />
            </p>

            <p className="text-justify-metamarce ">
              پروفایل کسب و کارتون رو با تأیید هویتتون تقویت کنبد
              <span>
                <i className="bi bi-patch-check-fill font-18"></i>
              </span>
              <br /> <br />
              با این کار از سایر رقبا برجسته می‌شوید و اعتماد مشتریانتون رو به
              دست میارید. داشتن نشان تأیید متامارس نشون میده که هویت شما به
              عنوان مسئول کسب و کار تائید شده و کاربران راحت‌تر می‌تونن به شما
              اعتماد کنند.
              <br />
              علاوه بر اون کسب و کارهای تأیید شده در نتایج جستجو در اولویت قرار
              می‌گیرند و و بالاتر از کسب و کارهایی که این نشان رو ندارند، دیده
              می‌شوند. در نتیجه کاربران سریع‌تر و راحت‌‌تر کسب و کار شما رو پیدا
              می‌کنند.
            </p>

            <p
              className="btn-click ms-auto d-flex justify-content-around align-items-center "
              // data-bs-toggle="collapse"
              // aria-expanded="false"
              href="#ad-verified"
              onClick={loginChecker}
            >
               <span className="toggle-text">بیشتر</span>

              <i className=" bi bi-arrow-up-circle-fill arrow-icon font-14"/>
            </p>
            <div className="collapse ads-all mx-auto" id="ad-verified">
              <div className="row">
                <div className="col-md-8">
                  <div className="form-label form-titr m-1 my-3  text-center">
                    {/* <span>
                    <i className="bi bi-circle-fill me-1"></i>
                  </span> */}
                    احراز هویت در متامارس باهمکاری پی پل انجام و پس از تکمیل
                    پرداخت از درگاه پی پل، هویت شما به صورت اتوماتیک تائید می
                    گردد.
                    {/* <div className="mx-auto">
                    <UploadPhotos docType="verifiedDoc1" />
                  </div> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex flex-column justify-content-center align-items-center my-3 ">
                    <div
                      // className="btn primary-btn view-cart checkout-btn cart-footer ad-no-total font-20 my-2 "
                      className="btn primary-btn view-cart checkout-btn cart-footer mb-3 font-20  mx-auto ad-no-total  "
                      style={{ cursor: "default" }}
                    >
                      {tools.showPrice(500, curCom?.currency,0)}
                      <span className="px-2">سالانه</span>
                    </div>

                    <button
                      type="button"
                      disabled={formik.values?.isPaid?.verifiedDoc}
                      className="btn primary-btn view-cart cart-footer mb-3  mx-auto ad-no-total  "
                      onClick={(e) =>
                        addVerified({
                          e,
                          formik,
                          serviceIncrement,
                          messageRef,
                          originalPhoto,
                          uploadPhotoServer,
                          setLoading,
                          serviceItems,
                          setOriginalPhoto,
                          checkCurrencies,
                          serviceItems,
                          curCom,
                        })
                      }
                    >
                      {loading.addVerify ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : formik.values?.isPaid?.verifiedDoc ? (
                        `${formik.values.title} دارای نشان تائید`
                      ) : (
                        "افزودن به سبد خرید"
                      )}
                    </button>
                    {/* {formik.values?.isPaid?.verifiedDoc && (
                    <Tooltip1
                      icon={
                        <WarningAmber sx={{ fontSize: 18, color: "orange" }} />
                      }
                      message={`نشان تائید برای " ${formik.values.title} " قبلا خریداری شده است .`}
                      position="left"
                    />
                  )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 ">
            <img
              src="/img/premium/verified-animate.svg"
              alt="verified"
              className="p-md-5 image-ad-respo"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      {/*******trusted | business */}
      <div className="img-banner-text-6 px-md-5 px-1 pt-5">
        <div className="row ads-all mx-auto">
          <div className="col-md-5">
            <img
              src="/img/premium/certification-animate.svg"
              alt="certification"
              className="mx-1 image-ad-respo"
              loading="lazy"
            />
            <div className="collapse ads-all mx-auto" id="ad-trusted">
              <div className="">
                <div className="d-flex flex-column justify-content-center align-items-center my-md-5 my-3 ">
                  <div
                    className="btn primary-btn checkout-btn cart-footer ad-no-total font-20 my-2 "
                    style={{ cursor: "default" }}
                  >
                    {tools.showPrice(2500, curCom?.currency,0)}
                    <span className="px-2">سالانه</span>
                  </div>
                  <button
                    disabled={formik.values?.isPaid?.trustedDoc}
                    type="button"
                    className="btn primary-btn view-cart cart-footer mb-3  mx-auto ad-no-total  "
                    onClick={(e) =>
                      addTrusted({
                        e,
                        formik,
                        serviceIncrement,
                        serviceItems,
                        messageRef,
                        originalPhoto,
                        uploadPhotoServer,
                        setLoading,
                        serviceItems,
                        setOriginalPhoto,
                        checkCurrencies,
                        curCom,
                      })
                    }
                  >
                    {loading.addTrust ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : formik.values?.isPaid?.trustedDoc ? (
                      `${formik.values.title} دارای نشان اعتماد`
                    ) : (
                      "افزودن به سبد خرید"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            {/* <div className="d-flex  p-3  mb-3 justify-content-between img-banner-text "> */}
            <p className="form-titr mt-0 py-0 mb-3 ">
              <span>
                <img
                  src="/img/premium/premium-quality.png"
                  className="arrow4 mx-1"
                  alt="targetAddress"
                  loading="lazy"
                />
              </span>
              گرفتن نشان طلایی متامارس
              <IsActive isActive={formik.values?.isPaid?.trustedDoc} />
            </p>

            <p className="text-justify-metamarce">
              "به شبکه کسب و کارهای طلایی بپیوندید!
              <span>
                <span className="m-0 p-0">
                  <img
                    src="/img/icons/premium-quality.png"
                    className="arrow2"
                    alt="premium"
                    loading="lazy"
                  />
                </span>
              </span>
              <br /> <br />
              اگه براتون مهمه که مشتریانتون با خیال راحت به شما اعتماد کنند و
              مطمئن باشند که شما تخصص کافی و مجوز لازم برای کاری که انجام می‌دید
              رو دارید و به طور خلاصه در کسب و کارتون حرفه‌ای هستید، نشان طلایی
              متامارس می‌تونه به شما کمک کنه. در این حالت هویت کسب و کار شما
              تایید میشه و نشون میده برای خدماتی که ارائه می‌دین مجوز یا تحصیلات
              لازم رو دارید.
              <br />
              با این کار نه تنها مشتریان بیش از پیش به شما اعتماد می‌کنند بلکه
              کسب و کار شما در بالاترین اولویت جستجو قرار می‌گیره و در بین
              همکاران خود متمایز خواهید شد.
            </p>
            <p
              className="btn-click ms-auto d-flex justify-content-around align-items-center"
              // data-bs-toggle="collapse"
              // aria-expanded="false"
              href="#ad-trusted"
              onClick={loginChecker}
            >
               <span className="toggle-text">بیشتر</span>

              <i className=" bi bi-arrow-up-circle-fill arrow-icon font-14"/>
            </p>
            
          </div>
        </div>
      </div>
      {/*** KEEP THIS!**** QR| business */}
      {/* <div className="img-banner-text-2 p-md-5">
        <div className="row ads-all mx-auto">
          <div className="col-md-5">
            <img
              src="/img/premium/qr-code-animate.svg"
              alt="premium"
              className="mx-1"
            />
          </div>
          <div className="col-md-7">
            <p
              className="form-titr mt-0 py-0 mb-3 "
              
            >
              <span>
                <img
                  src="/img/premium/premium-quality.png"
                  className="arrow4 mx-1"
                  alt="targetAddress"
                />
              </span>
              نمایش QR-code برای کسب و کار خود
            </p>

            <p>
              "تقویت پروفایل کسب و کار خود با تأیید هویت! <br /> <br />
              از سایر رقبا برجسته شده و اعتماد مشتریان بالا را با دریافت تأیید
              هویت کسب و کار خود در دایرکتوری ما به دست آورید. ند و باعث می‌شود
              کاربران به راحتی کسب و کار شما را پیدا کنند.
              <br />
              <strong> اعتماد بیشتر: </strong>
              با کسب و کارهایی که تأیید شده‌اند، اعتماد و قابلیت اعتماد در میان
              مشتریان ایجاد شود.
            </p>

            <p
              className="btn-click ms-auto "
              data-bs-toggle="collapse"
              aria-expanded="false"
              href="#ad-qr"
            >
              بیشتر
            </p>
          </div>
        </div>
        <div className="collapse" id="ad-qr">
          qD code
        </div>
      </div> */}
      {/*******Facilities | business */}
      <div className="img-banner-text-4 px-md-5 px-1 pt-5">
        <div className="row ads-all mx-auto">
          <div className="col-lg-7 col-md-9  ">
            <p className="form-titr mt-0 py-0 mb-3 ">
              <span>
                <img
                  src="/img/premium/premium-quality.png"
                  className="arrow4 me-2 "
                  alt="targetAddress"
                  loading="lazy"
                />
              </span>
              نمایش امکانات ویژه‌ای که در اختیار مشتریان خود قرار می‌دهید
              <IsActive isActive={formik.values?.isPaid?.facilities} />
            </p>
            <p className="text-justify-metamarce">
              این گزینه این امکان رو به شما میده تا خدمات و امکاناتی که به
              مشتریانتون ارائه می‌هید رو با جلوه‌های تصویری جذاب معرفی کنید.
              همچنین ویژگی‌ها و جزییاتی که در هنگام انتخاب کسب و کار برای
              مشتریان مهم هستند و به آنها کمک می‌کنه تا با آرامش و راحتی بیشتری
              تصمیم‌گیری کنند (مانند داشتن فضای کودکان، اینترنت رایگان، پارکینگ
              و …) این ویژگی‌ها با کمک فیلترهای متامارس قابل جستجو هستند و باعث
              متمایز و بیشتر دیده شدن شما در بین کسب و کارهای دیکه میشه.
            </p>
            <p
              className="btn-click ms-auto d-flex justify-content-around align-items-center"
              // data-bs-toggle="collapse"
              // aria-expanded="false"
              href="#ad-facility"
              onClick={loginChecker}
            >
               <span className="toggle-text">بیشتر</span>

              <i className=" bi bi-arrow-up-circle-fill arrow-icon font-14"/>
            </p>

            {/* <!-- Modal Premium --> */}
            <div className="collapse mx-auto ads-all " id="ad-facility">
          یکبار پرداخت بدون محدودیت در تعداد و با قابلیت ویرایش
          <Facilities
            formik={formik}
            serviceIncrement={serviceIncrement}
            serviceItems={serviceItems}
            messageRef={messageRef}
            checkCurrencies={checkCurrencies}
            curCom={curCom}
          />
        </div>
            {/* <PremiumModal />   */}
          </div>
          <div className="col-lg-5 col-md-3 px-0 px-lg-5">
            <img
              src="/img/premium/facilities-ad-banner.png"
              alt="facilities"
              className=" premium-banner-img  ad-img image-ad-respo"
              loading="lazy"
            />
          </div>
        </div>
       
      </div>
      {/*******YouTube Video | business */}
      <div className="img-banner-text-7 px-md-5 px-1 pt-5">
        <div className="row ads-all mx-auto">
          <div className="col-md-5 px-0 px-lg-5">
            <img
              src="/img/Images/Press-pause-bro.svg"
              className="img-fluid  image-ad-respo "
              alt="video link"
              loading="lazy"
            />
          </div>
          <div className="col-md-7">
            <p className="form-titr mt-0 py-0 mb-3 ">
              <span>
                <img
                  src="/img/premium/premium-quality.png"
                  className="arrow4 me-2 "
                  alt="targetAddress"
                  loading="lazy"
                />
              </span>
              افزودن ویدیو به صفحه کسب و کار شما
              <IsActive isActive={formik.values?.isPaid?.video} />
            </p>
            <p className="text-justify-metamarce">
              با افزودن ویدئو به صفحه کسب‌وکارتون می‌تونید ارتباط نزدیکتری با
              مخاطبانتون برقرار کنید و اطلاعات بیشتر و جذابتری رو در اختیار
              مشتریانتونشون قرار بدهید.
            </p>
            <p
              className="btn-click ms-auto d-flex justify-content-around align-items-center"
              // data-bs-toggle="collapse"
              // aria-expanded="false"
              href="#ad-video"
              onClick={loginChecker}
            >
               <span className="toggle-text">بیشتر</span>

              <i className=" bi bi-arrow-up-circle-fill arrow-icon font-14"/>
            </p>
            {/* <!-- Modal Premium --> */}
            {/* <PremiumModal />   */}
            <div className="collapse " id="ad-video">
              یکبار پرداخت و با قابلیت ویرایش و جایگزینی
              <div className="mb-3 mt-3">
                <label className="form-label form-titr mt-3  ps-2" htmlFor="f5">
                  <span>
                    <i className="bi bi-circle-fill me-1"></i>
                  </span>
                  لینک ویدیوی یوتوب
                  {/* <Tooltip1
                    message="در صورت تمایل لینک وبسایت خود را به صورت کامل وارد کنید."
                    position="top-end"
                  /> */}
                </label>
                <a
                  tabIndex="0"
                  className="btn info-btn shadow-none "
                  role="button"
                  data-bs-toggle="popover"
                  data-bs-trigger="focus"
                  data-bs-content=" لینک ویدیوی خود در یوتوی را به صورت کامل وارد کنید."
                >
                  <i className="bi bi-question-circle-fill"></i>
                </a>

                <input
                  type="text"
                  autoComplete="off"
                  className=" form-control my-5 mt-3 py-1 box2"
                  id="f5"
                  placeholder=" مثال:https://youtu.be/yfY9zRZxqtY."
                  onBlur={(e) =>
                    formik.setValues((pre) => ({
                      ...pre,
                      video: { ...pre.video, youtube: e.target.value },
                    }))
                  }
                  // {...formik.getFieldProps("video")}
                />
                {formik.values.video?.youtube &&
                  generateYoutubeIframe(formik.values.video.youtube)}

                {/* <label className="form-titr" htmlFor="f555">توضیحات ویدئو </label>
                <input
                  type="text"
                  className=" form-control my-5 mt-3 py-1 box2"
                  id="f555"
                  placeholder=" مثال:https://youtu.be/yfY9zRZxqtY."
                  {...formik.getFieldProps("video.description")}
                /> */}
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center my-5 ">
                <div className="btn primary-btn checkout-btn cart-footer ad-no-total font-20 my-2">
                {tools.showPrice(50, curCom?.currency,0)}
                  {/* <span className="small-gray-font">
                    یکبار خرید | بدون محدودیت
                  </span> */}
                </div>
                {formik.values?.isPaid?.video ? (
                  <Link
                    onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
                    to={`/${window.lang}/dashboard/user/business/edit/${formik.values._id}#facilitiesRender`}
                    type="button"
                    className="btn primary-btn view-cart cart-footer mb-3  mx-auto ad-no-total "
                  >
                    (فعال) انتقال به صفحه ویرایش کسب و کار
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="btn primary-btn view-cart cart-footer mb-3  mx-auto ad-no-total "
                    onClick={(e) =>
                      addVideoHandle({
                        e,
                        formik,
                        messageRef,
                        serviceIncrement,
                        curCom,
                        serviceItems,
                        checkCurrencies,
                      })
                    }
                  >
                    افزودن به سبد خرید
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*******target address | business */}
      <div className=" img-banner-text-4 px-md-5 px-1 pt-5">
        <div className="row ads-all mx-auto">
          <div className="col-md-7 py-5 px-3">
            <p className="form-titr mt-0 py-0 mb-3 ">
              <span>
                <img
                  src="/img/premium/premium-quality.png"
                  className="arrow4 me-2"
                  alt="targetAddress"
                  loading="lazy"
                />
              </span>
              نمایش صفحه‌ی کسب و کار شما در متامارس دیگر کشور/ استانها :
              <IsActive isActive={formik.values?.isPaid?.targetAddress} />
              {formik.values?.isPaid?.targetCount}
            </p>
            <p className="text-justify-metamarce">
              با انتخاب این گزینه می‌تونید کسب‌وکارتون رو در راهنمای کسب و کار
              دیگر کشورها نمایش دهید.
              {/* ( به جز استان و کشور خودتون که به صورت
              رایگان در دسترستون هست) هم ثبت نام کنید و به مخاطبان و مشتریان
              بیشتری در مناطق مختلف دسترسی داشته */}
            </p>
            <p
              className="btn-click ms-auto d-flex justify-content-around align-items-center"
              // data-bs-toggle="collapse"
              // aria-expanded="false"
              href="#ad-target"
              onClick={loginChecker}
            >
               <span className="toggle-text">بیشتر</span>

              <i className=" bi bi-arrow-up-circle-fill arrow-icon font-14"/>
            </p>
            <div className="collapse" id="ad-target">
              <TargetAddress01 formik={formik} />
              <button
                type="button"
                className="btn primary-btn  view-cart ad-no-total cart-footer my-3  mx-auto"
                onClick={(e) =>
                  addTargetBasket({
                    formik,
                    serviceIncrement,
                    serviceItems,
                    messageRef,
                    curCom,
                    checkCurrencies,
                  })
                }
              >
                افزودن به سبد خرید
              </button>
              {/* {formik.values?.isPaid?.targetAddress && (
                <Tooltip1
                  message={`جهت نمایش "${formik.values.title}" در ${formik.values.isPaid.targetCount} استان پرداخت انجام شده، برای استانهای بیشتر یا تغییر انتخاب کنید .`}
                  position="left"
                />
              )} */}
            </div>
          </div>
          <div className="col-md-5 px-0 px-lg-5">
            <img
              src="/img/premium/map-ad-banner.png"
              alt="banner"
              className="m-0 p-0 premium-banner-img image-ad-respo w-100"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      {/* *******plansX5********** */}
      {/* آگهی‌های تبلیغاتی در متامارس */}
      <div className=" img-banner-text-2 px-md-5 px-1 pt-5">
        <div className="row row ads-all mx-auto">
          <div className="col-md-5 d-flex">
            <img
              src="/img/premium/business-challenge-animate-2.svg"
              className="  w-100 md-5 image-ad-respo"
              alt="premium"
              loading="lazy"
            />
          </div>
          <div className="col-md-7 ">
            <p className="form-titr">
              <span className="mt-0 py-0 mb-3 ">
                <img
                  // src="/img/premium/premium.png"
                  src="/img/premium/premium-quality.png"
                  className="arrow4 me-2"
                  alt="premium"
                  loading="lazy"
                />
              </span>
              تبلیغات ممتاز
            </p>

            <p>
              با نمایش آگهیتون در
              <strong>«صفحه نخست متامارس» </strong>
              منحصر به فرد باشید.
              <br /> <br />
              برای قرار دادن آگهی در این جایگاه‌ها نیازی به افزودن کسب و کار
              ندارید و شما می‌تونید به راحتی با بارگذاری یک عکس از این گزینه
              استفاده کنید. علاوه بر اون امکان افرودن لینک به پوستر آگهی هم وجود
              داره.
              <br />
              «تبلیغات ممتاز» متامارس با امکانات ویژه‌ای که داره نه تنها به
              بیشتر و بهتر دیده‌شدن شما کمک می‌کنه بلکه شما می‌تونید از این
              امکانات برای آگاهی کاربران از تخفیف‌های ویژه، مناسبت‌های خاص و …
              استفاده کنید.
            </p>
            <p
              className="btn-click ms-auto d-flex justify-content-around align-items-center"
              // data-bs-toggle="collapse"
              // aria-expanded="false"
              href="#plansX5"
              onClick={loginChecker}
            >
               <span className="toggle-text">بیشتر</span>

              <i className="bi bi-arrow-up-circle-fill arrow-icon font-14"/>
            </p>
          </div>
        </div>
        <PlansRender
          formik={formik}
          photoHandle={photoHandle}
          resizedPhoto={resizedPhoto}
          loading={loading}
          section={"plansX5"}
          curCom={curCom}
        />
      </div>
      {/* *******planX4********** */}
      <div className="  px-md-5 px-1 pt-5 ">
        <div className="row ads-all mx-auto">
          <div className="col-md-7 ">
            <p className="form-titr">
              <span className="mt-0 py-0 mb-3 ">
                <img
                  // src="/img/premium/premium.png"
                  src="/img/premium/premium-quality.png"
                  className="arrow4 me-2"
                  alt="premium"
                  loading="lazy"
                />
              </span>
              تبلیغات ویژه در متامارس
            </p>

            <p className="text-justify-metamarce">
              با استفاده از این گزینه می‌تونید آگهیتون رو در صفحات مختلف متامارس
              به نمایش بگذارید و کسب و کارتون رو به سبکی جذاب و چشم‌نواز تبلیغ
              کنید.
              <br />
              افزودن کسب و کار برای استفاده از این امکانات نیازی به ثبت کسب و
              کار ندارید و به راحتی می‌تونید پوستر تبلیغاتی مورد نظر رو در
              جایگاه‌های مشخص شده به انتخاب خودتون قرار بدهید. علاوه بر اون
              امکان افزودن لینک به پوستر آگهی هم وجود داره. این ویژگی این امکان
              رو به شما می‌ده تا با توجه به نیاز کسب‌وکارتون و به انتخاب خودتون،
              پوسترهای جذاب و متنوعی رو در صفحات مختلف متامارس به نمایش بگذارید.
            </p>
            <p
              className="btn-click ms-auto d-flex justify-content-around align-items-center"
              // data-bs-toggle="collapse"
              // aria-expanded="false"
              href="#plansX4"
              onClick={loginChecker}
            >
               <span className="toggle-text">بیشتر</span>

              <i className=" bi bi-arrow-up-circle-fill arrow-icon font-14"/>
            </p>
          </div>
          <div className="col-md-5 d-flex">
            <img
              src="/img/premium/target-animate.svg"
              className="  w-100 my-auto image-ad-respo"
              alt="premium"
              loading="lazy"
            />
          </div>
        </div>
        <PlansRender
          formik={formik}
          photoHandle={photoHandle}
          resizedPhoto={resizedPhoto}
          loading={loading}
          section={"plansX4"}
          curCom={curCom}
        />
      </div>
      {/* *******End planX4********** */}
      {/* *******plansS1********** */}
      <div className=" img-banner-text px-md-5 px-1 pt-5">
        <div className="row ads-all mx-auto ">
          <div className="col-md-5 d-flex px-0 px-lg-5">
            <img
              src="/img/premium/business-competition-animate.svg"
              className="  w-100 image-ad-respo px-5"
              alt="premium"
              loading="lazy"
            />
          </div>
          <div className="col-md-7 ">
            <p className="form-titr">
              <span className="mt-0 py-0 mb-3 ">
                <img
                  src="/img/premium/premium-quality.png"
                  className="arrow4 me-2"
                  alt="premium"
                  loading="lazy"
                />
              </span>
              برگزیدگان در متامارس
            </p>

            <p className="text-justify-metamarce">
              از اونجایی که حمایت از کسب‌وکارهای کوچکتر یکی از اولویت‌های اصلی
              ماست، تبلیغات کم‌هزینه‌تر هم درنظر گرفتیم تا کسب‌وکارهای بیشتری
              امکان معرفی خودشون رو داشته باشند. برگزیدگان این امکان رو به شما
              میده تا کسب و کارتون رو در یک یا چند صفحه متامارس در سراسر دنیا
              معرفی کنید.
              <br />
              با توجه به این که برای ثبت آگهی از اطلاعات صفحه کسب‌وکار شما در
              متامارس استفاده میشه، استفاده از این گزینه بسیار ساده و سریع خواهد
              بود.
            </p>
            <p
              className="btn-click ms-auto d-flex justify-content-around align-items-center"
              // data-bs-toggle="collapse"
              // aria-expanded="false"
              href="#plansS1"
              onClick={loginChecker}
            >
               <span className="toggle-text">بیشتر</span>

              <i className=" bi bi-arrow-up-circle-fill arrow-icon font-14"/>
            </p>
          </div>
        </div>
        <div className="ads-all mx-auto"></div>
        <PlansRender
          formik={formik}
          photoHandle={photoHandle}
          resizedPhoto={resizedPhoto}
          loading={loading}
          section={"plansS1"}
          curCom={curCom}
        />
      </div>
      {/* *******End plansS1********** */}
      {/* *********plansCats***** */}
      <div className="   px-md-5 px-1 pt-5">
        <div className="row row ads-all mx-auto">
          <div className="col-md-7 ">
            <p className="form-titr">
              <span className="mt-0 py-0 mb-3 ">
                <img
                  // src="/img/premium/premium.png"
                  src="/img/premium/premium-quality.png"
                  className="arrow4 me-2"
                  alt="premium"
                  loading="lazy"
                />
              </span>
              آگهی ویژه طبقه‌بندی
            </p>
            <p className="text-justify-metamarce">
              اقصادی‌ترین گزینه‌ی تبلیغات در متامارس به شما این امکان رو می‌ده
              تا کسب و کارتون در صفحه‌ی نخست کسب و کار (متابیرنس) دردسته‌بندی
              ویژه و به صورت تفکیک شده به نمایش دربیاد.. این برنامه به کاربران
              این امکان را می‌ده که با بررسی سریع دسته‌بندی‌ها و کسب‌وکارهای
              ویژه در هر دسته، به سادگی شما رو پیدا کنند. با این برنامه کم
              هزینه، بیشترین ارزش را از تبلیغات خود به دست بیارید و کاربران را
              به سمت کسب‌وکارتون جذب کنید
            </p>
            <p
              className="btn-click ms-auto d-flex justify-content-around align-items-center"
              // data-bs-toggle="collapse"
              // aria-expanded="false"
              href="#plansCats"
              onClick={loginChecker}
            >
               <span className="toggle-text">بیشتر</span>

              <i className=" bi bi-arrow-up-circle-fill arrow-icon font-14"/>
            </p>
          </div>
          <div className="col-md-5 d-flex px-0 px-lg-5">
            <img
              src="/img/premium/think-different-animate.svg"
              className="  w-100 my-auto image-ad-respo"
              alt="premium"
              loading="lazy"
            />
          </div>
        </div>
        <PlansRender
          formik={formik}
          photoHandle={photoHandle}
          loading={loading}
          section={"plansCats"}
          curCom={curCom}
        />
      </div>
      {/* ********* End plansCats***** */}
    </div>
  );
  async function existBusinesses(e) {
    e.preventDefault();
    console.log("existBusinesses");
    if (!user.roles?.includes(9999))
      return document.querySelector("#loginTrigger").click();
    const showHide = document.querySelector("#existingBusShowHide");
    if (showHide !== null) {
      showHide.style.display =
        showHide.style.display === "none" ? "block" : "none";
    }
    document.querySelector("#existItemNotAvail")?.remove();
  }

  function UploadPhotos({ docType }) {
    return (
      <div className="d-flex flex-column" id={docType} key={docType}>
        <div className="userUpdateUpload d-flex flex-column">
          <div className="mb-0 form-group d-flex files border-0">
            <input
              className="form-control p-3 mx-auto"
              dir="ltr"
              type="file"
              id={docType + "file"}
              onChange={(e) => photoHandle(e, docType)}
              accept="image/jpeg, image/png, image/gif, application/pdf"
            />
          </div>
          <div>
            {originalPhoto[docType]?.name}
            {originalPhoto[docType]?.name && (
              <Clear
                sx={{ color: "red" }}
                onClick={(e) => {
                  // setResizedPhoto((pre) => ({ ...pre, [docType]: "" }))
                  setOriginalPhoto((pre) => ({ ...pre, [docType]: "" }));
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
function ExistBusRender({ bus, formik }) {
  return (
    <div
      key={bus?._id}
      className="m-1 p-3 bs-1 d-flex justify-content-between w-100"
    >
      <div
        className="d-flex ad-select cursor "
        onClick={(e) => {
          const showHide = (document.querySelector(
            "#existingBusShowHide"
          ).style.display = "none");
          formik.setValues((pre) => {
            const pre2 = { ...pre };
            pre2.targetAddress = bus.targetAddress || pre2.targetAddress;
            pre2.facilities = bus?.facilities || pre2.facilities;
            pre2.title = bus?.busName?.[window.lang] || "";
            pre2.img = "";
            pre2.mainImg = bus?.businessCard;
            pre2.isPaid = bus?.isPaid;
            pre2.targetIdentifier = bus?.targetIdentifier;
            pre2._id = bus?._id;
            return pre2;
          });
        }}
      >
        {/* <input
          type="radio"
          id={`ad-select-bus${bus?._id}`}
          name="busRadio"
          style={{ display: "none" }}
          defaultChecked={bus._id === formik.values._id}
        
        /> */}
        <div
          htmlFor={`ad-select-bus${bus?._id}`}
          className="d-flex justify-content-between"
        >
          <img
            height="40px"
            width="60px"
            src={bus?.businessCard}
            alt={bus?.busName?.[window.lang]}
            loading="lazy"
          />
          <span className="m-1 p-2">{bus?.busName?.[window.lang]}</span>
          <span className="m-1 p-2">
            دسته بندی : {bus?.category?.subCategory}
          </span>
          {/* <span className="m-1 p-2">
      تلفن : {bus?.moreInfo?.businessPhone}
    </span> */}
          <span className="m-1 p-2">ایمیل : {bus?.email}</span>
          <div className="m-1 p-2" dir="ltr">
            <i className="bi bi bi-geo-alt mx-2"></i>
            {`${window.capitalize(bus?.address?.city)},
${window.capitalize(bus?.address?.state)},
${window.capitalize(bus?.address?.countryShort)}
`}
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-around"
        onClick={(e) => {
          window.navigateHandle(
            `/${window.lang}/business/page/${tools.slugMaker({
              title: bus.busName[window.lang],
              city: bus?.address?.city,
              state: bus?.address?.state,
              serial: bus?.serial,
            })}`
          );
        }}
      >
        <button className="btn primary-btn delete-btn"> دیدن صفحه </button>
      </div>
    </div>
  );
}

function Facilities({
  formik,
  serviceIncrement,
  serviceItems,
  messageRef,
  curCom,
  checkCurrencies,
}) {
  return (
    <div className="mx-md-5 mx-1">
      <div className=" row row-cols-2 row-cols-md-4 row-cols-lg-6  justify-content-evenly g-4 px-3 med-gray-font">
        {renderData.facilities?.map((fac, i) => (
          <div key={i} id="facilitiesRender" className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={fac.name}
              checked={formik.values.facilities?.includes(fac.name)}
              id={`flexCheckfacilities${i}`}
              onChange={(e) => {
                let facilities = [...formik.values.facilities];
                if (facilities?.includes(e.target.value)) {
                  const newValues = facilities.filter(
                    (fac) => fac !== e.target.value
                  );
                  facilities = newValues;
                } else {
                  facilities.push(e.target.value);
                }
                formik.setValues((prev) => ({
                  ...prev,
                  facilities,
                }));
              }}
            />
            <label
              className="form-check-label"
              htmlFor={`flexCheckfacilities${i}`}
            >
              {fac?.[window.lang]}
            </label>
          </div>
        ))}
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center py-5 ">
        <div className="btn primary-btn checkout-btn cart-footer ad-no-total font-20 my-2">
          {tools.showPrice(50, curCom?.currency,0)}
          {/* <span className="small-gray-font"> یکبار خرید | بدون محدودیت</span> */}
        </div>
        {formik.values?.isPaid?.facilities ? (
          <Link
            onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
            to={`/${window.lang}/dashboard/user/business/edit/${formik.values._id}#facilitiesRender`}
            type="button"
            className="btn primary-btn view-cart cart-footer mb-3  mx-auto ad-no-total "
          >
            انتقال به صفحه ویرایش امکانات
          </Link>
        ) : (
          <button
            type="button"
            className="btn primary-btn view-cart cart-footer mb-3  mx-auto ad-no-total w-100"
            onClick={(e) =>
              addFacilitiesBasket({
                formik,
                serviceIncrement,
                serviceItems,
                messageRef,
                curCom,
                checkCurrencies,
              })
            }
          >
            افزودن به سبد خرید
          </button>
        )}
      </div>
    </div>
  );
}

function addFacilitiesBasket({
  formik,
  serviceIncrement,
  serviceItems,
  messageRef,
  curCom,
  checkCurrencies,
}) {
  // e.preventDefault()
  if (serviceItems.length > 0 && checkCurrencies()) return;
  if (!formik.values._id)
    return messageRef.current.showWarning({
      message: " کسب و کار را انتخاب کنید.",
    });

  let count = 0;
  const price = 50 * curCom?.exRate * curCom?.convertRate;

  if (formik.values.facilities <= 0)
    return messageRef.current.showWarning({
      message: " امکانات را انتخاب کنید.",
    });

  serviceIncrement({
    type: "facilities",
    items: formik.values.facilities,
    lcs: window.lcs,
    lang: window.lang,
    accepted: curCom?.accepted,
    currency: curCom?.currency,
    currency_symbol: curCom?.currency_symbol,
    convertRate: curCom?.convertRate,
    exRate: curCom?.exRate,
    price: Math.ceil(price),
    count: formik.values.facilities.length,
    _id: formik.values._id,
    title: formik.values.title,
    img: "/img/premium/facilities-ad-banner.png",
    itemImg: formik.values.img,
    targetIdentifier: "Business",
  });
  messageRef.current.showSuccess({
    message: "نمایش امکانات به سبد خریداضافه شد.",
  });
  document.querySelector("#sidebarRightTrigger").click();
}
async function addVerified({
  e,
  formik,
  serviceIncrement,

  messageRef,
  originalPhoto,
  uploadPhotoServer,
  setLoading,
  serviceItems,
  setOriginalPhoto,
  checkCurrencies,
  curCom,
}) {
  e.preventDefault();
  if (serviceItems.length > 0 && checkCurrencies()) return;
  if (
    serviceItems.some(
      (service) =>
        service._id === formik.values._id && service.type === "verifiedDoc"
    )
  )
    return messageRef.current.showWarning({
      message: `نشان تائید متامارس برای" ${formik.values.title} "در سبد خرید موجود می باشد`,
    });
  if (!formik.values._id)
    return messageRef.current.showWarning({
      message: " کسب و کار را انتخاب کنید.",
    });
  // if (!originalPhoto.verifiedDoc1.size)
  //   return messageRef.current.showWarning({
  //     message: " فایل مورد نیاز را بارگذاری کنید.",
  //   });
  // if (
  //   !(
  //     originalPhoto.verifiedDoc1.type.startsWith("image/") ||
  //     originalPhoto.verifiedDoc1.type.startsWith("application/pdf")
  //   )
  // )
  //   return messageRef.current.showError({
  //     message: " فرمت فایل را از میان  jpeg , png , gif, pdf انتخاب کنید.",
  //   });
  // if (originalPhoto.verifiedDoc1.size > 512 * 1024)
  //   return messageRef.current.showError({
  //     message: " حجم فایل کارت شناسایی را حداکثر تا 512 کیلو بایت انتخاب کنید",
  //   });
  // e.target.setAttribute("disabled", true);
  // setLoading((pre) => ({ ...pre, addVerify: true }));
  // const response = await Promise.all([
  //   await uploadPhotoServer(
  //     originalPhoto.verifiedDoc1,
  //     "images/identity/verified"
  //   ),
  // ]);
  // setLoading((pre) => ({ ...pre, addVerify: false }));
  // setTimeout(() => e.target.removeAttribute("disabled"), 10000);

  // console.log(response);

  serviceIncrement({
    type: "verifiedDoc",
    items: [{ identity: "response[0]" }],
    lcs: window.lcs,
    lang: window.lang,
    accepted: curCom?.accepted,
    currency: curCom?.currency,
    currency_symbol: curCom?.currency_symbol,
    convertRate: curCom?.convertRate,
    exRate: curCom?.exRate,
    price: Math.ceil(500 * curCom?.exRate * curCom?.convertRate),
    count: 1,
    _id: formik.values._id,
    title: formik.values.title,
    img: "/img/premium/verified-animate.svg",
    itemImg: formik.values.img,
    targetIdentifier: "Business",
  });
  messageRef.current.showSuccess({
    message: "نشان تائید کسب و کار به سبد خریداضافه شد.",
  });
  setOriginalPhoto((pre) => ({ ...pre, verifiedDoc1: "", verifiedDoc2: "" }));
  document.querySelector("#sidebarRightTrigger").click();
}
async function addTrusted({
  e,
  formik,
  serviceIncrement,
  messageRef,
  originalPhoto,
  uploadPhotoServer,
  setLoading,
  serviceItems,
  setOriginalPhoto,
  checkCurrencies,
  curCom,
}) {
  e.preventDefault();
  if (serviceItems.length > 0 && checkCurrencies()) return;
  if (
    serviceItems.some(
      (service) =>
        service._id === formik.values._id && service.type === "trustedDoc"
    )
  )
    return messageRef.current.showWarning({
      message: `نشان اعتماد متامارس برای" ${formik.values.title} "در سبد خرید موجود می باشد`,
    });
  if (!formik.values._id)
    return messageRef.current.showWarning({
      message: " کسب و کار را انتخاب کنید.",
    });
  // if (!originalPhoto.trustedDoc1.size || !originalPhoto.trustedDoc2.size)
  //   return messageRef.current.showWarning({
  //     message: "  هر دو فایل مورد نیاز را بارگذاری کنید.",
  //   });
  // if (
  //   !(
  //     originalPhoto.trustedDoc1.type.startsWith("image/") ||
  //     originalPhoto.trustedDoc1.type.startsWith("application/pdf")
  //   ) ||
  //   !(
  //     originalPhoto.trustedDoc2.type.startsWith("image/") ||
  //     originalPhoto.trustedDoc2.type.startsWith("application/pdf")
  //   )
  // )
  //   return messageRef.current.showError({
  //     message: " فرمت فایل را از میان  jpeg , png , gif, pdf انتخاب کنید.",
  //   });
  // if (originalPhoto.trustedDoc1.size > 512 * 1024)
  //   return messageRef.current.showError({
  //     message: " حجم فایل کارت شناسایی را حداکثر تا 512 کیلو بایت انتخاب کنید",
  //   });
  // if (originalPhoto.trustedDoc2.size > 512 * 1024)
  //   return messageRef.current.showError({
  //     message:
  //       " حجم فایل مدرک هویتی کسب و کار را حداکثر تا 512 کیلو بایت انتخاب کنید",
  //   });
  // e.target.setAttribute("disabled", true);
  // setLoading((pre) => ({ ...pre, addTrust: true }));
  // const response = await Promise.all([
  //   await uploadPhotoServer(
  //     originalPhoto.trustedDoc1,
  //     "images/identity/trusted"
  //   ),
  //   await uploadPhotoServer(
  //     originalPhoto.trustedDoc2,
  //     "images/identity/trusted"
  //   ),
  // ]);
  // setLoading((pre) => ({ ...pre, addTrust: false }));
  // setTimeout(() => e.target.removeAttribute("disabled"), 10000);

  // console.log(response);

  serviceIncrement({
    type: "trustedDoc",
    items: [
      {
        identity: "response[0]",
        identityBus: "response[1]",
      },
    ],
    lcs: window.lcs,
    lang: window.lang,
    accepted: curCom?.accepted,
    currency: curCom?.currency,
    currency_symbol: curCom?.currency_symbol,
    convertRate: curCom?.convertRate,
    exRate: curCom?.exRate,
    price: Math.ceil(2500 * curCom?.exRate * curCom?.convertRate),
    count: 1,
    _id: formik.values._id,
    title: formik.values.title,
    img: "/img/premium/certification-animate.svg",
    itemImg: formik.values.img,
    targetIdentifier: "Business",
  });
  messageRef.current.showSuccess({
    message: "نشان اعتماد متامارس به سبد خریداضافه شد.",
  });
  setOriginalPhoto((pre) => ({ ...pre, trustedDoc1: "", trustedDoc2: "" }));

  document.querySelector("#sidebarRightTrigger").click();
}
function addTargetBasket({
  formik,
  serviceIncrement,
  serviceItems,
  messageRef,
  curCom,
  checkCurrencies,
}) {
  // e.preventDefault()
  if (serviceItems.length > 0 && checkCurrencies()) return;
  if (!formik.values._id)
    return messageRef.current.showWarning({
      message: " کسب و کار را انتخاب کنید.",
    });

  let countTotal = 0;
  const price = formik.values.targetAddress.reduce((a, item) => {
    countTotal += 1;
    if (countTotal <= 20)
      return countTotal * 10 * curCom?.exRate * curCom?.convertRate;
    return (
      (countTotal - 20) * 2.5 * curCom?.exRate * curCom?.convertRate +
      20 * curCom?.exRate * curCom?.convertRate
    );
  }, 0);
  console.log("countTotal", countTotal, formik.values.targetAddress);

  if (countTotal <= 0)
    return messageRef.current.showWarning({
      message: " کشور را انتخاب کنید.",
    });

  const target = formik.values.targetAddress.map((neewTar) => {
    const newD = {
      country: neewTar.country,
      countryShort: neewTar.countryShort,
      states: neewTar.states,
    };
    return newD;
  });

  // console.log("newD", target);
  // console.log("formik.values", formik.values);

  serviceIncrement({
    type: "targetAddress",
    items: target,
    lcs: window.lcs,
    lang: window.lang,
    accepted: curCom?.accepted,
    currency: curCom?.currency,
    currency_symbol: curCom?.currency_symbol,
    convertRate: curCom?.convertRate,
    exRate: curCom?.exRate,
    price: Math.ceil(price),
    count: countTotal,
    _id: formik.values._id,
    title: formik.values.title,
    img: "/img/premium/map-ad-banner.png",
    mainImg: formik.values.img,
    targetIdentifier: "Business",
  });

  if (countTotal > formik.values.isPaid.targetCount) {
    let count = formik.values.isPaid.targetCount * -1;
    const price = formik.values.targetAddress.reduce((a, item) => {
      // count += item.states.length;
      count += 1;
      if (count <= 20) return count * 10 * curCom?.exRate * curCom?.convertRate;
      return (
        (count - 20) * 2.5 * curCom?.exRate * curCom?.convertRate +
        20 * curCom?.exRate * curCom?.convertRate
      );
    }, 0);

    if (
      window.confirm(
        `شما مجموعا ${countTotal} کشور را جهت نمایش کسب و کار خود انتخاب نموده اید و درگذشته برای ${
          countTotal - count
        } از آن پرداخت نموده اید. و با اضافه شدن ${count} کشور جدید به سبد خرید موافق هستین.`
      ) != true
    )
      return;

    serviceIncrement({
      type: "targetAddress",
      items: target,
      lcs: window.lcs,
      lang: window.lang,
      accepted: curCom?.accepted,
      currency: curCom?.currency,
      currency_symbol: curCom?.currency_symbol,
      convertRate: curCom?.convertRate,
      exRate: curCom?.exRate,
      price: Math.ceil(price),
      count,
      _id: formik.values._id,
      title: formik.values.title,
      img: "/img/premium/map-ad-banner.png",
      mainImg: formik.values.img,
      targetIdentifier: "Business",
    });
  }
  messageRef.current.showSuccess({
    message: "نمایش در استانهای دیگر با موفقیت به سبد خریداضافه شد.",
  });

  document.querySelector("#sidebarRightTrigger").click();
}

function IsActive({ isActive }) {
  return <>{isActive && <span style={{ color: "green" }}> (فعال)</span>}</>;
}

function PlansRender({
  formik,
  photoHandle,
  loading,
  resizedPhoto,
  section,
  curCom,
}) {
  // console.log('PlansRender',section)
  // const lcs = window.location.pathname.split("/").slice(1, 4).join("/");
  // const targetCount = formik.values[section]?.targetAddress.reduce((a, c) => a + c.states.length,0);
  const targetCount = formik.values[section]?.targetAddress.reduce(
    (a, c) => a + 1,
    0
  );
  const navigate = useNavigate();
  function navigateHanle({ e, url }) {
    e.preventDefault();
    navigate(url);
  }
  return (
    <form
      onSubmit={formik.handleSubmit}
      name="adsLocPlanRenForm"
      autoComplete="true"
    >
      <div
        className=" collapse my-5 p-md-5 px-1 bg-pure-white border"
        id={section}
      >
        {section === "plansX4" && (
          <PlansX4CardsRender
            formik={formik}
            section={section}
            resizedPhoto={resizedPhoto}
          />
        )}
        {section === "plansX5" && (
          <PlansX5CardsRender
            formik={formik}
            section={section}
            resizedPhoto={resizedPhoto}
          />
        )}
        {section === "plansS1" && (
          <PlansS1CardsRender
            formik={formik}
            section={section}
            resizedPhoto={resizedPhoto}
          />
        )}

        <div className="row ">
          <div className="col-lg-7 px-md-5">
            {/* ******card size */}
            {section === "plansX4" && (
              <PlansX4SizeRender
                formik={formik}
                section={section}
                curCom={curCom}
              />
            )}
            {section === "plansX5" && (
              <PlansX5SizeRender
                formik={formik}
                section={section}
                curCom={curCom}
              />
            )}
            <hr />
            {/* ******card position */}
            {section !== "plansCats" && (
              <PagesRender
                formik={formik}
                section={section}
                lcs={window.lcs}
                navigateHanle={navigateHanle}
              />
            )}

            <hr />
            {section !== "plansCats" && (
              <div className="mt-5">
                <div className="d-flex justify-content-between ">
                  <p className="ad-subtitle mt-0 py-0 mb-3 ">
                    <span>
                      <img
                        src="/img/premium/global.png"
                        className="arrow2 px-1"
                        alt="targetAddress"
                        loading="lazy"
                      />
                    </span>
                    در چه کشور هایی آگهیتون نمایش داده بشه؟
                  </p>
                </div>
                <p className="small-gray-font ">
                  می‌تونید بیشتر از یک انتخاب داشته باشید.
                </p>
                <div className="px-3">
                  <TargetAddress01 formik={formik} section={section} />
                </div>
              </div>
            )}
            <h5
              className="modal-title ad-subtitle mt-5"
              data-bs-toggle="collapse"
              aria-expanded="false"
              href={"#" + section + "Calendar-open"}
            >
              <span className="mt-0 py-0 mb-3 ">
                <img
                  // src="/img/premium/premium.png"
                  src="/img/premium/deadline.png"
                  className="arrow2 me-2"
                  alt="premium"
                  loading="lazy"
                />
              </span>
              زمان انتشار آگهی
            </h5>
            <div id={section + "Calendar-open"} className="my-5">
              <CalendarMy formik={formik} section={section} />
            </div>
          </div>

          <PriceSectionRender
            formik={formik}
            targetCount={targetCount}
            photoHandle={photoHandle}
            loading={loading}
            section={section}
            curCom={curCom}
          />
        </div>
        <p
          className="text-center primary-btn checkout-btn  w-100 mt-5"
          data-bs-toggle="collapse"
          aria-expanded="false"
          href={`#${section}`}
        >
          بستن
        </p>
      </div>
    </form>
  );
}

function PagesRender({ formik, section, navigateHanle }) {
  function plansPagesHandle(e, section) {
    function removePushArray(arr, value) {
      const index = arr.indexOf(value);
      if (index !== -1) {
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }
    }
    formik.setValues((pre) => {
      const pre2 = { ...pre };
      const newArray = pre2[section].pages;

      removePushArray(newArray, e.target.value);
      pre2[section].pages = newArray;
      return pre2;
    });
  }
  return (
    <div key={section}>
      <p className="ad-subtitle mb-1">
        <span>
          <img
            src="/img/premium/web-portal-2.png"
            className="arrow2 me-2"
            alt="web-portal"
            loading="lazy"
          />
        </span>
        در چه صفحه‌ای آگهیتون نمایش داده بشه؟
      </p>

      <p className="small-gray-font ">
        می‌تونید بیشتر از یک انتخاب داشته باشید.
      </p>
      <div className="px-3">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value="home"
            id={`ad-${section}-home`}
            checked={formik.values[section]?.pages?.includes("home")}
            onChange={(e) => plansPagesHandle(e, section)}
          />
          <label
            className="form-check-label d-flex "
            htmlFor={`ad-${section}-home`}
          >
            صفحه خانه
            <a
              className="btn-click ms-auto "
              href={`/${window.lang}#plans-X4`}
              target="_blank"
            >
              مشاهده صفحه
            </a>
          </label>
        </div>
        {section !== "plansX5" && (
          <div>
            <div className="d-flex my-3 justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="busHome"
                  id={`ad-${section}-busHome`}
                  checked={formik.values[section]?.pages?.includes("busHome")}
                  onChange={(e) => plansPagesHandle(e, section)}
                />

                <label
                  className="form-check-label "
                  htmlFor={`ad-${section}-busHome`}
                >
                  صفحه اول مشاغل
                </label>
              </div>

              <a
                className="btn-click ms-auto "
                href={`/${window.lang}/business#plans-X4`}
                target="_blank"
              >
                مشاهده صفحه
              </a>
              {/* <img
          src="/img/premium/web-portal-2.png"
          className="arrow3 ms-auto"
          alt="نگاهی به این صفحه بیندازیم"
        /> */}
            </div>
            <div className="d-flex my-3 justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="busFilters"
                  id={`ad-${section}-busFilters`}
                  checked={formik.values[section]?.pages?.includes(
                    "busFilters"
                  )}
                  onChange={(e) => plansPagesHandle(e, section)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`ad-${section}-busFilters`}
                >
                  صفحه فیلترهای مشاغل
                </label>
              </div>
              <a
                className="btn-click ms-auto "
                href={`/${window.lang}/business/filters#alans-X4`}
                target="_blank"
              >
                مشاهده صفحه
              </a>
            </div>
            <div className="d-flex my-3 justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="blog"
                  id={`ad-${section}-blog`}
                  checked={formik.values[section]?.pages?.includes("blog")}
                  onChange={(e) => plansPagesHandle(e, section)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`ad-${section}-blog`}
                >
                  بلاگ
                </label>
              </div>
              <a
                className="btn-click ms-auto "
                href={`/${window.lang}/blog/filters#plans-X4`}
                target="_blank"
              >
                مشاهده صفحه
              </a>
            </div>
            {/* <div className="d-flex my-3 justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="mediaHome"
                  id={`ad-${section}-mediaHome`}
                  checked={formik.values[section]?.pages?.includes("mediaHome")}
                  onChange={(e) => plansPagesHandle(e, section)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`ad-${section}-mediaHome`}
                >
                  صفحه اول شبکه های اجتماعی
                </label>
              </div>
              <a
                className="btn-click ms-auto "
                href={`/${window.lang}/media#plans-X4`}
                target="_blank"
              >
                مشاهده صفحه
              </a>
            </div> */}
            <div className="d-flex my-3 justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="mediaFilters"
                  id={`ad-${section}-mediaFilters`}
                  checked={formik.values[section]?.pages?.includes(
                    "mediaFilters"
                  )}
                  onChange={(e) => plansPagesHandle(e, section)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`ad-${section}-mediaFilters`}
                >
                  فیلتر های شبکه های اجتماعی
                </label>
              </div>
              <a
                className="btn-click ms-auto "
                href={`/${window.lang}/media/filters#plans-X4`}
                target="_blank"
              >
                مشاهده صفحه
              </a>
            </div>

            <div className="d-flex my-3 justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="hideFilters"
                  id={`ad-${section}-hideFilters`}
                  checked={formik.values[section]?.pages?.includes("hideFilters")}
                  onChange={(e) => plansPagesHandle(e, section)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`ad-${section}-hideFilters`}
                >
                  صفحه کاریابی / گوشه و کنار
                </label>
              </div>
              <a
                className="btn-click ms-auto "
                href={`/${window.lang}/jobs/hide/filters#plans-X4`}
                target="_blank"
              >
                مشاهده صفحه
              </a>
            </div>
            <div className="d-flex my-3 justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="jobsFilters"
                  id={`ad-${section}-jobsFilters`}
                  checked={formik.values[section]?.pages?.includes(
                    "jobsFilters"
                  )}
                  onChange={(e) => plansPagesHandle(e, section)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`ad-${section}-jobsFilters`}
                >
                  فیلترهای کاریابی
                </label>
              </div>
              <a
                className="btn-click ms-auto "
                href={`/${window.lang}/jobs/filters#plans-X4`}
                target="_blank"
              >
                مشاهده صفحه
              </a>
            </div>
            <div className="d-flex my-3 justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="mpHome"
                  id={`ad-${section}-mpHome`}
                  checked={formik.values[section]?.pages?.includes(
                    "mpHome"
                  )}
                  onChange={(e) => plansPagesHandle(e, section)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`ad-${section}-mpHome`}
                >
                  صفحه اصلی نیازمندی‌ها
                </label>
              </div>
              <a
                className="btn-click ms-auto "
                href={`/${window.lang}/mp#plans-X4`}
                target="_blank"
              >
                مشاهده صفحه
              </a>
            </div>
            <div className="d-flex my-3 justify-content-between">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="usedFilters"
                  id={`ad-${section}-usedFilters`}
                  checked={formik.values[section]?.pages?.includes(
                    "usedFilters"
                  )}
                  onChange={(e) => plansPagesHandle(e, section)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`ad-${section}-usedFilters`}
                >
                  صفحه کی‌چی‌کجا
                </label>
              </div>
              <a
                className="btn-click ms-auto "
                href={`/${window.lang}/mp/used/filters#plans-X4`}
                target="_blank"
              >
                مشاهده صفحه
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
function PriceSectionRender({
  formik,
  targetCount,
  photoHandle,
  loading,
  section,
  curCom,
}) {
  return (
    <div key={section} className="col-lg-5 bg-body p-md-5 ">
      <div className="d-flex justify-content-center font-20">
        <p className="ad-subtitle mt-0 py-0 mb-3 ">
          <span>
            <img
              src="/img/premium/global.png"
              className="arrow2 px-1"
              alt="targetAddress"
              loading="lazy"
            />
          </span>
          هزینه آگهی
        </p>
      </div>
      <div className=" d-flex justify-content-center  mt-3 mb-5">
        {/*******price */}
        <div
          dir="ltr"
          className="d-flex flex-column justify-content-center align-items-center "
        >
          <div className="d-flex">
            <p className="ad-box-title">اندازه</p>
            <p className="ad-box-title"> صفحه </p>
            <p className="ad-box-title"> کشور</p>
            <p className="ad-box-title">ماه</p>
          </div>
          <div className="d-flex">
            <p className="ad-no-box">
              {formik.values?.[section]?.price *
                curCom.exRate *
                curCom.convertRate}
              {/* {(
                formik.values?.[section]?.price *
                curCom.exRate *
                curCom.convertRate
              ).toLocaleString(window.lang)} */}
            </p>
            <p className="m-0">X</p>
            <p className="ad-no-box">
              {formik.values?.[section]?.pages.length}{" "}
            </p>{" "}
            <p>X</p>
            <p className="ad-no-box"> {targetCount}</p> <p>X</p>
            <p className="ad-no-box">
              {formik.values[section]?.yearMonth.length}
            </p>
          </div>
          <div className="btn primary-btn checkout-btn cart-footer ad-no-total font-20 mt-3">
            {tools.showPrice(
              formik.values[section]?.price *
                formik.values[section]?.pages.length *
                targetCount *
                formik.values[section]?.yearMonth.length,
              curCom?.currency,0
            )}
            {/* {curCom?.showNumber(
              formik.values[section]?.price *
                formik.values[section]?.pages.length *
                targetCount *
                formik.values[section]?.yearMonth.length
            )} */}
          </div>
        </div>
      </div>
      <hr />

      <div className="ad-planX4-input mx-auto mt-5 ">
        {section !== "plansS1" && section !== "plansCats" && (
          <div>
            {section}
            <div className="mb-3">
              <label className="form-label mt-3 mb-0 " htmlFor="f1">
                عنوان آگهی
                <Tooltip1
                  message=" عنوان دلخواه خود را اینجا وارد کنید!  (مثال: آموزشکاه دانا)"
                  position="top-end"
                />
              </label>

              <input
                type="text"
                autoComplete="off"
                className="form-control py-1 box2 "
                id="f1"
                placeholder="   (مثال: تخفیف تابستانه آموزشگاه دانا)"
                {...formik.getFieldProps(`${section}.title`)}
              />
              <FormikErrRender formik={formik} field={`${section}.title`} />
            </div>
            <div className="mb-3">
              <label className="form-label">
                توضیحات
                <Tooltip1
                  message=" کسب و کار خود و خدماتی که ارایه می‌دهید را با جزییات بیشتر معرفی کنید."
                  position="top-end"
                />
              </label>

              <textarea
                className="form-control"
                rows="3"
                placeholder="(زیر ۲۰۰ کلمه)"
                {...formik.getFieldProps(`[${section}]description`)}
              ></textarea>
              <FormikErrRender
                formik={formik}
                field={`${section}.description`}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={section + "-formFile-ad"} className="form-label">
                عکس آگهی
                <Tooltip1
                  message=" عکس آگهی خود را انتخاب کنید."
                  position="top-end"
                />
              </label>
              <input
                className="form-control required"
                type="file"
                id={section + "-formFile-ad"}
                onChange={(e) => photoHandle(e, [section])}
                accept="image/jpeg, image/png, image/gif"
              />
            </div>

            <div className="mb-3 mt-3">
              <label className="form-label mt-3  ps-2" htmlFor="f55">
                لینک
                <Tooltip1
                  message="در صورت تمایل لینک وبسایت خود را به صورت کامل وارد کنید."
                  position="top-end"
                />
              </label>
              <a
                tabIndex="0"
                className="btn info-btn shadow-none "
                role="button"
                data-bs-toggle="popover"
                data-bs-trigger="focus"
                data-bs-content="در صورت تمایل لینک وبسایت خود را به صورت کامل وارد کنید."
              ></a>
              <input
                type="text"
                autoComplete="off"
                className=" form-control mb-5 py-1 box2"
                id="f55"
                placeholder="مثال:https://www.metamarce.com/montreal_iranians"
                {...formik.getFieldProps(`${section}.url`)}
              />
              <FormikErrRender formik={formik} field={`${section}.url`} />
            </div>
          </div>
        )}
        <div className="d-flex flex-column justify-content-center align-items-center">
          <button
            type="submit"
            className="btn primary-btn view-cart cart-footer ad-no-total mt-2"
            onClick={(e) =>
              formik.setValues((pre) => ({ ...pre, active: section }))
            }
          >
            {loading[section] ? (
              <CircularProgress color="inherit" size="20px" />
            ) : (
              " افزودن به سبد خرید"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
function PlansX4CardsRender({ formik, resizedPhoto, section }) {
  return (
    <div className="row m-md-5 my-2 g-3">
      <div
        className="col-lg-6"
        onClick={(e) =>
          formik.setValues((pre) => ({
            ...pre,
            [section]: { ...pre[section], size: "plansLg", price: 200 },
          }))
        }
      >
        <div
          className={`card bg-dark text-white card1 ad-image container-ad-sq-img-slide ${
            formik.values[section].size === "plansLg" && "planx4-active"
          }`}
        >
          {/* <a href="#"> */}
          <img
            src={
              (formik.values[section].size === "plansLg" &&
                resizedPhoto?.plansX4) ||
              "/img/premium/planx4-1.png"
            }
            className={`card-img card-img1 w-100 ad-image ad-image1 `}
            alt="Plans X4"
            loading="lazy"
          />
          <div className="card-img-overlay  card-img-overlay-unclaimed ">
            <div>
              {/* <h5 className="card-title card-titel1 mt-3">
                          نمونه کارت
                        </h5> */}

              <p className="card-text overlay-text">
                {formik.values[section].size === "plansLg" &&
                formik.values[section].description.length
                  ? formik.values[section].description
                  : "توضیحات"}
              </p>

              <p className="card-text">
                {formik.values[section].size === "plansLg" &&
                formik.values[section].title.length
                  ? formik.values[section].title
                  : "عنوان آگهی"}
              </p>
            </div>
          </div>
          {/* </a> */}
        </div>
      </div>
      <div className="col-lg-6 ">
        <div className="row g-3">
          <div
            className="col-6"
            onClick={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                [section]: { ...pre[section], size: "plansSm", price: 100 },
              }))
            }
          >
            <div
              className={`card bg-dark text-white card1 ad-image container-ad-sq-img-slide ${
                formik.values[section].size === "plansSm" && "planx4-active"
              }`}
            >
              {/* <a href="#"> */}
              <img
                src={
                  (formik.values[section].size === "plansSm" &&
                    resizedPhoto?.plansX4) ||
                  "/img/premium/planx4-2.png"
                }
                className={`card-img card-img1 w-100 ad-image ad-image2 `}
                alt="planx4-2"
                loading="lazy"
              />
              <div className="card-img-overlay  card-img-overlay-unclaimed ">
                <div>
                  {/* <h5 className="card-title card-titel1 mt-3">
                          نمونه کارت
                        </h5> */}

                  <p className="card-text overlay-text">
                    {formik.values[section].size === "plansSm" &&
                    formik.values[section].description.length
                      ? formik.values[section].description
                      : " توضیحات"}
                  </p>

                  <p className="card-text">
                    {formik.values[section].size === "plansSm" &&
                    formik.values[section].title.length
                      ? formik.values[section].title
                      : "عنوان آگهی"}
                  </p>
                </div>
              </div>
              {/* </a> */}
            </div>
          </div>
          <div
            className="col-6"
            onClick={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                [section]: { ...pre[section], size: "plansSm", price: 100 },
              }))
            }
          >
            <div
              className={`card bg-dark text-white card1 container-ad-sq-img-slide ${
                formik.values[section].size === "plansSm" && "planx4-active"
              }`}
            >
              {/* <a href="#"> */}
              <img
                src="/img/premium/planx4-3.png"
                className={`card-img card-img1 w-100 ad-image ad-image2 `}
                alt="planx4-3"
                loading="lazy"
              />
              <div className="card-img-overlay  card-img-overlay-unclaimed ">
                <div>
                  {/* <h5 className="card-title card-titel1 mt-3">
                          نمونه کارت
                        </h5> */}

                  <p className="card-text overlay-text">
                    سب و کارهای قابل اعتماد ما بپیوندید! محصولات و خدمات
                    استثنایی شما را قدردانی می‌کنیم و می‌خواهیم کسب و کار شما را
                    به عنوان بخشی از دسته‌بندی انحصاری 'کسب و کارهای قابل
                    اعتماد' در وبسایت خود معرف آموزشی
                  </p>

                  <p className="card-text"> جزییات بیشتر</p>
                </div>
              </div>
              {/* </a> */}
            </div>
          </div>
          <div
            className="col-12 mt-2"
            onClick={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                [section]: { ...pre[section], size: "plansMd", price: 150 },
              }))
            }
          >
            <div
              className={`card bg-dark text-white card1 container-ad-2to1-img-slide ${
                formik.values[section].size === "plansMd" && "planx4-active"
              }`}
            >
              {/* <a href="#"> */}
              <img
                src={
                  (formik.values[section].size === "plansMd" &&
                    resizedPhoto?.plansX4) ||
                  "/img/premium/planx4-4.png"
                }
                className={`card-img card-img1 w-100 ad-image ad-image2 `}
                alt="planx4-4"
                loading="lazy"
              />
              <div className="card-img-overlay  card-img-overlay-unclaimed ">
                <div>
                  {/* <h5 className="card-title card-titel1 mt-3">
                          نمونه کارت
                        </h5> */}

                  <p className="card-text overlay-text">
                    {formik.values[section].size === "plansMd" &&
                    formik.values[section].description.length
                      ? formik.values[section].description
                      : " توضیحات"}
                  </p>

                  <p className="card-text">
                    {formik.values[section].size === "plansMd" &&
                    formik.values[section].title.length
                      ? formik.values[section].title
                      : "عنوان آگهی"}
                  </p>
                </div>
              </div>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function PlansX4SizeRender({ formik, curCom, section }) {
  return (
    <div className="w-100 mb-3">
      <p className="ad-subtitle mt-0 py-0 mb-3 d-flex ">
        <span>
          <img
            src="/img/premium/picture.png"
            className="arrow2 px-1"
            alt="targetAddress"
            loading="lazy"
          />
        </span>
        آگهی در چه اندازه‌ای باشه؟
        <p className="ms-auto small-gray-font ">ماهانه برای هر صفحه</p>
      </p>
      <div className="px-3">
        <div className="form-check form-check-px1 my-2">
          <input
            className="form-check-input"
            type="radio"
            name="plansX4"
            id="plansLg"
            value="200"
            checked={formik.values[section].size === "plansLg"}
            onChange={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                [section]: {
                  ...pre[section],
                  size: e.target.id,
                  price: e.target.value,
                },
              }))
            }
          />
          <label className="form-check-label d-flex" htmlFor="plansLg">
            کارت مربعی بزرگ
            {/* <span className="ms-auto"> {curCom?.showNumber(200)}</span> */}
            <span className="ms-auto">
              {" "}
              {tools.showPrice(500, curCom?.currency,0)}
              {/* {tools.dynamicNumber({ number: 200, lang: window.lang })} */}
            </span>
          </label>
        </div>
        <div className="form-check my-2">
          <input
            className="form-check-input"
            type="radio"
            name="plansX4"
            id="plansMd"
            value="150"
            checked={formik.values[section].size === "plansMd"}
            onChange={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                [section]: {
                  ...pre[section],
                  size: e.target.id,
                  price: e.target.value,
                },
              }))
            }
          />
          <label className="form-check-label d-flex" htmlFor="plansMd">
            کارت مستطیلی
            <span className="ms-auto">
              {tools.showPrice(150, curCom?.currency,0)}
            </span>
          </label>
        </div>
        <div className="form-check my-2">
          <input
            className="form-check-input"
            type="radio"
            name="plansX4"
            id="plansSm"
            value="100"
            checked={formik.values[section].size === "plansSm"}
            onChange={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                [section]: {
                  ...pre[section],
                  size: e.target.id,
                  price: e.target.value,
                },
              }))
            }
          />
          <label className="form-check-label d-flex" htmlFor="plansSm">
            یکی از دو کارت مربعی کوچک
            <span className="ms-auto">
              {tools.showPrice(100, curCom?.currency,0)}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}
function PlansX5SizeRender({ formik, curCom, section }) {
  return (
    <div className="w-100 mb-3 ">
      <p className="ad-subtitle mt-0 py-0 mb-3 d-flex ">
        <span>
          <img
            src="/img/premium/picture.png"
            className="arrow2 px-1"
            alt="targetAddress"
            loading="lazy"
          />
        </span>
        آگهی در چه اندازه‌ای باشه؟
        <p className="ms-auto small-gray-font ">ماهانه برای هر صفحه</p>
      </p>
      <div className="px-3">
        <div className="form-check form-check-px1 my-2">
          <input
            className="form-check-input"
            type="radio"
            name="plansX5"
            id="plansX5Lg"
            value="500"
            checked={formik.values[section].size === "plansLg"}
            onChange={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                [section]: {
                  ...pre[section],
                  size: "plansLg",
                  price: e.target.value,
                },
              }))
            }
          />
          <label className="form-check-label d-flex" htmlFor="plansX5Lg">
            یکی از دو کارت بالا
            <span className="ms-auto">
              {" "}
              {tools.showPrice(500, curCom?.currency,0)}
            </span>
          </label>
        </div>
        <div className="form-check my-2">
          <input
            className="form-check-input"
            type="radio"
            name="plansX5"
            id="plansX5Md"
            value="350"
            checked={formik.values[section].size === "plansMd"}
            onChange={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                [section]: {
                  ...pre[section],
                  size: "plansMd",
                  price: e.target.value,
                },
              }))
            }
          />
          <label className="form-check-label d-flex" htmlFor="plansX5Md">
            یکی از سه کارت پایین
            <span className="ms-auto">
              {" "}
              {tools.showPrice(350, curCom?.currency,0)}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}

function PlansX5CardsRender({ formik, resizedPhoto, section }) {
  return (
    <div className="row gx-0">
      <div
        className="col-lg-6"
        onClick={(e) =>
          formik.setValues((pre) => ({
            ...pre,
            [section]: { ...pre[section], size: "plansLg", price: 500 },
          }))
        }
      >
        <a
          className={`portfolio-item container-bus-big-img-slide ${
            formik.values.size === "plansLg" && "planx4-active"
          }`}
          href="#!"
        >
          <div className="caption">
            <div className="caption-content">
              <div className="h2">عنوان آگهی </div>
              <p className="mb-0">توضیحات1</p>
            </div>
          </div>
          <img
            className="img-fluid"
            src="/img/premium/planx4-1.png"
            alt="PlansX5"
            loading="lazy"
          />
        </a>
      </div>
      <div
        className="col-lg-6"
        onClick={(e) =>
          formik.setValues((pre) => ({
            ...pre,
            [section]: { ...pre[section], size: "plansLg", price: 500 },
          }))
        }
      >
        <a
          className={`portfolio-item container-bus-big-img-slide ${
            formik.values.size === "plansLg" && "planx4-active"
          }`}
          href="#!"
        >
          <div className="caption">
            <div className="caption-content">
              <div className="h2">عنوان آگهی 2</div>
              <p className="mb-0">توضیحات 2</p>
            </div>
          </div>
          <img
            className="img-fluid "
            src={
              (formik.values.size === "plansLg" && resizedPhoto?.plansX5) ||
              "/img/premium/planx4-2.png"
            }
            alt="PlansX5"
            loading="lazy"
          />
        </a>
      </div>
      <div
        className="col-lg-4"
        onClick={(e) =>
          formik.setValues((pre) => ({
            ...pre,
            [section]: { ...pre[section], size: "plansMd", price: 350 },
          }))
        }
      >
        <a
          className={`portfolio-item container-bus-big-img-slide ${
            formik.values.size === "plansMd" && "planx4-active"
          }`}
          href="#!"
        >
          <div className="caption">
            <div className="caption-content">
              <div className="h2">عنوان آگهی 3</div>
              <p className="mb-0">توضیحات3</p>
            </div>
          </div>
          <img
            className="img-fluid"
            src={
              (formik.values.size === "plansMd" && resizedPhoto?.plansX5) ||
              "/img/premium/planx4-3.png"
            }
            alt="planx4-1"
            loading="lazy"
          />
        </a>
      </div>
      <div
        className="col-lg-4"
        onClick={(e) =>
          formik.setValues((pre) => ({
            ...pre,
            [section]: { ...pre[section], size: "plansMd", price: 350 },
          }))
        }
      >
        <a
          className={`portfolio-item container-bus-big-img-slide ${
            formik.values.size === "plansMd" && "planx4-active"
          }`}
          href="#!"
        >
          <div className="caption">
            <div className="caption-content">
              <div className="h2">عنوان آگهی 4</div>
              <p className="mb-0">4توضیحات</p>
            </div>
          </div>
          <img
            className="img-fluid"
            src="/img/premium/planx4-4.png"
            alt="planx5-1"
            loading="lazy"
          />
        </a>
      </div>
      <div
        className="col-lg-4"
        onClick={(e) =>
          formik.setValues((pre) => ({
            ...pre,
            [section]: { ...pre[section], size: "plansMd", price: 350 },
          }))
        }
      >
        <a
          className={`portfolio-item container-bus-big-img-slide ${
            formik.values.size === "plansMd" && "planx4-active"
          }`}
          href="#!"
        >
          <div className="caption">
            <div className="caption-content">
              <div className="h2">عنون آگهی 5</div>
              <p className="mb-0">توضیحات 5</p>
            </div>
          </div>
          <img
            className="img-fluid"
            src="https://www.metamarce.com/uploads/images/blog/2023-12-11T21-25-04.554Z-man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg"
            alt="planx5-2"
            loading="lazy"
          />
        </a>
      </div>

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
function PlansS1CardsRender({ formik }) {
  // console.log('PlansS1CardsRender')
  return (
    <div>
      <div className="col-lg-5 d-flex">
        <img
          src="/img/premium/s1-Metamarce.png"
          className="  w-100 my-auto"
          alt="premium"
          loading="lazy"
        />
      </div>
    </div>
  );
}
function addVideoHandle({
  e,
  formik,
  messageRef,
  serviceIncrement,
  curCom,
  serviceItems,
  checkCurrencies,
}) {
  e.preventDefault();
  if (serviceItems.length > 0 && checkCurrencies()) return;

  console.log("serviceItems", serviceItems);
  if (!formik.values._id)
    return messageRef.current.showWarning({
      message: " کسب و کار را انتخاب کنید.",
    });

  if (formik.values.video.youtube?.length <= 0)
    return messageRef.current.showWarning({
      message: " ویدیو را انتخاب کنید.",
    });

  serviceIncrement({
    type: "video",
    items: [formik.values.video],
    lcs: window.lcs,
    lang: window.lang,
    accepted: curCom?.accepted,
    currency: curCom?.currency,
    currency_symbol: curCom?.currency_symbol,
    convertRate: curCom?.convertRate,
    exRate: curCom?.exRate,
    price: Math.ceil(50 * curCom?.exRate * curCom?.convertRate),
    count: 1,
    _id: formik.values._id,
    title: formik.values.title,
    img: "/img/Images/Press-pause-bro.svg",
    itemImg: formik.values.img,
    targetIdentifier: "Business",
  });
  messageRef.current.showSuccess({
    message: "نمایش ویدئو با موفقیت در سبد خریداضافه شد.",
  });
  document.querySelector("#sidebarRightTrigger").click();
}

function generateYoutubeIframe(videoLink) {
  const videoIdMatch = videoLink.match(/(?:v=|v\/|embed\/|youtu\.be\/)(.{11})/);
  if (videoIdMatch) {
    const videoId = videoIdMatch[1];
    const iframeSrc = `https://www.youtube.com/embed/${videoId}`;

    return (
      <iframe
        width="560"
        height="315"
        src={iframeSrc}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen="allowFullScreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
      ></iframe>
    );
  }

  return null; // Return null if video ID couldn't be extracted
}
function loginChecker2(e) {
  if (!window.logined(9999)) return;

  const collapseButton = e.currentTarget;
  const collapseTarget = document.querySelector(
    collapseButton.getAttribute("href")
  );

  const bsCollapse = new window.bootstrap.Collapse(collapseTarget, {
    toggle: false, // This prevents automatic toggling
  });

  // Check if the collapse is currently shown
  if (collapseTarget.classList.contains("show")) {
    bsCollapse.hide(); // Collapse the element
    collapseButton.setAttribute("aria-expanded", "false");
  } else {
    bsCollapse.show(); // Show the element
    collapseButton.setAttribute("aria-expanded", "true");
  }
}

function loginChecker(e) {
  if (!window.logined(9999)) return;

  const collapseButton = e.currentTarget;
  const arrowIcon = collapseButton.querySelector(".arrow-icon");
  const collapseTarget = document.querySelector(collapseButton.getAttribute("href"));
  const toggleText = collapseButton.querySelector(".toggle-text");
console.log(arrowIcon,toggleText,);


  const bsCollapse = new window.bootstrap.Collapse(collapseTarget, {
    toggle: false, // Prevent automatic toggling
  });

  // Check if the collapse is currently shown
  if (collapseTarget.classList.contains("show")) {
    bsCollapse.hide(); // Collapse the element
    collapseButton.setAttribute("aria-expanded", "false");

    // Change the icon to arrow-up when collapsed
    arrowIcon.classList.remove("bi-arrow-down-circle-fill");
    arrowIcon.classList.add("bi-arrow-up-circle-fill");
    toggleText.textContent = "بیشتر";
  } else {
    bsCollapse.show(); // Show the element
    collapseButton.setAttribute("aria-expanded", "true");

    // Change the icon to arrow-down when expanded
    arrowIcon.classList.remove("bi-arrow-up-circle-fill");
    arrowIcon.classList.add("bi-arrow-down-circle-fill");
    toggleText.textContent = "بستن"; 
  }
}

