import "./user.css";
import {
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
  ArrowBackIos,
  CheckCircle,
  Error,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../../../contexts/cartContext";
import apiUsers from "../../../../../../utils/apiUsers";
import { isValidPhoneNumber } from "react-phone-number-input";
import ToastNote from "../../../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import { CircularProgress } from "@mui/material";
// import Tooltip1 from "../../../../../golbal/tooltip/tooltip";
import FormikErrRender from "../../../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../../../persian/common/alerts/addErrClass";
import api from "../../../../../../utils/api";
import tools from "../../../../../../utils/tools";
import MetaDecorator from "../../../../../../utils/metaDecorator";
import FormikListErr from "../../../../../persian/common/alerts/formikListErr";

export default function UserProfile(props) {
  console.log("props", props);
  const effectRan = useRef(false);
  const messageRef = useRef(null);
  const { user, signedUser } = useContext(CartContext);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  const [resisedPhoto, setResisedPhoto] = useState(null);
  const [hasEmail, setHasEmail] = useState(false);
  const [storedUser, setStoredUser] = useState(null);
  const [loading, setLoading] = useState({ spinner: false, sendPhoto: false });
  const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));
  const sendFileHandle = async (file) => {
    if (file == undefined) return;
    setLoading((pre) => ({ ...pre, sendPhoto: true }));
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    // console.log(imageRef);
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    const getUrl = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/profile",
    });
    if (getUrl.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div>
            <div>{getUrl.error}</div>
          </div>
        ),
      });
    const response = await apiUsers.setUserPhoto(
      formik.values._id,
      user.token,
      getUrl.data
    );
    setLoading((pre) => ({ ...pre, sendPhoto: false }));

    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div dir="ltr">{response.error}</div>
          </div>
        ),
      });
    setResisedPhoto(null);
    messageRef.current.showSuccess({
      message: "عکس با موفقیت به روز رسانی شد.",
    });
    dataInitializer(response.data);

    // for updating sing Info in Navbar
    if (!props?.user) {
      console.log(4);
      setLoading((pre) => ({ ...pre, spinner: true }));
      const userSuccess = await api.getUser();
      console.log("userSuccess", userSuccess);
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (userSuccess.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      // console.log(userSuccess.data);
      signedUser(userSuccess.data);
    }
  };

  const photoHandle = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " از فایل با فرمت عکس استفاده کنید.",
      });
    setResisedPhoto(
      await tools.resizeFile({
        file: e.target.files[0],
        width: 500,
        height: 500,
      })
    );
    setOriginalPhoto(e.target.files[0]);
  };

  useEffect(() => {
    if (effectRan.current === false) {
      !props.user && fetchUserInfo();

      effectRan.current = true;
    }
  }, [window.location.pathname]);

  async function fetchUserInfo() {
    setLoading({ ...loading, spinner: true });
    const response = await apiUsers.getUserInfo(user._id, user.token);
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });

    dataInitializer(response.data);
    setStoredUser(response.data);
  }

  const lowercaseRegex = /(?=.*[a-z])/;
  const uppercaseRegex = /(?=.*[A-Z])/;
  const numericRegex = /(?=.*[0-9])/;
  const specialcharRegex = /(?=.*[$&+,:;=?@#|'<>.^*()%!-])/;
  const englishRegex = /^(?=.*[A-Za-z])[A-Za-z0-9#]+$/;
  const combinedRegex = /^[A-Za-z0-9# $&+,:;=?@|'<>.^*()%!-]+$/;
  const passChangeFormik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      console.log(passChangeFormik);

      const response = await api.passChange(
        values.password,
        values.newPassword,
        user.token
      );

      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });

      messageRef.current.showSuccess({
        message: "کلمه عبور با موفقیت به روز رسانی شد.",
      });
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "کلمه عبور حداقل باید شامل 8 حرف باشد.")
        .required("کلمه عبور فعلی خود را وارد کنید! "),
      newPassword: Yup.string()
        .required(" کلمه عبور را وارد کنید.")
        .matches(numericRegex, "حداقل یک عدد")
        .matches(combinedRegex, " از حروف انگلیسی استفاده کنید")
        .min(8, "کلمه عبور حداقل باید شامل 8 حرف باشد."),
      // .matches(lowercaseRegex, "حداقل یک حرف کوچک !")
      // .matches(uppercaseRegex, "حداقل یک حرف بزرگ")
      // .matches(specialcharRegex, "حداقل یک کاراکتر ویژه!")
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "کلمه عبور همخوانی ندارد!")
        .required("الزامی!"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      _id: props?.user?._id || "",
      hasEmail: false,
      birthDate: props?.user?.moreInfo?.birthDate?.slice(0, 10) || "",
      email: props?.user?.email || "",
      userName: props?.user?.userName || "",
      firstName: props?.user?.firstName || "",
      lastName: props?.user?.lastName || "",
      phoneNumber: props?.user?.moreInfo?.phoneNumber || "",
      jobTitle: props?.user?.jobTitle || "",
      thumbnail: props?.user?.thumbnail || "",
      showOnline: props?.user?.showOnline || true,
      marketerInfo: props?.user?.marketerInfo || {},

      location: {
        displayAddress: true,
        country: "",
        countryShort: "",
        state: "",
        stateShort: "",
        city: "",
        route: "",
        municipality: "",
        streetNumber: "",
        number: "",
        postalCode: "",
        googleAddress: "",
        lat: "",
        lng: "",
      },
      bankInfo: {
        confirmed: false,
        firstName: "",
        lastName: "",
        paymentMethod: "paypal",
        email: user?.email || "",
        accountNumber: "",
        institution: "",
        currency: countryDetails.currency,
        exRate: countryDetails.exRate,
      },
      affiliateInfo: props?.user?.affiliateInfo || {
        code: "",
        percent: 0,
      },
    },
    onSubmit: async (values) => {
      console.log("Submit", values);

      setLoading({ ...loading, spinner: true });
      const response = await apiUsers.setUserInfo({
        token: user.token,
        userInfo: values,
      });
      setLoading({ ...loading, spinner: false });
      console.log("response", response);
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>
                {response.error.startsWith("E11000")
                  ? "این شماره تلفن قبلا رزو شده است.  از طریق بازیابی کلمه عبور اقدام کنید."
                  : response.error}
              </div>
            </div>
          ),
        });

      messageRef.current.showSuccess({
        message: "پروفایل با موفقیت به روز رسانی شد.",
      });
      dataInitializer(response.data);
      if (!props.user) {
        setLoading({ ...loading, spinner: true });
        const userSuccess = await api.getUser();
        setLoading({ ...loading, spinner: false });
        if (userSuccess.error)
          return messageRef.current.showError({
            message: (
              <div>
                <div> مشکل در انجام عملیات !</div>
                <div>{response.error}</div>
              </div>
            ),
          });
        console.log(userSuccess.data);
        signedUser(userSuccess.data);
      }
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .lowercase()
        .when("hasEmail", (hasEmail, schema, value) => {
          const val = value.parent;
          console.log(!val.hasEmail && val.email?.length > 0, "val", val);
          console.log("hasEmail", hasEmail);
          return !val.hasEmail && val.email?.length > 0
            ? schema
                ?.required("لطفا ایمیل خود را وارد نمائید.")
                ?.trim()
                ?.lowercase()
                ?.email("ایمیل را به درستی وارد کنید.")
            : schema?.notRequired();
        }),

      userName: Yup.string()
        .min(3, "نام کاربری باید حداقل 3 حرف باشد.")
        .max(30, "نام کاربری باید حداکثر 30 حرف باشد.")
        .trim()
        .required(),
      firstName: Yup.string().max(30, "نام باید حداکثر 30 حرف باشد").trim(),
      lastName: Yup.string()
        .max(30, "نام خانوادگی باید حداکثر 30 حرف باشد")
        .trim(),
      birthDate: Yup.date(),
      // phoneNumber: Yup.string().matches(
      //   phoneRegExp,
      //   "Phone number is not valid"
      // ),
      phoneNumber: Yup.string().test(
        "is-valid-phone",
        "شماره تلفن نامعتبر میباشد.",
        (value) => {
          if (!value?.length) return true;
          return isValidPhoneNumber(value || "");
        }
      ),
      bankInfo: Yup.object().shape({
        confirmed: Yup.boolean(),
        firstName: Yup.string().when("confirmed", {
          is: true,
          then: Yup.string()
            .required("نام نمیتواند خالی باشد.")
            .min(3, "نام حداقل باید 3 حرف باشد.")
            .max(30, "نام حداکثر باید 30 حرف باشد."),
        }),
        lastName: Yup.string().when("confirmed", {
          is: true,
          then: Yup.string()
            .required("نام خانوادگی نمیتواند خالی باشد.")
            .min(3, "نام خانوادگی حداقل باید 3 حرف باشد.")
            .max(30, "نام خانوادگی حداکثر باید 30 حرف باشد."),
        }),
        email: Yup.string().when(["confirmed", "paymentMethod"], {
          is: (confirmed, paymentMethod) =>
            paymentMethod === "paypal" && confirmed === true,
          then: Yup.string()
            .required("لطفا ایمیل خود را وارد نمائید.")
            .trim()
            .lowercase()
            .email("ایمیل را به درستی وارد کنید."),
        }),
        paymentMethod: Yup.string().required(
          "لطفا روش پرداخت را انتخاب نمائید."
        ),
        accountNumber: Yup.string().when(["confirmed", "paymentMethod"], {
          is: (confirmed, paymentMethod) =>
            paymentMethod === "sheba" && confirmed === true,
          then: Yup.string().required("شماره شبای خود را وارد نمائید."),
        }),
        institution: Yup.string().when(["confirmed", "paymentMethod"], {
          is: (confirmed, paymentMethod) =>
            paymentMethod === "sheba" && confirmed === true,
          then: Yup.string().required("نام بانک خود را وارد نمائید."),
        }),
      }),
    }),
  });
  // console.log("formik error", formik.errors);
  // console.log("formik ", formik.values);
  const metaTagsData = {
    title: "متامارس | داشبورد | مشاهده پروفایل ",
    description:
      "مشاهده پروفایل | به روز رسانی اطلاعات کاربری | به روز رسانی عکس پروفایل | به روز رسانی رمز عبور",
    section: "oreders list",
    url: `https://www.metamarce.com/${window.lang}/dashboard/user/profile/me`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };
  return (
    <div className="user mx-md-5 mt-3">
      <MetaDecorator data={{ ...metaTagsData }} />

      <ToastNote messageRef={messageRef} />

      {loading.spinner && <ColoredSpiner />}
      <div className="userTitleContainer ">
        {/* <Link to="/dashboard/newuser"><button className="userAddButton">Create</button></Link> */}
      </div>

      <div className="row ">
        <div className="col">
          <p className="divider2 mt-2 ">
             
            <svg
              className="text-dark-550 dark:text-white ml-2"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
              <circle
                cx="35"
                cy="16"
                r="8"
                fill="currentColor"
                fillOpacity="0.4"
              ></circle>
              <circle
                cx="20"
                cy="8"
                r="5"
                fill="currentColor"
                fillOpacity="0.7"
              ></circle>
            </svg>
            <span className="p-0 m-z ">پروفایل من</span>
          </p>
        </div>
      </div>
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          className="nav-link active"
          id="nav-myProfile-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-myProfile"
          type="button"
          role="tab"
          aria-controls="nav-myProfile"
          aria-selected="true"
        >
          پروفایل من
        </button>
        {/* <button className="nav-link" id="nav-student-tab" data-bs-toggle="tab" data-bs-target="#nav-student" type="button" role="tab" aria-controls="nav-student" aria-selected="false"> ویرایش کلمه عبور</button> */}
        <button
          className="nav-link"
          id="nav-bankAcc-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-bankAcc"
          type="button"
          role="tab"
          aria-controls="nav-bankAcc"
          aria-selected="false"
        >
          حساب بانکی
        </button>
      </div>
      <div className="tab-content mt-5" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-myProfile"
          role="tabpanel"
          aria-labelledby="nav-myProfile-tab"
        >
          <div className="row userShow">
            <div className="col-md-4">
              <div className="d-flex flex-column userShowTop align-items-center ">
                <div className="userUpdateUpload d-flex flex-column justify-content-center">
                  <img
                    src={
                      (resisedPhoto !== null && resisedPhoto) ||
                      formik.values?.thumbnail
                    }
                    alt={formik.values.name}
                    className="userShowImg-profile"
                    loading="lazy"
                  />

                  <label className="my-2" htmlFor="file">
                    <Publish className="userUpdateIcon" />
                    تغییر عکس
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={photoHandle}
                    style={{ display: "none" }}
                  />

                  <button
                    className="userUpdateButton edit-btn"
                    style={{ display: resisedPhoto ? "block" : "none" }}
                    onClick={(e) => sendFileHandle(originalPhoto)}
                    disabled={loading.sendPhoto}
                  >
                    {loading.sendPhoto ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : (
                      "بارگذاری"
                    )}
                  </button>
                </div>

                <div
                  className="userShowTopTitle  mt-5"
                  title="نام و نام خانوادگی"
                >
                  <span className="userShowUsername">
                    {formik.values.firstName}|{formik.values.lastName}
                  </span>
                  <span className="userShowUserTitle" title="عنوان شغلی">
                    {formik.values.jobTitle}
                  </span>
                </div>
              </div>
              <div className="form-check form-switch mt-3 py-0 px-1">
                <input
                  className="form-check-input form-check-input-filter1  m-0 p-0 cursor"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={formik.values?.showOnline}
                  onChange={(e) =>
                    formik.setValues((prev) => ({
                      ...prev,
                      showOnline: !prev.showOnline,
                    }))
                  }
                />
                <label
                  className="form-check-label px-2 mt-0 cursor"
                  htmlFor="flexSwitchCheckChecked"
                >
                  نمایش آنلاین در متاچت
                </label>
              </div>
            </div>

            <div className="col-md-8 ps-5 ">
              <div className="userShowBottom ps-3">
                <span className="userShowTitle  mt-3">جزییات حساب کاربری</span>
                <div className="userShowInfo " title="نام کاربری">
                  <PermIdentity className="userShowIcon" />
                  <span className="userShowInfoTitle">
                    {formik.values?.userName}
                  </span>
                </div>
                {/* <div className="userShowInfo" title="تاریخ تولد">
              <CalendarToday className="userShowIcon" />
              <span className="userShowInfoTitle">
                {formik.values.birthDate}
              </span>
            </div> */}
                <div className="affiliateCode" title="Affiliation Link">
                  <i class="bi bi-share userShowIcon"></i>
                  <span className="userShowInfoTitle">
                    {formik.values?.affiliateInfo?.code.length > 10 && (
                      <a
                        href={`https://www.metamarce.com?affilate=${formik.values.affiliateInfo.code}`}
                        target="_blank"
                      >
                        لینک معرفی اختصاصی شما
                      </a>
                    )}
                  </span>
                </div>
                <div className="userShowTitle  mt-5">اطلاعات تماس</div>
                <div dir="ltr" className="userShowInfo" title="شماره تلفن">
                  <PhoneAndroid className="userShowIcon" />
                  <span className="userShowInfoTitle">
                    {tools.formatPhoneNumber(formik.values.phoneNumber)}
                  </span>
                </div>
                <div dir="ltr" className="userShowInfo">
                  <MailOutline className="userShowIcon" title="آدرس ایمیل" />
                  <span className="userShowInfoTitle">
                    {formik.values.email}
                  </span>
                </div>
                <div dir="ltr" className="userShowInfo">
                  <LocationSearching className="userShowIcon" />

                  <span className="userShowInfoTitle">
                    {formik.values.location && (
                      <>
                        {formik.values.location.number && (
                          <>{formik.values.location.number + ", "}</>
                        )}
                        {formik.values.location.streetNumber && (
                          <>{formik.values.location.streetNumber + ", "}</>
                        )}
                        {formik.values.location.city && (
                          <>{formik.values.location.city + ", "}</>
                        )}
                        {formik.values.location.state && (
                          <>{formik.values.location.state + ", "}</>
                        )}
                        {formik.values.location.country && (
                          <>{formik.values.location.country + ", "}</>
                        )}
                        {formik.values.location.postalCode && (
                          <>{formik.values.location.postalCode}</>
                        )}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="  mx-5  passwordChange text-md-start text-center ">
            <input type="checkbox" id="passwordChangeBtn" />
            <label
              className="login-btn login-btn2 "
              style={{ width: 240 }}
              htmlFor="passwordChangeBtn"
            >
              ویرایش کلمه عبور من{" "}
              <ArrowBackIos className="arrowForwardIcon ms-4" />
            </label>

            <form
              onSubmit={passChangeFormik.handleSubmit}
              className="passwordChangeForm col-md-6 text-start"
              name="editPassForm"
              autoComplete="true"
            >
              <div className=" form-floating  mt-2 py-1">
                <input
                  type="text"
                  autoComplete="off"
                  name="password"
                  className="form-control box1 border-0"
                  id="password"
                  placeholder="  "
                  {...passChangeFormik.getFieldProps("password")}
                />
                <label htmlFor="password" className="">
                  <i className="bi bi-lock-fill"></i> کلمه عبور فعلی
                </label>
                <FormikErrRender formik={passChangeFormik} field="password" />
              </div>
              <div className=" form-floating  mt-2 py-2">
                <input
                  type="text"
                  autoComplete="off"
                  name="password"
                  className="form-control box1 border-0"
                  placeholder=" "
                  id="newPassword"
                  {...passChangeFormik.getFieldProps("newPassword")}
                />
                <label htmlFor="newPassword" className="">
                  <i className="bi bi-lock-fill"></i> کلمه عبور جدید
                </label>
                <FormikErrRender
                  formik={passChangeFormik}
                  field="newPassword"
                />
              </div>
              <div className=" form-floating  mt-2 py-2">
                <input
                  type="text"
                  autoComplete="off"
                  name="password"
                  className="form-control box1"
                  placeholder=" "
                  id="confirmPassword"
                  {...passChangeFormik.getFieldProps("confirmPassword")}
                />
                <label htmlFor="confirmPassword" className="">
                  <i className="bi bi-lock-fill"></i> تکرار کلمه عبور
                </label>
                <FormikErrRender
                  formik={passChangeFormik}
                  field="confirmPassword"
                />
              </div>
              <SubmitBtn formik={passChangeFormik} />
            </form>
          </div>

          {/* <div className="userContainer"> */}

          <div className=" mx-5 passwordChange text-md-start text-center  ">
            <input type="checkbox" id="EditprofileBtn" />
            <label
              className="login-btn login-btn2 "
              style={{ width: 240 }}
              htmlFor="EditprofileBtn"
            >
              ویرایش پروفایل من{" "}
              <ArrowBackIos className="arrowForwardIcon ms-4" />
            </label>

            <div className="userUpdate">
              <form
                name="editProfForm01"
                className="userUpdateForm text-start"
                onSubmit={formik.handleSubmit}
                autoComplete="true"
              >
                <div className="userUpdateLeft w-100">
                  {/* <div className="userUpdateTitle">Edit</div> */}
                  <div className="row g-3 ">
                    <div className="col-md-6 px-3">
                      {!formik.values.hasEmail && (
                        <div className="userUpdateItem ">
                          <label className="" htmlFor="email">
                            ایمیل
                          </label>
                          <input
                            id={`email`}
                            {...formik.getFieldProps("email")}
                            className={`userUpdateInput form-control box2 ${addErrClass(
                              { formik, field: "email" }
                            )}`}
                            type="text"
                            autoComplete="true"
                          />
                          <FormikErrRender formik={formik} field="email" />
                        </div>
                      )}
                      <div className="userUpdateItem ">
                        <label className="required ps-3" htmlFor="userName">
                          نام کاربری
                        </label>
                        <input
                          id={`userName`}
                          {...formik.getFieldProps("userName")}
                          className={`userUpdateInput form-control box2 ${addErrClass(
                            { formik, field: "userName" }
                          )}`}
                          type="text"
                          autoComplete="true"
                        />
                        <FormikErrRender formik={formik} field="userName" />
                      </div>

                      <div className="userUpdateItem">
                        <label htmlFor="firstName">نام </label>
                        <input
                          id="firstName"
                          {...formik.getFieldProps("firstName")}
                          className={`userUpdateInput form-control box2 ${addErrClass(
                            { formik, field: "firstName" }
                          )}`}
                          type="text"
                          autoComplete="off"
                        />
                        <FormikErrRender formik={formik} field="firstName" />
                      </div>
                      <div className="userUpdateItem">
                        <label htmlFor="formikLastName">نام خانوادگی</label>
                        <input
                          id="formikLastName"
                          {...formik.getFieldProps("lastName")}
                          className={`userUpdateInput form-control box2  ${addErrClass(
                            { formik, field: "lastName" }
                          )}`}
                          type="text"
                          autoComplete="off"
                        />
                        <FormikErrRender formik={formik} field="lastName" />
                      </div>
                      <div className="userUpdateItem" title="عنوان شغلی">
                        <label htmlFor="formikJobTitle">عنوان شغلی</label>
                        <input
                          id="formikJobTitle"
                          {...formik.getFieldProps("jobTitle")}
                          className={`userUpdateInput form-control box2  ${addErrClass(
                            { formik, field: "jobTitle" }
                          )}`}
                          type="text"
                          autoComplete="off"
                        />
                        <FormikErrRender formik={formik} field="jobTitle" />
                      </div>

                      {/* <div className="userUpdateItem">
                    <label htmlFor="birthDate">تاریخ تولد</label>
                    <input
                      id="birthDate"
                      name="birthDate"
                     
                      {...formik.getFieldProps("birthDate")}
                      className="userUpdateInput form-control box2"
                      type="date"
                      // placeholder={formik.values.birthDate }
                    />
                    {formik.touched.birthDate && formik.errors.birthDate ? (
                      <div>{formik.errors.birthDate}</div>
                    ) : null}
                  </div> */}
                      {!storedUser?.phoneNumber?.length && (
                        <div className="userUpdateItem">
                          <label htmlFor="phoneNumber">شماره تلفن</label>
                          <input
                            dir="ltr"
                            id="phoneNumber"
                            className={`userUpdateInput form-control box2  ${addErrClass(
                              { formik, field: "phoneNumber" }
                            )}`}
                            // onBlur={formik.handleChange}
                            // value={formik.values.phoneNumber}
                            {...formik.getFieldProps("phoneNumber")}
                            type="text"
                            autoComplete="off"
                            placeholder={"+1 514 333 4444"}
                          />
                          <FormikErrRender
                            formik={formik}
                            field="phoneNumber"
                          />
                        </div>
                      )}
                    </div>
                    {/* <div className="col-md-6 px-3">
                  <RegisterAddressPlaces formik={formik} />
                </div> */}
                  </div>
                  <FormikListErr formik={formik} />

                  <SubmitBtn formik={formik} />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="tab-pane fade show"
          id="nav-bankAcc"
          role="tabpanel"
          aria-labelledby="nav-bankAcc-tab"
        >
          <form
            className="userShow bg-beigeYellow mx-0 mx-md-5 "
            onSubmit={formik.handleSubmit}
          >
            <div className="titr7 my-3">اطلاعات پرداخت</div>
            <div className="med-gray-font">
              (تکمیل این قسمت در مرحله ثبت نام الزامی نمی باشد ولی برای تسویه
              حساب به آن نیازاست.)
            </div>
            <div className="d-flex my-5">
              <p className="form-label  titr6 ">نوع پرداخت</p>
              <div className="form-check mx-3">
                <input
                  className="form-check-input px-1"
                  type="radio"
                  id="paymentTypeSheba"
                  value="sheba"
                  name="paymentInformation"
                  checked={formik.values.bankInfo?.paymentMethod === "sheba"}
                  onChange={(e) =>
                    formik.setValues((pre) => ({
                      ...pre,
                      bankInfo: {
                        ...pre.bankInfo,
                        paymentMethod: e.target.value,
                        email: "",
                        currency: "IRR",
                      },
                    }))
                  }
                />
                <label className="form-check-label " for="paymentTypeSheba">
                  شماره شبا
                </label>
              </div>
              <div className="form-check mx-3">
                <input
                  className="form-check-input px-1"
                  type="radio"
                  id="paymentTypePaypal"
                  value="paypal"
                  name="paymentInformation"
                  checked={formik.values.bankInfo?.paymentMethod === "paypal"}
                  onChange={(e) =>
                    formik.setValues((pre) => ({
                      ...pre,
                      bankInfo: {
                        ...pre.bankInfo,
                        paymentMethod: e.target.value,
                        currency: countryDetails.currency,
                        accountNumber: "",
                        institution: "",
                      },
                    }))
                  }
                />
                <label className="form-check-label " for="paymentTypePaypal">
                  Paypal
                </label>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-6">
                <label
                  className={`form-label mt-3 titr6 mb-0 ${
                    formik.values.bankInfo.confirmed ? "required" : ""
                  }`}
                  htmlFor="paymentInfoName"
                >
                  نام
                </label>
                <div className="med-gray-font font-10 my-2">
                  (نام دقیق، به همان صورت که در حساب{" "}
                  {formik.values.bankInfo?.paymentMethod} شماست .)
                </div>
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control py-1 box2 ${addErrClass({
                    formik,
                    field: "bankInfo.firstName",
                  })}`}
                  placeholder=""
                  id="paymentInfoName"
                  {...formik.getFieldProps("bankInfo.firstName")}
                />
                <FormikErrRender formik={formik} field="bankInfo.firstName" />
              </div>

              <div className="col-md-6">
                <label
                  className={`form-label mt-3 titr6 mb-0 ${
                    formik.values.bankInfo.confirmed ? "required" : ""
                  }`}
                  htmlFor="paymentInfoFamily"
                >
                  نام خانوادگی
                </label>
                <div className="med-gray-font font-10 my-2">
                  (نام خانوادگی دقیق، به همان صورت که در حساب{" "}
                  {formik.values.bankInfo?.paymentMethod} شماست .)
                </div>
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control py-1 box2 ${addErrClass({
                    formik,
                    field: "bankInfo.lastName",
                  })}`}
                  placeholder=""
                  id="paymentInfoFamily"
                  {...formik.getFieldProps("bankInfo.lastName")}
                />
                <FormikErrRender formik={formik} field="bankInfo.lastName" />
              </div>
            </div>

            {formik.values.bankInfo?.paymentMethod === "paypal" ? (
              <div>
                <label
                  // className="form-label titr6 mb-0 required"
                  className={`form-label titr6 mb-0 ${
                    formik.values.bankInfo.confirmed ? "required" : ""
                  }`}
                  htmlFor="emailApply"
                >
                  آدرس ایمیل
                </label>
                <div className="med-gray-font font-10 my-2">
                  (ایمیلی که PayPal شما به آن متصل است را در این‌جا وارد کنید .)
                </div>
                <input
                  dir="ltr"
                  id="emailApply"
                  {...formik.getFieldProps("bankInfo.email")}
                  className={`userUpdateInput  form-control box2 ${addErrClass({
                    formik,
                    field: "bankInfo.email",
                  })}`}
                  type="text"
                  autoComplete="off"
                  placeholder={formik.values.email}
                />
                <FormikErrRender formik={formik} field="bankInfo.email" />
              </div>
            ) : (
              <div className="row">
                <div className="col-md-6 mt-auto">
                  <label
                    // className="form-label mt-3 titr6 mb-0 required"
                    className={`form-label mt-3 titr6 mb-0 ${
                      formik.values.bankInfo.confirmed ? "required" : ""
                    }`}
                    htmlFor="sheba"
                  >
                    شماره شبا
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control py-1 box2 ${addErrClass({
                      formik,
                      field: `bankInfo.accountNumber`,
                    })} `}
                    id="sheba"
                    placeholder=""
                    sheba="sheba"
                    {...formik.getFieldProps(`bankInfo.accountNumber`)}
                  />
                  <FormikErrRender
                    formik={formik}
                    field={`bankInfo.accountNumber`}
                  />
                </div>
                <div className="col-md-6 mt-auto">
                  <label
                    // className="form-label mt-3 titr6 mb-0 required"
                    className={`form-label mt-3 titr6 mb-0 ${
                      formik.values.bankInfo.confirmed ? "required" : ""
                    }`}
                    htmlFor="institution"
                  >
                    نام بانک
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control py-1 box2 ${addErrClass({
                      formik,
                      field: `bankInfo.institution`,
                    })} `}
                    id="institution"
                    placeholder=""
                    {...formik.getFieldProps(`bankInfo.institution`)}
                  />
                  <FormikErrRender
                    formik={formik}
                    field={`bankInfo.institution`}
                  />
                </div>
              </div>
            )}
            <div className="mt-5">
              <label htmlFor="confirmedPaymentInfo" className="med-gray-font">
                <span>اطلاعات پرداخت درست می باشد </span>
                <input
                  id="confirmedPaymentInfo"
                  type="checkbox"
                  className="form-check-input mx-2"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "bankInfo.confirmed",
                      !formik.values.bankInfo?.confirmed
                    );
                  }}
                  checked={formik.values.bankInfo?.confirmed}
                />
              </label>
            </div>
            {formik.values.bankInfo.confirmed && (
              <button
                disabled={formik.isSubmitting}
                className="my-1 login-btn login-btn2  "
                type="submit"
              >
                بفرست!
              </button>
            )}
          </form>
        </div>
      </div>
      {/* </div> */}
    </div>
  );

  function SubmitBtn({ text, formik }) {
    const errorKeys = Object.keys(formik.errors);
    // console.log("errorKeys", errorKeys);
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2  "
        style={{
          backgroundColor: errorKeys.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {text || "بفرست!"}
        {errorKeys.length === 0 && <CheckCircle />}
        <span>
          {errorKeys.length > 0 && (
            <Error sx={{ fontSize: 18, color: "red" }} />
          )}
        </span>
      </button>
    );
  }

  function dataInitializer(data) {
    // console.log("data", data);
    formik.setValues({
      location: data?.address,
      birthDate: data.birthDate?.slice(0, 10),
      hobies: data?.hobies,
      showOnline: data?.showOnline,
      email: data?.email,
      hasEmail: data?.email ? data.email : false,
      userName: data?.userName,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phoneNumber: data?.phoneNumber,
      thumbnail: data?.thumbnail,
      jobTitle: data?.jobTitle,
      _id: data._id,
      bankInfo: data.bankInfo,
      affiliateInfo: data.affiliateInfo,
    });
    // if(data.email.length)setHasEmail(true)
  }
}
